import { gql } from "graphql-request";

export const fetchFullPartsByIdsQuery = gql`
  query fetchFullPartsByIds(
    $includePartIds: [ID!]
    $excludeHidden: Boolean
    $page: Int
    $pageSize: Int
    $geographicalRegionId: ID
  ) {
    parts(
      page: $page
      pageSize: $pageSize
      excludeHidden: $excludeHidden
      includePartIds: $includePartIds
      geographicalRegionId: $geographicalRegionId
    ) {
      collection {
        id
        name
        allowCheckout
        description
        partNumber
        displayPrice
        hidePrice
        isSoftware
        weight
        width
        height
        length
        material
        surfaceFinish
        applicableStandards
        ventionComment
        technicalSpecifications
        imagePath
        fullUrl
        shipmentDelayWeeks
        displayPriceRaw {
          amount
          currencySymbol
          currencyCode
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      errors {
        title
        message
      }
    }
  }
`;
