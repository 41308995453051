import React from 'react'
import I18n from '../../../helpers/I18n'

const AddressMontreal: React.FC = () => {
  return (
    <>
      <div>{I18n.t('home.about.location.montreal.address')},</div>
      <div>
        {I18n.t('home.about.location.montreal.city')},{' '}
        {I18n.t('home.about.location.montreal.province')},{' '}
        {I18n.t('home.about.location.montreal.country')}
      </div>
      <div>H4C 1L8</div>
    </>
  )
}

export default AddressMontreal
