import React, { useState, useEffect } from 'react'
import UserService from '../../api/Users/Service'
import InputValidator from '../common/InputValidator'
import { ValidatorForm } from 'react-form-validator-core'
import { useI18nContext } from '../common/providers/I18nProvider'
import I18n from '../../helpers/I18n'
import { EU_AND_UK } from '../common/providers/CurrencyProvider'

const VentionTerms: React.FC<{}> = () => {
  const [showTerms, setShowTerms] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const { localizedPaths } = useI18nContext()

  const checkUserLocation = async () => {
    try {
      const countryCodeResponse = await UserService.checkCountryCode()
      if (EU_AND_UK.includes(countryCodeResponse)) {
        setShowTerms(true)
      } else {
        setShowTerms(false)
      }
    } catch (error) {
      console.log(error, 'error fetching country code')
    }
  }

  useEffect(() => {
    checkUserLocation()
    ValidatorForm.addValidationRule('isChecked', value => {
      return value
    })

    return () => ValidatorForm.removeValidationRule('isChecked')
  }, [])

  return showTerms ? (
    <div className='vention-terms checkbox-grey'>
      <InputValidator
        onChange={event => setIsChecked(event.target.checked)}
        value={isChecked}
        type='checkbox'
        className='checkbox vention-terms-checkbox'
        id='vention-terms-checkbox'
        validators={['isChecked']}
        errorMessages={['You must agree to the terms and conditions to continue']}
      >
        <label className='checkbox-label' htmlFor='vention-terms-checkbox'>
          <span>
            {I18n.t('views.common.form.agreement.prefix')}{' '}
            <a
              className='get-started-terms-link'
              href={localizedPaths.terms_of_use}
              target='_blank'
              rel='noopener noreferrer'
            >
              {I18n.t('views.common.form.agreement.terms')}
            </a>{' '}
            {I18n.t('views.common.form.agreement.and')}{' '}
            <a
              className='get-started-privacy-link'
              href={localizedPaths.privacy_policy}
              target='_blank'
              rel='noopener noreferrer'
            >
              {I18n.t('views.common.form.agreement.policy')}
            </a>
            {I18n.t('views.common.form.agreement.suffix')}
          </span>
        </label>
      </InputValidator>
    </div>
  ) : null
}

export default VentionTerms
