import React from 'react'
import { ProductizedApplication } from '../../api/DesignCategories/Types'

interface Props {
  productizedApplication: ProductizedApplication
}

const ProductizedApplicationBanner: React.FC<Props> = ({ productizedApplication }) => {
  return (
    <div className='productized-application-banner'>
      <img
        className='productized-application-banner-image'
        src={productizedApplication.featured_image_url}
        alt={productizedApplication.url_slug.split('-').join(' ')}
      />
      <div className='productized-application-banner-info'>
        <h2 className='productized-application-banner-heading'>{productizedApplication.label}</h2>
        {productizedApplication.banner_description && (
          <p className='productized-application-banner-description'>
            {productizedApplication.banner_description}
          </p>
        )}
        <div className='productized-application-banner-cta-wrapper'>
          <a className='button button-info button-lg' href={`/${productizedApplication.url_slug}`}>
            Learn more
          </a>
          {productizedApplication.youtube_id && (
            <a
              className='productized-application-banner-watch-button'
              href={`http://youtube.com/watch?v=${productizedApplication.youtube_id}`}
            >
              Watch the video <i className='fas fa-play-circle'></i>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductizedApplicationBanner
