import React from 'react'
import BlogPostCard from '../../../../../../../components/vention_ui/marketing_website_components/BlogPostCard'
import SectionLead from '../../../../shared_components/SectionLead'

interface Props {
  contentfulEntry: any
}

const EducationalResources: React.FC<Props> = ({ contentfulEntry }) => {
  return (
    <section className='master-palletizer-landing-page__educational-resources section--spacing-9xl container-lg'>
      <SectionLead align='center' maxWidth='1000px' subHeading {...contentfulEntry.educationalResourcesSectionLead} />
      <div className='master-palletizer-landing-page__educational-resources-cards section--grid-3fr-spacing-4xl'>
        {contentfulEntry.educationalResourcesSectionBlogs?.map((blogProps, index) => (
          <BlogPostCard
            key={index}
            link={blogProps.link}
            image={blogProps.image.file.url}
            supertitle={blogProps.supertitle}
            title={blogProps.title}
            author={blogProps.author}
            date={blogProps.date}
          />
        ))}
      </div>
    </section>
  )
}

export default EducationalResources
