import { useState, useEffect } from 'react'

interface UseWindowSize {
  readonly width: number | undefined
  readonly height: number | undefined
}

export function useWindowSize(): UseWindowSize {
  const isClient = typeof window === 'object'

  function getSize(): UseWindowSize {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize(): void {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
