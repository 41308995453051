import React from 'react'
import ApplicationsList from '../../components/users/user_dashboard/public_team/components/ApplicationsList'
import DesignCard from '../../components/designs/DesignCard'
import { BadgeTeam } from '../../api/Badge/Types'
import { PublicTeam } from '../../api/Teams/Types'
import { buildSimpleBreadcrumb } from '../../components/common/Breadcrumbs'

export interface CertifiedSystemIntegratorProfilePageProps {
  team: PublicTeam
}

const DEFAULT_BANNER =
  'https://assets.vention.io/page-assets/public-company-page/default-csi-banner.jpg'

function getBadge(badges: ReadonlyArray<BadgeTeam> = []): undefined | BadgeTeam {
  return badges.find(b => b.category === 'role' && ['csi', 'partner'].includes(b.subcategory))
}

interface BadgeIconProps {
  badge?: BadgeTeam
}

function BadgeIcon(props: BadgeIconProps): null | JSX.Element {
  if (!props.badge) {
    return null
  }

  return <img className='feature-badge' src={props.badge.url} alt={props.badge.name} />
}

enum FeatureType {
  PARTNER = 'partner',
}

function breadCrumbNav(featureType: FeatureType, teamName: string): null | React.ReactNode {
  switch (featureType) {
    case FeatureType.PARTNER:
      return buildSimpleBreadcrumb([
        { name: teamName },
      ])
    default:
      return null
  }
}

function getFeatureType(team: PublicTeam): FeatureType {
  if (team.partner_feature) {
    return FeatureType.PARTNER
  }

  return ''
}

function getWebSiteUrl(url: string) {
  if (!url) {
    return url
  }

  if (url.match(/^http(s)?:\/\//)) {
    return url
  }

  if (url.match(/^www\./)) {
    return `http://${url}`
  }

  return `http://${url}`
}

function SocialMediaLinks(props: { team: PublicTeam }) {
  const team = props.team

  return (
    <div className='company-about-social-media'>
      {!!team.social_links.facebook && (
        <span>
          <a href={team.social_links.facebook} rel='noopener noreferrer' target='_blank'>
            <i className='fab fa-facebook-square'></i>
            Facebook
          </a>
        </span>
      )}
      {!!team.social_links.linkedin && (
        <span>
          <a
            href={getWebSiteUrl(team.social_links.linkedin)}
            rel='noopener noreferrer'
            target='_blank'
          >
            <i className='fab fa-linkedin'></i>
            LinkedIn
          </a>
        </span>
      )}
      {!!team.url && (
        <span>
          <a href={getWebSiteUrl(team.url)} rel='noopener noreferrer' target='_blank'>
            <i className='fas fa-globe'></i>
            Website
          </a>
        </span>
      )}
      {!!team.email && (
        <span>
          <a href={`mailto:${team.email}`}>
            <i className='fas fa-at'></i>Email
          </a>
        </span>
      )}
    </div>
  )
}

function CertifiedSystemIntegratorProfilePage(
  props: CertifiedSystemIntegratorProfilePageProps
): JSX.Element {
  const { team } = props
  const { address } = team

  const location = address.state
    ? `${address.city}, ${address.state}`
    : `${address.city}, ${address.country}`

  const featureType = getFeatureType(team)

  return (
    <React.Fragment>
      <header
        className='csi-profile-page-banner'
        style={{
          backgroundImage: `url("${team.banner_url || DEFAULT_BANNER}")`,
        }}
      >
        <div className='csi-profile-page-banner-content'>
          <div className='csi-profile-logo-name-slogan'>
            <div className='company-logo'>
              <img src={team.logo_url} alt={team.name} />
              <BadgeIcon badge={getBadge(team.badges)} />
            </div>
            <h2 className='company-name'>{team.name}</h2>
            <h3 className='company-slogan'>
              <i>{team.slogan}</i>
            </h3>
          </div>
          <div className='company-location'>
            <i className='fas fa-map-marker-alt'></i>
            <span>{location}</span>
          </div>
        </div>
      </header>
      <main>
        <div className='csi-profile-page-body container-lg'>
          {breadCrumbNav(featureType, team.name)}
          <div className='csi-profile-page-description'>
            <img className='company-about-image' src={team.about_url} />
            <div className='company-about-info'>
              <h3 className='company-about-header'>About {team.name}</h3>
              <p className='company-about-text'>{team.description}</p>
              <SocialMediaLinks team={team} />
            </div>
          </div>
        </div>

        {team.productized_applications.length > 0 && (
          <div className='csi-profile-page-applications container-lg'>
            <h2>Applications</h2>
            <ApplicationsList applications={team.productized_applications} />
          </div>
        )}
      </main>
    </React.Fragment>
  )
}

export default CertifiedSystemIntegratorProfilePage
