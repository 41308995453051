import React from 'react'
import AnchorLink from '../common/AnchorLink'

type AnchorLinkObject = {
  readonly label: string
  readonly href: string
}

interface Props {
  readonly anchorLinks: ReadonlyArray<AnchorLinkObject>
  readonly linkOffset: number
  readonly defaultFontSize?: string
  readonly navItemsStyle?: Partial<React.CSSProperties>
}

const AccordionNav: React.FunctionComponent<Props> = ({
  anchorLinks,
  linkOffset,
  navItemsStyle,
}) => {
  return (
    <div className='accordion-nav'>
      <ul className='accordion-nav-items'>
        {anchorLinks.map((anchor, i) => (
          <li key={`accordion-link-${i + 1}`}>
            <AnchorLink className='accordion-nav-item' href={`#${anchor.href}`} offset={linkOffset}>
              <div className='accordion-nav-item-label' style={{ ...navItemsStyle }}>
                {anchor.label}
              </div>
            </AnchorLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AccordionNav
