import { Button } from "@/components/ui/button";
import { QuerySummary } from "@coveo/headless";
import React, { useEffect, useState } from "react";
import {
  ControllerType,
  useControllerContext,
} from "../../../../coveo/components/part_library/EngineAndControllerProvider";
import { useControllerState } from "../../../../coveo/utils/useControllerState";

interface ViewResultsButtonProps {}

export const ViewResultsButton = React.forwardRef<
  HTMLButtonElement,
  ViewResultsButtonProps
>(({ ...props }, ref) => {
  const controller = useControllerContext(ControllerType.QuerySummary);
  const state = useControllerState(controller);

  return (
    <Button variant="default" className="w-full" size="lg" ref={ref} {...props}>
      View Results ({state.total})
    </Button>
  );
});
