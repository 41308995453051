import React from 'react'
import DesignCard from '../designs/DesignCard'
import Pagination from '../common/Pagination'
import Loading from '../common/Loading'
import EmptySearch from '../common/EmptySearch'
import { useDesigns } from '../../hooks/useDesigns'

const DesignBrowserList = props => {
  const { designs, currentPage, designCount, designsPerPage, handleClick, isRequestView } = props
  const content = designs.map((design, index) => (
    <DesignCard design={design} key={index} isDesignRequest={isRequestView} />
  ))
  const { designs: recommendedDesigns, isLoading } = useDesigns({
    category: 'ALL',
    sortBy: 'last_published',
    designAuthor: 'official',
    limit: 4,
  })

  const numberOfPages = Number(Math.ceil(designCount / designsPerPage))
  const emptySearchSubtitle = (
    <>
      Your search returned no results. However, you can still request a custom design by creating a
      scope of work.
    </>
  )

  const emptySearchFooter = (
    <>
      <hr />
      <h3 className='empty-search-footer-heading'>
        <span className='badge badge-secondary'>NEW</span> Vention Official Designs
      </h3>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='design-browser-list-grid'>
          {recommendedDesigns.map((design, index) => (
            <DesignCard design={design} key={index} />
          ))}
        </div>
      )}
    </>
  )

  return (
    <div className='design-browser-list'>
      {designCount > 0 ? (
        <React.Fragment>
          <div className='design-browser-list-grid'>{content}</div>
          {numberOfPages > 1 && (
            <Pagination
              currentPage={currentPage}
              numPages={numberOfPages}
              onPageChange={handleClick}
            />
          )}
        </React.Fragment>
      ) : (
        <EmptySearch
          icon={
            <img
              src='https://assets.vention.io/page-assets/common/designs-library-search.svg'
              alt='Designs Search Icon'
            />
          }
          title='Nothing matches your search'
          subtitle={emptySearchSubtitle}
          showNeedHelp
        >
          {emptySearchFooter}
        </EmptySearch>
      )}
    </div>
  )
}

export default DesignBrowserList
