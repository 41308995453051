import classNames from 'classnames'
import React from 'react'

interface TableProps {
  headers: string[]
  headersRowClassName?: string
  data: ReadonlyArray<ReadonlyArray<React.ReactNode>>
}

const Table: React.FC<TableProps> = ({ headers, headersRowClassName, data }) => {
  return (
    <table className='vention-ui-table'>
      <thead>
        <tr className={classNames('vention-ui-table-headers-row', headersRowClassName)}>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            className={classNames(
              'vention-ui-table-row',
              rowIndex % 2 === 0 ? 'vention-ui-table-row-odd' : 'vention-ui-table-row-even'
            )}
          >
            {row.map((cell, cellIndex) => (
              <td className='vention-ui-table-cell' key={cellIndex}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
