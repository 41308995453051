import React from 'react'
import { intersperse } from '../../helpers/Collections'
import { Link } from '../routing/Router'

export const BreadcrumbSeparator: React.ReactNode = <li className='breadcrumb-separator'>&gt;</li>

/**
 * A Breadcrumb component which accepts a number of BreadcrumbItem children
 * Applies appropriate styling and separation for breadcrumb rendering
 * @param param0 React Props
 */
const Breadcrumbs: React.FunctionComponent<{}> = ({ children }) => {
  const childrenArray: Array<React.ReactNode> = []
  React.Children.forEach(children, (child: any) => child && childrenArray.push(child))

  const childrenWithSeparators = [...intersperse(childrenArray, BreadcrumbSeparator)]

  const childrenWithIndices = childrenWithSeparators.map((child: any, index) => {
    return React.cloneElement(child, {
      key: index,
    })
  })

  return (
    <nav className='breadcrumbs'>
      <ul className='list-unstyled'>{childrenWithIndices}</ul>
    </nav>
  )
}

export default Breadcrumbs

/**
 * A single breadcrumb item with appropriate styling applied
 * @param param0 React Props
 */
export const BreadcrumbItem: React.FunctionComponent<{}> = ({ children }) => {
  return <li className='breadcrumb-item'>{children}</li>
}

export interface Breadcrumb {
  name: string
  href?: string
}

/**
 * A quick helper function to build more concise breadcrumb components, so that one need not always rely on
 * Writing out the full component hierarchy, although that's still fully possible with the Breadcrumbs component
 * @param nodes A list of objects containing names and optional Hrefs for breadcrumb items
 * @deprecated use NavBreadcrumbs instead
 */
export function buildSimpleBreadcrumb(nodes: ReadonlyArray<Breadcrumb>): React.ReactNode {
  return (
    <Breadcrumbs>
      {nodes.map(({ href, name }, index) => (
        <BreadcrumbItem key={index}>{href ? <a href={href}>{name}</a> : name}</BreadcrumbItem>
      ))}
    </Breadcrumbs>
  )
}

export interface NavBreadcrumbsProps {
  crumbs: Breadcrumb[]
  withRouter?: boolean
}

export interface CrumbLinkProps {
  crumb: Breadcrumb
  withRouter?: boolean
}
export const CrumbLink: React.FC<CrumbLinkProps> = ({ crumb, withRouter }) => {
  if (!crumb.href) {
    return <span>{crumb.name}</span>
  }

  if (withRouter) {
    return <Link to={crumb.href}>{crumb.name}</Link>
  }

  return <a href={crumb.href}>{crumb.name}</a>
}

export const NavBreadcrumbs: React.FC<NavBreadcrumbsProps> = ({ crumbs, withRouter }) => (
  <Breadcrumbs>
    {crumbs.map(crumb => (
      <BreadcrumbItem key={crumb.name}>
        <CrumbLink crumb={crumb} withRouter={withRouter} />
      </BreadcrumbItem>
    ))}
  </Breadcrumbs>
)
