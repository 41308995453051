import React from 'react'
import OnboardingCreateOrJoin from './OnboardingCreateOrJoin'
import { User, TeamPreview } from '../../api/Users/Types'

interface Props {
  readonly profile: User
  readonly existing_teams: ReadonlyArray<TeamPreview>
  readonly goToJoinTeam: () => void
  readonly goToCreateTeam: () => void
}

const OnboardingCreateOrJoinWrapper: React.FunctionComponent<Props> = ({
  goToJoinTeam,
  goToCreateTeam,
  profile,
  existing_teams,
}) => {
  const handleJoinTeamClick = e => {
    e.preventDefault()
    goToJoinTeam()
  }

  const handleCreateTeamClick = e => {
    e.preventDefault()
    goToCreateTeam()
  }

  return (
    <OnboardingCreateOrJoin
      handleJoinTeamClick={handleJoinTeamClick}
      handleCreateTeamClick={handleCreateTeamClick}
      existing_teams={existing_teams}
      profile={profile}
    />
  )
}

export default OnboardingCreateOrJoinWrapper
