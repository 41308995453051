import { useState, useEffect, useRef } from 'react'
import { removeArrayElement, replaceArrayElement } from '../helpers/Collections'

interface RepeatableData<T> {
  repeatableData: ReadonlyArray<T>
  addItem: (item: T) => void
  deleteItem: (index: number) => void
  updateItem: (updatedItem: T, index: number) => void
  resetData: () => void
  setRepeatableData: React.Dispatch<React.SetStateAction<ReadonlyArray<T>>>
}

export function useRepeatableData<T>(
  data: ReadonlyArray<T>,
  onDataChange?: (repeatableData: ReadonlyArray<T>) => void
): RepeatableData<T> {
  const [repeatableData, setRepeatableData] = useState<ReadonlyArray<T>>(data)

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (onDataChange) {
      // prevent callback from being run on unnecessary first render
      // only on subsequent data changes, for performance
      if (firstUpdate.current) {
        firstUpdate.current = false
        return
      }
      onDataChange(repeatableData)
    }
  }, [repeatableData])

  const deleteItem = (index: number): void => {
    setRepeatableData(removeArrayElement(repeatableData, index))
  }

  const addItem = (item: T): void => {
    setRepeatableData([...repeatableData, item])
  }

  const updateItem = (updatedItem: T, index: number): void => {
    setRepeatableData(replaceArrayElement(repeatableData, updatedItem, index))
  }

  const resetData = () => setRepeatableData(data)

  return {
    repeatableData,
    addItem,
    deleteItem,
    updateItem,
    resetData,
    setRepeatableData,
  }
}
