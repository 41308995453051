import React, { useState } from 'react'
import classNames from 'classnames'
import { ContentfulEntry } from '../Page'
import DOMPurify from 'dompurify'

interface Props {
  contentfulEntry: ContentfulEntry
}

const FAQs: React.FC<Props> = ({ contentfulEntry }) => {
  const [activeFAQIndex, setActiveFAQIndex] = useState<undefined | number>(undefined)

  return (
    <section className='subscriptions-pricing__faqs'>
      <div className='subscriptions-pricing__faqs-lead'>
        <h2 className='subscriptions-pricing__faqs-supertitle'>{contentfulEntry.faqsSupertitle}</h2>
        <h3 className='subscriptions-pricing__faqs-title'>{contentfulEntry.faqsTitle}</h3>
        <p className='subscriptions-pricing__faqs-description'>{contentfulEntry.faqsDescription}</p>
      </div>
      <ul className='subscriptions-pricing__faqs-list'>
        {contentfulEntry.faqsList?.map((FAQProps, index) => (
          <li
            key={index}
            className={classNames('subscriptions-pricing__faq', {
              'subscriptions-pricing__faq--active': activeFAQIndex === index,
            })}
          >
            <button
              className={'subscriptions-pricing__faq-question-button'}
              onClick={() => setActiveFAQIndex(activeFAQIndex === index ? undefined : index)}
            >
              <div className='subscriptions-pricing__faq-question-container'>
                <p className='subscriptions-pricing__faq-question'>{FAQProps.question}</p>
                <i
                  className={classNames('subscriptions-pricing__faq-icon', 'far', {
                    'fa-circle-minus': activeFAQIndex === index,
                    'fa-circle-plus': activeFAQIndex !== index,
                  })}
                ></i>
              </div>
            </button>
            <p
              className='subscriptions-pricing__faq-answer'
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(FAQProps.answer) }}
            ></p>
          </li>
        ))}
      </ul>
      <div className='container-lg'>
        <div className='subscriptions-pricing__faqs-footer'>
          <div className='subscriptions-pricing__faqs-footer-icons'>
            <img
              src='https://assets.vention.io/page-assets/subscriptions/icons/question.svg'
              alt={contentfulEntry.faqsFooterDescription}
            />
            <span>-</span>
            <img
              src='https://assets.vention.io/page-assets/subscriptions/icons/user.svg'
              alt={contentfulEntry.faqsFooterDescription}
            />
            <span>-</span>
            <img
              src='https://assets.vention.io/page-assets/subscriptions/icons/message.svg'
              alt={contentfulEntry.faqsFooterDescription}
            />
          </div>
          <h4 className='subscriptions-pricing__faqs-footer-title'>
            {contentfulEntry.faqsFooterTitle}
          </h4>
          <p className='subscriptions-pricing__faqs-footer-description'>
            {contentfulEntry.faqsFooterDescription}
          </p>
          <a
            className='subscriptions-pricing__faqs-footer-link'
            href={contentfulEntry.faqsFooterLinkUrl}
          >
            {contentfulEntry.faqsFooterLinkLabel}
          </a>
        </div>
      </div>
    </section>
  )
}

export default FAQs
