import React from 'react'
import DemoDayLiveBanner from '../../components/banners/DemoDayLiveBanner'

interface Props {
  demoDayVideoUrl: string
}

const HomeDemoDay: React.FunctionComponent<Props> = ({ demoDayVideoUrl }) => {
  return (
    <div>
      <header className='demo-day-hero-section'>
        <img
          src='https://assets.vention.io/page-assets/main-landing-page/demo-day/top-left.svg'
          className='demo-day-hero-section-line demo-day-hero-section-line-top-left'
        />
        <img
          src='https://assets.vention.io/page-assets/main-landing-page/demo-day/top-right.svg'
          className='demo-day-hero-section-line demo-day-hero-section-line-top-right'
        />
        <img
          src='https://assets.vention.io/page-assets/main-landing-page/demo-day/bottom-right.svg'
          className='demo-day-hero-section-line demo-day-hero-section-line-bottom-right'
        />
        <div className='demo-day-hero-section-wrapper'>
          <div className='demo-day-hero-section-iframe-border'>
            <div className='demo-day-hero-section-iframe-container'>
              <iframe
                className='demo-day-hero-section-iframe'
                width='1400'
                height='788'
                src={demoDayVideoUrl}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <DemoDayLiveBanner />
        </div>
      </header>
    </div>
  )
}

export default HomeDemoDay
