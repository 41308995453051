import React from "react";

export type PartCheckboxProps<T> = {
    readonly element: T;
    readonly isSelected: boolean
    readonly onSelectClicked?: (val: T) => void;
}

export function PartCheckbox<T>({ onSelectClicked, element, isSelected}: PartCheckboxProps<T>) {

    return (
        <div className="background-color-gray-light-100 space-padding-none space-margin-md" style={{
            display: "flex",
            position: 'absolute',
            top: "0px",
            right: "0px",
            borderRadius: "2px",
            justifyContent: "center",
            alignItems: "center",
            width: "30px",
            height: "30px",
            cursor: 'pointer'
        }} onClick={() => onSelectClicked?.(element)}>
            <input style={{
                alignSelf: 'center',
                margin: "0px",
                cursor: 'pointer'
            }} type='checkbox' checked={isSelected} onChange={() => {
                onSelectClicked?.(element)
            }} />
        </div>
    )

}