import React, { useState } from 'react'

const TagNav = ({ handleClick, tagList, className, isLoading, clearTagsFilter }) => {
  const [showTagFilter, setShowTagFilter] = useState(false)
  const toggleFilterOpen = () => setShowTagFilter(!showTagFilter)

  const showClear = tagList.some(tag => tag.selected)

  const content = tagList.map((tag, index) => (
    <li className='tag-nav-list-item' key={index}>
      <div className='checkbox'>
        <input
          type='checkbox'
          className='checkbox tag-checkbox'
          name={tag.name}
          id={tag.name}
          value={tag.name}
          checked={tag.selected}
          onChange={() => handleClick(tag.id)}
        />
        <label className='checkbox-label checkbox-tag' htmlFor={tag.name}>
          <span>{tag.name}</span>
        </label>
      </div>
    </li>
  ))

  return (
    <div className={`${className || 'tag-nav'} tag-filter-nav`}>
      <button
        className={`tag-filter-header ${showTagFilter ? 'open' : ''}`}
        onClick={() => toggleFilterOpen()}
      >
        <h5 className='tag-filter-title'>Tags&nbsp;</h5>
        <span className='tag-filter-chevron'></span>
      </button>

      <div className={`filter-content ${showTagFilter ? 'open' : ''}`}>
        {showClear && !className && (
          <button onClick={() => clearTagsFilter()} className='clear-filter'>
            Clear
          </button>
        )}
        {!isLoading && <ul className='tag-nav-list'>{content}</ul>}
      </div>
    </div>
  )
}
export default TagNav
