import React from 'react'
import InputValidator from '../InputValidator'

export enum FileSelectInputType {
  Single = 'single',
  AddMore = 'add-more',
}

export interface FileSelectInputProps {
  accept: string
  changeHandler: (event: any) => void
  label?: string
  multiple?: boolean
  loading?: boolean
  buttonType?: FileSelectInputType
  disabled?: boolean
  icon?: React.FC
}

export function FileSelectInput(props: FileSelectInputProps) {
  const buttonType = props.buttonType || FileSelectInputType.Single
  const buttonTypeClassName = `file-select-input-${buttonType}`
  const Icon = props.icon
  return (
    <label role='button' className={`file-select-input ${buttonTypeClassName}`}>
      {props.loading && <i className='fas fa-spinner-third fa-spin' />}
      {!props.loading && (
        <>
          {Icon && (
            <div className='file-select-icon'>
              <Icon />
            </div>
          )}
          <span>{props.label ?? 'Select Your File'}</span>
          <InputValidator
            accept={props.accept}
            multiple={!!props.multiple}
            onChange={props.changeHandler}
            type='file'
            disabled={props.disabled}
          />
        </>
      )}
    </label>
  )
}
