import classNames from 'classnames'
import React from 'react'

export interface TableGroupRowProps {
  title?: string
  description: string
  descriptionLink?: string
  oddRow?: boolean
  checkedValues: Array<'true' | 'false'>
}

const TableGroupRowDesktop: React.FC<TableGroupRowProps> = ({
  title,
  description,
  descriptionLink,
  oddRow,
  checkedValues,
}) => {
  const renderIcons = () => {
    return checkedValues.map((checked, index) => {
      if (checked === 'true') {
        return (
          <i
            key={index}
            className='subscriptions-pricing__table-group-row-icon fal fa-circle-check'
          ></i>
        )
      } else {
        return (
          <i key={index} className='subscriptions-pricing__table-group-row-icon far fa-dash'></i>
        )
      }
    })
  }
  return (
    <div
      className={classNames(
        'subscriptions-pricing__table-group-row',
        'subscriptions-pricing__table-group-row--desktop',
        {
          'subscriptions-pricing__table-group-row--odd': oddRow,
        }
      )}
    >
      <div>
        {title && <p className='subscriptions-pricing__table-group-row-title'>{title}</p>}
        {descriptionLink ? (
          <a
            className='subscriptions-pricing__table-group-row-link'
            href={descriptionLink}
            target='_blank'
            rel='noreferrer'
          >
            <p className='subscriptions-pricing__table-group-row-description'>{description}</p>
            <i className='far fa-arrow-up-right-from-square'></i>
          </a>
        ) : (
          <p className='subscriptions-pricing__table-group-row-description'>{description}</p>
        )}
      </div>
      {renderIcons()}
    </div>
  )
}

export default TableGroupRowDesktop
