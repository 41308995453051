import React, { useState } from 'react'
import TableGroup from './TableGroup'
import SubscriptionCard from './SubscriptionCard'
import { ContentfulEntry, CurrencyExchangeRate } from '../Page'
import StartBuildingButton from '../../../../../components/assembly_card/StartBuildingButton'

interface Props {
  contentfulEntry: ContentfulEntry
  currencyExchangeRate: CurrencyExchangeRate
}

const TieredPlans: React.FC<Props> = ({ contentfulEntry, currencyExchangeRate }) => {
  const [showTable, setShowTable] = useState(false)

  const fetchSubscriptionType = (index: number) => {
    switch (index) {
      case 0:
        return 'freemium'
      case 1:
        return 'pro'
      case 2:
        return 'business'
      default:
        return 'freemium'
    }
  }

  return (
    <div>
      <div className='subscriptions-pricing__tiered-plans'>
        <div>
          <div className='subscriptions-pricing__tiered-plans-card'>
            <img
              className='subscriptions-pricing__tiered-plans-card-icon'
              src='https://assets.vention.io/page-assets/subscriptions/icons/gripper.svg'
              alt={contentfulEntry.tieredPlansTitle}
            />
            <h2 className='subscriptions-pricing__tiered-plans-card-title'>
              {contentfulEntry.tieredPlansTitle}
            </h2>
            <p className='subscriptions-pricing__tiered-plans-card-description'>
              {contentfulEntry.tieredPlansDescription}
            </p>
          </div>
        </div>
        {contentfulEntry.tieredPlansPricingCards?.map((subscriptionCardProps, index) => (
          <SubscriptionCard
            key={index}
            currencyExchangeRate={currencyExchangeRate}
            showTable={showTable}
            setShowTable={setShowTable}
            subscriptionType={fetchSubscriptionType(index)}
            tableGroupsProps={contentfulEntry.tieredPlansTableGroups}
            {...subscriptionCardProps}
          />
        ))}
      </div>
      {showTable && (
        <div className='subscriptions-pricing__tiered-plans-table'>
          <div className='subscriptions-pricing__tiered-plans-table-column subscriptions-pricing__tiered-plans-table-column--hide-icons'>
            {contentfulEntry.tieredPlansTableGroups?.map((tableGroupProps, index) => (
              <TableGroup key={index} {...tableGroupProps} desktop />
            ))}
          </div>
        </div>
      )}
      {showTable && (
        <div className='subscriptions-pricing__tiered-plans-footer-links'>
          <div></div>
          {contentfulEntry.tieredPlansPricingCards.map((item, index) =>
            item.linkPrimaryToNewDesign ? (
              <StartBuildingButton
                key={index}
                label={item.linkPrimaryLabel}
                className='subscriptions-pricing__subscription-card-link'
              />
            ) : (
              <a
                key={index}
                className='subscriptions-pricing__subscription-card-link'
                href={item.linkPrimaryUrl}
              >
                {item.linkPrimaryLabel}
              </a>
            )
          )}
        </div>
      )}
    </div>
  )
}

export default TieredPlans
