interface SpecItems {
  readonly colHeader: string
  readonly items: ReadonlyArray<SpecItem>
}

interface SpecItem {
  readonly img: string
  readonly title: string
  readonly text: string
}

export const specsItems: ReadonlyArray<SpecItems> = [
  {
    colHeader: '3D Design',
    items: [
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/modular-parts.svg',
        title: 'Modular parts library',
        text: 'Find hundreds of compatible parts from Vention and curated manufacturers.',
      },
      {
        img: 'https://assets.vention.io/page-assets/benefits-page/icons/ai-enabled.svg',
        title: 'AI-enabled part suggestions',
        text: "Quickly select from the parts you're most likely to need next.",
      },
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/automatic-connection.svg',
        title: 'Automatic part connections',
        text:
          'Smart part connection removes the need for manual 3D constraints between components.',
      },
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/easy-programming.svg',
        title: 'Easy programming',
        text:
          'Code-free programming and simulation in the same environment. Push the validated program directly onto the physical equipment.',
      },
    ],
  },
  {
    colHeader: 'Ordering & Shipping',
    items: [
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/real-time-pricing.svg',
        title: 'Real-time price',
        text: 'See the price of your design in real time--it updates as your design evolves.',
      },
      {
        img: 'https://assets.vention.io/page-assets/benefits-page/icons/invoice.svg',
        title: 'Instant PDF quotes',
        text: 'Get a PDF containing your quote and a link you can click to order.',
      },
      {
        img: 'https://assets.vention.io/page-assets/benefits-page/icons/cart.svg',
        title: 'Simple online purchasing',
        text: 'Buy online with a credit card or secured corporate account.',
      },
      {
        img: 'https://assets.vention.io/page-assets/benefits-page/icons/delivery.svg',
        title: 'Multiple shipping options',
        text: 'Receive your order next-day or in up to 7 business days.',
      },
    ],
  },
  {
    colHeader: 'Training & setup',
    items: [
      {
        img: 'https://assets.vention.io/page-assets/benefits-page/icons/free.svg',
        title: 'No installation or license',
        text: 'Completely free to use, cloud-based, and browser agnostic.',
      },
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/intuitive-interface.svg',
        title: 'Intuitive interface',
        text: 'Learn it in 15 to 30 minutes.',
      },
    ],
  },
  {
    colHeader: 'Commissioning & Support',
    items: [
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/complementary-reviews.svg',
        title: 'Complimentary reviews',
        text: 'Our application engineers are available upon request for free design reviews.',
      },
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/assembly-services.svg',
        title: 'Assembly services',
        text:
          'Choose to have your equipment fully assembled by selecting this option during checkout for qualifying designs.',
      },
      {
        img:
          'https://assets.vention.io/page-assets/benefits-page/icons/certified-engineer.svg',
        title: 'Certified system integrators',
        text: 'Hire a Vention-trained integrator for more specialized help.',
      },
    ],
  },
]
