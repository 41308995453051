import { useState } from 'react'
import PartDocumentService from '../../../../api/PartDocument/Service'
import { PartDocument } from '../../../../api/PartDocument/Types'
import { PartType } from '../../../../types/PartType'

export enum PartDocumentationStatus {
  Initial = 'initial',
  Creating = 'creating',
  Deleting = 'deleting',
  Idle = 'idle',
}

export function usePartDocumentationStatus() {
  const [partDocumentStatus, setPartDocumentStatus] = useState<PartDocumentationStatus>(
    PartDocumentationStatus.Initial
  )
  const [docUploadProgress, setDocUploadProgress] = useState(0)

  const createPartDocument = (
    partId: number,
    partType: PartType,
    partDocumentPayload: string | ArrayBuffer | null,
    partDocumentFilename: string | null
  ) => {
    setPartDocumentStatus(PartDocumentationStatus.Creating)
    setDocUploadProgress(0)

    return PartDocumentService.createPartDocument(
      partId,
      partType,
      partDocumentPayload,
      partDocumentFilename,
      setDocUploadProgress
    )
      .then(response => response.part_document as PartDocument)
      .finally(() => setPartDocumentStatus(PartDocumentationStatus.Idle))
  }

  const deletePartDocument = async (partDocumentId: number): Promise<void> => {
    setPartDocumentStatus(PartDocumentationStatus.Deleting)
    try {
      await PartDocumentService.deletePartDocument(partDocumentId)
      toastr.success('Successfully deleted part document')
    } catch (error) {
      console.error(error)
      toastr.error('Failed deleting part document')
    } finally {
      setPartDocumentStatus(PartDocumentationStatus.Idle)
    }
  }

  return {
    partDocumentStatus,
    docUploadProgress,
    createPartDocument,
    deletePartDocument,
  }
}
