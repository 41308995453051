import React from 'react'
import { BlogTile } from '../../components/common/blog/BlogTile'
import { BlogPost } from '../../api/Blog/Types'

interface Props {
  readonly posts: ReadonlyArray<BlogPost>
}

export const BlogSidebarPosts: React.FC<Props> = ({ posts }) => {
  return (
    <div className='blog-sidebar-posts'>
      <div className='blog-sidebar-posts-title'>Most popular</div>
      <div className='blog-sidebar-posts-list'>
        {posts.map((post, i) => (
          <BlogTile
            key={`blog-posts-${i}`}
            post={post}
            showThumbnail={false}
            includeRevealableBlurb={false}
          />
        ))}
      </div>
    </div>
  )
}
