import React from 'react'

interface FilterOption {
  label: string
  value: string
}

interface Props {
  label: string
  handleChange: (newValue: string) => void
  filterOptions: ReadonlyArray<FilterOption>
  defaultValue?: FilterOption
  value?: string
}

const Filter = (props: Props) => {
  const { defaultValue, value, filterOptions, label, handleChange } = props
  const prefixOptions = defaultValue
    ? [
        <option value={defaultValue.value} key={-1}>
          {defaultValue.label}
        </option>,
      ]
    : []
  const content = prefixOptions.concat(
    filterOptions.map((filterOption, index) => (
      <option value={filterOption.value} key={index}>
        {filterOption.label}
      </option>
    ))
  )

  return (
    <div className='sort'>
      {label && <span className='sort-filter-label'>{label}&nbsp;</span>}
      <select
        className='form-control'
        defaultValue={defaultValue?.value}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleChange(event.target.value)}
      >
        {content}
      </select>
    </div>
  )
}

export default Filter
