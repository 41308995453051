import React, { useState, useEffect, useRef } from 'react'

interface Props {
  readonly children: React.ReactNode
  readonly lines?: number
  readonly readMoreText?: string
  readonly readLessText?: string
}

const ReadMoreText: React.FC<Props> = ({ lines = 2, readMoreText, readLessText, children }) => {
  const contentRef = useRef<HTMLParagraphElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [needsClamping, setNeedsClamping] = useState(false)

  useEffect(() => {
    const initializeClamp = () => {
      if (contentRef.current) {
        contentRef.current.style['-webkit-line-clamp'] = String(lines)
        const isClamped = contentRef.current.scrollHeight > contentRef.current.clientHeight
        setNeedsClamping(isClamped)
      }
    }

    window.addEventListener('resize', initializeClamp)
    initializeClamp()

    return () => window.removeEventListener('resize', initializeClamp)
  }, [lines])

  const toggle = () => setIsExpanded(!isExpanded)

  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        contentRef.current.classList.remove('line-clamp')
      } else {
        contentRef.current.classList.add('line-clamp')
      }
    }
  }, [isExpanded])

  return (
    <div className='vention-ui-read-more-text'>
      <p ref={contentRef} className='line-clamp'>
        {children}
      </p>
      {needsClamping && (
        <button className='vention-ui-read-more-text-button' type='button' onClick={toggle}>
          {isExpanded ? readLessText || 'Read less' : readMoreText || 'Read more'}
        </button>
      )}
    </div>
  )
}

export default ReadMoreText
