const HomeService = {
  submitContactUs: function (formData, cb, url = '/do_contact_us') {
    $.ajax({
      type: 'POST',
      url: url,
      beforeSend: $.rails.CSRFProtection,
      data: formData,
      success: res => {
        if (undefined != cb) {
          cb(res)
        }
      },
      error: res => {
        if (undefined != cb) {
          cb(null, res)
        }
      },
    })
  },
}

export default HomeService
