import React, { SyntheticEvent } from 'react'
import InputValidator from '../../components/common/InputValidator'
import { ValidatorForm } from 'react-form-validator-core'

interface Props {
  readonly handleNewTeamNameChange: (event: SyntheticEvent) => void
  readonly newTeamName: string
  readonly handleCreateTeamSubmit: (event: SyntheticEvent) => void
}

const OnboardingCreateTeam: React.FunctionComponent<Props> = ({
  handleNewTeamNameChange,
  newTeamName,
  handleCreateTeamSubmit,
}) => {
  return (
    <ValidatorForm className='create-team-form join-team-form' onSubmit={handleCreateTeamSubmit}>
      <h2 className='onboarding-form-heading create-team-heading'>Name your team</h2>
      <InputValidator
        onChange={handleNewTeamNameChange}
        id='team-name'
        name='teamName'
        value={newTeamName}
        placeholder=' '
        validators={['required']}
        errorMessages={['Name your new team']}
      >
        <label htmlFor='team-name'>Team Name</label>
      </InputValidator>
      <div className='skip-btn-wrapper'>
        <button className='button button-success button-md next-btn' type='submit'>
          Next
        </button>
      </div>
    </ValidatorForm>
  )
}

export default OnboardingCreateTeam
