import React from 'react'
import I18n from '../../helpers/I18n'

const DemoDayLiveBanner = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div className='demo-day-lower-banner'>
      <div className='demo-day-banner-text'>
        {I18n.t('views.common.demo_day.turn_sound_on')}{' '}
        <i className='far fa-volume-up' aria-hidden='true'></i>
      </div>
      <div>
        <a
          className='link-unstyled'
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.vention.io/demo-day'
        >
          {I18n.t('views.common.demo_day.learn_more', { year: currentYear })}
          <span className='demo-day-banner-arrow-icon'></span>
        </a>
      </div>
    </div>
  )
}

export default DemoDayLiveBanner
