const DesignBrowserSideNavTab = props => {
  const { label, html, anchor, categoryKey, subCategoryKey, handleClick } = props

  return (
    <React.Fragment>
      {/* If subcategory display anchor, else button */}
      {anchor ? (
        <a className='side-nav-tab-anchor' onClick={() => handleClick(categoryKey, subCategoryKey)}>
          {label}
        </a>
      ) : (
        <React.Fragment>
          {html ? (
            React.createElement('button', {
              className: 'side-nav-tab-button',
              onClick: () => handleClick(categoryKey),
              dangerouslySetInnerHTML: { __html: html },
            })
          ) : (
            <button className='side-nav-tab-button' onClick={() => handleClick(categoryKey)}>
              {label}
            </button>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default DesignBrowserSideNavTab
