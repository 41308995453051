import React from "react";
import LandingPageHero from "../../../../../components/cms/landing_pages/sections/LandingPageHero";
import LandingPageLRContent from "../../../../../components/cms/landing_pages/sections/LandingPageLRContent";
import LandingPageTable from "../../../../../components/cms/landing_pages/sections/LandingPageTable";
import LandingPageInfoGraphic from "../../../../../components/cms/landing_pages/sections/LandingPageInfoGraphic";
import LandingPageMultiTile from "../../../../../components/cms/landing_pages/sections/LandingPageMultiTile";
import I18n from "../../../../../helpers/I18n";
import { ResourceDocObject } from "../../../../../components/cms/api/Types";
import LandingPageInteractiveSlider from "../../../../../components/cms/landing_pages/sections/LandingPageInteractiveSlider";
import LandingPageEducationalResources from "../../../../../components/cms/landing_pages/sections/LandingPageEducationalResources";
import CtaDivider from "../../../../../components/common/dividers/CtaDivider";
import LandingPageSectionWrapper from "../../../../../components/cms/landing_pages/common/LandingPageSectionWrapper";

interface Props {
  resourcesByUuid: Record<string, ResourceDocObject>;
}

const Page: React.FC<Props> = ({ resourcesByUuid }) => {
  return (
    <div className="machine_motion">
      <LandingPageHero
        type="hero"
        pageTypeFlag={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.hero.page_type_flag",
        )}
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.hero.title",
        )}
        subtitle={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.hero.subtitle",
        )}
        sectionCTA={{
          label: I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.hero.section_cta",
          ),
          color: "secondary",
        }}
        imageLayout="full"
        style={{
          backgroundImage:
            "https://s3.amazonaws.com/ventioncms/vention_images/images/000/007/350/original/machinemotion-new-colour-optimized.jpg?1674501514",
          textColor: "dark",
        }}
        resourcesByUuid={{}}
      />

      <LandingPageLRContent
        type="lrContent"
        textAlign="right"
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_1.title",
        )}
        content={[
          {
            content: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_1.description",
            ),
          },
        ]}
        imageUrl="https://s3.amazonaws.com/ventioncms/vention_images/images/000/007/357/original/machine-motion-feature-1v2-optimized.png?1674568468"
        cta={{
          url: "https://vention.io/resources/datasheets/machinemotion-pendant-v3-datasheet-475",
          label: I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_1.cta_label",
          ),
        }}
        style={{
          backgroundColor: "white",
        }}
      />

      <LandingPageLRContent
        type="lrContent"
        textAlign="left"
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_2.title",
        )}
        content={[
          {
            content: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_2.description",
            ),
          },
        ]}
        imageUrl="https://s3.amazonaws.com/ventioncms/vention_images/images/000/005/977/original/mmv2-comparison-1d-4d.png?1628265252"
        cta={{
          url: "https://vention.io/resources/guides/machine-motion-2-user-manual-111",
          label: I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_2.cta_label",
          ),
        }}
        style={{
          backgroundColor: "white",
        }}
      />

      <LandingPageLRContent
        type="lrContent"
        textAlign="right"
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_3.title",
        )}
        content={[
          {
            content: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_3.description",
            ),
          },
        ]}
        imageUrl="https://s3.amazonaws.com/ventioncms/vention_images/images/000/007/849/original/mmv2-applications-6-750x425-01.png?1684180888"
        cta={{
          url: "https://vention.io/applications",
          label: I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.large_img_section_3.cta_label",
          ),
        }}
        style={{
          backgroundColor: "white",
        }}
      />

      <LandingPageInfoGraphic
        type="infoGraphic"
        imageUrl="https://s3.amazonaws.com/ventioncms/vention_images/images/000/005/987/original/machinemotionv2-specifications-infographic.png?1629211105"
        imageUrlMobile="https://s3.amazonaws.com/ventioncms/vention_images/images/000/005/988/original/machinemotionv2-specifications-mobile.png?1629211121"
        alt={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.info_graphic.img_alt",
        )}
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.info_graphic.title",
        )}
        subtitle={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.info_graphic.description",
        )}
        textAlign="center"
        cta={{
          label: I18n.t(
            "views.landing_pages.hardware_landing_pages.actuators.info_graphic.cta_label",
          ),
          url: "https://vention.io/parts-library",
        }}
        description={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.info_graphic.description",
        )}
        style={{
          backgroundColor: "vention-grey",
        }}
      />

      <LandingPageTable
        type="table"
        titleTextAlign="center"
        tableHeader={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.table.header",
        )}
        data={[
          I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.table.data_1",
          ).reduce((obj, { key, value }) => {
            obj[key] = value;
            return obj;
          }, {}),
          I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.table.data_2",
          ).reduce((obj, { key, value }) => {
            obj[key] = value;
            return obj;
          }, {}),
          I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.table.data_3",
          ).reduce((obj, { key, value }) => {
            obj[key] = value;
            return obj;
          }, {}),
          I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.table.data_4",
          ).reduce((obj, { key, value }) => {
            obj[key] = value;
            return obj;
          }, {}),
          I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.table.data_5",
          ).reduce((obj, { key, value }) => {
            obj[key] = value;
            return obj;
          }, {}),
          I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.table.data_6",
          ).reduce((obj, { key, value }) => {
            obj[key] = value;
            return obj;
          }, {}),
          I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.table.data_7",
          ).reduce((obj, { key, value }) => {
            obj[key] = value;
            return obj;
          }, {}),
        ]}
        style={{
          backgroundColor: "white",
          paddingTop: "40",
        }}
        tableStyle={{
          columnWidths: [3, 1, 1],
          layout: "horizontal",
          borderRow: false,
          borderColumn: true,
          borderStyle: "solid",
          headerBackgroundColor: "vention-blue",
          responsiveStyle: "scroll",
          alterningRowBackgroundColor: true,
          innerCellTextAlignment: "center",
        }}
      />

      <LandingPageMultiTile
        type="multiTile"
        imageMaxWidth={150}
        titleTextAlign="center"
        countTiles={4}
        textAlign="center"
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.heading",
        )}
        tiles={[
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/162/original/vention-icon-led-light.png?1599422489",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.status_led.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.status_led.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/619/original/certification-europe-us-resied-optimized.png?1644511381",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.certification.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.certification.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/169/original/vention-icon-control-module.png?1599422605",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.control_modules.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.control_modules.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/164/original/vention-icon-urcap.png?1599422523",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.ur_integration.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.ur_integration.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/163/original/vention-icon-actuators.png?1599422508",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.vention_actuators.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.vention_actuators.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/165/original/vention-icon-software-stop.png?1599422540",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.software_hardware.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.software_hardware.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/170/original/vention-icon-software.png?1599422622",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.code_free.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.code_free.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/167/original/vention-icon-icloud.png?1599422574",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.open_source.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.open_source.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/205/original/peripherals-icon.png?1599582489",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.peripherals.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.peripherals.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/161/original/vention-icon-servo-motor.png?1599422472",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.step_servos.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.step_servos.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/168/original/vention-icon-enclosure.png?1599422590",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.rugged_enclosure.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.rugged_enclosure.description",
            ),
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/ventioncms/vention_images/images/000/004/166/original/vention-icon-cabling.png?159942255",
            title: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.flex_cable.title",
            ),
            description: I18n.t(
              "views.landing_pages.hardware_landing_pages.machine_motion.multi_tile_1.flex_cable.description",
            ),
          },
        ]}
      />

      <LandingPageInfoGraphic
        type="infoGraphic"
        imageUrl="https://s3.amazonaws.com/ventioncms/vention_images/images/000/008/579/original/machinemotionv2-pendantv3-ecosystem-infographic.png?1709828029"
        imageUrlMobile="https://s3.amazonaws.com/ventioncms/vention_images/images/000/008/579/original/machinemotionv2-pendantv3-ecosystem-infographic.png?1709828029"
        alt={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.info_graphic_2.img_alt",
        )}
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.info_graphic_2.title",
        )}
        subtitle={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.info_graphic_2.description",
        )}
        textAlign="center"
        style={{
          backgroundColor: "white",
        }}
      />

      <LandingPageInteractiveSlider />

      <LandingPageEducationalResources
        title={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.educational_resources.title",
        )}
        subtitle={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.educational_resources.subtitle",
        )}
        description={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.educational_resources.description",
        )}
        cardDetails={I18n.t(
          "views.landing_pages.hardware_landing_pages.machine_motion.educational_resources.card_details",
        )}
      />
      <LandingPageSectionWrapper cssNamespace="landing-page-educational-resources cta-divider__wrapper">
        <CtaDivider
          description={I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.cta_divider.description",
          )}
          btnText={I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.cta_divider.btnText",
          )}
          btnUrl={I18n.t(
            "views.landing_pages.hardware_landing_pages.machine_motion.cta_divider.btnUrl",
          )}
        />
      </LandingPageSectionWrapper>
    </div>
  );
};

export default Page;
