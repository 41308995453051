import React from 'react'
import { ProductizedApplication } from '../../../../../api/DesignCategories/Types'

interface ApplicationProps {
  application: ProductizedApplication
}

export function ApplicationListItem(props: ApplicationProps) {
  return (
    <div key={props.application.id} className='applications-list-item'>
      <img
        alt={props.application.label}
        id={`applications-list-item-${props.application.id}`}
        src={props.application.image_url}
      />
      <label htmlFor={`applications-list-item-${props.application.id}`}>
        {props.application.label}
      </label>
    </div>
  )
}

interface ApplicationsListProps {
  readonly applications: ReadonlyArray<ProductizedApplication>
}

export default function ApplicationsList(props: ApplicationsListProps) {
  return (
    <div className='applications-list'>
      {props.applications.map(item => (
        <ApplicationListItem application={item} key={`application-${item.id}`} />
      ))}
    </div>
  )
}
