import React, { useContext } from "react";
import { Result } from "@coveo/headless";
import { useMutation } from '@tanstack/react-query'
import { PartCardV2 } from "../../../components/parts/PartCardV2";
import { VRegularText, VSemiBoldText } from "../../../components/text/Text";
import Button from "../../../components/common/buttons/Button";
import { leadTimeText } from "../../../helpers/LeadTimeHelper";
import { Status } from '../../../api/Parts/Types';
import { CurrencyRegion, CurrencySymbol, RegionType } from "../../../pages/views/part_searches/Types";
import { SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS } from "../../../pages/views/partsV2/constants";
import { RegionContext } from "./RegionProvider";
import { quickAddToCart } from "../../../api/Cart/Service";

export interface PartResult extends Result {
  raw: Result["raw"] & {
    ec_images: string,
    part_number: string,
    local_price: number,
    shipment_delays: number,
    part_status: string,
    entity_id: number,
    admin_only: number
  }
  childResults: PartResult[];
}

export interface ResultCardProps {
  data: PartResult;
}

export const ResultCard: React.FC<ResultCardProps> = (props) => {
  const region = useContext(RegionContext);

  const addToCartMutation = useMutation({
    mutationFn: (partId: number) => {
      return new Promise((resolve, _reject) => {
        quickAddToCart(null, `/parts/${partId}/buy`, "Part", resolve);
      });
    }
  });

  return (
    <PartCardV2 isSelectable={false} isSelected={false}
      part={{
        id: props.data.uniqueId,
        imagePath: props.data.raw.ec_images,
        name: props.data.title,
        partNumber: props.data.raw.part_number,
        fullUrl: props.data.clickUri,
        adminOnly: props.data.raw.admin_only === 1,
        displayPriceRaw: {
          amount: props.data.raw.local_price?.toString(),
          currencyCode: CurrencyRegion[region].toString(),
          currencySymbol: CurrencySymbol[region].toString()
        }
      }}
      subtitleChildren={props.data.childResults.length > 0 ? <VRegularText className="text-slate-400 text-sm p-0 m-0"> {`+ ${props.data.childResults.length} options`}</VRegularText> : null}
      labelChildren={<p className={`${[Status.IN_STOCK, Status.DEPLETE].includes(props.data.raw.part_status as Status) ? 'text-[#1ac876]' : 'text-[#197ce0]'}`} >
        {
          leadTimeText(
            props.data.raw.shipment_delays,
            props.data.raw.part_status
          )
        }
      </p>}
    >
      {
        props.data.childResults.length === 0 && props.data.raw.shipment_delays < SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS ? (
          <Button
            className={`vention-ui-button--secondary-gray-outline self-center w-full m-2`}
            onClick={() => addToCartMutation.mutate(props.data.raw.entity_id)}
            disabled={addToCartMutation.isPending}
          ><VSemiBoldText fontSize="lg">Add to cart</VSemiBoldText></Button>
        ) : (
          <Button
            className={`vention-ui-button--secondary-gray-outline self-center w-full m-2`}
            onClick={() => window.open(props.data.clickUri, "_blank")}
          ><VSemiBoldText fontSize="lg">See Buying Options</VSemiBoldText></Button>
        )
      }
    </PartCardV2>
  );
}