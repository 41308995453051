import React from "react";
import I18n from "../../../../helpers/I18n";

const SuccessNote: React.FC = () => {
  return (
    <div className="try-before-you-buy-success-note_container">
      <h3 className="try-before-you-buy-success-note_title">
        {I18n.t("views.try_before_you_buy.success_note.title")}
      </h3>
      <p className="try-before-you-buy-success-note_description">
        {I18n.t("views.try_before_you_buy.success_note.description")}
      </p>
    </div>
  );
};
export default SuccessNote;
