import React from "react";
import { ShowIncludedPartOrFastener } from "../Page";
import Table from "../../../../../components/vention_ui/shared_components/Table";

interface Props {
  readonly includedPartsAndFasteners: ReadonlyArray<ShowIncludedPartOrFastener>;
}

const WhatsIncluded: React.FC<Props> = ({ includedPartsAndFasteners }) => {
  const tableData = includedPartsAndFasteners.map((includedPartOrFastener) => [
    includedPartOrFastener.name,
    includedPartOrFastener.part_number,
    includedPartOrFastener.quantity,
    includedPartOrFastener.part_url ? (
      <a
        href={includedPartOrFastener.part_url}
        target="_blank"
        className="product-viewer-v2__text-link"
      >
        View
      </a>
    ) : (
      ""
    ),
  ]);
  return (
    <section id="whats-included" className="product-viewer-v2__whats-included">
      <Table
        headers={["Parts", "Quantity", ""]}
        headersRowClassName="screen-reader-text"
        data={[["Parts", "Part number", "Qty", ""], ...tableData]}
      ></Table>
    </section>
  );
};

export default WhatsIncluded;
