import React from 'react'
import Header from './sections/Header'
import Software from './sections/Software'
import CustomerStories from './sections/CustomerStories'
import EducationalResources from './sections/EducationalResources'
import Footer from './sections/Footer'
import { CaseStudy } from '../../../../../../components/cms/api/Types'

interface Props {
  contentfulEntry: any
  caseStudies: CaseStudy[]
}

const Page: React.FC<Props> = ({ contentfulEntry, caseStudies }) => {
  return (
    <div className='master-palletizer-landing-page'>
      <Header contentfulEntry={contentfulEntry} />
      <Software contentfulEntry={contentfulEntry} />
      <CustomerStories contentfulEntry={contentfulEntry} caseStudies={caseStudies} />
      <EducationalResources contentfulEntry={contentfulEntry} />
      <Footer contentfulEntry={contentfulEntry} />
    </div>
  )
}

export default Page
