import React from 'react'
import InputValidator from '../../common/InputValidator'

interface OwnProps {
  readonly email: string
  readonly updateNewMembersEmailList: (index: number, newEmail?: string) => void
  readonly index: number
  readonly removeNewMemberEmailInput: (index: number) => void
}

const TeamInviteMemberAccess: React.FunctionComponent<OwnProps> = ({
  email,
  updateNewMembersEmailList,
  index,
  removeNewMemberEmailInput,
}) => {
  // validators and corresponding error messages for all email fields except the first
  const validators = ['isEmail', 'userAlreadyInTeam', 'inviteAlreadyExist']
  const errorMessages = [
    'Email is not valid',
    'User is already in the team.',
    'Invite already exists.',
  ]
  if (index === 0) {
    validators.push('required')
    errorMessages.push('Atleast one email is required')
  }
  return (
    <>
      <div className='invite-email-row'>
        <InputValidator
          className='form-control full-width'
          name='email'
          value={email.replace(/\s/g, '')}
          onChange={event => updateNewMembersEmailList(index, event.target.value)}
          placeholder='Type work email address'
          autoComplete='new-password'
          validators={validators}
          errorMessages={errorMessages}
        />
        {index > 2 && (
          <button
            type='button'
            className='button-blank button-no-padding'
            onClick={() => removeNewMemberEmailInput(index)}
          >
            <i className='fal fa-trash-alt' aria-hidden='true' />
          </button>
        )}
      </div>
    </>
  )
}

export default TeamInviteMemberAccess
