import React from "react";
import { Pagination } from "../../../../../components/simple_pagination/Pagination";
import { PartCardV2 } from "../../../../../components/parts/PartCardV2";
import { SimilarPartDTO } from "../../../../../api/Parts/dtos/SimilarPartDTO";
import { PartDTO } from "../../../../../api/Parts/dtos/PartDTO";
import { AddToCartButton } from "./AddToCartButton";
import { SimilarPartCollectionDTO } from "../../../../../api/Parts/Types";

export interface SimilarPartsProps {
  similarPartsCollection?: SimilarPartCollectionDTO;
  isAddingSimilarPartToCart: { [key: string]: boolean };
  selectedParts: SimilarPartDTO[];
  canSelectPart: boolean;
  onAddSimilarPartToCart: (partId: string) => void;
  onSelectSimilarPartToCompare: (part: Partial<PartDTO>) => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

const SimilarParts: React.FC<SimilarPartsProps> = (
  props: SimilarPartsProps,
) => {
  return (
    <>
      <div className="product-viewer-v2__card-links">
        {props.similarPartsCollection?.parts.collection.map((part, index) => (
          <PartCardV2
            part={part}
            isSelected={props.selectedParts.includes(part)}
            isSelectable={props.canSelectPart}
            key={index}
            onSelectClicked={props.onSelectSimilarPartToCompare}
          >
            {
              <AddToCartButton
                style="secondary-gray-outline"
                onAddPartToCart={() => props.onAddSimilarPartToCart(part.id)}
                isLoadingAddingPartToCart={props.isAddingSimilarPartToCart}
                part={part}
              />
            }
          </PartCardV2>
        ))}
      </div>
      <div
        className="space-margin-left-lg space-margin-bottom-lg space-margin-top-xl"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Pagination
          currentPage={
            props.similarPartsCollection?.parts?.metadata?.currentPage
          }
          lastPage={props.similarPartsCollection?.parts?.metadata?.totalPages}
          isLoading={false}
          moveToNext={props.onNextPage}
          moveToPrevious={props.onPreviousPage}
        />
      </div>
    </>
  );
};

export default SimilarParts;
