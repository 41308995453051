export enum RegionType {
    US = 'us',
    CAD = 'ca',
    EU = 'eu',
    INTL = 'intl'
}

export const CurrencyRegion: Record<RegionType, String> = {
  ca: "CAD",
  eu: "EUR",
  intl: "USD",
  us: "USD",
};

export const CurrencySymbol: Record<RegionType, String> = {
  ca: "$",
  eu: "€",
  intl: "$",
  us: "$",
};

export type PartCategoryByCode = {
  [key: string]: {
    name: string;
    description: string;
  };
};
