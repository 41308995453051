import React from 'react'
import GenericBanner from '../../../../partials/common/banner/GenericBanner'
import AccordionNav from '../../../../components/views/AccordionNav'
import LandingPageVention101 from '../../../../components/cms/landing_pages/sections/LandingPageVention101'
import LandingPageLRContent from '../../../../components/cms/landing_pages/sections/LandingPageLRContent'
import DocsContact from '../../../../components/cms/docs/DocsContact'
import { specsItems } from '../../../../components/views/marketing/benefits/specsItems'
import StartBuildingButton from '../../../../components/assembly_card/StartBuildingButton'

const Benefits: React.FunctionComponent<{}> = () => (
  <div className='benefits-page'>
    <GenericBanner
      title='Benefits of Vention'
      subtitle='Discover what a manufacturing automation platform can do for your next automation project'
      theme='light'
      accentAUrl='https://assets.vention.io/page-assets/headers/services-accent-1.png'
      accentBUrl='https://assets.vention.io/page-assets/headers/benefits-accent.png'
    >
      <AccordionNav
        linkOffset={100}
        anchorLinks={[
          {
            href: 'how-soon-can-get-equipment',
            label: 'How soon can I get my custom equipment?',
          },
          {
            href: 'what-roi-can-be-expected',
            label: 'What ROI can be expected?',
          },
          {
            href: 'how-powerful-is-platform',
            label: 'How powerful is the platform?',
          },
          {
            href: 'how-much-money-save',
            label: 'How much can digital engineering save me?',
          },
          {
            href: 'where-do-i-start',
            label: 'Where do I start?',
          },
        ]}
      />
    </GenericBanner>
    <div className='benefits-page-section'>
      <h2 className='subheading'>Management benefits</h2>
      <div className='benefits-lr-component container-lg'>
        <div className='benefits-lr-component-text'>
          <div className='benefits-lr-component-text-wrapper'>
            <h3>Simplify the design-to-deploy workflow</h3>
            <p>
              Design, automate, order and deploy automated equipment in a single digital
              environment. Choose from hundreds of modular components. Every piece fits together, so
              there&apos;s no need to design custom parts.
            </p>
            <p>
              How fast can it be done? Vention clients typically complete projects in 3 to 10
              business days.
            </p>
            <div className='lp-dynamic-cta cta-link'>
              <StartBuildingButton label='Start designing now' anchorTag={true} />
            </div>
          </div>
        </div>
        <LandingPageVention101
          type='vention101'
          style={{ backgroundColor: 'white' }}
          title=''
          display='mobile'
        />
      </div>
      <LandingPageLRContent
        type='lrContent'
        alt='price comparison graphic'
        textAlign='right'
        imageUrl='https://assets.vention.io/page-assets/benefits-page/price-comparison-graph.svg'
        content={[
          {
            subtitle: 'Reduce the cost of machine design',
            content: `
              <p>Short-circuit the traditional design process and save precious engineering hours.</p>
              <p>
                The digital integration of MAP, from requirement definitions, to 3D design, cloud-simulation, 
                and factory commissioning, enables a continuous and intuitive environment suitable for all types 
                of manufacturing professionals, beyond design and control experts.
              </p>
            `,
          },
        ]}
        cta={{
          label: 'View customer stories',
          url: '/customer-stories',
        }}
      />
      <LandingPageLRContent
        type='lrContent'
        alt='production volume graphic'
        textAlign='left'
        imageUrl='https://assets.vention.io/page-assets/benefits-page/production-volume-graph.svg'
        content={[
          {
            subtitle: 'Accelerate ROI of your automation investment',
            content: `
              <p>Automate low-volume, high-mix production profitably with Vention's plug-and-play industrial automation components.</p>
              <p>
                Because Vention clients have no need for complex integration or custom software development,
                they typically earn a return on their automation investment within one year.
              </p>
            `,
          },
        ]}
        cta={{
          label: 'Discover automation components',
          url: '/parts-library',
        }}
      />
      <div className='info-banner'>
        <div className='info-banner-container'>
          <div id='how-soon-can-get-equipment' className='info-banner-block'>
            <h3>How soon can I get my custom equipment?</h3>
            <p>
              Within <b>3 to 10</b> business days. This includes all design, procurement and
              deployment tasks.
            </p>
          </div>
          <div id='what-roi-can-be-expected' className='info-banner-block'>
            <h3>What ROI can be expected?</h3>
            <p>
              Vention clients typically earn a return on their investment within
              <b> 1 year</b>, thanks to the self-serve manufacturing automation platform.
            </p>
          </div>
        </div>
      </div>

      <div id='engineering-benefits' className='engineering-benefits'>
        <h2 className='subheading'>Engineering benefits</h2>
        <LandingPageLRContent
          type='lrContent'
          alt="screencapture of Vention's cloud-CAD software"
          textAlign='right'
          imageUrl='https://assets.vention.io/page-assets/benefits-page/cloud-cad-software.png'
          content={[
            {
              subtitle: 'The manufacturing automation platform for all manufacturing professionals',
              content: `
                <p>
                  Design anything from simple equipment to fully automated machines in just a few hours, 
                  directly in your web browser. Further simplify your design process with Vention's advanced 
                  design features, like automatic part connections, a part recommendation engine, automated assembly 
                  drawings, and real-time pricing.</p>
                <p>Consult our video tutorial library and learn how to use Vention's MachineBuilder platform in 15 to 30 minutes.</p>
              `,
            },
          ]}
          cta={{
            label: 'Try out the MachineBuilder',
          }}
        />
        <LandingPageLRContent
          type='lrContent'
          alt="screencapture of Vention's design library"
          textAlign='left'
          imageUrl='https://assets.vention.io/page-assets/benefits-page/design-library.png'
          content={[
            {
              subtitle: 'Community-driven public design&nbsp;library',
              content: `
                <p>
                  Jump-start your design by browsing the free design library. Hundreds of designs are added monthly, 
                  covering everything from robotics to industrial automation, and manufacturing applications. Plus, 
                  add your design to the public library and you'll earn rewards towards your public profile.
                </p>
              `,
            },
          ]}
          cta={{
            label: 'Browse designs',
            url: '/designs',
          }}
        />
      </div>

      <div id='how-powerful-is-platform' className='container-lg platform-specs'>
        <h3 className='subheading'>
          A powerful manufacturing automation platform dedicated to custom industrial equipment
        </h3>
        <div className='platform-specs-container'>
          {specsItems.map((specCol, i) => (
            <div className={`specs-col col-${i + 1}`} key={`specs-col-${i + 1}`}>
              <h4 className='specs-col-header'>{specCol.colHeader}</h4>
              {specCol.items.map((colItem, j) => (
                <div className='specs-col-item' key={`specs-col-${i + 1}-item-${j + 1}`}>
                  <div
                    className='specs-col-item-img'
                    style={{
                      background: `center/60% no-repeat url(${colItem.img}) white`,
                    }}
                  />
                  <div className='specs-col-item-text'>
                    <h5>{colItem.title}</h5>
                    <p>{colItem.text}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className='text-center'>
          <StartBuildingButton
            label='Start designing in minutes'
            className='button button-md button-success'
          />
        </div>
      </div>

      <div className='info-banner'>
        <div className='info-banner-container'>
          <div id='how-much-money-save' className='info-banner-block'>
            <h3>How much can digital engineering save me?</h3>
            <p>
              <b>40%</b> — That&apos;s how much Vention clients reported saving, compared to
              traditional custom manufacturing.
            </p>
          </div>
          <div id='where-do-i-start' className='info-banner-block'>
            <h3>Where do I start?</h3>
            <p>
              It only takes <b>15 to 30 minutes</b> to learn your way around the Vention platform.
              Get started with a <a href='/resources/video-tutorials'>quick video tutorial</a> or{' '}
              <StartBuildingButton
                label='start designing now!'
                className='link-secondary'
                anchorTag={true}
              />
            </p>
          </div>
        </div>
      </div>
    </div>{' '}
    {/* END div.benefits-page-section */}
    <DocsContact
      title='Simplify your next manufacturing automation project'
      description='Reduce implementation times and costs with MAP'
    />
  </div>
)

export default Benefits
