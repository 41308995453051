import React from 'react'
import OnboardingCreateTeam from './OnboardingCreateTeam'

interface Props {
  readonly goToInviteToNewTeam: () => void
  readonly newTeamName: string
  readonly setNewTeamName: (value: string) => void
  readonly userFirstName: string
}

const OnboardingCreateTeamWrapper: React.FunctionComponent<Props> = ({
  goToInviteToNewTeam,
  newTeamName,
  setNewTeamName,
  userFirstName,
}) => {
  const handleNewTeamNameChange = e => {
    setNewTeamName(e.target.value)
  }

  const teamName = () => {
    if (newTeamName.length == 0) {
      return `${userFirstName}'s Team`
    } else {
      return newTeamName
    }
  }

  const handleCreateTeamSubmit = e => {
    e.preventDefault()
    setNewTeamName(teamName())
    goToInviteToNewTeam()
  }

  return (
    <OnboardingCreateTeam
      handleNewTeamNameChange={handleNewTeamNameChange}
      newTeamName={teamName()}
      handleCreateTeamSubmit={handleCreateTeamSubmit}
    />
  )
}

export default OnboardingCreateTeamWrapper
