import { buildCriterionExpression } from "@coveo/headless";
import React from "react";
import { VMediumText, VSemiBoldText } from "../../../components/text/Text";
import {
  Select,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SelectContent } from "@radix-ui/react-select";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { useSearchMade } from "../../hooks/part_library/useSearchMade";
import { useControllerState } from "../../utils/useControllerState";
import { useNoResults } from "../../hooks/part_library/noResults";

export const Sort: React.FC = () => {
  const controller = useControllerContext(ControllerType.Sort);
  const criteria = useControllerContext(ControllerType.Criteria);
  const state = useControllerState(controller);

  const searchMade = useSearchMade();
  const noResults = useNoResults();

  const getCriterionFromName = (name: string) =>
    criteria.find(([criterionName]) => criterionName === name)!;

  const getCurrentCriterion = () =>
    criteria.find(
      ([, criterion]) =>
        state.sortCriteria === buildCriterionExpression(criterion),
    )!;

  if (!searchMade || noResults) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 md:flex-row md:items-center md:m-4 text-md">
      <VSemiBoldText className="p-0 m-0">Sort by:&nbsp;</VSemiBoldText>
      <Select onValueChange={(value: string) => {
        controller.sortBy(getCriterionFromName(value)[1]);
      }}>
        <SelectTrigger className="w-60 pl-4 bg-white border-solid border-2 border-slate-50 focus:border-slate-300 text-lg">
          <SelectValue className="" placeholder={getCurrentCriterion()[0]} />
        </SelectTrigger>
        <SelectContent position="popper" className="w-60 z-50 border-2 border-slate-50 border-solid bg-white rounded-lg">
          <SelectGroup>
            {criteria.map(([criterionName]) => (
              <SelectItem
                className="hover:bg-slate-100"
                key={criterionName}
                value={criterionName}
              >
                <VMediumText fontSize="lg">{criterionName}</VMediumText>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
