import React, { useState } from 'react'
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm'
import InputValidator from '../../common/InputValidator'
import TextAreaValidator from '../../common/TextAreaValidator'
import { User } from '../../../api/Users/Types'
import PhoneCountryInput from '../../common/inputs/PhoneCountryInput/PhoneCountryInput'
import SuccessMessage from '../../common/SuccessMessage'
import VentionTerms from '../../onboarding/VentionTerms'
import I18n from '../../../helpers/I18n'
import { GoogleCaptchaV3Service } from '../../../api/Google/captchaV3.service'
import httpClient from '../../../api/httpClient'

interface Props {
  readonly currentUser?: Pick<User, 'email' | 'phone'>
  readonly captchaKey?: string
}

const ContactForm: React.FunctionComponent<Props> = ({ currentUser, captchaKey }) => {
  const [email, setEmail] = useState(currentUser?.email || '')
  const [phone, setPhone] = useState(currentUser?.phone)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const errorMessages = {
    required: I18n.t('views.common.form.errors.required'),
    invalid: (label: string) => I18n.t('views.common.form.errors.required', { field: label }),
  }

  const defaultLabels = {
    email: I18n.t('home.contact.form.fields.email'),
    phone: I18n.t('home.contact.form.fields.phone'),
    message: I18n.t('home.contact.form.fields.message'),
  }

  const resetState = () => {
    setEmail('')
    setPhone('')
    setMessage('')
  }

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    setIsSending(true)
    setError('')

    const formData = {
      email,
      phone,
      message,
    }

    formData['ga_client_id'] = window.ga_client_id

    GoogleCaptchaV3Service.verifyCaptcha(token => {
      if (token) {
        formData['g-recaptcha-response'] = token
      }
      submitForm(formData)
    }, captchaKey)
  }

  const submitForm = formData => {
    if (window._mfq) window._mfq.push(['formSubmitAttempt', '#contact-form'])

    httpClient
      .post('/contact', formData)
      .then(() => {
        resetState()
        setEmailSent(true)
        if (window._mfq) window._mfq.push(['formSubmitSuccess', '#contact-form'])
      })
      .catch(error => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'An error has occurred'
        setError(errorMessage)
        if (window._mfq) window._mfq.push(['formSubmitFailure', '#contact-form'])
      })
      .finally(() => {
        setIsSending(false)
      })
  }

  if (emailSent) {
    return (
      <div className='book-meeting-form success-message-container'>
        <SuccessMessage
          message={`${I18n.t('home.contact.form.success.text_1')}<br/>${I18n.t(
            'home.contact.form.success.text_2'
          )}`}
        />
      </div>
    )
  }

  return (
    <ValidatorForm
      id='contact-form'
      className='book-meeting-form'
      onSubmit={handleOnSubmit}
      instantValidate={false}
    >
      <InputValidator
        type='email'
        id='email'
        placeholder=' '
        onChange={event => setEmail(event.target.value)}
        name='email'
        value={email}
        validators={['required', 'isEmail']}
        errorMessages={[errorMessages.required, errorMessages.invalid(defaultLabels.email)]}
      >
        <label htmlFor='email'>{defaultLabels.email}</label>
      </InputValidator>

      <PhoneCountryInput placeholder=' ' phone={phone} onChange={phone => setPhone(phone)} required>
        <label htmlFor='phone'>{defaultLabels.phone}</label>
      </PhoneCountryInput>

      <TextAreaValidator
        className='form-control book-meeting-description'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setMessage(event.target.value)
        }}
        id='message'
        name='message'
        value={message}
        placeholder=' '
        validators={['required']}
        errorMessages={[errorMessages.required]}
        autoResize={true}
      >
        <label htmlFor='message'>{!message.length && defaultLabels.message}</label>
      </TextAreaValidator>

      <VentionTerms />

      {error && <div className='form-error-message'>{error}</div>}

      <button type='submit' className='contact-form-submit-button' disabled={isSending}>
        {I18n.t('home.contact.form.buttons.submit')}
      </button>
    </ValidatorForm>
  )
}

export default ContactForm
