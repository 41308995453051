import { useState } from "react"
import { QueryClient, useQuery } from "@tanstack/react-query";
import { ErrorMetadata, PaginationMetadata } from "../api/Types";

const queryClient = new QueryClient({});

export const useQueryPaginationGQL = <TResult, TRecord extends keyof TResult, TParams extends any[]>(
    K: TRecord & TResult[TRecord] extends PaginationMetadata & ErrorMetadata ? TRecord : never,
    keyName: string,
    params: TParams,
    defaultPage: number = 1, 
    defaultPerPage:number = 10 , 
    request: (page: number, perPage: number, ...args: TParams) => Promise<TResult>,
    enabled?: boolean | (() => boolean)
) => {

    const [page, setPage] = useState<number>(defaultPage);
    const [perPage, setPerPage] = useState<number>(defaultPerPage);

    const result = useQuery<TResult>({
        queryKey: [keyName, ...params, page, perPage],
        queryFn: async () => {
            return request(page, perPage, ...params)
        },
        enabled: typeof enabled === 'boolean' ? enabled : enabled? enabled() : true
    }, queryClient);

    const moveToPage = (page: number) => {
        if(page <= 0){
            return;
        }
        if(page > ((result?.data?.[K] as PaginationMetadata)?.metadata?.totalPages ?? 1)) {
            setPage((result?.data?.[K] as PaginationMetadata)?.metadata?.totalPages ?? 1)
        }else{
            setPage(page);
        }
    }

    const changePerPage = (perPage: number) => {
        if(perPage < 0){
            return // do nothing
        }
        setPerPage(perPage)
    }

    const pagePlusOne = () => {
        setPage(page + 1);
    }

    const pageMinusOne = () => {
        setPage(page - 1);
    }

    return {
        result,
        moveToPage,
        changePerPage,
        pagePlusOne,
        pageMinusOne,
        page, //current page
        perPage,
        lastPage: (result?.data?.[K] as PaginationMetadata)?.metadata?.totalPages ?? 0
    };
}