import httpClient from '../httpClient'
import { PartType } from '../../types/PartType'

const progressHandler = handler => progressEvent => {
  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  if (typeof handler === 'function') {
    handler(percentCompleted)
  }
}

const PartDocumentService = {
  createPartDocument: (
    partId: number,
    partType: PartType,
    partDocumentationPayload: string | ArrayBuffer | null,
    partnerPartDocumentationFilename: string | null,
    progressCallback: (number) => void
  ) => {
    const config = {
      onUploadProgress: progressHandler(progressCallback),
    }

    return httpClient
      .post(
        '/admin/part_documents',
        {
          file_name: partnerPartDocumentationFilename,
          part_id: partId,
          part_type: partType,
          payload: partDocumentationPayload,
        },
        config
      )
      .then(response => response.data)
  },

  deletePartDocument: partDocumentationId =>
    httpClient
      .delete(`/admin/part_documents/${partDocumentationId}`)
      .then(response => response.data),
}

export default PartDocumentService
