import React from 'react'
import DesignBrowser from '../../pages/designs/DesignBrowser'
import { User } from '../../api/Users/Types'
import DesignCategoriesProvider, {
  DesignCategoriesConsumer,
} from '../../components/common/providers/DesignCategoriesProvider'

interface Props {
  isRequestView: boolean
  currentUser: User
}

const DesignBrowserPage: React.FC<Props> = ({ currentUser, isRequestView }) => {
  return (
    <DesignCategoriesProvider skipFetch>
      {/* Consumer is required as `DesignBrowser` is a class component */}
      <DesignCategoriesConsumer>
        {designCategoriesContext => (
          <DesignBrowser
            currentUser={currentUser}
            isRequestView={isRequestView}
            designCategoriesLoading={!designCategoriesContext.isLoaded}
            designCategories={designCategoriesContext.designCategories}
          />
        )}
      </DesignCategoriesConsumer>
    </DesignCategoriesProvider>
  )
}

export default DesignBrowserPage
