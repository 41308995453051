import React from "react";
import { NumericFilter as NumericFilterController } from "@coveo/headless";
import { IconCurrencyDollar, IconCurrencyEuro } from "@tabler/icons-react";
import { useState } from "react";
import { VMediumText, VRegularText } from "../../../components/text/Text";
import { DualRangeSlider } from "../../../components/slider/DualRangeSlider";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { RegionType } from "../../../pages/views/part_searches/Types";
import { useControllerState } from "../../utils/useControllerState";
import { AccordionHeader } from "@radix-ui/react-accordion";

export interface NumericFilterProps {
  controller: NumericFilterController;
  title: string;
  region: RegionType;
}

export const NumericFilter: React.FC<NumericFilterProps> = (props) => {
  const { controller, region, title } = props;

  const state = useControllerState(controller);

  const [min, setMin] = useState<string>(state.range?.start?.toString() ?? "0");
  const [max, setMax] = useState<string>(state.range?.end?.toString() ?? "50000");

  return (
    <div className="min-w-56">
      <VMediumText fontSize="xl" className="flex flex-row bg-white justify-between w-full py-2 px-1 border border-b-2 border-t-0 border-l-0 border-r-0 border-slate-50 border-solid mt-2 mb-4">{title}</VMediumText>
      <div className="flex flex-col text-slate-500 opacity-100">
        <VRegularText className="mb-2 text-slate-700">Min</VRegularText>
        <div>
          {
            region === 'eu' ? <IconCurrencyEuro className="absolute w-5 h-5 left-12 mt-4" /> : <IconCurrencyDollar className="absolute w-5 h-5 left-12 mt-4" />
          }

          <input
            className="py-4 pr-4 pl-8 mb-4 w-full border-2 border-solid border-slate-300 rounded-lg"
            type="number"
            value={min}
            onChange={e => setMin(e.target.value)}
            placeholder="Start"
            onBlur={() => {
              controller.setRange({ start: Number(min), end: Number(max) })
            }}
          />
        </div>


        <VRegularText className="mb-2 text-slate-700">Max</VRegularText>
        <div className="flex flex-col text-slate-500 opacity-100">
          {
            region === 'eu' ? <IconCurrencyEuro className="absolute w-5 h-5 left-12 mt-4" /> : <IconCurrencyDollar className="absolute w-5 h-5 left-12 mt-4" />
          }
          <input
            className="py-4 pr-4 pl-8 w-full border-2 border-solid border-slate-300 rounded-lg"
            type="number"
            value={max}
            onChange={e => setMax(e.target.value)}
            placeholder="End"
          />
        </div>

        <DualRangeSlider
          value={[Number(min), Number(max)]}
          className="mb-8 mt-12"
          minStepsBetweenThumbs={0}
          min={0}
          max={500_000}
          onValueCommit={(value: number[]) => {
            controller.setRange({ start: Number(value[0]), end: Number(value[1]) })
          }}
          onValueChange={(value: number[]) => {
            setMin(value[0].toString());
            setMax(value[1].toString());
          }}
        />
      </div>
    </div>
  );
};
