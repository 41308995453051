import React from 'react'
import Subscriptions from './sections/Subscriptions'
import Header from './sections/Header'
import FAQs from './sections/FAQs'

interface Faq {
  question: string
  answer: string
}

interface TableGroupRow {
  title?: string
  description: string
  descriptionLink?: string
  checkedValues: Array<'true' | 'false'>
}

interface TableGroup {
  title: string
  titleIcon?: string
  rows: TableGroupRow[]
}

interface PricingCard {
  supertitle: string
  pill?: string
  usdBasePrice: number
  trailingPriceText: string
  linkPrimaryLabel: string
  linkPrimaryUrl?: string
  linkPrimaryToNewDesign?: boolean
  linkSecondaryLabel: string
  linkSecondaryAlternateLabel: string
  description: string
}

export interface ContentfulEntry {
  headerSupertitle: string
  headerTitle: string
  headerDescription: string
  tieredPlansTitle: string
  tieredPlansDescription: string
  tieredPlansPricingCards: PricingCard[]
  tieredPlansTableGroups: TableGroup[]
  bundlePlanTitle: string
  bundlePlanPill: string
  bundlePlanDescription: string
  bundlePlanPricingCard: PricingCard
  bundlePlanFeaturesList: string[]
  bundlePlanTableGroups: TableGroup[]
  faqsSupertitle: string
  faqsTitle: string
  faqsDescription: string
  faqsList: Faq[]
  faqsFooterTitle: string
  faqsFooterDescription: string
  faqsFooterLinkLabel: string
  faqsFooterLinkUrl: string
}

export interface CurrencyExchangeRate {
  rate: number
  currency_symbol: string
}

interface Props {
  contentfulEntry: ContentfulEntry
  currencyExchangeRate: CurrencyExchangeRate
}

const Page: React.FC<Props> = ({ contentfulEntry, currencyExchangeRate }) => {
  return (
    <div className='subscriptions-pricing'>
      <Header contentfulEntry={contentfulEntry} />
      <Subscriptions
        contentfulEntry={contentfulEntry}
        currencyExchangeRate={currencyExchangeRate}
      />
      <FAQs contentfulEntry={contentfulEntry} />
    </div>
  )
}

export default Page
