import React from 'react'

interface Props {
  readonly minPlaceholder?: string
  readonly maxPlaceholder?: string
  readonly handleSubmit: (min: number, max: number) => void
}

class CustomRangeFilter extends React.Component<Props> {
  private minValue
  private maxValue

  constructor(props) {
    super(props)
    this.minValue = React.createRef()
    this.maxValue = React.createRef()
  }
  static defaultProps = {
    minPlaceholder: 'Min',
    maxPlaceholder: 'Max',
  }

  render() {
    const { minPlaceholder, maxPlaceholder, handleSubmit } = this.props
    return (
      <div className='custom-range-filter'>
        <input ref={this.minValue} placeholder={minPlaceholder} />
        <input ref={this.maxValue} placeholder={maxPlaceholder} />
        <button
          className='custom-range-filter-go'
          onClick={() => handleSubmit(this.minValue.current.value, this.maxValue.current.value)}
        >
          Go
        </button>
      </div>
    )
  }
}

export default CustomRangeFilter
