import React, { useState } from 'react'
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm'
import InputValidator from '../../common/InputValidator'
import SelectValidator from '../../common/SelectValidator.jsx'
import TextAreaValidator from '../../common/TextAreaValidator'
import HomeService from '../../../api/Home/Service'
import { User } from '../../../api/Users/Types'
import PhoneCountryInput from '../../common/inputs/PhoneCountryInput/PhoneCountryInput'
import SuccessMessage from '../../common/SuccessMessage'
import VentionTerms from '../../onboarding/VentionTerms'
import I18n from '../../../helpers/I18n'
import { GoogleCaptchaV3Service } from '../../../api/Google/captchaV3.service'

type InputType = 'email' | 'subject' | 'phone' | 'ventionOrderId' | 'description' | 'primaryDomain'
type AvailableFields = { [label in InputType]?: boolean }

export const TalkToSupportInputs: ReadonlyArray<InputType> = [
  'email',
  'subject',
  'phone',
  'ventionOrderId',
  'description',
  'primaryDomain',
]
interface Props {
  readonly currentUser?: User
  readonly captchaKey?: string
  readonly inputs: ReadonlyArray<InputType>
  readonly labels?: Partial<{ [label in InputType]: string }>
  readonly submitLabel?: string
  readonly apiEndpoint?: string
}

const TalkToSupportForm: React.FunctionComponent<Props> = ({
  currentUser,
  captchaKey,
  inputs,
  labels,
  apiEndpoint,
  children,
}) => {
  const [email, setEmail] = useState(currentUser?.email || '')
  const [phone, setPhone] = useState(currentUser?.phone)
  const [error, setError] = useState('')
  const [subject, setSubject] = useState('')
  const [ventionOrderId, setVentionOrderId] = useState('')
  const [primaryDomain, setPrimaryDomain] = useState('')
  const [description, setDescription] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const errorMessages = {
    required: I18n.t('views.common.form.errors.required'),
    invalid: (label: string) => I18n.t('views.common.form.errors.required', { field: label }),
  }

  const primaryDomainOptions = (I18n.t(
    'home.talk_to_support.form.fields.primary_domain_options'
  ) as unknown) as string[]

  const DefaultLabels: { [label in InputType]: string } = {
    email: I18n.t('home.talk_to_support.form.fields.email'),
    phone: I18n.t('home.talk_to_support.form.fields.phone'),
    subject: I18n.t('home.talk_to_support.form.fields.subject'),
    description: I18n.t('home.talk_to_support.form.fields.description'),
    primaryDomain: I18n.t('home.talk_to_support.form.fields.primary_domain'),
    ventionOrderId: I18n.t('home.talk_to_support.form.fields.vention_order_id'),
  }

  const availableFields: AvailableFields = TalkToSupportInputs.reduce((result, item) => {
    result[item] = inputs.includes(item)
    return result
  }, {})

  const resetState = () => {
    setEmail('')
    setPhone('')
    setSubject('')
    setVentionOrderId('')
    setPrimaryDomain('')
    setDescription('')
  }

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    setIsSending(true)
    event.preventDefault()
    setError('')

    const formData = {}
    if (availableFields?.email) formData['email'] = email
    if (availableFields?.phone) formData['phone'] = phone
    if (availableFields?.subject) formData['subject'] = subject
    if (availableFields?.ventionOrderId) formData['vention_order_id'] = ventionOrderId
    if (availableFields?.description) formData['description'] = description
    if (availableFields?.primaryDomain) formData['primary_domain'] = primaryDomain
    // uncomment below for testing
    //formData['debug'] = 1
    //formData['debugEmail'] = 'nachiket.pusalkar@vention.cc'

    formData['ga_client_id'] = window.ga_client_id
    formData['00N4v00000BqKZT'] = ventionOrderId
    formData['00N6g00000TcDMW'] = primaryDomain
    formData['orgid'] = '00D6g000000DIg1'
    formData['retURL'] = 'http://'

    GoogleCaptchaV3Service.verifyCaptcha(token => {
      if (token) {
        formData['g-recaptcha-response'] = token
      }
      submitContactUs(formData)
    }, captchaKey)
  }

  const submitContactUs = formData => {
    if (window._mfq) window._mfq.push(['formSubmitAttempt', '#talk-to-support-form'])

    HomeService.submitContactUs(
      formData,
      (success, err) => {
        if (success) {
          resetState()

          setEmailSent(true)

          GoogleAnalyticsHelper.VentionGAEvent('talk-to-an-expert-submit', 'submit', 'funnel')

          setIsSending(false)

          if (window._mfq) window._mfq.push(['formSubmitSuccess', '#talk-to-support-form'])
        } else if (err) {
          const errorMessage = err.responseJSON ? err.responseJSON.message : 'An error has occurred'

          setError(errorMessage)

          setIsSending(false)

          if (window._mfq) window._mfq.push(['formSubmitFailure', '#talk-to-support-form'])
        }
      },
      apiEndpoint
    )
  }

  if (emailSent) {
    return (
      <div className='book-meeting-form success-message-container'>
        <SuccessMessage
          message={`${I18n.t('home.talk_to_support.form.success.text_1')}<br/>${I18n.t(
            'home.talk_to_support.form.success.text_2'
          )}`}
        />
      </div>
    )
  }

  return (
    <ValidatorForm
      id='talk-to-support-form'
      className='book-meeting-form'
      onSubmit={handleOnSubmit}
      instantValidate={false}
    >
      {children}
      {availableFields?.email && (
        <InputValidator
          type='email'
          id='email'
          placeholder=' '
          onChange={event => setEmail(event.target.value)}
          name='email'
          value={email}
          validators={['required', 'isEmail']}
          errorMessages={[
            errorMessages.required,
            errorMessages.invalid(labels?.email || DefaultLabels.email),
          ]}
        >
          <label htmlFor='email'>{(labels && labels.email) || DefaultLabels.email}</label>
        </InputValidator>
      )}
      {availableFields?.subject && (
        <InputValidator
          type='text'
          id='subject'
          value={subject}
          placeholder=' '
          onChange={event => setSubject(event.target.value)}
          name='subject'
          validators={['required']}
          errorMessages={[errorMessages.required]}
        >
          <label htmlFor='subject'>{(labels && labels.subject) || DefaultLabels.subject}</label>
        </InputValidator>
      )}
      {availableFields?.ventionOrderId && (
        <InputValidator
          type='text'
          id='vention-order-id'
          value={ventionOrderId}
          placeholder=' '
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setVentionOrderId(event.target.value)
          }
          name='vention_order_id'
        >
          <label htmlFor='vention-order-id'>
            {(labels && labels.ventionOrderId) || DefaultLabels.ventionOrderId}
          </label>
        </InputValidator>
      )}
      {availableFields?.phone && (
        <>
          <PhoneCountryInput
            placeholder=' '
            phone={phone}
            onChange={phone => setPhone(phone)}
            required
          >
            <label htmlFor='phone'>{(labels && labels.phone) || DefaultLabels.phone}</label>
          </PhoneCountryInput>
        </>
      )}
      {availableFields?.description && (
        <TextAreaValidator
          className='form-control book-meeting-description'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDescription(event.target.value)
          }}
          id='description'
          name='description'
          value={description}
          placeholder=' '
          validators={['required', 'maxStringLength:500']}
          characterLimit={500}
          errorMessages={[errorMessages.required]}
          autoResize={true}
        >
          <label htmlFor='description'>
            {!description.length && ((labels && labels.description) || DefaultLabels.description)}
          </label>
        </TextAreaValidator>
      )}
      {availableFields?.primaryDomain && (
        <div className='form-group'>
          <SelectValidator
            className='form-control application-select'
            addWrapperAroundSelect={true}
            name='use-case'
            value={primaryDomain}
            options={primaryDomainOptions}
            validators={['required']}
            errorMessages={[I18n.t('home.talk_to_support.form.errors.select_domain')]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPrimaryDomain(event.target.value)
            }
            placeholderOption={DefaultLabels.primaryDomain}
          />
        </div>
      )}
      <VentionTerms />

      {error && <div className='form-error-message'>{error}</div>}

      <button type='submit' className='contact-form-submit-button' disabled={isSending}>
        {I18n.t('views.common.form.buttons.submit')}
      </button>
    </ValidatorForm>
  )
}

TalkToSupportForm.defaultProps = {
  apiEndpoint: '/talk_to_support_submit',
} as Partial<Props>

export default TalkToSupportForm
