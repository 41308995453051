import React from 'react'
import { LocalizedPaths } from '../../../../types/Common'
import I18nProvider from '../../../../components/common/providers/I18nProvider'
import Form from './components/Form'
import { User } from '../../../../api/Users/Types'

interface Props {
  currentUser: User
  localizedPaths: LocalizedPaths
  captchaKey: string
  year: number
}

const Page: React.FC<Props> = ({ currentUser, localizedPaths, captchaKey, year }) => {
  return (
    <I18nProvider localizedPaths={localizedPaths}>
      <div className='diy-industrial-automation-report'>
        <div className='diy-industrial-automation-report__cover'>
          <img
            src='https://assets.vention.io/page-assets/diy-report/diy-industrial-automation-report-cover.jpg'
            alt='DIY Industrial Automation Report Cover'
          />
        </div>
        <Form
          currentUser={currentUser}
          localizedPaths={localizedPaths}
          captchaKey={captchaKey}
          year={year}
        />
      </div>
    </I18nProvider>
  )
}

export default Page
