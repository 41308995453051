import React from 'react'
import Button from '../../../../../components/vention_ui/shared_components/Button'

const Faqs: React.FC = () => {
  return (
    <section id='faqs' className='product-viewer__faqs product-viewer__section'>
      <div className='product-viewer__footer'>
        <div className='product-viewer__footer-text'>
          <h2 className='product-viewer__footer-text-title'>Questions about this part?</h2>
          <p className='product-viewer__footer-text-description'>
            Post a question on Vention community forum for insights on this part.
          </p>
        </div>

        <div className='product-viewer__footer-cta'>
          <Button size='xl' heirarchy='primary' href='https://forum.vention.io/' target='_blank'>
            Go to the forum
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Faqs
