import React, { useState } from 'react'
import OnboardingInviteToNewTeam from './OnboardingInviteToNewTeam'

interface NewTeamEmails {
  readonly email: string
}
interface Props {
  readonly teamMateEmails: Array<string>
  readonly setTeamMateEmails: React.Dispatch<React.SetStateAction<Array<string>>>
  readonly goToFinish: () => void
  readonly createNewTeam: () => Promise<number | undefined>
  readonly inviteToNewTeam: (teamID: number, newTeamEmails: Array<NewTeamEmails>) => Promise<void>
  readonly newTeamName: null | string
  readonly referral_code: number
}

const OnboardingInviteToNewTeamWrapper: React.FunctionComponent<Props> = ({
  teamMateEmails,
  setTeamMateEmails,
  goToFinish,
  createNewTeam,
  inviteToNewTeam,
  newTeamName,
  referral_code,
}) => {
  const [invitingToTeam, setInvitingToTeam] = useState(false)

  const handleInviteNewMembersSubmit = async event => {
    event.preventDefault()

    setInvitingToTeam(true)

    const newTeamEmails = teamMateEmails.map(email => {
      return { email: email }
    })

    // 1. If create team form is populated then create team
    if (newTeamName !== '') {
      const teamID = await createNewTeam()
      // 2. If emails are set then invite new members to your team
      if (teamMateEmails[0] !== '' && teamMateEmails.length >= 1 && teamID !== undefined) {
        await inviteToNewTeam(teamID, newTeamEmails)
      }
    }

    setInvitingToTeam(false)
    goToFinish()
  }

  const handleSkip = async event => {
    event.preventDefault()

    if (newTeamName !== '') {
      await createNewTeam()
    }
    goToFinish()
  }

  const handleAddEmail = event => {
    event.preventDefault()
    setTeamMateEmails([...teamMateEmails, ''])
  }

  const handleUpdateEmailFieldChange = event => {
    const emailIndex = event.target.dataset.index
    teamMateEmails[emailIndex] = event.target.value
    setTeamMateEmails([...teamMateEmails])
  }

  const handleDeleteEmailFieldClick = event => {
    event.preventDefault()
    if (teamMateEmails.length > 1) {
      const emailIndex = event.target.dataset.emailIndex
      teamMateEmails.splice(emailIndex, 1)
      setTeamMateEmails([...teamMateEmails])
    }
  }

  return (
    <OnboardingInviteToNewTeam
      teamMateEmails={teamMateEmails}
      handleAddEmail={handleAddEmail}
      handleUpdateEmailFieldChange={handleUpdateEmailFieldChange}
      handleInviteNewMembersSubmit={handleInviteNewMembersSubmit}
      handleDeleteEmailFieldClick={handleDeleteEmailFieldClick}
      handleSkip={handleSkip}
      referral_code={referral_code}
      invitingToTeam={invitingToTeam}
    />
  )
}

export default OnboardingInviteToNewTeamWrapper
