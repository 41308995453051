import React from "react";
import { VRegularText } from "../text/Text";
import { PartCheckbox } from "../../components/parts/PartCheckbox";
import { PartDTO } from "../../api/Parts/dtos/PartDTO";

export type SimplePartCardV2Props = {
  readonly part: Partial<PartDTO>,
  readonly isSelectable: boolean
  readonly isSelected: boolean;
  readonly isPinned?: boolean;
  readonly onSelectClicked?: (part: Partial<PartDTO>) => void;
}

export const SimplePartCardV2: React.FC<SimplePartCardV2Props> = (props: SimplePartCardV2Props) => {
  const { part, isSelectable, isSelected, onSelectClicked } = props;

  const SelectCheckbox = () => {
    if (!isSelectable) {
      return null;
    }

    return (
      <PartCheckbox<Partial<PartDTO>>
        element={part}
        isSelected={isSelected}
        onSelectClicked={onSelectClicked} />
    )
  }
  return (
    <div className="simple-card__card-link" style={{ maxWidth: "100px" }}>
      <div className="simple-card__card-link-image-container">
        <img
          className="simple-card__card-link-image"
          src={part?.imagePath}
          alt={part?.name}
          loading="lazy"
        />
      </div>
      {
        props.isPinned && (<div className="chip-pinned">Pinned</div>)
      }
      <SelectCheckbox />

      <div className="simple-card__card-link-text">
        <VRegularText className="space-padding-none space-margin-none space-margin-top-lg" color='terciary' fontSize='lg'>
          {part?.partNumber ?? ''}
        </VRegularText>

        <VRegularText className="space-padding-none space-margin-none" color="primary" fontSize="lg">
          {part?.name ?? ''}
        </VRegularText>
      </div>
    </div>
  )
}