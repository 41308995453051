import React from 'react'
import BlogCard from './BlogCard'
import I18n from '../../../../../helpers/I18n'
import { BlogPost } from '../../../../../api/Blog/Types'

interface Props {
  blogs: BlogPost[]
}

const Blogs: React.FC<Props> = ({ blogs }) => {
  return (
    <section className='contact__blogs'>
      <div className='contact__blogs-container'>
        <p className='contact__blogs-supertitle'>
          {I18n.t('views.contacts.components.blogs.supertitle')}
        </p>
        <h2 className='contact__blogs-title'>{I18n.t('views.contacts.components.blogs.title')}</h2>
        <div className='contact__blogs-grid'>
          {blogs.map((blog, index) => (
            <BlogCard key={index} blog={blog} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Blogs
