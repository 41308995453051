import React from 'react'
import {
  VENTION_EU_PHONE_NUMBER,
  VENTION_US_PHONE_NUMBER,
  VENTION_UK_PHONE_NUMBER,
} from '../../constants/Vention'
import I18n from '../../helpers/I18n'

interface Props {
  readonly background: string
  readonly listItems: ReadonlyArray<string>
  readonly headerText: string
  readonly expertImageUrl: string
}
const ExpertInfo: React.FunctionComponent<Props> = ({
  headerText,
  listItems,
  background,
  expertImageUrl,
}) => {
  return (
    <div className={`expert-info-background background-${background}`}>
      <div className={`expert-info-container container-${background}`}>
        <h1 className='expert-info-title'>{headerText}</h1>
        <img className='expert-info-image' src={expertImageUrl} alt='talk to an expert' />
        <ul className='expert-info-list'>
          {listItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <div className='expert-info-phone'>
          {I18n.t('views.common.get_support_now')}:{' '}
          <div>
            NA{' '}
            <a
              className={`expert-info-phone-${background}`}
              href={`tel:${VENTION_US_PHONE_NUMBER}`}
            >
              {VENTION_US_PHONE_NUMBER}
            </a>
          </div>
          <div>
            EU{' '}
            <a
              className={`expert-info-phone-${background}`}
              href={`tel:${VENTION_EU_PHONE_NUMBER}`}
            >
              {VENTION_EU_PHONE_NUMBER}
            </a>
          </div>
          <div>
            UK{' '}
            <a
              className={`expert-info-phone-${background}`}
              href={`tel:${VENTION_UK_PHONE_NUMBER}`}
            >
              {VENTION_UK_PHONE_NUMBER}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpertInfo
