import React, { useState, useEffect } from 'react'
import { useBlogPosts } from '../../hooks/useBlogPosts'
import Pagination from '../../components/common/Pagination'
import { BlogPostQuerySettings } from '../../api/Blog/Types'
import BlogViewLanding from './BlogViewLanding'
import BlogViewFiltered from './BlogViewFiltered'

function initialSearchParams(): BlogPostQuerySettings {
  const url = new URL(window.location.href)
  const pageNumber = Number(url.searchParams.get('pageNumber')) || 1
  const filterBy = url.searchParams.get('filterBy') || undefined
  const editor = url.searchParams.get('editor') || undefined
  return {
    pageNumber,
    filterBy,
    editor,
  }
}

const Blog: React.FC<{}> = () => {
  const [activeCategory, setActiveCategory] = useState('')
  const [querySettings, setQuerySettings] = useState<BlogPostQuerySettings>(() =>
    initialSearchParams()
  )
  const { blogPosts, fetchBlogPosts, isLoading, count: blogPostsCount, perPage } = useBlogPosts(
    querySettings
  )
  const { blogPosts: featuredPosts } = useBlogPosts({
    limit: 1,
    feature: 'featured',
  })
  const featuredPost = featuredPosts[0]
  const pagination = {
    pageNumber: querySettings.pageNumber || 1,
    numberOfPages: perPage ? Number(Math.ceil(blogPostsCount / perPage)) : 0,
  }
  const isFilteredView =
    querySettings.filterBy ||
    querySettings.editor ||
    querySettings.searchTerm ||
    pagination.pageNumber > 1

  function updateSearchParams(querySettings): void {
    const url = new URL(window.location.href)

    for (const key in querySettings) {
      const value = querySettings[key]
      if (value) {
        url.searchParams.set(key, value)
      } else {
        url.searchParams.delete(key)
      }
    }
    window.history.pushState({}, '', url.href)
  }

  function handleSearch(searchTerm): void {
    const updatedQuery = { ...querySettings, searchTerm: searchTerm }
    setQuerySettings(updatedQuery)
    updateSearchParams(updatedQuery)
  }

  function handlePageChange(pageNumber: BlogPostQuerySettings['pageNumber']): void {
    const updatedQuery = { ...querySettings, pageNumber }
    setQuerySettings(updatedQuery)
    updateSearchParams(updatedQuery)
  }

  function handleChangeCategory(newCategory): void {
    const filterBy = newCategory !== 'all' ? newCategory : null
    setActiveCategory(newCategory)
    setQuerySettings({ ...querySettings, filterBy, pageNumber: 1 })
  }

  function initializeCategory(): void {
    const category =
      typeof querySettings.filterBy === 'object'
        ? querySettings.filterBy[0]
        : querySettings.filterBy
    setActiveCategory(category || '')
  }

  useEffect(() => {
    fetchBlogPosts(querySettings)
    updateSearchParams(querySettings)
  }, [
    querySettings.pageNumber,
    querySettings.filterBy,
    querySettings.searchTerm,
    querySettings.editor,
  ])

  useEffect(() => {
    initializeCategory()
    window.onpopstate = () => {
      fetchBlogPosts(querySettings)
    }
  }, [])

  return (
    <div className='blog-page'>
      {isFilteredView ? (
        <BlogViewFiltered
          posts={blogPosts}
          isLoading={isLoading}
          querySettings={querySettings}
          handleChangeCategory={handleChangeCategory}
          handleSearch={handleSearch}
          activeCategory={activeCategory}
        />
      ) : (
        <BlogViewLanding
          posts={blogPosts}
          isLoading={isLoading}
          featuredPost={featuredPost}
          handleChangeCategory={handleChangeCategory}
          handleSearch={handleSearch}
          activeCategory={activeCategory}
        />
      )}
      {pagination.numberOfPages > 1 && (
        <Pagination
          currentPage={pagination.pageNumber}
          numPages={pagination.numberOfPages}
          onPageChange={handlePageChange}
          nextLabel='Older posts'
          nextOn={pagination.pageNumber < pagination.numberOfPages}
          previousLabel='Newer posts'
          previousOn={pagination.pageNumber > 1}
        />
      )}
    </div>
  )
}

export default Blog
