import React from 'react'

interface Props {
  readonly text: string
  readonly author: string
  readonly authorImg: string
}

const Careers: React.FunctionComponent<Props> = ({ text, author, authorImg }) => {
  return (
    <div className='careers-quote'>
      <img className='careers-quote-img' src={authorImg} alt='Author' />
      <div className='wrapper'>
        <p className='careers-quote-text'>&quot;{text}&quot;</p>
        <div className='careers-quote-author'>{author}</div>
      </div>
    </div>
  )
}

export default Careers
