import React, { useState } from "react";
import { ShowPart } from "../Page";

interface Props {
  readonly product: ShowPart;
}

export const ImageGallery: React.FC<Props> = ({ product }) => {
  const [selectedImage, setSelectedImage] = useState(product.image_path);
  const [selectedImageId, setSelectedImageId] = useState(0);

  return (
    <div>
      <div className="product-viewer-v2__header-image-container">
        {product.certification_logos && (
          <div className="product-viewer-v2__header-certification-logos">
            {product.certification_logos.map((logo, index) => (
              <img
                key={index}
                className="product-viewer-v2__header-certification-logo"
                src={logo.image_url}
                alt={logo.name}
              />
            ))}
          </div>
        )}
        {product.additional_image && (
          <img
            className="product-viewer-v2__header-additional-image"
            src={product.additional_image.path}
            alt={product.additional_image.className}
          />
        )}
        <img
          className="product-viewer-v2__header-image"
          src={selectedImage}
          alt={product.name}
        />
        <div className="mt-4 flex gap-2 -mb-16">
          <button className="button-blank m-0 p-0"
            onClick={() => {
              setSelectedImage(product.image_path);
              setSelectedImageId(0);
            }}>
            <img
              className={`w-14 h-14 rounded-lg border-2 cursor-pointer border-solid ${selectedImageId === 0 ? 'border-violet-200' : 'border-slate-50'}`}
              src={product.image_path}
              alt={product.name}
            />
          </button>
          {
            product.part_images?.map((partImage) => {
              return (
                <button className="button-blank m-0 p-0"
                  onClick={() => {
                    setSelectedImage(partImage.image.url);
                    setSelectedImageId(partImage.id);
                  }}>
                  <img
                    className={`w-14 h-14 rounded-lg border-2 cursor-pointer border-solid ${selectedImageId === partImage.id ? 'border-violet-200' : 'border-slate-50'}`}
                    key={partImage.id}

                    alt={product.name}
                    src={partImage.image.thumb.url}
                  />
                </button>
              )
            })
          }
        </div>
      </div>



    </div>

  )
};
