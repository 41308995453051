import React from 'react'
import PropTypes from 'prop-types'

const SuccessMessage = props => {
  return (
    <div className='success-message'>
      <i className='success-message-icon fas fa-check-circle' />
      {React.createElement('div', {
        className: 'text-center',
        dangerouslySetInnerHTML: { __html: props.message },
      })}
    </div>
  )
}

SuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default SuccessMessage
