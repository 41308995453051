import { QueryClient, useQuery, UseQueryResult } from "@tanstack/react-query";

const queryClient = new QueryClient({});

export const useQuerySimpleGQL = <T>(
    keyName: string,
    params: any[],
    request: ( ...args: any) => Promise<T>,
    enabled?: boolean | (() => boolean)
) : { result: UseQueryResult<T> }=> {

    const result = useQuery<T>({
        queryKey: [keyName, ...params],
        queryFn: async () => {
            return request(...params)
        },
        enabled: typeof enabled === 'boolean' ? enabled : enabled? enabled() : true
    }, queryClient);

    return {
        result
    };
}