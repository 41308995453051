import React, { useState } from 'react'
import QuantitySelector from '../../../../../components/vention_ui/shared_components/QuantitySelector'
import Button from '../../../../../components/vention_ui/shared_components/Button'
import Breadcrumbs from '../../../../../components/vention_ui/application_components/Breadcrumbs'
import { ShowPart, ShowPartVariantOption } from '../Page'
import ReadMoreText from '../../../../../components/vention_ui/shared_components/ReadMoreText'
import * as CartService from '../../../../../api/Cart/Service'
import { scrollTo } from '../../../../../helpers/WindowHelper'
import Badge from '../../../../../components/vention_ui/shared_components/Badge'

const SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS = 8

interface Props {
  readonly product: ShowPart
  readonly productVariantOptions: ReadonlyArray<ShowPartVariantOption>
}

export const getStatusColor = statusClassName => {
  switch (statusClassName) {
    case 'warning':
      return 'error'
    case 'soon':
      return 'warning'
    default:
      return 'brand'
  }
}

const onProductVariantOptionChange = event => {
  window.location.href = `/parts/${event.target.value}`
}

const onTechDocsClick = event => {
  event.preventDefault()

  const element = document.querySelector('#technical-documents')
  scrollTo(element)
}

const Header: React.FC<Props> = ({ product, productVariantOptions }) => {
  const [quantity, setQuantity] = useState<number | null>(null)
  const [addingToCart, setAddingToCart] = useState(false)

  const buildBreadcrumbs = () => {
    const breadCrumbs = [
      {
        label: 'Parts Library',
        link: '/parts-library',
      },
    ]

    if (product.part_category) {
      breadCrumbs.push({
        label: product.part_category.parent.name,
        link: `/parts-library/${product.part_category.parent.url}`,
      })
      breadCrumbs.push({
        label: product.part_category.name,
        link: `/parts-library/${product.part_category.url}`,
      })
    }

    breadCrumbs.push({
      label: 'Product Details',
      link: product.canonical_url,
    })

    return breadCrumbs
  }

  const onQuantityChange = (quantity: number) => {
    setQuantity(quantity)
  }

  const onAddToCartClick = () => {
    if (!quantity) return

    setAddingToCart(true)
    CartService.quickAddToCart(null, `/parts/${product.id}/buy?quantity=${quantity}`, 'Part', () =>
      setAddingToCart(false)
    )
  }

  const currentVariantOption = productVariantOptions.find(
    variantOption => variantOption.id === product.id
  )

  return (
    <header className='product-viewer__header'>
      <Breadcrumbs
        className='product-viewer__header-breadcrumbs'
        type='extended'
        divider='chevron'
        breadcrumbs={buildBreadcrumbs()}
      />
      <div className='product-viewer__header-container'>
        <div className='product-viewer__header-image-container'>
          {product.certification_logos && (
            <div className='product-viewer__header-certification-logos'>
              {product.certification_logos.map((logo, index) => (
                <img
                  key={index}
                  className='product-viewer__header-certification-logo'
                  src={logo.image_url}
                  alt={logo.name}
                />
              ))}
            </div>
          )}
          {product.additional_image && (
            <img
              className='product-viewer__header-additional-image'
              src={product.additional_image.path}
              alt={product.additional_image.className}
            />
          )}
          <img
            className='product-viewer__header-image'
            src={product.image_path}
            alt={product.name}
          />
        </div>
        <div className='product-viewer__header-details'>
          <div>
            <p className='product-viewer__header-number'>{product.part_number}</p>
            <h1 className='product-viewer__header-name'>{product.name}</h1>
            <p className='product-viewer__header-price'>{product.display_price}</p>
            {product.shipment_delay_weeks < SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS && <div className='product-viewer__header-statuses'>
                <Badge
                  size='lg'
                  color={getStatusColor(product.status_availability.className)}
                  label={product.status_availability.text}
                  iconLeading={
                    product.status_availability.className === 'in-stock' ? 'ti ti-truck' : undefined
                  }
                />

                {product.is_light_duty && <Badge size='lg' color='gray' label='Light duty part' />}
              </div>
            }
            <div className='product-viewer__header-description'>
              <ReadMoreText lines={4} readMoreText='See more' readLessText='See less'>
                {product.description}
              </ReadMoreText>
            </div>
          </div>
          <div className='product-viewer__header-details-footer'>
            {(product.allow_checkout && product.shipment_delay_weeks < SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS) ? (
              <>
                <div className='product-viewer__header-selectors'>
                  {productVariantOptions.length > 0 && (
                    <div className='product-viewer__header-dropdown'>
                      <label className='product-viewer__header-dropdown-label'>Length</label>
                      <div className='product-viewer__header-dropdown-select-wrapper'>
                        <select
                          onChange={onProductVariantOptionChange}
                          defaultValue={currentVariantOption?.slug}
                        >
                          {productVariantOptions.map(option => (
                            <option key={option.id} value={option.slug}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className='product-viewer__header-quantity'>
                    <QuantitySelector
                      onQuantityChange={onQuantityChange}
                      name='quantity'
                      label='Quantity'
                      size='xl'
                    />
                  </div>
                </div>
                <Button
                  className='product-viewer__header-cta'
                  heirarchy='primary'
                  size='2xl'
                  iconLeading='ti ti-shopping-cart'
                  onClick={onAddToCartClick}
                  disabled={addingToCart}
                >
                  {addingToCart ? 'Adding...' : 'Add to cart'}
                </Button>
              </>
            ) : (
              <Button
                className='product-viewer__header-cta'
                heirarchy='primary'
                size='2xl'
                iconLeading='ti ti-messages'
                href='/contact'
              >
                Contact Us
              </Button>
            )}

            <Button
              className='product-viewer__header-cta'
              heirarchy='secondary-gray'
              size='2xl'
              iconLeading='ti ti-file-analytics'
              onClick={onTechDocsClick}
            >
              See technical documents
            </Button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
