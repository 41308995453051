import React, { useState } from 'react'
import HeaderCard from './HeaderCard'
import SectionLead from '../../../../shared_components/SectionLead'

interface Props {
  contentfulEntry: any
}

const Header: React.FC<Props> = ({ contentfulEntry }) => {
  const [backgroundImageIndex, setBackgroundImageIndex] = useState<number>(0)

  return (
    <header className='master-palletizer-landing-page__header'>
      <SectionLead align='center' maxWidth='800px' linkIcon='ti ti-arrow-right' {...contentfulEntry.headerSectionLead} />
      <div className='master-palletizer-landing-page__header-background-container' style={{backgroundImage: `url(${contentfulEntry.headerBackgroundImages[backgroundImageIndex].file.url})`}}>
        <div className='master-palletizer-landing-page__header-background-gradient'></div>
      </div>
      <div className='master-palletizer-landing-page__header-cards container-lg'>
        {contentfulEntry.headerFeatureCards.map((headerCardProps, index) => (
          <HeaderCard key={index} index={index + 1} setBackgroundImageIndex={setBackgroundImageIndex} {...headerCardProps} />
        ))}
      </div>
    </header>
  )
}

export default Header
