import React from 'react'
import { BlogTile } from '../../components/common/blog/BlogTile'
import VentionNews from '../../components/common/VentionNews'
import { BlogSidebarPosts } from './BlogSidebarPosts'
import Loading from '../../components/common/Loading'
import { useBlogPosts } from '../../hooks/useBlogPosts'
import BlogFilters from './BlogFilters'
import { BlogPost } from '../../api/Blog/Types'
import Search from '../../components/common/Search'

interface Props {
  readonly posts: ReadonlyArray<BlogPost>
  readonly isLoading: boolean
  readonly featuredPost: BlogPost
  readonly handleChangeCategory: (newCategory) => void
  readonly handleSearch: (searchTerm) => void
  readonly activeCategory: string | undefined
}

const BlogViewLanding: React.FC<Props> = ({
  featuredPost,
  posts,
  handleChangeCategory,
  handleSearch,
  activeCategory,
  isLoading,
}) => {
  const firstSectionPosts = posts.slice(0, 6)
  const secondSectionPosts = posts.slice(6)
  const { blogPosts: sidebarPosts } = useBlogPosts({
    limit: 3,
    feature: 'most_popular',
  })
  return (
    <>
      <div className='blog-page-header'>
        <h1 className='blog-page-header-title'>Blog</h1>
        <BlogFilters handleChangeCategory={handleChangeCategory} activeCategory={activeCategory} />
        <Search searchFunction={searchTerm => handleSearch(searchTerm)} />
      </div>
      {featuredPost ? (
        <div className='blog-page-featured'>
          <div className='blog-page-featured-post'>
            <BlogTile
              post={featuredPost}
              featured={true}
              className='blog-tile-featured'
              includeRevealableBlurb={false}
            />
          </div>
          <div className='blog-page-featured-sidebar'>
            <BlogSidebarPosts posts={sidebarPosts} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <div className='blog-page-posts'>
        <div className='blog-page-posts-title'>Latest Stories</div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='blog-page-posts-list-container'>
            {firstSectionPosts.map((post, i) => (
              <BlogTile post={post} key={i} />
            ))}
            <VentionNews addClass='vention-news-wide' title='Vention News' />
            {secondSectionPosts.map((post, i) => (
              <BlogTile post={post} key={i} />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default BlogViewLanding
