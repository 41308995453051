import classNames from "classnames";
import React, { PropsWithChildren } from "react";

interface ButtonProps {
  size: "sm" | "md" | "lg" | "xl" | "2xl";
  heirarchy: "primary" | "secondary-gray";
  href?: string;
  icon?: string;
  iconLeading?: string;
  iconTrailing?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  target?: string;
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  size,
  heirarchy,
  href,
  icon,
  iconLeading,
  iconTrailing,
  className,
  onClick,
  disabled,
  children,
  ...rest
}) => {
  if (href) {
    return (
      <a
        {...rest}
        className={classNames(
          "vention-ui-button",
          `vention-ui-button--${size}`,
          `vention-ui-button--${heirarchy}`,
          className,
        )}
        href={href}
      >
        {iconLeading && <i className={iconLeading}></i>}
        {children}
        {iconTrailing && <i className={iconTrailing}></i>}
      </a>
    );
  } else {
    return (
      <button
        {...rest}
        className={classNames(
          "vention-ui-button",
          `vention-ui-button--${size}`,
          `vention-ui-button--${heirarchy}`,
          className,
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {iconLeading && <i className={iconLeading}></i>}
        {children}
        {iconTrailing && <i className={iconTrailing}></i>}
      </button>
    );
  }
};

export default Button;
