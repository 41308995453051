import React, { PropsWithChildren, useContext } from "react";
import I18n from "../../../../helpers/I18n";
import {
  IconMapPin,
  IconMessages,
  IconPackage,
  IconX,
} from "@tabler/icons-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Sort } from "../../../../coveo/components/part_library/Sort";
import { ViewResultsButton } from "./ViewResultsButton";
import { SearchAndFilterButton } from "./SearchAndFilterButton";
import { FacetList } from "../../../../coveo/components/part_library/FacetList";
import {
  PartCategoryByCode,
  CurrencyRegion,
  CurrencySymbol,
} from "../Types";
import { useSearchMade } from "../../../../coveo/hooks/part_library/useSearchMade";
import {
  ControllerType,
  useControllerContext,
} from "../../../../coveo/components/part_library/EngineAndControllerProvider";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { VMediumText, VRegularText } from "../../../../components/text/Text";
import { latestPartCategoryByCode } from "../../../../coveo/components/part_library/CategoryFacet";
import { RegionContext } from "../../../../coveo/components/part_library/RegionProvider";
import { useQuerySimpleGQL } from "../../../../hooks/useSimpleGQL";
import { FeaturedBrandsService } from "../../../../api/FeaturedBrands/FeaturedBrandsService";
import { FeaturedBrandsCollectionDTO } from "../../../../api/FeaturedBrands/Types";

interface HeaderProps
  extends PropsWithChildren {
    partCategoryByCode: PartCategoryByCode;
  }

export const Header: React.FC<HeaderProps> = ({
  partCategoryByCode,
  children
}) => {
  const { result } = useQuerySimpleGQL<FeaturedBrandsCollectionDTO>("featuredBrands", [], FeaturedBrandsService.queryFeaturedBrands);

  const searchMade = useSearchMade();
  const region = useContext(RegionContext);

  const searchBoxController = useControllerContext(ControllerType.SearchBox);
  const categoryController = useControllerContext(ControllerType.CategoryFacet);
  const priceController = useControllerContext(ControllerType.PriceFilter);
  const brandController = useControllerContext(ControllerType.BrandFacet);

  const searchState = useControllerState(searchBoxController);
  const categoryState = useControllerState(categoryController);
  const priceState = useControllerState(priceController);
  const brandState = useControllerState(brandController);

  const selectBrand = (brand: string) => {
    const facet = brandState.values.find(facet => facet.value.toLocaleLowerCase() === brand.toLocaleLowerCase());
    if (facet) {
      brandController.toggleSelect(facet);
    } else {
      brandController.toggleSelect({
        value: brand,
        state: 'selected',
        numberOfResults: 0
      });
    }
  }

  const BadgeContainer = ({ children, onClick }) => (
    <button onClick={onClick} className={`flex group flex-row border-2 border-slate-200 border-solid bg-slate-50 rounded-full h-fit w-fit pl-4 pr-2 py-1 justify-center items-center`}>
      {children}
    </button>
  );

  const TextSearchBadge = () => {
    if (!searchState.value) {
      return null;
    }
    return (
      <BadgeContainer onClick={() => {
        searchBoxController.clear();
        searchBoxController.submit();
      }} >
        <VMediumText>{searchState.value}</VMediumText>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-4 text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const CategorySearchBadge = () => {
    const selectedCategoryCode = categoryState.values.find(
      (category) => category.state === "selected",
    );

    if (!selectedCategoryCode) {
      return null;
    }

    const selectedCategory = latestPartCategoryByCode(
      selectedCategoryCode.value,
      partCategoryByCode,
    );

    return (
      <BadgeContainer onClick={() => categoryController.deselectAll()}>
        <VRegularText>{selectedCategory.name}</VRegularText>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-4 text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const PriceSearchBadge = () => {
    if (!priceState.range) {
      return null;
    }

    const minPrice = priceState.range.start;
    const maxPrice = priceState.range.end;

    const currencyCode = CurrencyRegion[region];
    const currencySymbol = CurrencySymbol[region];

    return (
      <BadgeContainer onClick={() => priceController.clear()}>
        <VRegularText>{`Price: ${currencySymbol}${minPrice} ${currencyCode}  - ${currencySymbol}${maxPrice} ${currencyCode}`}</VRegularText>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-4 text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const BrandSearchBadge = () => {
    if (
      !brandState.values ||
      brandState.values.filter((facet) => facet.state === "selected").length ===
        0
    ) {
      return null;
    }

    return (
      <BadgeContainer onClick={() => brandController.deselectAll()}>
        <VRegularText>{`Brands: ${brandState.values
          .filter((facet) => facet.state === "selected")
          .map((facet) => facet.value)
          .join(", ")}`}</VRegularText>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-4 text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const ClearButton = () => {
    const onClick = () => {
      if (searchBoxController.state.value.length > 0) {
        searchBoxController.updateText("");
        searchBoxController.submit();
      }
      if (categoryController.state.hasActiveValues) {
        categoryController.deselectAll();
      }
      if (priceController.state.range) {
        priceController.clear();
      }
      if (brandController.state.hasActiveValues) {
        brandController.deselectAll();
      }
    };
    return (
      <button className="bg-transparent border-none" onClick={onClick}>
        <VRegularText className="text-blue-500">Clear</VRegularText>
      </button>
    );
  };

  return (
    <header className="bg-gray-50 text-gray-900 px-8 py-4 border-solid border-0 border-b border-gray-200">
      <div className="flex justify-between h-8 mb-2">
        <h1 className="text-3xl">{I18n.t("views.parts_library.header")}</h1>
        <ul className="list-none h-full flex items-center divide-x divide-y-0 divide-solid divide-gray-200 [&_a]:flex [&_a]:items-center [&_a]:no-underline">
          <li>
            <a href="/contact">
              <IconMessages className="w-6 h-6 mr-1" />
              {I18n.t("views.parts_library.headerLinks.support")}
            </a>
          </li>
        </ul>
      </div>

      {children}

      {searchMade && (
        <div className="flex flex-row mt-3 gap-2">
          <TextSearchBadge />
          <CategorySearchBadge />
          <PriceSearchBadge />
          <BrandSearchBadge />
          <ClearButton />
        </div>
      )}
      {!searchMade && ((result?.data?.featuredBrands?.collection?.length ?? 0) > 0) && (
        <div className="flex items-center text-lg mt-4 max-lg:!hidden">
          <label className="m-0 font-normal">
            {I18n.t("views.parts_library.featuredBrands.label")}
          </label>
          <ul className="list-none flex items-center space-x-2 mb-0 pl-2">
            {result?.data?.featuredBrands?.collection?.map((item) => (
              <li key={item.name}>
                <button
                  className="p-0 m-0 no-underline text-blue-700 hover:text-blue-800 bg-transparent border-none"
                  onClick={() => selectBrand(item.name)}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="lg:hidden">
        <Dialog>
          <DialogTrigger asChild>
            <SearchAndFilterButton />
          </DialogTrigger>
          <DialogContent
            className="w-full h-full pb-[72px]"
            title="Sort & Filter"
          >
            <DialogDescription className="sr-only">
              Sort and filter the search results
            </DialogDescription>
            <Sort />

            <FacetList partCategoryByCode={partCategoryByCode} />
            <DialogFooter className="absolute bottom-0 left-0 right-0 p-4">
              <DialogClose asChild>
                <ViewResultsButton />
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </header>
  );
};
