import classNames from 'classnames'
import React from 'react'
import { stringToKebabCase } from '../../../helpers/StringHelper'

interface Props {
  size: 'sm' | 'md' | 'lg'
  color: 'primary'
  type: 'lineOnly'
  label: string
  theme?: 'dark'
}

const CheckItemText: React.FC<Props> = ({ label, size, color, type, theme }) => {
  return (
    <li
      className={classNames(
        'vention-ui-check-item-text',
        `vention-ui-check-item-text--${size}`,
        `vention-ui-check-item-text--${color}`,
        `vention-ui-check-item-text--${stringToKebabCase(type)}`,
        `vention-ui-check-item-text--${theme}`
      )}
    >
      <i className='vention-ui-check-item-text__icon ti ti-check'></i>
      {label && <p className='vention-ui-check-item-text__label'>{label}</p>}
    </li>
  )
}

export default CheckItemText
