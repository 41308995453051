import React, { useState } from 'react'
import I18n from '../../../../../helpers/I18n'
import { ValidatorForm } from 'react-form-validator-core'
import InputValidator from '../../../../../components/common/InputValidator'
import { GoogleCaptchaV3Service } from '../../../../../api/Google/captchaV3.service'
import httpClient from '../../../../../api/httpClient'
import Loading from '../../../../../components/common/Loading'
import { camelToSnakeCase } from '../../../../../helpers/StringHelper'
import { LocalizedPaths } from '../../../../../types/Common'
import PhoneCountryInput from '../../../../../components/common/inputs/PhoneCountryInput/PhoneCountryInput'
import { User } from '../../../../../api/Users/Types'

interface Props {
  currentUser: User
  localizedPaths: LocalizedPaths
  captchaKey: string
  year: number
}

const Form: React.FC<Props> = ({ currentUser, localizedPaths, captchaKey, year }) => {
  const FORM_DEFAULT_STATE = {
    firstName: currentUser?.first_name || '',
    lastName: currentUser?.last_name || '',
    email: currentUser?.email || '',
    industry: '',
    profession: '',
    company: '',
    phone: currentUser?.phone || '',
  }

  const [formSuccess, setFormSuccess] = useState<boolean>(false)
  const [form, setForm] = useState(FORM_DEFAULT_STATE)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleFormChange = (key: string, value: string): void => {
    setForm({ ...form, [key]: value })
  }

  const resetForm = (): void => {
    setForm(FORM_DEFAULT_STATE)
  }

  const formId = 'diy-industrial-automation-report-form'

  const submitForm = (formData): void => {
    if (window._mfq) window._mfq.push(['formSubmitAttempt', `#${formId}`])

    httpClient
      .post('/diy-industrial-automation-report', formData)
      .then(() => {
        resetForm()
        setFormSuccess(true)
        window.scrollTo(0, 0)
        if (window._mfq) window._mfq.push(['formSubmitSuccess', `#${formId}`])
      })
      .catch(error => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : I18n.t('views.diy_industrial_automation_reports.components.form.standard_error')
        setError(errorMessage)
        if (window._mfq) window._mfq.push(['formSubmitFailure', `#${formId}`])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSubmit = (event): void => {
    event.preventDefault()

    setIsLoading(true)
    setError('')

    const formData = Object.keys(form).reduce((object, key) => {
      object[camelToSnakeCase(key)] = form[key]
      return object
    }, {})

    formData['ga_client_id'] = window.ga_client_id
    formData['year'] = year

    GoogleCaptchaV3Service.verifyCaptcha(token => {
      if (token) {
        formData['g_recaptcha_response'] = token
      }
      submitForm(formData)
    }, captchaKey)
  }

  if (formSuccess) {
    return (
      <div className='diy-industrial-automation-report__form diy-industrial-automation-report__form--success'>
        <div className='diy-industrial-automation-report__form-subtitle'>
          {I18n.t('views.diy_industrial_automation_reports.components.form_success.subtitle', {
            year: year,
          })}
        </div>
        <h1 className='diy-industrial-automation-report__form-heading'>
          {I18n.t('views.diy_industrial_automation_reports.components.form_success.heading')}
        </h1>
        {I18n.t('views.diy_industrial_automation_reports.components.form_success.paragraphs', {
          year: year,
        }).map((paragraph, index) => (
          <p className='diy-industrial-automation-report__form-paragraph' key={index}>
            {paragraph}
          </p>
        ))}
      </div>
    )
  }

  return (
    <div className='diy-industrial-automation-report__form'>
      <h1 className='diy-industrial-automation-report__form-heading'>
        {I18n.t('views.diy_industrial_automation_reports.components.form.heading', { year: year })}
      </h1>
      <p className='diy-industrial-automation-report__form-paragraph'>
        {I18n.t('views.diy_industrial_automation_reports.components.form.paragraph')}
      </p>
      <ValidatorForm
        id={formId}
        className='diy-industrial-automation-report__form-container'
        onSubmit={onSubmit}
        instantValidate={false}
      >
        <div className='diy-industrial-automation-report__form-names'>
          <InputValidator
            onChange={event => handleFormChange('firstName', event.target.value)}
            id='first-name'
            className='form-control'
            name='first-name'
            value={form.firstName}
            validators={['required']}
            errorMessages={[
              I18n.t('views.diy_industrial_automation_reports.components.form.required_error'),
            ]}
            type='text'
            label={I18n.t(
              'views.diy_industrial_automation_reports.components.form.first_name.label'
            )}
            placeholder={I18n.t(
              'views.diy_industrial_automation_reports.components.form.first_name.placeholder'
            )}
          />
          <InputValidator
            onChange={event => handleFormChange('lastName', event.target.value)}
            id='last-name'
            className='form-control'
            name='last-name'
            value={form.lastName}
            validators={['required']}
            errorMessages={[
              I18n.t('views.diy_industrial_automation_reports.components.form.required_error'),
            ]}
            type='text'
            label={I18n.t(
              'views.diy_industrial_automation_reports.components.form.last_name.label'
            )}
            placeholder={I18n.t(
              'views.diy_industrial_automation_reports.components.form.last_name.placeholder'
            )}
          />
        </div>

        <InputValidator
          onChange={event => handleFormChange('email', event.target.value)}
          id='email'
          className='form-control'
          name='email'
          value={form.email}
          validators={['required', 'isEmail']}
          errorMessages={[
            I18n.t('views.diy_industrial_automation_reports.components.form.required_error'),
            I18n.t('views.diy_industrial_automation_reports.components.form.is_email_error'),
          ]}
          type='text'
          label={I18n.t('views.diy_industrial_automation_reports.components.form.email.label')}
          placeholder={I18n.t(
            'views.diy_industrial_automation_reports.components.form.email.placeholder'
          )}
        />

        <InputValidator
          onChange={event => handleFormChange('industry', event.target.value)}
          id='industry'
          className='form-control'
          name='industry'
          value={form.industry}
          validators={['required']}
          errorMessages={[
            I18n.t('views.diy_industrial_automation_reports.components.form.required_error'),
          ]}
          type='text'
          label={I18n.t('views.diy_industrial_automation_reports.components.form.industry.label')}
          placeholder={I18n.t(
            'views.diy_industrial_automation_reports.components.form.industry.placeholder'
          )}
        />

        <InputValidator
          onChange={event => handleFormChange('profession', event.target.value)}
          id='profession'
          className='form-control'
          name='profession'
          value={form.profession}
          validators={['required']}
          errorMessages={[
            I18n.t('views.diy_industrial_automation_reports.components.form.required_error'),
          ]}
          type='text'
          label={I18n.t('views.diy_industrial_automation_reports.components.form.profession.label')}
          placeholder={I18n.t(
            'views.diy_industrial_automation_reports.components.form.profession.placeholder'
          )}
        />

        <InputValidator
          onChange={event => handleFormChange('company', event.target.value)}
          id='company'
          className='form-control'
          name='company'
          value={form.company}
          validators={['required']}
          errorMessages={[
            I18n.t('views.diy_industrial_automation_reports.components.form.required_error'),
          ]}
          type='text'
          label={I18n.t('views.diy_industrial_automation_reports.components.form.company.label')}
          placeholder={I18n.t(
            'views.diy_industrial_automation_reports.components.form.company.placeholder'
          )}
        />

        <div>
          <label htmlFor='phone'>
            {I18n.t('views.diy_industrial_automation_reports.components.form.phone.label')}
          </label>
          <PhoneCountryInput
            className='form-control diy-industrial-automation-report__form-phone'
            placeholder={I18n.t(
              'views.diy_industrial_automation_reports.components.form.phone.placeholder'
            )}
            phone={form.phone}
            onChange={phone => handleFormChange('phone', phone)}
            useCountryCode
            required
          />
        </div>

        {error && <p className='diy-industrial-automation-report__form-error has-error'>{error}</p>}

        <p className='diy-industrial-automation-report__form-terms'>
          {I18n.t('views.diy_industrial_automation_reports.components.form.disclosure.prefix')}{' '}
          <a className='diy-industrial-automation-report__form-terms-link' href='/terms-of-use'>
            {I18n.t('views.diy_industrial_automation_reports.components.form.disclosure.terms')}
          </a>{' '}
          {I18n.t('views.diy_industrial_automation_reports.components.form.disclosure.and')}{' '}
          <a
            className='diy-industrial-automation-report__form-terms-link'
            href={localizedPaths.privacy_policy}
          >
            {I18n.t('views.diy_industrial_automation_reports.components.form.disclosure.policy')}
          </a>{' '}
          {I18n.t('views.diy_industrial_automation_reports.components.form.disclosure.suffix')}
        </p>

        <button className='diy-industrial-automation-report__form-submit' type='submit'>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {I18n.t('views.diy_industrial_automation_reports.components.form.submit')}{' '}
              <i className='ti ti-download'></i>
            </>
          )}
        </button>
      </ValidatorForm>
    </div>
  )
}

export default Form
