import React, { useState, useEffect } from 'react'
import { BlogTile } from '../../components/common/blog/BlogTile'
import { BlogPost, BlogPostQuerySettings, BlogEditor } from '../../api/Blog/Types'
import BlogFilters from './BlogFilters'
import BlogService from '../../api/Blog/Service'
import Loading from '../../components/common/Loading'
import Search from '../../components/common/Search'
import EmptySearch from '../../components/common/EmptySearch'
import { useBlogPosts } from '../../hooks/useBlogPosts'

interface Props {
  readonly posts: ReadonlyArray<BlogPost>
  readonly isLoading: boolean
  readonly querySettings: BlogPostQuerySettings
  readonly handleChangeCategory: (newCategory) => void
  readonly handleSearch: (newCategory) => void
  readonly activeCategory: string
}

const BlogViewFiltered: React.FC<Props> = ({
  posts,
  isLoading,
  querySettings,
  handleChangeCategory,
  handleSearch,
  activeCategory,
}) => {
  const [editor, setEditor] = useState<BlogEditor | null>(null)
  const isEditorView = !!querySettings.editor
  const isCategoryView = !isEditorView && querySettings.filterBy
  const { blogPosts: emptySearchRecentPosts } = useBlogPosts({
    limit: 3,
  })

  const Header = ({ prefix, postfix, postfixClass }): React.ReactElement => {
    return (
      <>
        <div className='blog-page-header'>
          <div className='blog-page-header-title'>
            {prefix} <span className={postfixClass}>{postfix}</span>
          </div>
          <BlogFilters
            handleChangeCategory={handleChangeCategory}
            activeCategory={activeCategory}
          />
          <Search
            searchFunction={searchTerm => handleSearch(searchTerm)}
            searchTerm={querySettings.searchTerm}
          />
        </div>
      </>
    )
  }

  const HeaderCategory = (
    <Header
      prefix='Posts about'
      postfix={querySettings.filterBy}
      postfixClass='blog-page-header-title-category'
    />
  )
  const HeaderSearch = (
    <Header prefix='All posts' postfix={''} postfixClass='blog-page-header-title-category' />
  )

  const HeaderEditor = editor && (
    <>
      <Header
        prefix='Author:'
        postfix={editor?.name}
        postfixClass='blog-page-header-title-editor'
      />
      <div className='blog-page-header-editor'>
        <img src={editor?.thumbnail_image} alt={editor?.name} />
        <div className='blog-page-header-editor-details'>
          {editor?.name}
          {editor.name != 'Vention Official' && (
            <span className='blog-page-header-editor-details-position'>
              , Vention&apos;s {editor?.title}
            </span>
          )}
        </div>
      </div>
    </>
  )

  const getHeader = function (): React.ReactElement | null {
    if (isEditorView) {
      return HeaderEditor
    } else if (isCategoryView) {
      return HeaderCategory
    } else {
      return HeaderSearch
    }
  }

  async function fetchEditor(): Promise<void> {
    if (querySettings.editor) {
      const response = await BlogService.fetchBlogEditor(querySettings.editor)
      setEditor(response.editor)
    }
  }

  useEffect(() => {
    if (isEditorView) {
      fetchEditor()
    }
  }, [querySettings.editor])

  const emptySearchTitle = `We couldn't find any blog posts${
    querySettings.searchTerm ? ` matching "${querySettings.searchTerm}"` : ''
  }`
  const emptySearchButtonData = {
    href: '/blogs',
    label: 'Clear search',
  }

  return (
    <>
      {getHeader()}
      <div className='blog-page-posts'>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {posts.length > 0 ? (
              <div className='blog-page-posts-list-container'>
                {posts.map((post, i) => (
                  <BlogTile post={post} key={i} />
                ))}
              </div>
            ) : (
              <EmptySearch
                title={emptySearchTitle}
                subtitle='Try being less specific, using different keywords, or checking your spelling'
                buttonData={emptySearchButtonData}
              >
                <hr />
                <h3 className='blog-page-recent-posts-heading'>Recent posts</h3>
                <div className='blog-page-posts-list-container'>
                  {emptySearchRecentPosts.map((post, i) => (
                    <BlogTile post={post} key={i} />
                  ))}
                </div>
              </EmptySearch>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default BlogViewFiltered
