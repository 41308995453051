import React from 'react'

const ContactCta: React.FC = () => {
  return (
    <p className='product-viewer__contact-cta'>
      Can’t find what you’re looking for? <a href='/contact'>Contact us.</a>
    </p>
  )
}

export default ContactCta
