import React, { useEffect, useState } from 'react'
import { ProductizedApplication } from '../../api/DesignCategories/Types'
import { PartListSortOrder } from '../../api/Parts/Types'
import ProductizedApplicationBanner from '../banners/ProductizedApplicationBanner'
import CustomBreadCrumb, { BreadCrumbDataItem } from '../common/CustomBreadCrumb'
import { useDesignCategoriesContext } from '../common/providers/DesignCategoriesProvider'
import Search from '../common/Search'

interface SortOption {
  readonly label: string
  readonly value: string
}

interface Props {
  readonly heading: string
  readonly sortBy: string
  readonly updateSorting: (sortOrder: PartListSortOrder) => void
  readonly searchFunction: (searchTerm: string, poppedState?: boolean | undefined) => void
  readonly searchTerm: string
  readonly sortOptions: ReadonlyArray<SortOption>
  readonly breadCrumbData: ReadonlyArray<BreadCrumbDataItem>
  readonly partCount?: number
  readonly resourceHeaderMobile?: React.ReactNode
  readonly showSortOption?: boolean
  readonly searchIsLoading?: boolean
}

const ListingPageHeader: React.FunctionComponent<Props> = ({
  heading,
  sortBy,
  updateSorting,
  showSortOption = true,
  searchFunction,
  searchTerm,
  sortOptions,
  breadCrumbData,
  partCount,
  resourceHeaderMobile,
  searchIsLoading,
}) => {
  const [bannerApplication, setBannerApplication] = useState<ProductizedApplication | null>(null)
  const { productizedApplications } = useDesignCategoriesContext()

  const setProductizedApplicationBanner = () => {
    setBannerApplication(null)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const urlParamsCategoryCode = urlParams.get('subCategory')

    productizedApplications.forEach(application => {
      const applicationResult = application.productized_applications?.find(
        productizedApplication => productizedApplication.code === urlParamsCategoryCode
      )

      if (applicationResult) {
        setBannerApplication(applicationResult.productized_application)
        return
      }
    })
  }

  useEffect(() => {
    setProductizedApplicationBanner()
  }, [productizedApplications, window.location.search])

  const handleSort = (event): void => {
    updateSorting && updateSorting(event.target.value)
  }

  const displaySortOptions = (option: SortOption, index: number): React.ReactNode => (
    <option key={index} value={option.value}>
      {option.label}
    </option>
  )

  const displaySortBy = (): React.ReactNode => (
    <div className='part-list-header-sort'>
      Sort By:&nbsp;
      <select name='sort' className='form-control' value={sortBy} onChange={handleSort}>
        {sortOptions.map(displaySortOptions)}
      </select>
    </div>
  )

  return (
    <div className='listing-page-header'>
      <div className='listing-page-header-crumbs-search-wrapper'>
        <CustomBreadCrumb data={breadCrumbData} showHome={false} />
        <div className='listing-page-header-search listing-page-header-search-constrained'>
          <Search
            searchFunction={searchFunction}
            searchTerm={searchTerm}
            placeholder='Search Library...'
            dataTestId='browser-search'
            isLoading={searchIsLoading}
          />
        </div>
      </div>
      {bannerApplication && (
        <ProductizedApplicationBanner productizedApplication={bannerApplication} />
      )}
      <div className='listing-page-heading-sort-wrapper'>
        <h1 className='listing-page-heading'>
          {heading} {partCount && <span className='text-muted'>({partCount})</span>}
        </h1>
        {showSortOption && displaySortBy()}
      </div>
      {resourceHeaderMobile && <div className='resource-header-mobile'>{resourceHeaderMobile}</div>}
    </div>
  )
}

export default ListingPageHeader
