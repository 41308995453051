import React from 'react'

import { BlogTile } from '../../components/common/blog/BlogTile'
import LinkWithIcon from '../../components/common/buttons/LinkWithIcon'
import { BlogPost as Post } from '../../api/Blog/Types'
import { BlogSidebarPosts } from './BlogSidebarPosts'
import { useBlogPosts } from '../../hooks/useBlogPosts'
import SocialLinks from '../../partials/common/SocialLinks'
import { BlogTags } from '../../components/common/blog/BlogTags'
import VentionNews from '../../components/common/VentionNews'
import classnames from 'classnames'

interface Props {
  readonly post: Post
  readonly socialLinks: any
  readonly published?: boolean
}

const BlogPost: React.FC<Props> = ({
  post,
  socialLinks,
  published,
}) => {

  const authorFirstName = post.editor_name.split(' ')[0] || post.editor_name
  const { blogPosts: sidebarPosts } = useBlogPosts({
    limit: 3,
    feature: 'most_popular',
    currentBlogId: post.id,
  })
  const { blogPosts: moreInCategoryPosts } = useBlogPosts({
    limit: 3,
    currentBlogId: post.id,
    filterBy: post.tag_list,
    filterByMethod: 'match_all',
  })

  return (
    <div className='blog-post-page'>
      <div
        className={classnames('blog-post-page-header', 'legacy-blog', {
          'with-admin-banner': !published,
        })}
      >
        <LinkWithIcon target='/blogs' text='Back to blog' arrowLeft />
      </div>
      <div className='blog-post'>
        <div className='blog-post-content'>
          <div className='blog-post-content-header'>
            <BlogTile
              post={post}
              showThumbnail={false}
              anchorTitle={false}
              includeRevealableBlurb={false}
            />
            <div className='blog-post-content-header-social-links'>
              <SocialLinks socialLinks={socialLinks} type='standardize' />
            </div>
          </div>
          <div
            className={classnames('blog-post-content-body', {
              'vention-markdown': post.has_markdown,
            })}
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          ></div>
          <div className='blog-post-content-social-links'>
            <SocialLinks socialLinks={socialLinks} type='standardize' />
          </div>
          <div className='blog-post-author legacy-blog'>
            <img
              className='blog-post-author-image'
              src={post.blog_editor.thumbnail_image}
              alt={post.editor_name}
            />
            <div className='blog-post-author-details'>
              <div className='blog-post-author-details-info'>
                <span className='blog-post-author-name'>Written by {post.editor_name},</span>{' '}
                {post.blog_editor.title}
              </div>
              <LinkWithIcon
                target={`/blogs?editor=${post.editor}`}
                text={`More posts by ${authorFirstName}`}
                arrowRight
              />
            </div>
          </div>
          <div className='blog-post-comments'>
            <div id='discourse-comments'></div>
            <meta name='discourse-username' content='vention-robot'/>

          </div>
          <div className='blog-post-page-footer'>
            <LinkWithIcon target='/blogs' text='Back to blog' arrowLeft/>
          </div>
        </div>
        <div className='blog-post-sidebar'>
          <BlogSidebarPosts posts={sidebarPosts}/>
          <div className='blog-post-subscribe'>
            <VentionNews
                addClass='vention-news-sidebar'
                title={
                  <>
                    <div>Subscribe to</div>
                    <div>Vention News</div>
                  </>
                }
            />
          </div>
        </div>
      </div>
      {moreInCategoryPosts.length > 0 && (
        <div className='blog-post-more'>
          <h3 className='blog-post-more-title'>
            More in <BlogTags tags={post.tag_list} useAnchor={false} />
          </h3>
          <div className='blog-post-more-posts'>
            {moreInCategoryPosts.map((morePost, i) => (
              <BlogTile key={i} post={morePost} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default BlogPost
