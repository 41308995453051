import React from "react";
import { ControllerType, useControllerContext } from "./EngineAndControllerProvider";
import { VRegularText, VSemiBoldText } from "../../../components/text/Text";
import { Button } from "@/components/ui/button";

export const NoResults: React.FC = () => {
  const searchBoxController = useControllerContext(ControllerType.SearchBox);

  const submitEmptySearch = () => {
    searchBoxController.clear();
    searchBoxController.submit();
  }

  const backgroundImgageUrl = "https://assets.vention.io/page-assets/part-library/no_results_background.svg";

  return (
    <div className="flex flex-wrap justify-center">
      <div className="h-[480px] max-w-[480px] flex-1 flex flex-col">
        <div
          className="h-[300px] w-full flex flex-col justify-end items-center bg-no-repeat bg-bottom "
          style={{backgroundImage: `url(${backgroundImgageUrl})`}}
        >
          <img src="https://assets.vention.io/page-assets/part-library/no_results_illustration.svg" alt="No results found" />
          <VSemiBoldText fontSize="xl" marginTop="xl" marginBottom="sm">No results found</VSemiBoldText>
          <VRegularText fontSize="lg" color="terciary">Try refining your search terms or filters.</VRegularText>
        </div>
        <Button className="self-center mt-6" size="lg" variant="secondary" onClick={submitEmptySearch}>Clear Search</Button>
      </div>
    </div>
  );
}
