import keyBy from 'lodash/keyBy'

export interface DialCodeOption {
  name: string
  dialCode: string
  countryCode: string
  label: string
  countryFlag: string
}

// initial data from: https://gist.github.com/anubhavshrimal/75f6183458db8c453306f93521e93d37
export const dialCodeOptions: DialCodeOption[] = [
  {
    name: 'United States',
    dialCode: '+1',
    countryCode: 'US',
    label: '🇺🇸 United States (+1)',
    countryFlag: '🇺🇸',
  },
  {
    name: 'Canada',
    dialCode: '+1',
    countryCode: 'CA',
    label: '🇨🇦 Canada (+1)',
    countryFlag: '🇨🇦',
  },
  {
    name: 'Afghanistan',
    dialCode: '+93',
    countryCode: 'AF',
    label: '🇦🇫 Afghanistan (+93)',
    countryFlag: '🇦🇫',
  },
  {
    name: 'Aland Islands',
    dialCode: '+358',
    countryCode: 'AX',
    label: '🇦🇽 Aland Islands (+358)',
    countryFlag: '🇦🇽',
  },
  {
    name: 'Albania',
    dialCode: '+355',
    countryCode: 'AL',
    label: '🇦🇱 Albania (+355)',
    countryFlag: '🇦🇱',
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    countryCode: 'DZ',
    label: '🇩🇿 Algeria (+213)',
    countryFlag: '🇩🇿',
  },
  {
    name: 'AmericanSamoa',
    dialCode: '+1684',
    countryCode: 'AS',
    label: '🇦🇸 AmericanSamoa (+1684)',
    countryFlag: '🇦🇸',
  },
  {
    name: 'Andorra',
    dialCode: '+376',
    countryCode: 'AD',
    label: '🇦🇩 Andorra (+376)',
    countryFlag: '🇦🇩',
  },
  {
    name: 'Angola',
    dialCode: '+244',
    countryCode: 'AO',
    label: '🇦🇴 Angola (+244)',
    countryFlag: '🇦🇴',
  },
  {
    name: 'Anguilla',
    dialCode: '+1264',
    countryCode: 'AI',
    label: '🇦🇮 Anguilla (+1264)',
    countryFlag: '🇦🇮',
  },
  {
    name: 'Antarctica',
    dialCode: '+672',
    countryCode: 'AQ',
    label: '🇦🇶 Antarctica (+672)',
    countryFlag: '🇦🇶',
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '+1268',
    countryCode: 'AG',
    label: '🇦🇬 Antigua and Barbuda (+1268)',
    countryFlag: '🇦🇬',
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    countryCode: 'AR',
    label: '🇦🇷 Argentina (+54)',
    countryFlag: '🇦🇷',
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    countryCode: 'AM',
    label: '🇦🇲 Armenia (+374)',
    countryFlag: '🇦🇲',
  },
  {
    name: 'Aruba',
    dialCode: '+297',
    countryCode: 'AW',
    label: '🇦🇼 Aruba (+297)',
    countryFlag: '🇦🇼',
  },
  {
    name: 'Ascension Island',
    dialCode: '+247',
    countryCode: 'AI',
    label: '🇦🇨 Ascension Island (+247)',
    countryFlag: '🇦🇨',
  },
  {
    name: 'Australia',
    dialCode: '+61',
    countryCode: 'AU',
    label: '🇦🇺 Australia (+61)',
    countryFlag: '🇦🇺',
  },
  {
    name: 'Austria',
    dialCode: '+43',
    countryCode: 'AT',
    label: '🇦🇹 Austria (+43)',
    countryFlag: '🇦🇹',
  },
  {
    name: 'Azerbaijan',
    dialCode: '+994',
    countryCode: 'AZ',
    label: '🇦🇿 Azerbaijan (+994)',
    countryFlag: '🇦🇿',
  },
  {
    name: 'Bahamas',
    dialCode: '+1242',
    countryCode: 'BS',
    label: '🇧🇸 Bahamas (+1242)',
    countryFlag: '🇧🇸',
  },
  {
    name: 'Bahrain',
    dialCode: '+973',
    countryCode: 'BH',
    label: '🇧🇭 Bahrain (+973)',
    countryFlag: '🇧🇭',
  },
  {
    name: 'Bangladesh',
    dialCode: '+880',
    countryCode: 'BD',
    label: '🇧🇩 Bangladesh (+880)',
    countryFlag: '🇧🇩',
  },
  {
    name: 'Barbados',
    dialCode: '+1246',
    countryCode: 'BB',
    label: '🇧🇧 Barbados (+1246)',
    countryFlag: '🇧🇧',
  },
  {
    name: 'Belarus',
    dialCode: '+375',
    countryCode: 'BY',
    label: '🇧🇾 Belarus (+375)',
    countryFlag: '🇧🇾',
  },
  {
    name: 'Belgium',
    dialCode: '+32',
    countryCode: 'BE',
    label: '🇧🇪 Belgium (+32)',
    countryFlag: '🇧🇪',
  },
  {
    name: 'Belize',
    dialCode: '+501',
    countryCode: 'BZ',
    label: '🇧🇿 Belize (+501)',
    countryFlag: '🇧🇿',
  },
  {
    name: 'Benin',
    dialCode: '+229',
    countryCode: 'BJ',
    label: '🇧🇯 Benin (+229)',
    countryFlag: '🇧🇯',
  },
  {
    name: 'Bermuda',
    dialCode: '+1441',
    countryCode: 'BM',
    label: '🇧🇲 Bermuda (+1441)',
    countryFlag: '🇧🇲',
  },
  {
    name: 'Bhutan',
    dialCode: '+975',
    countryCode: 'BT',
    label: '🇧🇹 Bhutan (+975)',
    countryFlag: '🇧🇹',
  },
  {
    name: 'Bolivia',
    dialCode: '+591',
    countryCode: 'BO',
    label: '🇧🇴 Bolivia (+591)',
    countryFlag: '🇧🇴',
  },
  {
    name: 'Bosnia and Herzegovina',
    dialCode: '+387',
    countryCode: 'BA',
    label: '🇧🇦 Bosnia and Herzegovina (+387)',
    countryFlag: '🇧🇦',
  },
  {
    name: 'Botswana',
    dialCode: '+267',
    countryCode: 'BW',
    label: '🇧🇼 Botswana (+267)',
    countryFlag: '🇧🇼',
  },
  {
    name: 'Brazil',
    dialCode: '+55',
    countryCode: 'BR',
    label: '🇧🇷 Brazil (+55)',
    countryFlag: '🇧🇷',
  },
  {
    name: 'British Indian Ocean Territory',
    dialCode: '+246',
    countryCode: 'IO',
    label: '🇮🇴 British Indian Ocean Territory (+246)',
    countryFlag: '🇮🇴',
  },
  {
    name: 'Brunei Darussalam',
    dialCode: '+673',
    countryCode: 'BN',
    label: '🇧🇳 Brunei Darussalam (+673)',
    countryFlag: '🇧🇳',
  },
  {
    name: 'Bulgaria',
    dialCode: '+359',
    countryCode: 'BG',
    label: '🇧🇬 Bulgaria (+359)',
    countryFlag: '🇧🇬',
  },
  {
    name: 'Burkina Faso',
    dialCode: '+226',
    countryCode: 'BF',
    label: '🇧🇫 Burkina Faso (+226)',
    countryFlag: '🇧🇫',
  },
  {
    name: 'Burundi',
    dialCode: '+257',
    countryCode: 'BI',
    label: '🇧🇮 Burundi (+257)',
    countryFlag: '🇧🇮',
  },
  {
    name: 'Cambodia',
    dialCode: '+855',
    countryCode: 'KH',
    label: '🇰🇭 Cambodia (+855)',
    countryFlag: '🇰🇭',
  },
  {
    name: 'Cameroon',
    dialCode: '+237',
    countryCode: 'CM',
    label: '🇨🇲 Cameroon (+237)',
    countryFlag: '🇨🇲',
  },
  {
    name: 'Cape Verde',
    dialCode: '+238',
    countryCode: 'CV',
    label: '🇨🇻 Cape Verde (+238)',
    countryFlag: '🇨🇻',
  },
  {
    name: 'Cayman Islands',
    dialCode: '+1345',
    countryCode: 'KY',
    label: '🇰🇾 Cayman Islands (+1345)',
    countryFlag: '🇰🇾',
  },
  {
    name: 'Central African Republic',
    dialCode: '+236',
    countryCode: 'CF',
    label: '🇨🇫 Central African Republic (+236)',
    countryFlag: '🇨🇫',
  },
  {
    name: 'Chad',
    dialCode: '+235',
    countryCode: 'TD',
    label: '🇹🇩 Chad (+235)',
    countryFlag: '🇹🇩',
  },
  {
    name: 'Chile',
    dialCode: '+56',
    countryCode: 'CL',
    label: '🇨🇱 Chile (+56)',
    countryFlag: '🇨🇱',
  },
  {
    name: 'China',
    dialCode: '+86',
    countryCode: 'CN',
    label: '🇨🇳 China (+86)',
    countryFlag: '🇨🇳',
  },
  {
    name: 'Christmas Island',
    dialCode: '+61',
    countryCode: 'CX',
    label: '🇨🇽 Christmas Island (+61)',
    countryFlag: '🇨🇽',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    countryCode: 'CC',
    label: '🇨🇨 Cocos (Keeling) Islands (+61)',
    countryFlag: '🇨🇨',
  },
  {
    name: 'Colombia',
    dialCode: '+57',
    countryCode: 'CO',
    label: '🇨🇴 Colombia (+57)',
    countryFlag: '🇨🇴',
  },
  {
    name: 'Comoros',
    dialCode: '+269',
    countryCode: 'KM',
    label: '🇰🇲 Comoros (+269)',
    countryFlag: '🇰🇲',
  },
  {
    name: 'Congo',
    dialCode: '+242',
    countryCode: 'CG',
    label: '🇨🇬 Congo (+242)',
    countryFlag: '🇨🇬',
  },
  {
    name: 'Cook Islands',
    dialCode: '+682',
    countryCode: 'CK',
    label: '🇨🇰 Cook Islands (+682)',
    countryFlag: '🇨🇰',
  },
  {
    name: 'Costa Rica',
    dialCode: '+506',
    countryCode: 'CR',
    label: '🇨🇷 Costa Rica (+506)',
    countryFlag: '🇨🇷',
  },
  {
    name: 'Croatia',
    dialCode: '+385',
    countryCode: 'HR',
    label: '🇭🇷 Croatia (+385)',
    countryFlag: '🇭🇷',
  },
  {
    name: 'Cuba',
    dialCode: '+53',
    countryCode: 'CU',
    label: '🇨🇺 Cuba (+53)',
    countryFlag: '🇨🇺',
  },
  {
    name: 'Cyprus',
    dialCode: '+357',
    countryCode: 'CY',
    label: '🇨🇾 Cyprus (+357)',
    countryFlag: '🇨🇾',
  },
  {
    name: 'Czech Republic',
    dialCode: '+420',
    countryCode: 'CZ',
    label: '🇨🇿 Czech Republic (+420)',
    countryFlag: '🇨🇿',
  },
  {
    name: 'Democratic Republic of the Congo',
    dialCode: '+243',
    countryCode: 'CD',
    label: '🇨🇩 Democratic Republic of the Congo (+243)',
    countryFlag: '🇨🇩',
  },
  {
    name: 'Denmark',
    dialCode: '+45',
    countryCode: 'DK',
    label: '🇩🇰 Denmark (+45)',
    countryFlag: '🇩🇰',
  },
  {
    name: 'Djibouti',
    dialCode: '+253',
    countryCode: 'DJ',
    label: '🇩🇯 Djibouti (+253)',
    countryFlag: '🇩🇯',
  },
  {
    name: 'Dominica',
    dialCode: '+1767',
    countryCode: 'DM',
    label: '🇩🇲 Dominica (+1767)',
    countryFlag: '🇩🇲',
  },
  {
    name: 'Dominican Republic',
    dialCode: '+1849',
    countryCode: 'DO',
    label: '🇩🇴 Dominican Republic (+1849)',
    countryFlag: '🇩🇴',
  },
  {
    name: 'Ecuador',
    dialCode: '+593',
    countryCode: 'EC',
    label: '🇪🇨 Ecuador (+593)',
    countryFlag: '🇪🇨',
  },
  {
    name: 'Egypt',
    dialCode: '+20',
    countryCode: 'EG',
    label: '🇪🇬 Egypt (+20)',
    countryFlag: '🇪🇬',
  },
  {
    name: 'El Salvador',
    dialCode: '+503',
    countryCode: 'SV',
    label: '🇸🇻 El Salvador (+503)',
    countryFlag: '🇸🇻',
  },
  {
    name: 'Equatorial Guinea',
    dialCode: '+240',
    countryCode: 'GQ',
    label: '🇬🇶 Equatorial Guinea (+240)',
    countryFlag: '🇬🇶',
  },
  {
    name: 'Eritrea',
    dialCode: '+291',
    countryCode: 'ER',
    label: '🇪🇷 Eritrea (+291)',
    countryFlag: '🇪🇷',
  },
  {
    name: 'Estonia',
    dialCode: '+372',
    countryCode: 'EE',
    label: '🇪🇪 Estonia (+372)',
    countryFlag: '🇪🇪',
  },
  {
    name: 'Eswatini',
    dialCode: '+268',
    countryCode: 'SZ',
    label: '🇸🇿 Eswatini (+268)',
    countryFlag: '🇸🇿',
  },
  {
    name: 'Ethiopia',
    dialCode: '+251',
    countryCode: 'ET',
    label: '🇪🇹 Ethiopia (+251)',
    countryFlag: '🇪🇹',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    countryCode: 'FK',
    label: '🇫🇰 Falkland Islands (Malvinas) (+500)',
    countryFlag: '🇫🇰',
  },
  {
    name: 'Faroe Islands',
    dialCode: '+298',
    countryCode: 'FO',
    label: '🇫🇴 Faroe Islands (+298)',
    countryFlag: '🇫🇴',
  },
  {
    name: 'Fiji',
    dialCode: '+679',
    countryCode: 'FJ',
    label: '🇫🇯 Fiji (+679)',
    countryFlag: '🇫🇯',
  },
  {
    name: 'Finland',
    dialCode: '+358',
    countryCode: 'FI',
    label: '🇫🇮 Finland (+358)',
    countryFlag: '🇫🇮',
  },
  {
    name: 'France',
    dialCode: '+33',
    countryCode: 'FR',
    label: '🇫🇷 France (+33)',
    countryFlag: '🇫🇷',
  },
  {
    name: 'French Guiana',
    dialCode: '+594',
    countryCode: 'GF',
    label: '🇬🇫 French Guiana (+594)',
    countryFlag: '🇬🇫',
  },
  {
    name: 'French Polynesia',
    dialCode: '+689',
    countryCode: 'PF',
    label: '🇵🇫 French Polynesia (+689)',
    countryFlag: '🇵🇫',
  },
  {
    name: 'Gabon',
    dialCode: '+241',
    countryCode: 'GA',
    label: '🇬🇦 Gabon (+241)',
    countryFlag: '🇬🇦',
  },
  {
    name: 'Gambia',
    dialCode: '+220',
    countryCode: 'GM',
    label: '🇬🇲 Gambia (+220)',
    countryFlag: '🇬🇲',
  },
  {
    name: 'Georgia',
    dialCode: '+995',
    countryCode: 'GE',
    label: '🇬🇪 Georgia (+995)',
    countryFlag: '🇬🇪',
  },
  {
    name: 'Germany',
    dialCode: '+49',
    countryCode: 'DE',
    label: '🇩🇪 Germany (+49)',
    countryFlag: '🇩🇪',
  },
  {
    name: 'Ghana',
    dialCode: '+233',
    countryCode: 'GH',
    label: '🇬🇭 Ghana (+233)',
    countryFlag: '🇬🇭',
  },
  {
    name: 'Gibraltar',
    dialCode: '+350',
    countryCode: 'GI',
    label: '🇬🇮 Gibraltar (+350)',
    countryFlag: '🇬🇮',
  },
  {
    name: 'Greece',
    dialCode: '+30',
    countryCode: 'GR',
    label: '🇬🇷 Greece (+30)',
    countryFlag: '🇬🇷',
  },
  {
    name: 'Greenland',
    dialCode: '+299',
    countryCode: 'GL',
    label: '🇬🇱 Greenland (+299)',
    countryFlag: '🇬🇱',
  },
  {
    name: 'Grenada',
    dialCode: '+1473',
    countryCode: 'GD',
    label: '🇬🇩 Grenada (+1473)',
    countryFlag: '🇬🇩',
  },
  {
    name: 'Guadeloupe',
    dialCode: '+590',
    countryCode: 'GP',
    label: '🇬🇵 Guadeloupe (+590)',
    countryFlag: '🇬🇵',
  },
  {
    name: 'Guam',
    dialCode: '+1671',
    countryCode: 'GU',
    label: '🇬🇺 Guam (+1671)',
    countryFlag: '🇬🇺',
  },
  {
    name: 'Guatemala',
    dialCode: '+502',
    countryCode: 'GT',
    label: '🇬🇹 Guatemala (+502)',
    countryFlag: '🇬🇹',
  },
  {
    name: 'Guernsey',
    dialCode: '+44',
    countryCode: 'GG',
    label: '🇬🇬 Guernsey (+44)',
    countryFlag: '🇬🇬',
  },
  {
    name: 'Guinea',
    dialCode: '+224',
    countryCode: 'GN',
    label: '🇬🇳 Guinea (+224)',
    countryFlag: '🇬🇳',
  },
  {
    name: 'Guinea-Bissau',
    dialCode: '+245',
    countryCode: 'GW',
    label: '🇬🇼 Guinea-Bissau (+245)',
    countryFlag: '🇬🇼',
  },
  {
    name: 'Guyana',
    dialCode: '+592',
    countryCode: 'GY',
    label: '🇬🇾 Guyana (+592)',
    countryFlag: '🇬🇾',
  },
  {
    name: 'Haiti',
    dialCode: '+509',
    countryCode: 'HT',
    label: '🇭🇹 Haiti (+509)',
    countryFlag: '🇭🇹',
  },
  {
    name: 'Holy See (Vatican City State)',
    dialCode: '+379',
    countryCode: 'VA',
    label: '🇻🇦 Holy See (Vatican City State) (+379)',
    countryFlag: '🇻🇦',
  },
  {
    name: 'Honduras',
    dialCode: '+504',
    countryCode: 'HN',
    label: '🇭🇳 Honduras (+504)',
    countryFlag: '🇭🇳',
  },
  {
    name: 'Hong Kong',
    dialCode: '+852',
    countryCode: 'HK',
    label: '🇭🇰 Hong Kong (+852)',
    countryFlag: '🇭🇰',
  },
  {
    name: 'Hungary',
    dialCode: '+36',
    countryCode: 'HU',
    label: '🇭🇺 Hungary (+36)',
    countryFlag: '🇭🇺',
  },
  {
    name: 'Iceland',
    dialCode: '+354',
    countryCode: 'IS',
    label: '🇮🇸 Iceland (+354)',
    countryFlag: '🇮🇸',
  },
  {
    name: 'India',
    dialCode: '+91',
    countryCode: 'IN',
    label: '🇮🇳 India (+91)',
    countryFlag: '🇮🇳',
  },
  {
    name: 'Indonesia',
    dialCode: '+62',
    countryCode: 'ID',
    label: '🇮🇩 Indonesia (+62)',
    countryFlag: '🇮🇩',
  },
  {
    name: 'Iran',
    dialCode: '+98',
    countryCode: 'IR',
    label: '🇮🇷 Iran (+98)',
    countryFlag: '🇮🇷',
  },
  {
    name: 'Iraq',
    dialCode: '+964',
    countryCode: 'IQ',
    label: '🇮🇶 Iraq (+964)',
    countryFlag: '🇮🇶',
  },
  {
    name: 'Ireland',
    dialCode: '+353',
    countryCode: 'IE',
    label: '🇮🇪 Ireland (+353)',
    countryFlag: '🇮🇪',
  },
  {
    name: 'Isle of Man',
    dialCode: '+44',
    countryCode: 'IM',
    label: '🇮🇲 Isle of Man (+44)',
    countryFlag: '🇮🇲',
  },
  {
    name: 'Israel',
    dialCode: '+972',
    countryCode: 'IL',
    label: '🇮🇱 Israel (+972)',
    countryFlag: '🇮🇱',
  },
  {
    name: 'Italy',
    dialCode: '+39',
    countryCode: 'IT',
    label: '🇮🇹 Italy (+39)',
    countryFlag: '🇮🇹',
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: '+225',
    countryCode: 'CI',
    label: "🇨🇮 Ivory Coast / Cote d'Ivoire (+225)",
    countryFlag: '🇨🇮',
  },
  {
    name: 'Jamaica',
    dialCode: '+1876',
    countryCode: 'JM',
    label: '🇯🇲 Jamaica (+1876)',
    countryFlag: '🇯🇲',
  },
  {
    name: 'Japan',
    dialCode: '+81',
    countryCode: 'JP',
    label: '🇯🇵 Japan (+81)',
    countryFlag: '🇯🇵',
  },
  {
    name: 'Jersey',
    dialCode: '+44',
    countryCode: 'JE',
    label: '🇯🇪 Jersey (+44)',
    countryFlag: '🇯🇪',
  },
  {
    name: 'Jordan',
    dialCode: '+962',
    countryCode: 'JO',
    label: '🇯🇴 Jordan (+962)',
    countryFlag: '🇯🇴',
  },
  {
    name: 'Kazakhstan',
    dialCode: '+77',
    countryCode: 'KZ',
    label: '🇰🇿 Kazakhstan (+77)',
    countryFlag: '🇰🇿',
  },
  {
    name: 'Kenya',
    dialCode: '+254',
    countryCode: 'KE',
    label: '🇰🇪 Kenya (+254)',
    countryFlag: '🇰🇪',
  },
  {
    name: 'Kiribati',
    dialCode: '+686',
    countryCode: 'KI',
    label: '🇰🇮 Kiribati (+686)',
    countryFlag: '🇰🇮',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: '+850',
    countryCode: 'KP',
    label: "🇰🇵 Korea, Democratic People's Republic of Korea (+850)",
    countryFlag: '🇰🇵',
  },
  {
    name: 'Korea, Republic of South Korea',
    dialCode: '+82',
    countryCode: 'KR',
    label: '🇰🇷 Korea, Republic of South Korea (+82)',
    countryFlag: '🇰🇷',
  },
  {
    name: 'Kosovo',
    dialCode: '+383',
    countryCode: 'XK',
    label: '🇽🇰 Kosovo (+383)',
    countryFlag: '🇽🇰',
  },
  {
    name: 'Kuwait',
    dialCode: '+965',
    countryCode: 'KW',
    label: '🇰🇼 Kuwait (+965)',
    countryFlag: '🇰🇼',
  },
  {
    name: 'Kyrgyzstan',
    dialCode: '+996',
    countryCode: 'KG',
    label: '🇰🇬 Kyrgyzstan (+996)',
    countryFlag: '🇰🇬',
  },
  {
    name: 'Laos',
    dialCode: '+856',
    countryCode: 'LA',
    label: '🇱🇦 Laos (+856)',
    countryFlag: '🇱🇦',
  },
  {
    name: 'Latvia',
    dialCode: '+371',
    countryCode: 'LV',
    label: '🇱🇻 Latvia (+371)',
    countryFlag: '🇱🇻',
  },
  {
    name: 'Lebanon',
    dialCode: '+961',
    countryCode: 'LB',
    label: '🇱🇧 Lebanon (+961)',
    countryFlag: '🇱🇧',
  },
  {
    name: 'Lesotho',
    dialCode: '+266',
    countryCode: 'LS',
    label: '🇱🇸 Lesotho (+266)',
    countryFlag: '🇱🇸',
  },
  {
    name: 'Liberia',
    dialCode: '+231',
    countryCode: 'LR',
    label: '🇱🇷 Liberia (+231)',
    countryFlag: '🇱🇷',
  },
  {
    name: 'Libya',
    dialCode: '+218',
    countryCode: 'LY',
    label: '🇱🇾 Libya (+218)',
    countryFlag: '🇱🇾',
  },
  {
    name: 'Liechtenstein',
    dialCode: '+423',
    countryCode: 'LI',
    label: '🇱🇮 Liechtenstein (+423)',
    countryFlag: '🇱🇮',
  },
  {
    name: 'Lithuania',
    dialCode: '+370',
    countryCode: 'LT',
    label: '🇱🇹 Lithuania (+370)',
    countryFlag: '🇱🇹',
  },
  {
    name: 'Luxembourg',
    dialCode: '+352',
    countryCode: 'LU',
    label: '🇱🇺 Luxembourg (+352)',
    countryFlag: '🇱🇺',
  },
  {
    name: 'Macau',
    dialCode: '+853',
    countryCode: 'MO',
    label: '🇲🇴 Macau (+853)',
    countryFlag: '🇲🇴',
  },
  {
    name: 'Madagascar',
    dialCode: '+261',
    countryCode: 'MG',
    label: '🇲🇬 Madagascar (+261)',
    countryFlag: '🇲🇬',
  },
  {
    name: 'Malawi',
    dialCode: '+265',
    countryCode: 'MW',
    label: '🇲🇼 Malawi (+265)',
    countryFlag: '🇲🇼',
  },
  {
    name: 'Malaysia',
    dialCode: '+60',
    countryCode: 'MY',
    label: '🇲🇾 Malaysia (+60)',
    countryFlag: '🇲🇾',
  },
  {
    name: 'Maldives',
    dialCode: '+960',
    countryCode: 'MV',
    label: '🇲🇻 Maldives (+960)',
    countryFlag: '🇲🇻',
  },
  {
    name: 'Mali',
    dialCode: '+223',
    countryCode: 'ML',
    label: '🇲🇱 Mali (+223)',
    countryFlag: '🇲🇱',
  },
  {
    name: 'Malta',
    dialCode: '+356',
    countryCode: 'MT',
    label: '🇲🇹 Malta (+356)',
    countryFlag: '🇲🇹',
  },
  {
    name: 'Marshall Islands',
    dialCode: '+692',
    countryCode: 'MH',
    label: '🇲🇭 Marshall Islands (+692)',
    countryFlag: '🇲🇭',
  },
  {
    name: 'Martinique',
    dialCode: '+596',
    countryCode: 'MQ',
    label: '🇲🇶 Martinique (+596)',
    countryFlag: '🇲🇶',
  },
  {
    name: 'Mauritania',
    dialCode: '+222',
    countryCode: 'MR',
    label: '🇲🇷 Mauritania (+222)',
    countryFlag: '🇲🇷',
  },
  {
    name: 'Mauritius',
    dialCode: '+230',
    countryCode: 'MU',
    label: '🇲🇺 Mauritius (+230)',
    countryFlag: '🇲🇺',
  },
  {
    name: 'Mayotte',
    dialCode: '+262',
    countryCode: 'YT',
    label: '🇾🇹 Mayotte (+262)',
    countryFlag: '🇾🇹',
  },
  {
    name: 'Mexico',
    dialCode: '+52',
    countryCode: 'MX',
    label: '🇲🇽 Mexico (+52)',
    countryFlag: '🇲🇽',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dialCode: '+691',
    countryCode: 'FM',
    label: '🇫🇲 Micronesia, Federated States of Micronesia (+691)',
    countryFlag: '🇫🇲',
  },
  {
    name: 'Moldova',
    dialCode: '+373',
    countryCode: 'MD',
    label: '🇲🇩 Moldova (+373)',
    countryFlag: '🇲🇩',
  },
  {
    name: 'Monaco',
    dialCode: '+377',
    countryCode: 'MC',
    label: '🇲🇨 Monaco (+377)',
    countryFlag: '🇲🇨',
  },
  {
    name: 'Mongolia',
    dialCode: '+976',
    countryCode: 'MN',
    label: '🇲🇳 Mongolia (+976)',
    countryFlag: '🇲🇳',
  },
  {
    name: 'Montenegro',
    dialCode: '+382',
    countryCode: 'ME',
    label: '🇲🇪 Montenegro (+382)',
    countryFlag: '🇲🇪',
  },
  {
    name: 'Montserrat',
    dialCode: '+1664',
    countryCode: 'MS',
    label: '🇲🇸 Montserrat (+1664)',
    countryFlag: '🇲🇸',
  },
  {
    name: 'Morocco',
    dialCode: '+212',
    countryCode: 'MA',
    label: '🇲🇦 Morocco (+212)',
    countryFlag: '🇲🇦',
  },
  {
    name: 'Mozambique',
    dialCode: '+258',
    countryCode: 'MZ',
    label: '🇲🇿 Mozambique (+258)',
    countryFlag: '🇲🇿',
  },
  {
    name: 'Myanmar',
    dialCode: '+95',
    countryCode: 'MM',
    label: '🇲🇲 Myanmar (+95)',
    countryFlag: '🇲🇲',
  },
  {
    name: 'Namibia',
    dialCode: '+264',
    countryCode: 'NA',
    label: '🇳🇦 Namibia (+264)',
    countryFlag: '🇳🇦',
  },
  {
    name: 'Nauru',
    dialCode: '+674',
    countryCode: 'NR',
    label: '🇳🇷 Nauru (+674)',
    countryFlag: '🇳🇷',
  },
  {
    name: 'Nepal',
    dialCode: '+977',
    countryCode: 'NP',
    label: '🇳🇵 Nepal (+977)',
    countryFlag: '🇳🇵',
  },
  {
    name: 'Netherlands',
    dialCode: '+31',
    countryCode: 'NL',
    label: '🇳🇱 Netherlands (+31)',
    countryFlag: '🇳🇱',
  },
  {
    name: 'Netherlands Antilles',
    dialCode: '+599',
    countryCode: 'AN',
    label: '🇦🇳 Netherlands Antilles (+599)',
    countryFlag: '🇦🇳',
  },
  {
    name: 'New Caledonia',
    dialCode: '+687',
    countryCode: 'NC',
    label: '🇳🇨 New Caledonia (+687)',
    countryFlag: '🇳🇨',
  },
  {
    name: 'New Zealand',
    dialCode: '+64',
    countryCode: 'NZ',
    label: '🇳🇿 New Zealand (+64)',
    countryFlag: '🇳🇿',
  },
  {
    name: 'Nicaragua',
    dialCode: '+505',
    countryCode: 'NI',
    label: '🇳🇮 Nicaragua (+505)',
    countryFlag: '🇳🇮',
  },
  {
    name: 'Niger',
    dialCode: '+227',
    countryCode: 'NE',
    label: '🇳🇪 Niger (+227)',
    countryFlag: '🇳🇪',
  },
  {
    name: 'Nigeria',
    dialCode: '+234',
    countryCode: 'NG',
    label: '🇳🇬 Nigeria (+234)',
    countryFlag: '🇳🇬',
  },
  {
    name: 'Niue',
    dialCode: '+683',
    countryCode: 'NU',
    label: '🇳🇺 Niue (+683)',
    countryFlag: '🇳🇺',
  },
  {
    name: 'Norfolk Island',
    dialCode: '+672',
    countryCode: 'NF',
    label: '🇳🇫 Norfolk Island (+672)',
    countryFlag: '🇳🇫',
  },
  {
    name: 'North Macedonia',
    dialCode: '+389',
    countryCode: 'MK',
    label: '🇲🇰 North Macedonia (+389)',
    countryFlag: '🇲🇰',
  },
  {
    name: 'Northern Mariana Islands',
    dialCode: '+1670',
    countryCode: 'MP',
    label: '🇲🇵 Northern Mariana Islands (+1670)',
    countryFlag: '🇲🇵',
  },
  {
    name: 'Norway',
    dialCode: '+47',
    countryCode: 'NO',
    label: '🇳🇴 Norway (+47)',
    countryFlag: '🇳🇴',
  },
  {
    name: 'Oman',
    dialCode: '+968',
    countryCode: 'OM',
    label: '🇴🇲 Oman (+968)',
    countryFlag: '🇴🇲',
  },
  {
    name: 'Pakistan',
    dialCode: '+92',
    countryCode: 'PK',
    label: '🇵🇰 Pakistan (+92)',
    countryFlag: '🇵🇰',
  },
  {
    name: 'Palau',
    dialCode: '+680',
    countryCode: 'PW',
    label: '🇵🇼 Palau (+680)',
    countryFlag: '🇵🇼',
  },
  {
    name: 'Palestine',
    dialCode: '+970',
    countryCode: 'PS',
    label: '🇵🇸 Palestine (+970)',
    countryFlag: '🇵🇸',
  },
  {
    name: 'Panama',
    dialCode: '+507',
    countryCode: 'PA',
    label: '🇵🇦 Panama (+507)',
    countryFlag: '🇵🇦',
  },
  {
    name: 'Papua New Guinea',
    dialCode: '+675',
    countryCode: 'PG',
    label: '🇵🇬 Papua New Guinea (+675)',
    countryFlag: '🇵🇬',
  },
  {
    name: 'Paraguay',
    dialCode: '+595',
    countryCode: 'PY',
    label: '🇵🇾 Paraguay (+595)',
    countryFlag: '🇵🇾',
  },
  {
    name: 'Peru',
    dialCode: '+51',
    countryCode: 'PE',
    label: '🇵🇪 Peru (+51)',
    countryFlag: '🇵🇪',
  },
  {
    name: 'Philippines',
    dialCode: '+63',
    countryCode: 'PH',
    label: '🇵🇭 Philippines (+63)',
    countryFlag: '🇵🇭',
  },
  {
    name: 'Pitcairn',
    dialCode: '+872',
    countryCode: 'PN',
    label: '🇵🇳 Pitcairn (+872)',
    countryFlag: '🇵🇳',
  },
  {
    name: 'Poland',
    dialCode: '+48',
    countryCode: 'PL',
    label: '🇵🇱 Poland (+48)',
    countryFlag: '🇵🇱',
  },
  {
    name: 'Portugal',
    dialCode: '+351',
    countryCode: 'PT',
    label: '🇵🇹 Portugal (+351)',
    countryFlag: '🇵🇹',
  },
  {
    name: 'Puerto Rico',
    dialCode: '+1939',
    countryCode: 'PR',
    label: '🇵🇷 Puerto Rico (+1939)',
    countryFlag: '🇵🇷',
  },
  {
    name: 'Qatar',
    dialCode: '+974',
    countryCode: 'QA',
    label: '🇶🇦 Qatar (+974)',
    countryFlag: '🇶🇦',
  },
  {
    name: 'Reunion',
    dialCode: '+262',
    countryCode: 'RE',
    label: '🇷🇪 Reunion (+262)',
    countryFlag: '🇷🇪',
  },
  {
    name: 'Romania',
    dialCode: '+40',
    countryCode: 'RO',
    label: '🇷🇴 Romania (+40)',
    countryFlag: '🇷🇴',
  },
  {
    name: 'Russia',
    dialCode: '+7',
    countryCode: 'RU',
    label: '🇷🇺 Russia (+7)',
    countryFlag: '🇷🇺',
  },
  {
    name: 'Rwanda',
    dialCode: '+250',
    countryCode: 'RW',
    label: '🇷🇼 Rwanda (+250)',
    countryFlag: '🇷🇼',
  },
  {
    name: 'Saint Barthelemy',
    dialCode: '+590',
    countryCode: 'BL',
    label: '🇧🇱 Saint Barthelemy (+590)',
    countryFlag: '🇧🇱',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    countryCode: 'SH',
    label: '🇸🇭 Saint Helena, Ascension and Tristan Da Cunha (+290)',
    countryFlag: '🇸🇭',
  },
  {
    name: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    countryCode: 'KN',
    label: '🇰🇳 Saint Kitts and Nevis (+1869)',
    countryFlag: '🇰🇳',
  },
  {
    name: 'Saint Lucia',
    dialCode: '+1758',
    countryCode: 'LC',
    label: '🇱🇨 Saint Lucia (+1758)',
    countryFlag: '🇱🇨',
  },
  {
    name: 'Saint Martin',
    dialCode: '+590',
    countryCode: 'MF',
    label: '🇲🇫 Saint Martin (+590)',
    countryFlag: '🇲🇫',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    countryCode: 'PM',
    label: '🇵🇲 Saint Pierre and Miquelon (+508)',
    countryFlag: '🇵🇲',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    countryCode: 'VC',
    label: '🇻🇨 Saint Vincent and the Grenadines (+1784)',
    countryFlag: '🇻🇨',
  },
  {
    name: 'Samoa',
    dialCode: '+685',
    countryCode: 'WS',
    label: '🇼🇸 Samoa (+685)',
    countryFlag: '🇼🇸',
  },
  {
    name: 'San Marino',
    dialCode: '+378',
    countryCode: 'SM',
    label: '🇸🇲 San Marino (+378)',
    countryFlag: '🇸🇲',
  },
  {
    name: 'Sao Tome and Principe',
    dialCode: '+239',
    countryCode: 'ST',
    label: '🇸🇹 Sao Tome and Principe (+239)',
    countryFlag: '🇸🇹',
  },
  {
    name: 'Saudi Arabia',
    dialCode: '+966',
    countryCode: 'SA',
    label: '🇸🇦 Saudi Arabia (+966)',
    countryFlag: '🇸🇦',
  },
  {
    name: 'Senegal',
    dialCode: '+221',
    countryCode: 'SN',
    label: '🇸🇳 Senegal (+221)',
    countryFlag: '🇸🇳',
  },
  {
    name: 'Serbia',
    dialCode: '+381',
    countryCode: 'RS',
    label: '🇷🇸 Serbia (+381)',
    countryFlag: '🇷🇸',
  },
  {
    name: 'Seychelles',
    dialCode: '+248',
    countryCode: 'SC',
    label: '🇸🇨 Seychelles (+248)',
    countryFlag: '🇸🇨',
  },
  {
    name: 'Sierra Leone',
    dialCode: '+232',
    countryCode: 'SL',
    label: '🇸🇱 Sierra Leone (+232)',
    countryFlag: '🇸🇱',
  },
  {
    name: 'Singapore',
    dialCode: '+65',
    countryCode: 'SG',
    label: '🇸🇬 Singapore (+65)',
    countryFlag: '🇸🇬',
  },
  {
    name: 'Sint Maarten',
    dialCode: '+1721',
    countryCode: 'SX',
    label: '🇸🇽 Sint Maarten (+1721)',
    countryFlag: '🇸🇽',
  },
  {
    name: 'Slovakia',
    dialCode: '+421',
    countryCode: 'SK',
    label: '🇸🇰 Slovakia (+421)',
    countryFlag: '🇸🇰',
  },
  {
    name: 'Slovenia',
    dialCode: '+386',
    countryCode: 'SI',
    label: '🇸🇮 Slovenia (+386)',
    countryFlag: '🇸🇮',
  },
  {
    name: 'Solomon Islands',
    dialCode: '+677',
    countryCode: 'SB',
    label: '🇸🇧 Solomon Islands (+677)',
    countryFlag: '🇸🇧',
  },
  {
    name: 'Somalia',
    dialCode: '+252',
    countryCode: 'SO',
    label: '🇸🇴 Somalia (+252)',
    countryFlag: '🇸🇴',
  },
  {
    name: 'South Africa',
    dialCode: '+27',
    countryCode: 'ZA',
    label: '🇿🇦 South Africa (+27)',
    countryFlag: '🇿🇦',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    countryCode: 'GS',
    label: '🇬🇸 South Georgia and the South Sandwich Islands (+500)',
    countryFlag: '🇬🇸',
  },
  {
    name: 'South Sudan',
    dialCode: '+211',
    countryCode: 'SS',
    label: '🇸🇸 South Sudan (+211)',
    countryFlag: '🇸🇸',
  },
  {
    name: 'Spain',
    dialCode: '+34',
    countryCode: 'ES',
    label: '🇪🇸 Spain (+34)',
    countryFlag: '🇪🇸',
  },
  {
    name: 'Sri Lanka',
    dialCode: '+94',
    countryCode: 'LK',
    label: '🇱🇰 Sri Lanka (+94)',
    countryFlag: '🇱🇰',
  },
  {
    name: 'Sudan',
    dialCode: '+249',
    countryCode: 'SD',
    label: '🇸🇩 Sudan (+249)',
    countryFlag: '🇸🇩',
  },
  {
    name: 'Suriname',
    dialCode: '+597',
    countryCode: 'SR',
    label: '🇸🇷 Suriname (+597)',
    countryFlag: '🇸🇷',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    countryCode: 'SJ',
    label: '🇸🇯 Svalbard and Jan Mayen (+47)',
    countryFlag: '🇸🇯',
  },
  {
    name: 'Sweden',
    dialCode: '+46',
    countryCode: 'SE',
    label: '🇸🇪 Sweden (+46)',
    countryFlag: '🇸🇪',
  },
  {
    name: 'Switzerland',
    dialCode: '+41',
    countryCode: 'CH',
    label: '🇨🇭 Switzerland (+41)',
    countryFlag: '🇨🇭',
  },
  {
    name: 'Syrian Arab Republic',
    dialCode: '+963',
    countryCode: 'SY',
    label: '🇸🇾 Syrian Arab Republic (+963)',
    countryFlag: '🇸🇾',
  },
  {
    name: 'Taiwan',
    dialCode: '+886',
    countryCode: 'TW',
    label: '🇹🇼 Taiwan (+886)',
    countryFlag: '🇹🇼',
  },
  {
    name: 'Tajikistan',
    dialCode: '+992',
    countryCode: 'TJ',
    label: '🇹🇯 Tajikistan (+992)',
    countryFlag: '🇹🇯',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dialCode: '+255',
    countryCode: 'TZ',
    label: '🇹🇿 Tanzania, United Republic of Tanzania (+255)',
    countryFlag: '🇹🇿',
  },
  {
    name: 'Thailand',
    dialCode: '+66',
    countryCode: 'TH',
    label: '🇹🇭 Thailand (+66)',
    countryFlag: '🇹🇭',
  },
  {
    name: 'Timor-Leste',
    dialCode: '+670',
    countryCode: 'TL',
    label: '🇹🇱 Timor-Leste (+670)',
    countryFlag: '🇹🇱',
  },
  {
    name: 'Togo',
    dialCode: '+228',
    countryCode: 'TG',
    label: '🇹🇬 Togo (+228)',
    countryFlag: '🇹🇬',
  },
  {
    name: 'Tokelau',
    dialCode: '+690',
    countryCode: 'TK',
    label: '🇹🇰 Tokelau (+690)',
    countryFlag: '🇹🇰',
  },
  {
    name: 'Tonga',
    dialCode: '+676',
    countryCode: 'TO',
    label: '🇹🇴 Tonga (+676)',
    countryFlag: '🇹🇴',
  },
  {
    name: 'Trinidad and Tobago',
    dialCode: '+1868',
    countryCode: 'TT',
    label: '🇹🇹 Trinidad and Tobago (+1868)',
    countryFlag: '🇹🇹',
  },
  {
    name: 'Tunisia',
    dialCode: '+216',
    countryCode: 'TN',
    label: '🇹🇳 Tunisia (+216)',
    countryFlag: '🇹🇳',
  },
  {
    name: 'Turkey',
    dialCode: '+90',
    countryCode: 'TR',
    label: '🇹🇷 Turkey (+90)',
    countryFlag: '🇹🇷',
  },
  {
    name: 'Turkmenistan',
    dialCode: '+993',
    countryCode: 'TM',
    label: '🇹🇲 Turkmenistan (+993)',
    countryFlag: '🇹🇲',
  },
  {
    name: 'Turks and Caicos Islands',
    dialCode: '+1649',
    countryCode: 'TC',
    label: '🇹🇨 Turks and Caicos Islands (+1649)',
    countryFlag: '🇹🇨',
  },
  {
    name: 'Tuvalu',
    dialCode: '+688',
    countryCode: 'TV',
    label: '🇹🇻 Tuvalu (+688)',
    countryFlag: '🇹🇻',
  },
  {
    name: 'Uganda',
    dialCode: '+256',
    countryCode: 'UG',
    label: '🇺🇬 Uganda (+256)',
    countryFlag: '🇺🇬',
  },
  {
    name: 'Ukraine',
    dialCode: '+380',
    countryCode: 'UA',
    label: '🇺🇦 Ukraine (+380)',
    countryFlag: '🇺🇦',
  },
  {
    name: 'United Arab Emirates',
    dialCode: '+971',
    countryCode: 'AE',
    label: '🇦🇪 United Arab Emirates (+971)',
    countryFlag: '🇦🇪',
  },
  {
    name: 'United Kingdom',
    dialCode: '+44',
    countryCode: 'GB',
    label: '🇬🇧 United Kingdom (+44)',
    countryFlag: '🇬🇧',
  },
  {
    name: 'Uruguay',
    dialCode: '+598',
    countryCode: 'UY',
    label: '🇺🇾 Uruguay (+598)',
    countryFlag: '🇺🇾',
  },
  {
    name: 'Uzbekistan',
    dialCode: '+998',
    countryCode: 'UZ',
    label: '🇺🇿 Uzbekistan (+998)',
    countryFlag: '🇺🇿',
  },
  {
    name: 'Vanuatu',
    dialCode: '+678',
    countryCode: 'VU',
    label: '🇻🇺 Vanuatu (+678)',
    countryFlag: '🇻🇺',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dialCode: '+58',
    countryCode: 'VE',
    label: '🇻🇪 Venezuela, Bolivarian Republic of Venezuela (+58)',
    countryFlag: '🇻🇪',
  },
  {
    name: 'Vietnam',
    dialCode: '+84',
    countryCode: 'VN',
    label: '🇻🇳 Vietnam (+84)',
    countryFlag: '🇻🇳',
  },
  {
    name: 'Virgin Islands, British',
    dialCode: '+1284',
    countryCode: 'VG',
    label: '🇻🇬 Virgin Islands, British (+1284)',
    countryFlag: '🇻🇬',
  },
  {
    name: 'Virgin Islands, U.S.',
    dialCode: '+1340',
    countryCode: 'VI',
    label: '🇻🇮 Virgin Islands, U.S. (+1340)',
    countryFlag: '🇻🇮',
  },
  {
    name: 'Wallis and Futuna',
    dialCode: '+681',
    countryCode: 'WF',
    label: '🇼🇫 Wallis and Futuna (+681)',
    countryFlag: '🇼🇫',
  },
  {
    name: 'Yemen',
    dialCode: '+967',
    countryCode: 'YE',
    label: '🇾🇪 Yemen (+967)',
    countryFlag: '🇾🇪',
  },
  {
    name: 'Zambia',
    dialCode: '+260',
    countryCode: 'ZM',
    label: '🇿🇲 Zambia (+260)',
    countryFlag: '🇿🇲',
  },
  {
    name: 'Zimbabwe',
    dialCode: '+263',
    countryCode: 'ZW',
    label: '🇿🇼 Zimbabwe (+263)',
    countryFlag: '🇿🇼',
  },
]
export const dialCodeOptionsByCountryCode = keyBy(dialCodeOptions, x => x.countryCode)
