import React from 'react'
import { DesignAuthors } from '../../pages/designs/DesignBrowser'

interface IconTagListItem {
  readonly label: string
  readonly logo: React.ReactNode | string
  readonly defaultChecked?: boolean
  readonly backgroundColor?: string
}

interface Props {
  readonly label: string
  readonly onChange?: any
  readonly tagList: ReadonlyArray<IconTagListItem>
  readonly designAuthorState: DesignAuthors
}

const IconTagList: React.FunctionComponent<Props> = ({
  tagList,
  onChange,
  label,
  designAuthorState,
}) => {
  const displayItems = (item, index): React.ReactNode => (
    <li key={index} className='tag-list-icon-item checkbox'>
      <input
        type='checkbox'
        name={item.label}
        value={item.id}
        id={`tag-list-icon-checkbox-${index}`}
        onChange={event => onChange(event)}
        checked={designAuthorState[item.id]}
      />
      <label className='checkbox-label' htmlFor={`tag-list-icon-checkbox-${index}`}>
        <span
          className='tag-list-icon-label-wrapper'
          style={{ backgroundColor: item.backgroundColor }}
        >
          {typeof item.logo === 'string' ? (
            <img className='tag-list-icon-item-logo' src={item.logo} alt={`${item.label} Logo`} />
          ) : (
            <span className='tag-list-icon-item-logo'>{item.logo}</span>
          )}
          {item.label}
        </span>
      </label>
    </li>
  )

  return (
    <div className='tag-list-icon'>
      <div className='tag-list-icon-label'>{label}</div>
      <ul className='tag-list-icon-ul'>{tagList.map(displayItems)}</ul>
    </div>
  )
}

export default IconTagList
