import React from 'react'
import classnames from 'classnames'
import { BlogPost } from '../../../api/Blog/Types'

interface Props {
  readonly tags: BlogPost['tag_list']
  readonly className?: string
  readonly useAnchor?: boolean
}

export const BlogTags: React.FC<Props> = ({ tags, className = 'blog-tags', useAnchor = true }) => {
  const tagClasses = classnames('blog-tags-item', useAnchor && 'blog-tags-item-link')
  const TagElement = useAnchor
    ? ('a' as keyof JSX.IntrinsicElements)
    : ('span' as keyof JSX.IntrinsicElements)

  return (
    <div className={className}>
      {tags.map((tag, i) => (
        <React.Fragment key={i}>
          {i > 0 && <span className='blog-tags-separator' key={`tag-sep-${i}`}></span>}
          <TagElement className={tagClasses} key={`tag-item-${i}`} href={`/blogs?filterBy=${tag}`}>
            {tag}
          </TagElement>
        </React.Fragment>
      ))}
    </div>
  )
}
