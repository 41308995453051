import React, { useState } from 'react'
import OnboardingJoinTeam, { TeamSelection } from './OnboardingJoinTeam'
import { TeamPreview, User } from '../../api/Users/Types'
import UserService from '../../api/Users/Service'

interface Props {
  readonly existing_teams: ReadonlyArray<TeamPreview>
  readonly setTeamSelected: React.Dispatch<
    React.SetStateAction<{
      teamId: null
      active: boolean
    }>
  >
  readonly teamSelected: TeamSelection
  readonly companyIdFromInvitation?: number
  profile: User
  joinSelectedTeam: (arg: number) => Promise<void>
  goToFinish: () => void
}

const OnboardingJoinTeamWrapper: React.FunctionComponent<Props> = ({
  existing_teams,
  setTeamSelected,
  teamSelected,
  companyIdFromInvitation,
  profile,
  joinSelectedTeam,
  goToFinish,
}) => {
  const [selectedTeamError, setSelectedTeamError] = useState(false)
  const [joiningTeam, setJoiningTeam] = useState(false)

  const selectTeam = event => {
    const teamId = event.target.dataset.teamId
    setTeamSelected(teamSelected => {
      if (
        (!teamSelected.teamId && !teamSelected.active) ||
        (teamSelected.teamId != teamId && teamSelected.active)
      ) {
        return { teamId, active: true }
      } else {
        return { teamId: null, active: false }
      }
    })
  }

  const handleSelectTeam = event => {
    event.preventDefault()
    selectTeam(event)
  }

  const handleJoinTeamSubmit = event => {
    event.preventDefault()
    const teamToJoin = teamSelected.teamId

    setJoiningTeam(true)

    if (!teamSelected.active || !teamToJoin) {
      setSelectedTeamError(true)
      setJoiningTeam(false)
    } else {
      setSelectedTeamError(false)
      joinSelectedTeam(parseInt(teamToJoin))
        .then(() => {
          trackJoinTeamSubmit()
          goToFinish()
        })
        .catch(() => {
          setJoiningTeam(false)
        })
    }
  }

  const trackJoinTeamSubmit = () => {
    GoogleAnalyticsHelper.VentionGAEvent('completed_team_setup', 'click', 'onboarding', profile.id)

    UserService.reportActivity(
      'onboarding',
      profile.id,
      {
        step: 'completed_team_setup',
      },
      () => undefined
    )
  }

  return (
    <OnboardingJoinTeam
      existing_teams={existing_teams}
      handleSelectTeam={handleSelectTeam}
      teamSelected={teamSelected}
      handleJoinTeamSubmit={handleJoinTeamSubmit}
      selectedTeamError={selectedTeamError}
      companyIdFromInvitation={companyIdFromInvitation}
      joiningTeam={joiningTeam}
    />
  )
}

export default OnboardingJoinTeamWrapper
