import React from "react";
import { VRegularText } from "../text/Text";
import { PartCheckbox } from "./PartCheckbox";
import { PartDTO } from "../../api/Parts/dtos/PartDTO";

export const EmptySimplePartCard: React.FC = () => {

  return (
    <div className="simple-card__empty-container">

      <div className="simple-card__empty-container__image">
        <i className='ti ti-plus icon-size-20px simple-card__empty-container__image__icon' />
      </div>
      <VRegularText className="space-margin-top-sm" fontSize="lg" color="terciary">Add Part</VRegularText>

    </div>
  )
}