import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
  size: 'xl'
  label?: string
  name: string
  min?: number
  max?: number
  onQuantityChange: (quantity: number) => void
}

const QuantitySelector: React.FC<Props> = ({
  className,
  size,
  label,
  name,
  min = 1,
  max = 1000,
  onQuantityChange,
  ...rest
}) => {
  const [value, setValue] = useState<number>(min)

  useEffect(() => {
    onQuantityChange(value)
  }, [value])

  const handleIncrement = event => {
    event.preventDefault()

    value < max && setValue(value + 1)
  }

  const handleDecrement = event => {
    event.preventDefault()

    value > min && setValue(value - 1)
  }

  const onChange = event => {
    setValue(event.target.value)
  }

  return (
    <div
      {...rest}
      className={classNames(
        'vention-ui-quantity-selector',
        `vention-ui-quantity-selector--${size}`,
        className
      )}
    >
      {label && (
        <label className='vention-ui-quantity-selector__label' htmlFor={name}>
          {label}
        </label>
      )}
      <div className='vention-ui-quantity-selector__container'>
        <button
          className='vention-ui-quantity-selector__button vention-ui-quantity-selector__button--left'
          onClick={handleDecrement}
        >
          <i className='ti ti-minus'></i>
        </button>
        <input
          className='vention-ui-quantity-selector__input'
          name={name}
          id={name}
          type='number'
          value={value}
          onChange={onChange}
          min={min}
          max={max}
        ></input>
        <button
          className='vention-ui-quantity-selector__button vention-ui-quantity-selector__button--right'
          onClick={handleIncrement}
        >
          <i className='ti ti-plus'></i>
        </button>
      </div>
    </div>
  )
}

export default QuantitySelector
