import React from 'react'
import { ShowIncludedPartOrFastener } from '../Page'

interface Props {
  readonly includedPartsAndFasteners: ReadonlyArray<ShowIncludedPartOrFastener>
}

const IncludedPartLinkOrText = ({ url, children }) => {
  if (url) {
    return (
      <a className='product-viewer__grid-link' href={url} target='_blank'>
        {children}

        <i className='ti ti-external-link'></i>
      </a>
    )
  } else {
    return <div className='product-viewer__grid-link'>{children}</div>
  }
}

const WhatsIncluded: React.FC<Props> = ({ includedPartsAndFasteners }) => {
  return (
    <section id='whats-included' className='product-viewer__whats-included product-viewer__section'>
      <h2 className='product-viewer__section-heading'>What's Included</h2>
      <div className='product-viewer__grid-links'>
        {includedPartsAndFasteners.map((includedPartOrFastener, index) => (
          <IncludedPartLinkOrText url={includedPartOrFastener.part_url} key={index}>
            <>
              <img
                className='product-viewer__grid-link-image'
                src={includedPartOrFastener.thumbnail_image_path}
                alt={includedPartOrFastener.name}
              />
              <div className='product-viewer__grid-link-text'>
                <div className='product-viewer__grid-link-title'>{includedPartOrFastener.name}</div>
                <div className='product-viewer__grid-link-subtitle'>
                  Quantity: {includedPartOrFastener.quantity}
                </div>
              </div>
            </>
          </IncludedPartLinkOrText>
        ))}
      </div>
    </section>
  )
}

export default WhatsIncluded
