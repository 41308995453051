import React from 'react'
import { BlogPost } from '../../../../api/Blog/Types'
import { BlogTile } from '../../../../components/common/blog/BlogTile'

interface PropsBlogPosts {
  readonly posts: ReadonlyArray<BlogPost>
  isHomepage?: boolean
}

const BlogPosts: React.FC<PropsBlogPosts> = ({ posts, isHomepage }) => {
  return (
    <div className='blog-posts-section'>
      <div className='blog-posts-section-header'>
        <h2 className='blog-posts-section-header-title'>Explore the Blog</h2>
        <a
          className='blog-posts-section-header-link'
          href='/blogs'
          data-analytic-id='home-blog-view-all-link'
        >
          View all
        </a>
      </div>
      <div className='blog-posts-section-list'>
        {posts.map((post, i) => (
          <BlogTile
            key={`blog-posts-${i}`}
            post={post}
            isHomepage={isHomepage}
            postNumber={i + 1}
            lazyLoadImage
          />
        ))}
      </div>
    </div>
  )
}

export default BlogPosts
