import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import ApplicationItemRow from './ApplicationItemRow'
import DocsContact from '../../../../components/cms/docs/DocsContact'
import { useDesignCategoriesContext } from '../../../../components/common/providers/DesignCategoriesProvider'
import Loading from '../../../../components/common/Loading'
import parameterize from 'parameterize'
import { scrollTo } from '../../../../helpers/WindowHelper'
import ApplicationFloorPlan from './ApplicationFloorPlan'
import uniq from 'lodash/uniq'

// calibrated according to the image and hover areas;
// the names must match the values returned from the database
const applicationPopoverSections = [
  {
    name: 'Manufacturing',
    tooltipPlace: 'right',
    tooltipLeft: 0.38,
    tooltipTop: 0.65,
  },
  {
    name: 'Packaging',
    tooltipPlace: 'left',
    tooltipLeft: 0.46,
    tooltipTop: 0.72,
  },
  {
    name: 'Quality Inspection',
    tooltipPlace: 'right',
    tooltipLeft: 0.38,
    tooltipTop: 0.22,
  },
  {
    name: 'Assembly',
    tooltipPlace: 'left',
    tooltipLeft: 0.46,
    tooltipTop: 0.35,
  },
]

const Applications: FunctionComponent<{}> = () => {
  const [category, setCurrentCategory] = useState('All')
  const { isLoaded, productizedApplicationsByCategory } = useDesignCategoriesContext()
  const categoryHeaderRef = useRef<HTMLDivElement>(null)

  const allApplications = uniq(Object.values(productizedApplicationsByCategory).flat())

  const categoryLabels = Object.keys(productizedApplicationsByCategory)

  const labelsByParam = { 'all-applications': 'All' }
  categoryLabels.forEach(categoryLabel => {
    labelsByParam[parameterize(categoryLabel)] = categoryLabel
  })

  const selectedApplications = productizedApplicationsByCategory[category]

  const setFilterFromParams = (): void => {
    const urlIncludesAnchor = location.href.includes('#')
    if (urlIncludesAnchor) {
      const anchorSlug = location.href.split('#').pop()
      anchorSlug && setCurrentCategory(labelsByParam[anchorSlug])

      if (anchorSlug && categoryHeaderRef.current) {
        scrollTo(categoryHeaderRef.current)
      }
    }
  }

  useEffect(() => {
    if (isLoaded) {
      setFilterFromParams()
      window.addEventListener('hashchange', setFilterFromParams)
    }

    return () => window.removeEventListener('hashchange', setFilterFromParams)
  }, [isLoaded])

  const ApplicationsFilter = (): JSX.Element => (
    <div className='applications-filter'>
      <label className='applications-filter-label'>Filter by use case:</label>
      <select
        className='applications-filter-select'
        disabled={!isLoaded}
        onChange={event => {
          window.location.hash = `#${parameterize(event.target.value)}`
          setCurrentCategory(event.target.value)
        }}
        value={category}
      >
        <option value='All Applications'>All Applications</option>
        {categoryLabels.map((categoryLabel, i) => (
          <option key={i} value={categoryLabel}>
            {categoryLabel}
          </option>
        ))}
      </select>
    </div>
  )

  return (
    <div className='applications-list-page'>
      <div className='container-lg'>
        <div className='applications-list-page-heading-wrapper'>
          <h1 className='applications-list-page-heading no-mobile'>
            Out-of-the-box solutions for manufacturing automation
          </h1>
          <p className='no-mobile'>Hover over the shop floor to explore applications</p>

          <h1 className='applications-list-page-heading no-desktop'>
            Explore customizable applications
          </h1>
        </div>

        <ApplicationFloorPlan
          productizedApplicationsByCategory={productizedApplicationsByCategory}
          applicationPopoverSections={applicationPopoverSections}
          hideOnMobile
        />

        <div ref={categoryHeaderRef} className='applications-list-page-section-header'>
          <h2 className='applications-list-page-section-header-title'>{category} Applications</h2>
          <ApplicationsFilter />
        </div>

        {isLoaded ? (
          <ApplicationItemRow
            productizedApplications={selectedApplications || allApplications}
            key={'app-row'}
          />
        ) : (
          <Loading />
        )}
      </div>
      <DocsContact
        title='Build your factory faster'
        description='Industrial machines out of the box'
      />
    </div>
  )
}

export default Applications
