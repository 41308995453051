import React from 'react'
import TableGroupRow, { TableGroupRowProps } from './TableGroupRow'
import TableGroupRowDesktop from './TableGroupRowDesktop'

export interface TableGroupProps {
  title: string
  titleIcon?: string
  rows: TableGroupRowProps[]
  subscriptionType?: 'freemium' | 'pro' | 'business' | 'robot'
  desktop?: boolean
}

const TableGroup: React.FC<TableGroupProps> = ({
  title,
  titleIcon,
  rows,
  desktop,
  subscriptionType,
}) => {
  return (
    <div className='subscriptions-pricing__table-group'>
      <p className='subscriptions-pricing__table-group-title'>
        {title}
        {titleIcon && <i className={titleIcon}></i>}
      </p>
      <div>
        {desktop
          ? rows?.map((tableGroupRowProps, index) => (
              <TableGroupRowDesktop
                key={index}
                {...tableGroupRowProps}
                oddRow={Math.abs(index % 2) == 1}
              />
            ))
          : rows?.map((tableGroupRowProps, index) => (
              <TableGroupRow
                key={index}
                {...tableGroupRowProps}
                oddRow={Math.abs(index % 2) == 1}
                subscriptionType={subscriptionType}
              />
            ))}
      </div>
    </div>
  )
}

export default TableGroup
