import React, { ReactElement } from 'react'

interface Props {
  readonly icon: ReactElement
  readonly title: string
  readonly text: string
}

const CareersListItem: React.FunctionComponent<Props> = ({ icon, title, text }) => {
  return (
    <div className='careers-list-item'>
      <div className='careers-list-item-icon'>{icon}</div>
      <div className='careers-list-item-title'>{title}</div>
      <p className='careers-list-item-text'>{text}</p>
    </div>
  )
}

export default CareersListItem
