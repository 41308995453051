import { BlogPostQuerySettings, fetchBlogPostsCallback, BlogEditor } from './Types'
import { appendQueryString } from '../../helpers/StringHelper'
import axios from 'axios'
import { graphqlPublicQuery } from '../graphql'

interface FetchBlogEditorResponse {
  readonly editor: BlogEditor
  readonly editor_name: string
}
const BlogService = {
  fetchBlogPosts: function (
    querySettings: BlogPostQuerySettings,
    cb: fetchBlogPostsCallback | undefined = undefined
  ) {
    const params = querySettings

    graphqlPublicQuery(`query (
      $page: Int,
      $pageSize: Int,
      $search: String,
      $feature: BlogFeature,
      $editor: String,
      $excludeBlogIds: [ID!],
      $tags: [String!],
      $tagsByMethod: String,
      $unfilterFeatureIfEmpty: Boolean,
    ) {
      blogs(
        page: $page,
        pageSize: $pageSize,
        search: $search,
        feature: $feature,
        editor: $editor,
        excludeBlogIds: $excludeBlogIds,
        tags: $tags,
        tagsByMethod: $tagsByMethod,
        unfilterFeatureIfEmpty: $unfilterFeatureIfEmpty,
      ) {
        collection {
          editor
          feature
          description
          title
          subtitle
          title_image: titleImage
          id
          url
          author_created: authorCreated
          description
          thumbnail_title_image: thumbnailTitleImage
          editor_name: editorName
          tag_list: tags {
            id
            name
          }
          categorized_path: categorizedPath
          blog_editor: blogEditor {
            id
            name
            title
            editor_key: editorKey
            image
            thumbnail_url: thumbnailUrl
          }
        }
        
        metadata {
          totalPages
          totalCount
          currentPage
          limitValue
        }
      }
    }`, {
      page: params.pageNumber || 1,
      pageSize: params.limit || 24,
      feature: params.feature,
      search: params.searchTerm,
      editor: params.editor,
      excludeBlogIds: ((params.currentBlogId) ? [params.currentBlogId] : null),
      tags: (params.filterBy ? [params.filterBy] : []),
      tagsByMethod: params.filterByMethod,
      unfilterFeatureIfEmpty: true,
    }).then(function (response: any) {
      const preparedResponse = {
        blog_posts: response.data.blogs.collection.map((blog: any) => {
          if (blog.tag_list) {
            blog.tag_list = blog.tag_list.map((tag: any) => tag.name)
          }

          return blog
        }),
        count: response.data.blogs.metadata.totalCount,
        per_page: response.data.blogs.metadata.limitValue
      }

      if (undefined != cb) {
        cb(preparedResponse)
      }
    }).catch(function (error) {
      console.error(error)
    })
  },

  fetchBlogEditor: async function (editorCode: string): Promise<FetchBlogEditorResponse>{
    try {
      const url = appendQueryString('/fetch_blog_editor', {
        editor: editorCode,
      })
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
}

export default BlogService
