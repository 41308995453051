import React from 'react'
import { DesignCategory } from '../../api/DesignCategories/Types'
import DesignBrowserSideNavButton from './DesignBrowserSideNavButton'

interface Props {
  label?: string
  html?: string
  activeCategory?: string
  activeSubCategory?: string
  categoryKey?: string
  subCategoryKey?: string
  anchor?: boolean
  subCategories?: ReadonlyArray<DesignCategory>
  handleClick?: Function
  isSubCategory?: boolean
}

const DesignBrowserSideNavTab: React.FC<Props> = ({
  label,
  html,
  activeCategory,
  activeSubCategory,
  categoryKey,
  subCategoryKey,
  anchor,
  subCategories,
  handleClick,
  isSubCategory,
}) => {
  function isCategoryActive() {
    return (
      activeCategory === categoryKey || (activeSubCategory && activeSubCategory === subCategoryKey)
    )
  }

  const classNames: string[] = []
  if (isCategoryActive()) classNames.push('active')
  if (activeSubCategory && !isSubCategory) classNames.push('has-active-subcategory')

  return (
    <li className={`side-nav-tab ${classNames.join(' ')}`}>
      {subCategories ? (
        <>
          <DesignBrowserSideNavButton
            label={label}
            html={html}
            categoryKey={categoryKey}
            subCategoryKey={subCategoryKey}
            handleClick={handleClick}
          />
          <ul className='side-nav-dropdown'>
            {subCategories.map((subCategory, index) => (
              <DesignBrowserSideNavTab
                activeSubCategory={activeSubCategory}
                label={subCategory.name}
                categoryKey={categoryKey}
                subCategoryKey={subCategory.code}
                anchor={true}
                key={`option-${categoryKey}-${index}`}
                handleClick={handleClick}
                isSubCategory
              />
            ))}
          </ul>
        </>
      ) : (
        <DesignBrowserSideNavButton
          label={label}
          html={html}
          categoryKey={categoryKey}
          subCategoryKey={subCategoryKey}
          anchor={anchor}
          handleClick={handleClick}
        />
      )}
    </li>
  )
}

export default DesignBrowserSideNavTab
