import moment from 'moment'
import React from 'react'
import { BlogPost } from '../../../../../api/Blog/Types'

interface Props {
  blog: BlogPost
}

const BlogCard: React.FC<Props> = ({ blog }) => {
  return (
    <a className='contact__blog-card' href={`/blogs/${blog.url}-${blog.id}`}>
      <img className='contact__blog-card-image' src={blog.title_image.thumb.url} alt={blog.title} />
      <h3 className='contact__blog-card-title'>{blog.title}</h3>
      <p className='contact__blog-card-description'>{blog.description}</p>
      <div className='contact__blog-card-footer'>
        <p className='contact__blog-card-name'>{blog.editor.split('_').join(' ')}</p>
        <p className='contact__blog-card-date'>
          {moment(blog.author_created).format('MMMM Do, YYYY')}
        </p>
      </div>
    </a>
  )
}

export default BlogCard
