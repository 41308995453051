import React, { useMemo } from 'react'
import classNames from 'classnames'

export interface ProgressBar {
  readonly onProfileSetup: boolean
  readonly onCreateOrJoin: boolean
  readonly onCreateTeam: boolean
  readonly onInviteToNewTeam: boolean
  readonly onJoinTeam: boolean
}

interface Props {
  readonly stepsBar: ProgressBar
  readonly goToCreateOrJoin: () => void
  readonly goToJoinTeam?: () => void
  readonly createOrJoinTeamInStepsBar?: boolean
  readonly allowBackToJoinTeam?: boolean
}

const OnboardingStepsBar: React.FunctionComponent<Props> = ({
  stepsBar,
  goToCreateOrJoin,
  goToJoinTeam,
  createOrJoinTeamInStepsBar,
  allowBackToJoinTeam,
}) => {
  const shouldDisplayBackButton = () => {
    return stepsBar.onCreateTeam || stepsBar.onJoinTeam || stepsBar.onInviteToNewTeam
  }

  const displayBackButton = useMemo(() => shouldDisplayBackButton(), [stepsBar])

  return (
    <div className='activation-steps-bar-wrapper'>
      {allowBackToJoinTeam && displayBackButton && (
        <button
          className='activation-steps-back-to-team'
          onClick={
            stepsBar.onJoinTeam || stepsBar.onInviteToNewTeam || stepsBar.onCreateTeam
              ? goToCreateOrJoin
              : goToJoinTeam
          }
        >
          Back
        </button>
      )}
      <div className='activation-steps-bar'>
        <div className={classNames('progress-step-wrapper')}>
          <div className='progress-icon-wrapper'>
            {stepsBar.onProfileSetup ? (
              <div className='number-icon-circle active'>
                <p className='progress-icon-number active'>1</p>
              </div>
            ) : (
              <button className='button-blank button-no-padding' disabled={true}>
                <i className='progress-icon-active progress-icon fas fa-check-circle'></i>
              </button>
            )}
            <div className={`${!stepsBar.onProfileSetup && 'progress-active'} progress-line`}></div>
          </div>

          <p className={`${stepsBar.onProfileSetup && 'progress-icon-active'} progress-label`}>
            Complete profile
          </p>
        </div>
        <div className={classNames('progress-step-wrapper')}>
          <div className='progress-icon-wrapper'>
            {stepsBar.onCreateOrJoin || !stepsBar.onProfileSetup ? (
              <button
                className='button-blank button-no-padding'
                onClick={goToCreateOrJoin}
                disabled={stepsBar.onCreateOrJoin || !stepsBar.onProfileSetup}
              >
                <div className='number-icon-circle active'>
                  <p className='progress-icon-number active'>2</p>
                </div>
                {(stepsBar.onCreateTeam || stepsBar.onJoinTeam) && (
                  <div className='progress-active progress-line half-progress-line'></div>
                )}
                <div
                  className={`${stepsBar.onInviteToNewTeam && 'progress-active'} progress-line`}
                ></div>
              </button>
            ) : (
              <>
                <div className='number-icon-circle'>
                  <p className='progress-icon-number'>2</p>
                </div>
                {(stepsBar.onCreateTeam || stepsBar.onJoinTeam) && (
                  <div className='progress-active progress-line half-progress-line'></div>
                )}
                <div
                  className={`${stepsBar.onInviteToNewTeam && 'progress-active'} progress-line`}
                ></div>
              </>
            )}
          </div>

          <p className={`${!stepsBar.onProfileSetup && 'progress-icon-active'} progress-label`}>
            {`${createOrJoinTeamInStepsBar ? 'Join' : 'Create'} a team`}
          </p>
        </div>
      </div>
    </div>
  )
}
export default OnboardingStepsBar
