import { gql } from "graphql-request";

export const fetchFeaturedPartCategoriesQuery = gql`
  query fetchFeaturedPartCategories {
    featuredPartCategories {
      collection {
        id
        order
        partCategory {
          id
          imageUrl
          name
          description
          code
          parent {
            id
            code
            name
          }
        }
      }
    }
  }
`;
