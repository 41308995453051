import React, { useState } from 'react'
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm'
import InputValidator from '../../common/InputValidator'
import TextAreaValidator from '../../common/TextAreaValidator'
import SelectValidator from '../../common/SelectValidator.jsx'
import HomeService from '../../../api/Home/Service'
import { User } from '../../../api/Users/Types'
import PhoneCountryInput from '../../common/inputs/PhoneCountryInput/PhoneCountryInput'
import SuccessMessage from '../../common/SuccessMessage'
import { useDesignCategoriesContext } from '../../common/providers/DesignCategoriesProvider'
import VentionTerms from '../../onboarding/VentionTerms'
import I18n from '../../../helpers/I18n'
import { GoogleCaptchaV3Service } from '../../../api/Google/captchaV3.service'

type InputType =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'useCase'
  | 'application'
  | 'description'
type AvailableFields = { [label in InputType]?: boolean }

export const DefaultInputs: ReadonlyArray<InputType> = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'useCase',
  'application',
  'description',
]

interface Props {
  readonly currentUser?: User
  readonly captchaKey?: any
  readonly inputs: ReadonlyArray<InputType>
  readonly labels?: Partial<{ [label in InputType]: string }>
  readonly submitLabel?: string
  readonly apiEndpoint?: string
  readonly successCallback?: Function
  readonly errorCallback?: Function
  readonly showUseCase?: boolean
  readonly successMessage?: string
  submitText?: string
}

const BookMeetingForm: React.FunctionComponent<Props> = ({
  currentUser,
  captchaKey,
  inputs,
  labels,
  successCallback,
  errorCallback,
  apiEndpoint,
  children,
  showUseCase = true,
  successMessage = 'Thank you, we will get back to you today! <br/> Visit our <a href="/designs">design library</a> while we schedule your call.',
  submitText,
}) => {
  const [firstName, setFirstName] = useState(currentUser?.first_name || '')
  const [lastName, setLastName] = useState(currentUser?.last_name || '')
  const [email, setEmail] = useState(currentUser?.email || '')
  const [phone, setPhone] = useState(currentUser?.phone)
  const [error, setError] = useState('')
  const [useCase, setSelectedUseCase] = useState('')
  const [application, setSelectedApplication] = useState('')
  const [description, setDescription] = useState('')
  const [tteEmailSent, setTteEmailSent] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const errorMessages = {
    required: I18n.t('views.common.form.errors.required'),
    invalid: (label: string) => I18n.t('views.common.form.errors.required', { field: label }),
  }

  const DefaultLabels: { [label in InputType]: string } = {
    firstName: I18n.t('home.book_meeting.form.fields.first_name'),
    lastName: I18n.t('home.book_meeting.form.fields.last_name'),
    email: I18n.t('home.book_meeting.form.fields.email'),
    phone: I18n.t('home.book_meeting.form.fields.phone'),
    useCase: I18n.t('home.book_meeting.form.fields.select_use_case'),
    application: I18n.t('home.book_meeting.form.fields.select_application'),
    description: I18n.t('home.book_meeting.form.fields.description'),
  }

  const { isLoaded, designCategories } = useDesignCategoriesContext()

  const useCases = isLoaded ? designCategories.map(categoryUseCase => categoryUseCase.name) : []

  const selectedUseCase =
    isLoaded && designCategories.find(categoryUseCase => categoryUseCase.name === useCase)

  const applications =
    selectedUseCase &&
    selectedUseCase.subcategories?.map(categoryApplication => categoryApplication.name)

  const availableFields: AvailableFields = DefaultInputs.reduce((result, item) => {
    result[item] = inputs.includes(item)
    return result
  }, {})

  const resetState = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setSelectedUseCase('')
    setSelectedApplication('')
    setDescription('')
  }

  const handleOnSubmit = event => {
    setIsSending(true)
    event.preventDefault()
    setError('')

    const formData = {}
    if (availableFields?.firstName) formData['first_name'] = firstName
    if (availableFields?.lastName) formData['last_name'] = lastName
    if (availableFields?.phone) formData['phone'] = phone
    if (availableFields?.email) formData['email'] = email
    if (availableFields?.useCase) formData['use_case'] = useCase
    if (availableFields?.application) formData['application'] = application
    if (availableFields?.description) formData['description'] = description
    formData['ga_client_id'] = window.ga_client_id

    GoogleCaptchaV3Service.verifyCaptcha(token => {
      if (token) {
        formData['g-recaptcha-response'] = token
      }
      submitContactUs(formData)
    }, captchaKey)
  }

  const submitContactUs = formData => {
    if (window._mfq) window._mfq.push(['formSubmitAttempt', '#talk-to-expert-form'])

    HomeService.submitContactUs(
      formData,
      (success, err) => {
        if (success) {
          resetState()
          setTteEmailSent(true)
          if (typeof successCallback === 'function') {
            successCallback()
          }

          GoogleAnalyticsHelper.VentionGAEvent('talk-to-an-expert-submit', 'submit', 'funnel')

          // LinkedIn Conversion Tracking
          if (window.lintrk) {
            window.lintrk('track', { conversion_id: 4044785 })
          }

          setIsSending(false)

          if (window._mfq) window._mfq.push(['formSubmitSuccess', '#talk-to-expert-form'])
        } else if (err) {
          const errorMessage = err.responseJSON ? err.responseJSON.message : 'An error has occurred'

          setError(errorMessage)

          if (typeof errorCallback === 'function') {
            errorCallback(errorMessage)
          }
          setIsSending(false)

          if (window._mfq) window._mfq.push(['formSubmitFailure', '#talk-to-expert-form'])
        }
      },
      apiEndpoint
    )
  }

  if (tteEmailSent) {
    return (
      <div className='book-meeting-form tte-success-message-container '>
        <SuccessMessage message={successMessage} />
      </div>
    )
  }

  return (
    <ValidatorForm
      id='talk-to-expert-form'
      className='book-meeting-form'
      onSubmit={handleOnSubmit}
      instantValidate={false}
    >
      {children}
      {availableFields?.firstName && (
        <InputValidator
          type='text'
          id='first-name'
          placeholder=' '
          onChange={event => setFirstName(event.target.value)}
          name='firstName'
          value={firstName}
          validators={['required']}
          errorMessages={[errorMessages.required]}
        >
          <label htmlFor='first-name'>
            {(labels && labels.firstName) || DefaultLabels.firstName}
          </label>
        </InputValidator>
      )}

      {availableFields?.lastName && (
        <InputValidator
          type='text'
          id='last-name'
          placeholder=' '
          onChange={event => setLastName(event.target.value)}
          name='lastName'
          value={lastName}
          validators={['required']}
          errorMessages={[errorMessages.required]}
        >
          <label htmlFor='last-name'>{(labels && labels.lastName) || DefaultLabels.lastName}</label>
        </InputValidator>
      )}

      {availableFields?.email && (
        <InputValidator
          type='email'
          id='email'
          placeholder=' '
          onChange={event => setEmail(event.target.value)}
          name='email'
          value={email}
          validators={['required', 'isEmail']}
          errorMessages={[
            errorMessages.required,
            errorMessages.invalid(labels?.email || DefaultLabels.email),
          ]}
        >
          <label htmlFor='email'>{(labels && labels.email) || DefaultLabels.email}</label>
        </InputValidator>
      )}

      {availableFields?.phone && (
        <>
          <PhoneCountryInput
            placeholder=' '
            phone={phone}
            onChange={phone => setPhone(phone)}
            required
          >
            <label htmlFor='phone'>{(labels && labels.phone) || DefaultLabels.phone}</label>
          </PhoneCountryInput>
        </>
      )}
      {availableFields?.description && (
        <>
          <TextAreaValidator
            className='form-control'
            id='description'
            value={description}
            placeholder=' '
            validators={['maxStringLength:500']}
            characterLimit={500}
            errorMessages={[errorMessages.required]}
            autoResize={true}
            onChange={event => setDescription(event.target.value)}
          >
            <label htmlFor='description'>
              {!description.length && ((labels && labels.description) || DefaultLabels.description)}
            </label>
          </TextAreaValidator>
        </>
      )}
      <>
        {showUseCase && (
          <>
            <div className='form-group'>
              <SelectValidator
                className='form-control application-select'
                addWrapperAroundSelect={true}
                name='use-case'
                value={useCase}
                options={useCases}
                onChange={event => setSelectedUseCase(event.target.value)}
                placeholderOption={DefaultLabels.useCase}
              />
            </div>
            {useCase && (
              <div className='form-group'>
                <SelectValidator
                  className='form-control application-select'
                  addWrapperAroundSelect={true}
                  name='application'
                  value={application}
                  options={applications}
                  onChange={event => setSelectedApplication(event.target.value)}
                  placeholderOption={DefaultLabels.application}
                />
              </div>
            )}
          </>
        )}
        <VentionTerms />
      </>

      {error && <div className='form-error-message'>{error}</div>}

      <button type='submit' className='contact-form-submit-button' disabled={isSending}>
        {submitText || I18n.t('views.common.form.buttons.submit')}
      </button>
    </ValidatorForm>
  )
}

BookMeetingForm.defaultProps = {
  apiEndpoint: '/do_book_meeting',
} as Partial<Props>

export default BookMeetingForm
