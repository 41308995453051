import React, { useEffect, SyntheticEvent } from "react"
import { ValidatorForm } from "react-form-validator-core"
import InputValidator from "../common/InputValidator"
import { User } from "../../api/Users/Types"
import PhoneCountryInput from "../common/inputs/PhoneCountryInput/PhoneCountryInput"
import I18n from "../../helpers/I18n"
import { VentionLogoSvg } from "./VentionLogoSvg"
import { ProfileSvg } from "./ProfileSvg"

export interface UserInfoFields {
  readonly firstName: string
  readonly lastName: string
  readonly password: string
  readonly phone: string
  readonly role: string
}

interface Props {
  readonly handleProfileSetupSubmit: (event: SyntheticEvent) => void
  readonly handleFirstNameChange: (event: SyntheticEvent) => void
  readonly handlePasswordChange: (event: SyntheticEvent) => void
  readonly handleLastNameChange: (event: SyntheticEvent) => void
  readonly handlePhoneChange: (phone: string) => void
  readonly handleRoleChange: (event: SyntheticEvent) => void
  readonly user: UserInfoFields
  readonly handleSubscriptionChange: (event: SyntheticEvent) => void
  readonly profile: User
  readonly isLoading: boolean
  readonly subscription?: boolean
  readonly errors?: { [key: string]: string }
}

const ProjectTitanOnboardingProfileSetUp: React.FunctionComponent<Props> = ({
  handleProfileSetupSubmit,
  handleFirstNameChange,
  handleLastNameChange,
  handlePhoneChange,
  handlePasswordChange,
  handleRoleChange,
  user,
  handleSubscriptionChange,
  profile,
  isLoading,
  subscription,
  errors,
}) => {
  useEffect(() => {
    ValidatorForm.addValidationRule("isPhoneNumber", value => {
      const input = parseInt(value)
      return Number.isInteger(input) && value.length > 1
    })
  }, [])

  const onSubmit = async (event: SyntheticEvent) => {
    handleProfileSetupSubmit(event)
  }

  return (
    <div className="project-titan-form__container">
      <div className="project-titan-form__generic project-titan-form__sign-up">
        <div className="project-titan-auth-modal__body">
          <div className="project-titan-auth-modal__logo">
            <VentionLogoSvg />
          </div>

          <div className="project-titan-auth-modal__activation-header">
            <ProfileSvg />
            <h2>{I18n.t("devise.onboarding.onboarding.title")}</h2>
          </div>

          <ValidatorForm onSubmit={onSubmit} instantValidate={false}>
            <InputValidator
              onChange={handleFirstNameChange}
              id="first-name"
              name="firstName"
              value={user.firstName}
              placeholder={I18n.t("devise.onboarding.onboarding.form.first_name.placeholder")}
              validators={["required"]}
              errorMessages={[I18n.t("devise.onboarding.onboarding.form.first_name.required_error")]}
              type="text"
              label={I18n.t("devise.onboarding.onboarding.form.first_name.label")}
            />
            <InputValidator
              onChange={handleLastNameChange}
              id="last-name"
              name="lastName"
              value={user.lastName}
              placeholder={I18n.t("devise.onboarding.onboarding.form.last_name.placeholder")}
              validators={["required"]}
              errorMessages={[I18n.t("devise.onboarding.onboarding.form.last_name.required_error")]}
              type="text"
              label={I18n.t("devise.onboarding.onboarding.form.last_name.label")}
            />

            {/* only show if empty from current profile,
            e.g. if user bypassed signup form via invite */}
            {!profile.phone && (
              <PhoneCountryInput
                placeholder={I18n.t("devise.onboarding.onboarding.form.phone_number.placeholder")}
                onChange={phone => handlePhoneChange(phone)}
                label={I18n.t("devise.onboarding.onboarding.form.phone_number.label")}
                required
              />
            )}

            <InputValidator
              onChange={handlePasswordChange}
              id="password"
              name="password"
              value={user.password}
              placeholder={I18n.t("devise.onboarding.onboarding.form.password.placeholder")}
              validators={["required", "minStringLength: 8", "maxStringLength: 72"]}
              errorMessages={[
                I18n.t("devise.onboarding.onboarding.form.password.required_error"),
                I18n.t("devise.onboarding.onboarding.form.password.min_length_error"),
                I18n.t("devise.onboarding.onboarding.form.password.max_length_error")
              ]}
              type="password"
              autoComplete="new-password"
              label={I18n.t("devise.onboarding.onboarding.form.password.label")}
            >
              <ul className="password-length-text">
                <li>{I18n.t("devise.onboarding.onboarding.password_hint")}</li>
              </ul>
            </InputValidator>

            {!profile.marketing_email_opt_out_key && (
              <div>
                <input
                  type="checkbox"
                  id="news-checkbox"
                  onChange={event => handleSubscriptionChange(event)}
                  checked={subscription}
                />
                &nbsp;
                <label htmlFor="news-checkbox">
                  {I18n.t("devise.onboarding.onboarding.keep_me_updated")}
                </label>
              </div>
            )}

            {!!errors && <div>{Object.values(errors).join(", ")}</div>}

            <input name="email" value={profile.email} type="hidden"/>

            <input type="submit" className="submit-button" value={I18n.t("devise.onboarding.onboarding.submit")} disabled={isLoading}/>
          </ValidatorForm>
        </div>
      </div>
    </div>
  )
}

export default ProjectTitanOnboardingProfileSetUp
