import React from "react";
import { ResultCard, PartResult } from "./ResultCard";
import { Result } from "@coveo/headless";

export interface SearchResultsProps {
  results: Result[];
}

export const SearchResults: React.FC<SearchResultsProps> = (props: SearchResultsProps) => {
  return (
    <div className="grid gap-4 grid-flow-row grid-cols-2 2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3">

      {
        props.results.map((result) => (
          <ResultCard
            key={result.uniqueId}
            data={result as PartResult}
          />
        ))
      }
    </div>
  );
}
