import React, { SyntheticEvent } from 'react'
import { TeamPreview, User } from '../../api/Users/Types'

interface Props {
  readonly profile: User
  readonly existing_teams: ReadonlyArray<TeamPreview>
  readonly handleJoinTeamClick: (event?: SyntheticEvent) => void
  readonly handleCreateTeamClick: (event?: SyntheticEvent) => void
}

const companyImage = (logoUrl: string | undefined) => {
  return logoUrl ? (
    <img className='member-avatar' src={logoUrl} alt={'team logo'} />
  ) : (
    <div className='member-avatar'>
      <i className='fal fa-user-friends fa-2x'></i>
    </div>
  )
}

const OnboardingCreateOrJoin: React.FunctionComponent<Props> = ({
  handleJoinTeamClick,
  handleCreateTeamClick,
  existing_teams,
  profile,
}) => {
  const numberOfTeamMembers = existing_teams.reduce(
    (sum, aTeam) => (sum += aTeam.public_users.length),
    0
  )
  const ctaAction = existing_teams.length ? handleJoinTeamClick : handleCreateTeamClick

  return (
    <div className='create-or-join-form'>
      {existing_teams.length ? (
        <div className='avatars-wrapper'>{companyImage(profile.company_logo)}</div>
      ) : null}
      <h2 className='make-team-heading'>
        {!existing_teams.length
          ? "Be the first to create your company's team"
          : numberOfTeamMembers > 1
          ? `${numberOfTeamMembers} team members at your company are already using Vention`
          : `${numberOfTeamMembers} team member at your company is already using Vention`}
      </h2>
      <div className='make-team-btn-wrapper'>
        <button className='button button-vention button-md join-company-btn' onClick={ctaAction}>
          {!existing_teams.length ? 'Create your own team' : "Join your company's team"}
        </button>
      </div>
      {existing_teams.length > 0 && (
        <div>
          <button className='button-link-underline' onClick={handleCreateTeamClick}>
            Create a new team
          </button>
        </div>
      )}
    </div>
  )
}

export default OnboardingCreateOrJoin
