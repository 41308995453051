import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Nav from './components/Nav'
import TechnicalDocuments from './components/TechnicalDocuments'
import Specifications from './components/Specifications'
import WhatsIncluded from './components/WhatsIncluded'
import Recommendations from './components/Recommendations'
import Faqs from './components/Faqs'

export interface ShowPart {
  readonly id: number
  readonly name: string
  readonly part_number: string
  readonly description: string
  readonly is_light_duty: boolean
  readonly weight?: number
  readonly length?: number
  readonly width?: number
  readonly height?: number
  readonly hide_price: boolean
  readonly material: string
  readonly surface_finish: string
  readonly applicable_standards: string
  readonly vention_comment: string
  readonly is_software: boolean
  readonly part_category: ShowPartCategory & {
    parent: ShowPartCategory
  }
  readonly image_path: string
  readonly certification_logos: ReadonlyArray<ShowCertificationLogo>
  readonly additional_image: {
    readonly path: string
    readonly className: string
  }
  readonly status_availability: ShowPartStatusAvailability
  readonly display_price: string
  readonly shipment_delay_weeks: number
  readonly canonical_url: string
  readonly allow_checkout: boolean
  readonly pdf_availability: boolean
  readonly step_availability: boolean
  readonly hide_drawing_2d_file: boolean
  readonly published_technical_documents: ReadonlyArray<ShowTechnicalDocument>
  readonly legacy_technical_documents: ReadonlyArray<ShowLegacyTechnicalDocument>
  readonly associated_fasteners: ReadonlyArray<ShowFastener>
  readonly light_duty_fasteners: ReadonlyArray<ShowFastener>
  readonly part_documents: ReadonlyArray<ShowPartDocument>
  readonly calculators: ReadonlyArray<ShowCalculator>
  readonly technical_specifications: ReadonlyArray<ShowTechnicalSpecs>
}

interface ShowPartStatusAvailability {
  readonly className: string
  readonly text: string
}
interface ShowPartCategory {
  readonly name: string
  readonly url: string
}

interface ShowTechnicalDocument {
  readonly url: string
  readonly id: number
  readonly title: string
}

interface ShowLegacyTechnicalDocument {
  readonly name: string
  readonly path: string
}

interface ShowPartDocument {
  readonly title: string
  readonly file_url: string
}

interface ShowCalculator {
  readonly name: string
  readonly url: string
  readonly analytic_id: string
  readonly description: string
}

interface ShowTechnicalSpecs {
  readonly label: string
  readonly properties: ReadonlyArray<ShowTechnicalSpecsProperties>
}

interface ShowTechnicalSpecsProperties {
  readonly label: string
  readonly unit: string
  readonly value: string
  readonly spec_name: string
}

interface ShowCertificationLogo {
  readonly id: number
  readonly image_url: string
  readonly name: string
}

export interface ShowFastener {
  readonly child_id: number
  readonly child_repeat_count: number
  readonly part: {
    readonly part_number: string
  }
}

export interface ShowPartVariantOption {
  readonly id: number
  readonly label: string
  readonly order: number
  readonly slug: string
}

export interface ShowIncludedPartOrFastener {
  readonly name: string
  readonly quantity: number
  readonly part_url?: string
  readonly thumbnail_image_path: string
}

export interface ShowRecommendedPartSearch {
  readonly part_name: string
  readonly shipment_delay_weeks: number
  readonly status_availability: ShowPartStatusAvailability
  readonly display_price: string
  readonly image_path: string
  readonly full_url: string
}

interface Props {
  readonly part: ShowPart
  readonly partVariantsOptions: ReadonlyArray<ShowPartVariantOption>
  readonly includedPartsAndFasteners: ReadonlyArray<ShowIncludedPartOrFastener>
  readonly recommendedPartSearches: ReadonlyArray<ShowRecommendedPartSearch>
}

const Page: React.FC<Props> = ({
  part,
  partVariantsOptions,
  includedPartsAndFasteners,
  recommendedPartSearches,
}) => {
  const navLinks = [
    { label: 'Technical Documents', anchor: '#technical-documents' },
    { label: 'Specifications', anchor: '#specifications' },
    ...(includedPartsAndFasteners.length > 0
      ? [{ label: "What's Included", anchor: '#whats-included' }]
      : []),
    ...(recommendedPartSearches.length > 0
      ? [{ label: 'Part Recommendations', anchor: '#part-recommendations' }]
      : []),

    { label: 'FAQs', anchor: '#faqs' },
  ]
  return (
    <div className='product-viewer'>
      <Header product={part} productVariantOptions={partVariantsOptions} />
      <Nav navLinks={navLinks} />
      <TechnicalDocuments part={part} />
      <Specifications part={part} />
      {includedPartsAndFasteners.length > 0 && (
        <WhatsIncluded includedPartsAndFasteners={includedPartsAndFasteners} />
      )}
      {recommendedPartSearches.length > 0 && (
        <Recommendations recommendedPartSearches={recommendedPartSearches} />
      )}
      <Faqs />
    </div>
  )
}

export default Page