import React from 'react'
import { stringToKebabCase } from '../../../../../helpers/StringHelper'
import { ShowPart } from '../Page'
import { downloadPartStepFile } from '../../../../../helpers/DownloadHelper'
import ContactCta from './ContactCta'

interface Props {
  part: ShowPart
}

const TechnicalDocuments: React.FC<Props> = ({ part }) => {
  return (
    <section
      id='technical-documents'
      className='product-viewer__technical-documents product-viewer__section'
    >
      <h2 className='product-viewer__section-heading'>Technical Documents</h2>
      <div className='product-viewer__grid-links'>
        {!part.hide_drawing_2d_file && part.pdf_availability && (
          <a
            className='product-viewer__grid-link'
            href={`/parts/${part.id}/download_pdf`}
            data-analytic-id='part-page-2d-drawing-link'
          >
            <div className='product-viewer__grid-link-text'>
              <div className='product-viewer__grid-link-title'>2D Drawing</div>
              <div className='product-viewer__grid-link-subtitle'>Technical Drawing</div>
            </div>
            <i className='ti ti-download'></i>
          </a>
        )}
        {part.step_availability && (
          <button
            className='product-viewer__grid-link'
            onClick={() => downloadPartStepFile(part.id)}
          >
            <div className='product-viewer__grid-link-text'>
              <div className='product-viewer__grid-link-title'>3D Model</div>
              <div className='product-viewer__grid-link-subtitle'>Technical Drawing</div>
            </div>
            <i className='ti ti-download'></i>
          </button>
        )}
        {part.published_technical_documents.map((technicalDocument, index) => (
          <a
            className='product-viewer__grid-link'
            key={index}
            href={`/resources/guides/${technicalDocument.url}-${technicalDocument.id}`}
            target='_blank'
            data-analytic-id={`part-page-${stringToKebabCase(technicalDocument.title)}-link`}
          >
            <div className='product-viewer__grid-link-text'>
              <div className='product-viewer__grid-link-title'>{technicalDocument.title}</div>
              <div className='product-viewer__grid-link-subtitle'>Technical Document</div>
            </div>
            <i className='ti ti-external-link'></i>
          </a>
        ))}
        {part.legacy_technical_documents.map((technicalDocument, index) => (
          <a
            className='product-viewer__grid-link'
            key={index}
            href={technicalDocument.path}
            target='_blank'
            data-analytic-id={`part-page-${stringToKebabCase(technicalDocument.name)}-link`}
          >
            <div className='product-viewer__grid-link-text'>
              <div className='product-viewer__grid-link-title'>{technicalDocument.name}</div>
              <div className='product-viewer__grid-link-subtitle'>Technical Document</div>
            </div>
            <i className='ti ti-external-link'></i>
          </a>
        ))}
        {part.part_documents.map((partDocument, index) => (
          <a
            className='product-viewer__grid-link'
            key={index}
            href={partDocument.file_url}
            target='_blank'
            data-analytic-id={`part-page-${stringToKebabCase(partDocument.title)}-link`}
          >
            <div className='product-viewer__grid-link-text'>
              <div className='product-viewer__grid-link-title'>{partDocument.title}</div>
              <div className='product-viewer__grid-link-subtitle'>Technical Document</div>
            </div>

            <i className='ti ti-download'></i>
          </a>
        ))}
        {part.calculators.map((calculator, index) => (
          <a
            className='product-viewer__grid-link'
            key={index}
            href={calculator.url}
            target='_blank'
            data-analytic-id={calculator.analytic_id}
          >
            <div className='product-viewer__grid-link-text'>
              <div className='product-viewer__grid-link-title'>{calculator.name}</div>
              <div className='product-viewer__grid-link-subtitle'>{calculator.description}</div>
            </div>

            <i className='ti ti-external-link'></i>
          </a>
        ))}
      </div>
      <ContactCta />
    </section>
  )
}

export default TechnicalDocuments
