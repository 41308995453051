import React, { SyntheticEvent } from 'react'
import InputValidator from '../../components/common/InputValidator'
import { ValidatorForm } from 'react-form-validator-core'
import InviteWithLink from '../../components/common/InviteWithLink'

interface Props {
  readonly teamMateEmails: Array<string>
  readonly handleAddEmail: (event: SyntheticEvent) => void
  readonly handleUpdateEmailFieldChange: (event: SyntheticEvent) => void
  readonly handleInviteNewMembersSubmit: (event: SyntheticEvent) => void
  readonly handleSkip: (event: SyntheticEvent) => void
  readonly handleDeleteEmailFieldClick: (event: SyntheticEvent) => void
  readonly referral_code: number
  invitingToTeam: boolean
}

const OnboardingInviteToNewTeam: React.FunctionComponent<Props> = ({
  teamMateEmails,
  handleAddEmail,
  handleUpdateEmailFieldChange,
  handleInviteNewMembersSubmit,
  handleDeleteEmailFieldClick,
  handleSkip,
  referral_code,
  invitingToTeam,
}) => {
  const referralUrl = `${window.location.origin}/invite/${referral_code}`
  return (
    <ValidatorForm
      className='collaborate-form invite-colleagues-form'
      onSubmit={handleInviteNewMembersSubmit}
      instantValidate={false}
    >
      <h2 className='onboarding-form-heading collaborate-heading'>Who&apos;s on your team?</h2>
      {teamMateEmails.map((emailForm, index) => (
        <div key={index} className='input-wrapper'>
          <InputValidator
            onChange={handleUpdateEmailFieldChange}
            id={`teammate-email-${index}`}
            name='email'
            value={teamMateEmails[index]}
            data-index={index}
            placeholder=' '
            validators={['isEmail']}
            errorMessages={['Email is not valid']}
          >
            <label htmlFor={`teammate-email-${index}`}>{`Teammate Email #${index + 1}`}</label>
          </InputValidator>
          <button className='delete-icon' onClick={handleDeleteEmailFieldClick}>
            <i
              className={`fas fa-times-circle ${index > 2 ? 'show-delete-icon' : ''}`}
              data-email-index={index}
            ></i>
          </button>
        </div>
      ))}
      {teamMateEmails.length < 30 && (
        <button className='add-team-wrapper' onClick={handleAddEmail}>
          <i className='fas fa-plus-circle plus-icon'></i>
          <span className='add-teams-link'>Add more</span>
        </button>
      )}
      <h4>or</h4>
      <InviteWithLink referralUrl={referralUrl} referralType='referral-link-team' />

      <div className='skip-btn-wrapper'>
        <button
          className='button button-success button-md next-btn'
          type='submit'
          disabled={invitingToTeam}
        >
          Next
        </button>
        <button className='button skip-btn' onClick={handleSkip}>
          Do this later
        </button>
      </div>
    </ValidatorForm>
  )
}

export default OnboardingInviteToNewTeam
