import React, { useContext } from "react";
import { RegionContext } from "./RegionProvider";
import { ControllerType, useControllerContext } from "./EngineAndControllerProvider";
import { CategoryFacet } from "./CategoryFacet";
import { NumericFilter } from "./NumericFilter";
import { BrandFacet } from "./BrandFacet";
import { PartCategoryByCode } from "../../../pages/views/part_searches/Types";

export interface FacetListProps {
  partCategoryByCode: PartCategoryByCode;
}

export const FacetList: React.FC<FacetListProps> = (props) => {
  const categoryController = useControllerContext(ControllerType.CategoryFacet);
  const priceFilterController = useControllerContext(ControllerType.PriceFilter);
  const brandController = useControllerContext(ControllerType.BrandFacet);
  const region = useContext(RegionContext);

  return (
    <>
      <CategoryFacet
        controller={categoryController}
        title="Category"
        labels={props.partCategoryByCode}
      />
      <BrandFacet
        controller={brandController}
        title="Brand" 
      />
      <NumericFilter
        controller={priceFilterController}
        title="Price"
        region={region}
      />
    </>
  );
};
