import React from 'react'
import TeamInviteMemberAccess from './TeamInviteMemberAccess'
import TeamService from '../../../api/Teams/Service'
import { ValidatorForm } from 'react-form-validator-core'
import withSave, { SaveProps } from '../../common/hocs/withSave'

import { TeamMember, JoinRequest } from '../../../api/Teams/Types'
import { removeArrayElement } from '../../../helpers/Collections'
import { handleStandardVentionError } from '../../../api/httpClient'
import InviteWithLink from '../../common/InviteWithLink'

interface OwnProps {
  readonly existingTeamMembers: ReadonlyArray<TeamMember>
  readonly existingInvitations: ReadonlyArray<JoinRequest>
  readonly closeModal: () => void
  readonly teamId: number
  readonly referralUrl?: string
  invitationSource: string
}
interface State {
  newMembersList: ReadonlyArray<NewMember>
}
interface NewMember {
  email: string
  readonly buyerChecked: boolean
  readonly adminChecked: boolean
}

type Props = OwnProps & SaveProps

class TeamInviteMembersModal extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      newMembersList: [
        { email: '', buyerChecked: false, adminChecked: false },
        { email: '', buyerChecked: false, adminChecked: false },
        { email: '', buyerChecked: false, adminChecked: false },
      ],
    }
    this.addNewMemberEmailInput = this.addNewMemberEmailInput.bind(this)
    this.updateNewMembersEmailList = this.updateNewMembersEmailList.bind(this)
    this.removeNewMemberEmailInput = this.removeNewMemberEmailInput.bind(this)
    this.submitNewMemberForm = this.submitNewMemberForm.bind(this)
  }

  componentDidMount(): void {
    ValidatorForm.addValidationRule(
      'userAlreadyInTeam',
      value => this.props.existingTeamMembers.filter(member => member.email === value).length === 0
    )
    ValidatorForm.addValidationRule(
      'inviteAlreadyExist',
      value => this.props.existingInvitations.filter(invite => invite.email === value).length === 0
    )
  }

  componentWillUnmount(): void {
    ValidatorForm.removeValidationRule('userAlreadyInTeam')
    ValidatorForm.removeValidationRule('inviteAlreadyExist')
  }

  addNewMemberEmailInput(): void {
    const newMember = { email: '', buyerChecked: false, adminChecked: false }
    const updatedList = [...this.state.newMembersList, newMember]
    this.setState({
      newMembersList: updatedList,
    })
  }

  updateNewMembersEmailList(pIndex, newValue): void {
    const updatedList: Array<NewMember> = Object.assign([], this.state.newMembersList)
    updatedList[pIndex].email = newValue
    this.setState({ newMembersList: updatedList }, this.props.allowSave)
  }

  removeNewMemberEmailInput(pIndex): void {
    const { newMembersList } = this.state
    if (newMembersList.length > 1) {
      const nextNewMemberList = removeArrayElement(newMembersList, pIndex)
      this.setState({
        newMembersList: nextNewMemberList,
      })
    } else {
      this.setState({
        newMembersList: Object.assign([{ email: '', buyerChecked: false, adminChecked: false }]),
      })
    }
  }

  submitNewMemberForm(): void {
    // TODO: handle logic for new member invite with permissions
    const teamFormData = JSON.stringify(this.state.newMembersList)
    TeamService.inviteNewMembersToTeam(this.props.teamId, teamFormData, this.props.invitationSource)
      .then(() => {
        toastr.success('Email invites sent.')
        this.props.closeModal()
      })
      .catch(e => {
        handleStandardVentionError(e, toastr.error)
        this.props.closeModal()
      })
  }

  render(): React.ReactNode {
    const { newMembersList } = this.state
    return (
      <div className='invite-members-container'>
        <div className='modal-body'>
          <ValidatorForm
            id='invite-members-form'
            className='form'
            onSubmit={this.submitNewMemberForm}
            instantValidate={false}
          >
            <div className='emails-container'>
              <h5>Invite colleagues to your team by email</h5>
              {newMembersList.map((member, index) => (
                <TeamInviteMemberAccess
                  key={index}
                  index={index}
                  email={member.email}
                  removeNewMemberEmailInput={this.removeNewMemberEmailInput}
                  updateNewMembersEmailList={this.updateNewMembersEmailList}
                />
              ))}
            </div>
          </ValidatorForm>
          {newMembersList.length < 15 && (
            <button onClick={this.addNewMemberEmailInput} className='invite-members-btn'>
              <i className='fal fa-user-friends fa-lg' aria-hidden='true' />
              <span className='invite-more-members-link'>Invite More Members</span>
            </button>
          )}
          {this.props.referralUrl && (
            <InviteWithLink
              referralUrl={this.props.referralUrl}
              referralType='referral-link-team'
            />
          )}
        </div>
        <div className='modal-footer'>
          <div className='create-team-actions'>
            {React.cloneElement(this.props.children(this.submitNewMemberForm), {
              form: 'invite-members-form',
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default withSave<OwnProps>(TeamInviteMembersModal, {
  label: 'Send',
  form: 'invite-members-form',
})
