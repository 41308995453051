import React, { useEffect, useState } from 'react'
import Pill from '../../show/components/Pill'
import I18n from '../../../../../helpers/I18n'
import { ValidatorForm } from 'react-form-validator-core'
import InputValidator from '../../../../../components/common/InputValidator'
import TextAreaValidator from '../../../../../components/common/TextAreaValidator'
import SelectValidator from '../../../../../components/common/SelectValidator'
import { GoogleCaptchaV3Service } from '../../../../../api/Google/captchaV3.service'
import httpClient from '../../../../../api/httpClient'
import Loading from '../../../../../components/common/Loading'
import { camelToSnakeCase } from '../../../../../helpers/StringHelper'
import { LocalizedPaths } from '../../../../../types/Common'
import PhoneCountryInput from '../../../../../components/common/inputs/PhoneCountryInput/PhoneCountryInput'
import { User } from '../../../../../api/Users/Types'
import { AxiosResponse } from 'axios'

interface Props {
  currentUser: User
  localizedPaths: LocalizedPaths
  captchaKey: string
  setFormSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const Form: React.FC<Props> = ({ currentUser, localizedPaths, captchaKey, setFormSuccess }) => {
  const FORM_DEFAULT_STATE = {
    helpType: '',
    needType: '',
    needArea: '',
    firstName: currentUser?.first_name || '',
    lastName: currentUser?.last_name || '',
    phone: currentUser?.phone || '',
    email: currentUser?.email || '',
    company: '',
    jobTitle: '',
    orderId: '',
    message: '',
  }

  const [form, setForm] = useState(FORM_DEFAULT_STATE)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const setHelpTypeFromChoiceParam = (): void => {
    const url = new URL(window.location.href)

    switch (url.searchParams.get('choice')) {
      case 'technical support':
        setForm({
          ...form,
          helpType: I18n.t('views.contacts.components.form.help_type.options')[0],
        })
        break
      case 'general inquiries':
        setForm({
          ...form,
          helpType: I18n.t('views.contacts.components.form.help_type.options')[1],
        })
        break
      case 'talk to expert':
        setForm({
          ...form,
          helpType: I18n.t('views.contacts.components.form.help_type.options')[2],
        })
        break
      case 'book a demo':
        setForm({
          ...form,
          helpType: I18n.t('views.contacts.components.form.help_type.options')[3],
        })
        break
      default:
        setForm({ ...form, helpType: '' })
    }
  }

  useEffect(() => {
    setHelpTypeFromChoiceParam()
  }, [])

  const setChoiceParamFromHelpType = (): void => {
    const url = new URL(window.location.href)

    if (url.searchParams.has('choice')) {
      url.searchParams.set('choice', form.helpType.toLowerCase())
    } else {
      url.searchParams.append('choice', form.helpType.toLowerCase())
    }

    window.history.pushState({}, '', url.href)
  }

  useEffect(() => {
    setChoiceParamFromHelpType()
  }, [form.helpType])

  const needAreaOptions = (): string[] => {
    const formattedFormHelpType = form.needType?.toLowerCase()?.split(' ')?.join('_')
    return I18n.t(`views.contacts.components.form.need_area.${formattedFormHelpType}`) as string[]
  }

  const handleFormChange = (key: string, value: string): void => {
    if (key === 'helpType' && (form.needType || form.needArea)) {
      setForm({ ...form, needType: '', needArea: '', [key]: value })
    } else {
      setForm({ ...form, [key]: value })
    }
  }

  const resetForm = (): void => {
    setForm(FORM_DEFAULT_STATE)
  }

  const submitForm = (formData): void => {
    if (window._mfq) window._mfq.push(['formSubmitAttempt', '#contact-form'])

    httpClient
      .post<any, AxiosResponse<{ ga_event: { id: number; name: string } }>>('/contact', formData)
      .then(response => {
        resetForm()
        setFormSuccess(true)
        window.scrollTo(0, 0)
        if (window._mfq) window._mfq.push(['formSubmitSuccess', '#contact-form'])
        if (Array.isArray(window.dataLayer)) {
          window.dataLayer.push({
            event: response.data.ga_event.name,
            event_id: response.data.ga_event.id,
          })
        }
      })
      .catch(error => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : I18n.t('views.contacts.components.form.standard_error')
        setError(errorMessage)
        if (window._mfq) window._mfq.push(['formSubmitFailure', '#contact-form'])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSubmit = (event): void => {
    event.preventDefault()

    setIsLoading(true)
    setError('')

    const formData = Object.keys(form).reduce((object, key) => {
      object[camelToSnakeCase(key)] = form[key]
      return object
    }, {})

    formData['ga_client_id'] = window.ga_client_id

    GoogleCaptchaV3Service.verifyCaptcha(token => {
      if (token) {
        formData['g_recaptcha_response'] = token
      }
      submitForm(formData)
    }, captchaKey)
  }

  return (
    <div className='contact__form'>
      <Pill label={I18n.t('views.contacts.show.response_time')} icon={'fas fa-bolt-lightning'} />
      <h1 className='contact__form-heading'>{I18n.t('views.contacts.components.form.heading')}</h1>
      <ValidatorForm
        id='contact-form'
        className='contact__form-container'
        onSubmit={onSubmit}
        instantValidate={false}
      >
        <SelectValidator
          onChange={event => handleFormChange('helpType', event.target.value)}
          id='help-type'
          name='help-type'
          className='form-control'
          value={form.helpType}
          validators={['required']}
          errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
          label={I18n.t('views.contacts.components.form.help_type.label')}
          placeholderOption={I18n.t('views.contacts.components.form.help_type.placeholder')}
          options={I18n.t('views.contacts.components.form.help_type.options') as string[]}
          addWrapperAroundSelect={true}
        />
        {form.helpType &&
          I18n.t('views.contacts.components.form.help_type.options')[0] === form.helpType && (
            <SelectValidator
              onChange={event => handleFormChange('needType', event.target.value)}
              id='need-type'
              className='form-control'
              name='need-type'
              value={form.needType}
              validators={['required']}
              errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
              label={I18n.t('views.contacts.components.form.need_type.label')}
              placeholderOption={I18n.t('views.contacts.components.form.need_type.placeholder')}
              options={I18n.t('views.contacts.components.form.need_type.options') as string[]}
              addWrapperAroundSelect={true}
            />
          )}
        {form.needType && (
          <SelectValidator
            onChange={event => handleFormChange('needArea', event.target.value)}
            id='need-area'
            className='form-control'
            name='need-area'
            value={form.needArea}
            validators={['required']}
            errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
            label={I18n.t('views.contacts.components.form.need_area.label')}
            placeholderOption={I18n.t('views.contacts.components.form.need_area.placeholder')}
            options={needAreaOptions() as string[]}
            addWrapperAroundSelect={true}
          />
        )}
        <div className='contact__form-names'>
          <InputValidator
            onChange={event => handleFormChange('firstName', event.target.value)}
            id='first-name'
            className='form-control'
            name='first-name'
            value={form.firstName}
            validators={['required']}
            errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
            type='text'
            label={I18n.t('views.contacts.components.form.first_name.label')}
            placeholder={I18n.t('views.contacts.components.form.first_name.placeholder')}
          />
          <InputValidator
            onChange={event => handleFormChange('lastName', event.target.value)}
            id='last-name'
            className='form-control'
            name='last-name'
            value={form.lastName}
            validators={['required']}
            errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
            type='text'
            label={I18n.t('views.contacts.components.form.last_name.label')}
            placeholder={I18n.t('views.contacts.components.form.last_name.placeholder')}
          />
        </div>
        <div>
          <label htmlFor='phone'>{I18n.t('views.contacts.components.form.phone.label')}</label>
          <PhoneCountryInput
            className='form-control contact__form-phone'
            placeholder={I18n.t('views.contacts.components.form.phone.placeholder')}
            phone={form.phone}
            onChange={phone => handleFormChange('phone', phone)}
            useCountryCode
            required
          />
        </div>
        <InputValidator
          onChange={event => handleFormChange('email', event.target.value)}
          id='email'
          className='form-control'
          name='email'
          value={form.email}
          validators={['required', 'isEmail']}
          errorMessages={[
            I18n.t('views.contacts.components.form.required_error'),
            I18n.t('views.contacts.components.form.is_email_error'),
          ]}
          type='text'
          label={I18n.t('views.contacts.components.form.business_email.label')}
          placeholder={I18n.t('views.contacts.components.form.business_email.placeholder')}
        />
        {form.helpType === I18n.t('views.contacts.components.form.help_type.options')[0] && (
          <InputValidator
            onChange={event => handleFormChange('orderId', event.target.value)}
            id='orderId'
            className='form-control'
            name='orderId'
            value={form.orderId}
            errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
            type='text'
            label={I18n.t('views.contacts.components.form.order_id.label')}
            placeholder={I18n.t('views.contacts.components.form.order_id.placeholder')}
          />
        )}
        {form.helpType !== I18n.t('views.contacts.components.form.help_type.options')[0] && (
          <InputValidator
            onChange={event => handleFormChange('company', event.target.value)}
            id='company'
            className='form-control'
            name='company'
            value={form.company}
            validators={['required']}
            errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
            type='text'
            label={I18n.t('views.contacts.components.form.company_name.label')}
            placeholder={I18n.t('views.contacts.components.form.company_name.placeholder')}
          />
        )}
        {form.helpType !== I18n.t('views.contacts.components.form.help_type.options')[0] && (
          <InputValidator
            onChange={event => handleFormChange('jobTitle', event.target.value)}
            id='job-title'
            className='form-control'
            name='job-title'
            value={form.jobTitle}
            validators={['required']}
            errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
            type='text'
            label={I18n.t('views.contacts.components.form.job_title.label')}
            placeholder={I18n.t('views.contacts.components.form.job_title.placeholder')}
          />
        )}
        <TextAreaValidator
          onChange={event => handleFormChange('message', event.target.value)}
          id='message'
          className='form-control'
          name='message'
          value={form.message}
          validators={['required']}
          errorMessages={[I18n.t('views.contacts.components.form.required_error')]}
          label={I18n.t('views.contacts.components.form.message.label')}
          placeholder={I18n.t('views.contacts.components.form.message.placeholder')}
        />
        {error && <p className='contact__form-error has-error'>{error}</p>}
        <button className='contact__form-submit' type='submit'>
          {isLoading ? <Loading /> : I18n.t('views.contacts.components.form.submit')}
        </button>
        <p className='contact__form-terms'>
          {I18n.t('views.contacts.components.form.terms')}
          <a className='contact__form-terms-link' href={localizedPaths.privacy_policy}>
            {I18n.t('views.contacts.components.form.terms_link')}.
          </a>
        </p>
      </ValidatorForm>
    </div>
  )
}

export default Form
