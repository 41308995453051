import React from "react";
import Button from "../../../../../components/vention_ui/shared_components/Button";

const Faqs: React.FC = () => {
  return (
    <section id="faqs" className="space-margin-top-9xl space-margin-bottom-9xl">
      <div className="product-viewer-v2__footer product-viewer-v2__section product-viewer-v2__faqs">
        <div className="product-viewer-v2__footer-text">
          <h2 className="product-viewer-v2__footer-text-title">
            Questions about this part?
          </h2>
          <p className="product-viewer-v2__footer-text-description">
            Post a question on Vention community forum for insights on this
            part.
          </p>
        </div>

        <div className="product-viewer-v2__footer-cta">
          <Button
            size="xl"
            heirarchy="primary"
            href="https://forum.vention.io/"
            target="_blank"
          >
            Go to the forum
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
