import classNames from 'classnames'
import React from 'react'
import CheckItemText from '../../../../../../../components/vention_ui/foundations/CheckItemText'

interface Props {
  title: string
  list: string[]
  media: any
  layoutReverse?: boolean
}

const SoftwareFeature: React.FC<Props> = ({
  title,
  list,
  media,
  layoutReverse
}) => {
  return (
    <div className={classNames('master-palletizer-landing-page__software-feature', {
      'master-palletizer-landing-page__software-feature--reverse': layoutReverse
    })}>
      <div className='master-palletizer-landing-page__software-feature-content'>
        <h3 className='master-palletizer-landing-page__software-feature-title'>{title}</h3>
        <ul className='master-palletizer-landing-page__software-feature-list'>
          {list.map((item, index) => (
            <CheckItemText key={index} color='primary' type='lineOnly' size='md' label={item} theme='dark' />
          ))}
        </ul>
      </div>
      <img src={media.file.url} alt={title} loading='lazy' />
    </div>
  )
}

export default SoftwareFeature
