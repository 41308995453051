import { buildUrlManager, SearchEngine } from "@coveo/headless";

export const initializeUrlManager = (engine: SearchEngine) => {
  const fragment = window.location.hash.slice(1);
  const urlManager = buildUrlManager(engine, {
    initialState: { fragment: fragment },
  });

  urlManager.synchronize(fragment);

  urlManager.subscribe(() => {
    const hash = `#${urlManager.state.fragment}`;

    history.pushState(null, document.title, hash);
  });
};