import React, { ReactNode } from "react";
import { VMediumText, VRegularText } from "../text/Text";
import { PartCheckbox } from "./PartCheckbox";
import { PartDTO } from "../../api/Parts/dtos/PartDTO";
import { FormattedPrice } from "../../pages/views/partsV2/show/components/FormattedPrice";
import { Badge } from "@/components/ui/badge";

const DEFAULT_PART_IMAGE_URL = "https://assets.vention.io/png/generic_machine.png"
export type PartCardType = Pick<PartDTO, 'id' | 'imagePath' | 'name' | 'displayPriceRaw' | 'partNumber' | 'fullUrl' | 'adminOnly' >

export type PartCardV2Props = {
  readonly part: PartCardType;
  readonly children: ReactNode;
  readonly subtitleChildren?: ReactNode;
  readonly labelChildren?: ReactNode;
  readonly isSelectable: boolean
  readonly isSelected: boolean;
  readonly onSelectClicked?: (part: Partial<PartDTO>) => void;
}

export const PartCardV2: React.FC<PartCardV2Props> = (props: PartCardV2Props) => {
  const { part, subtitleChildren, children, labelChildren, isSelectable, isSelected, onSelectClicked } = props;

  const SelectCheckbox = () => {
    if (!isSelectable) {
      return null;
    }

    return (
      <PartCheckbox<Partial<PartDTO>>
        element={part}
        isSelected={isSelected}
        onSelectClicked={onSelectClicked} />
    )
  }
  return (
    <div className="rounded-md no-underline text-light-500 transition-[color] duration-200 ease-in flex flex-col flex-nowrap relative">
      {
        props.part.adminOnly &&
        <Badge
          variant="outline"
          className="text-sm absolute m-2 z-[1] text-brand-700 bg-brand-50 border-brand-200"
        >
          Admin
        </Badge>
      }
      <div className="relative overflow-hidden rounded-md bg-gray-800/10 transition-colors duration-200 max-w-full w-full pt-[100%] h-0">
        <a href={part.fullUrl}>
          <img
            className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 mix-blend-multiply block"
            src={part.imagePath}
            alt={part.name}
            loading="lazy"
            onError={
              (e) => {
                if (e.currentTarget.src !== DEFAULT_PART_IMAGE_URL) {
                  e.currentTarget.src = DEFAULT_PART_IMAGE_URL
                }
              }
            }
          />
        </a>
      </div>
      <SelectCheckbox />

      <div className="flex flex-col leading-5 mt-2 pt-2 px-2">
        <a href={part.fullUrl} className="no-underline text-gray-500 transition-colors duration-200 flex flex-col">
          <VRegularText color='terciary' fontSize='lg'>
            {part?.partNumber ?? ''}
          </VRegularText>

          <VMediumText color="primary" className="hover:text-blue-600" fontSize="lg">
            {part?.name ?? ''}
          </VMediumText>
          {
            subtitleChildren
          }
        </a>
      </div>

      <div className="flex grow"></div>

      <div className="flex flex-col px-2 pb-2">
        <div className="h-8" />

        <a href={part.fullUrl} className="no-underline">
          {
            part.displayPriceRaw ? (
              <p className="text-black text-2xl m-0  product-viewer-v2__similar-price">
                <FormattedPrice price={{
                  amount: part.displayPriceRaw.amount,
                  currency_code: part.displayPriceRaw.currencyCode,
                  currency_symbol: part.displayPriceRaw.currencySymbol
                }} />
              </p>
            ) : (
              <VRegularText className="text-black m-0" fontSize="xl" maxLine={2}>{'N/A'}</VRegularText>
            )
          }
        </a>
        {
          labelChildren
        }
        <div className="h-8" />

        {
          children
        }
      </div>
    </div>
  )
}