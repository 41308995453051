export const PartDocumentTypes = {
  technical_document: 'Technical document',
  terms_and_conditions: 'Terms and Conditions',
} as const

export type PartDocumentTypeNames = keyof typeof PartDocumentTypes

export interface PartDocument {
  id: number
  file_name: string
  file_url: string
  document_type: PartDocumentTypeNames
}
export interface PartDocumentQueryResponse {
  part_document: PartDocument
}
