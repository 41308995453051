import React, { useState } from 'react'
import { LocalizedPaths } from '../../../../types/Common'
import I18nProvider from '../../../../components/common/providers/I18nProvider'
import Form from './components/Form'
import FormSuccess from './components/FormSuccess'
import { BlogPost } from '../../../../api/Blog/Types'
import { User } from '../../../../api/Users/Types'

interface Props {
  currentUser: User
  localizedPaths: LocalizedPaths
  captchaKey: string
  blogs: BlogPost[]
}

const Page: React.FC<Props> = ({ currentUser, localizedPaths, captchaKey, blogs }) => {
  const [formSuccess, setFormSuccess] = useState<boolean>(false)

  return (
    <I18nProvider localizedPaths={localizedPaths}>
      <div className='contact contact--no-container'>
        {formSuccess ? (
          <FormSuccess blogs={blogs} />
        ) : (
          <Form
            currentUser={currentUser}
            localizedPaths={localizedPaths}
            captchaKey={captchaKey}
            setFormSuccess={setFormSuccess}
          />
        )}
      </div>
    </I18nProvider>
  )
}

export default Page
