import { useState, useEffect } from 'react'
import DesignService from '../api/Designs/Service'
import { Design, DesignQuerySettings } from '../api/Designs/Types'

interface UseDesigns {
  readonly designs: ReadonlyArray<Design>
  readonly fetchDesigns: (querySettings) => Promise<void>
  readonly count: number
  readonly isLoading: boolean
}

export const DEFAULT_DESIGN_QUERY_SETTINGS = {
  category: 'ALL',
  subCategory: '',
  pageNumber: 1,
  designsPerPage: 20,
  sortBy: 'popularity_month',
  designAuthor: '',
  designer: '',
  searchTerm: '',
  lifecycle: 'not_archived',
  tagList: [],
  price_range: '',
  assembly_time_range: '',
  min_price: '',
  max_price: '',
  min_assembly_time: '',
  max_assembly_time: '',
  filter_by_partner: [],
}

export function useDesigns(querySettings: DesignQuerySettings): UseDesigns {
  const [designs, setDesigns] = useState<ReadonlyArray<Design>>([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  async function fetchDesigns(querySettings): Promise<void> {
    setIsLoading(true)
    try {
      const libraryDesignsResponse = await DesignService.fetchLibraryDesigns({
        ...DEFAULT_DESIGN_QUERY_SETTINGS,
        ...querySettings,
      })
      setDesigns(libraryDesignsResponse?.designs ?? [])
      setCount(libraryDesignsResponse?.max ?? 0)
      setIsLoading(false)
    } catch (error) {
      toastr.error('Could not retrieve library designs from server.')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchDesigns(querySettings)
  }, [])

  return { designs, fetchDesigns, isLoading, count }
}
