import React from 'react'

interface Props {
  readonly classNames?: string
  readonly label: string | React.ReactNode
  readonly position?: 'center' | 'left-center'
  readonly children: React.ReactNode
  readonly tooltipClassName? : string
}

const WithTooltip: React.FunctionComponent<Props> = ({
  classNames,
  label,
  children,
  tooltipClassName,
  position = 'left',
}) => {
  return (
    <div className={`withtooltip-wrapper ${classNames || ''}`}>
      <div className='withtooltip-content'>{children}</div>
      <span className={`withtooltip-tooltip ${position} ${tooltipClassName || ''}`}>{label}</span>
    </div>
  )
}

export default WithTooltip
