import React from 'react'

interface Props {
  label: string
  value?: React.ReactNode
}

const LabelValueView: React.FC<Props> = props => {
  return (
    <>
      <strong>{props.label}:</strong>
      <span>{props.value || props.children}</span>
    </>
  )
}

export default LabelValueView
