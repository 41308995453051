import React from "react";

interface Props {
  description: string;
  btnText: string;
  btnUrl: string;
}

const CtaDivider: React.FC<Props> = ({ description, btnText, btnUrl }) => {
  return (
    <div className="cta-divider__container">
      <h3 className="cta-divider__description">{description}</h3>
      <a className="button cta-divider__button" type="button" href={btnUrl} target="_blank" >
        {btnText}
      </a>
      
    </div>
  );
};

export default CtaDivider;
