import { useEffect, useState } from "react"

export type UseModalProps = {
    deafult?: boolean
}

export type UseModalReturn = {
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isOpen: boolean;
}

export const useModal = (props?: UseModalProps): UseModalReturn => {

    const [isOpen, setIsOpen] = useState<boolean>(props?.deafult ?? false);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);
    const onToggle = () => isOpen ? onClose() : onOpen();

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    return {
        isOpen,
        onOpen,
        onClose,
        onToggle
    }
}