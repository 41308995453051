export const leadTimeText = (shipmentDelay: number, status: string) => {
  switch (status) {
    case "in_stock":
      return leadTimeDaysInWords(shipmentDelay);
    case "deplete":
      return leadTimeDaysInWords(shipmentDelay);
    case "not_released":
      return "Not released";
    case "internal":
      return "Not for release";
    case "soon":
      return "Coming soon";
    case "back_order":
      return "Limited quantity available";
    case "deprecated":
      return "Obsolete";
    case "not_available":
      return "Not all parts available";
    case "virtual":
      return "For trial";
    case "ready":
      return "All parts available";
    default:
      return ""
  }
}

const leadTimeDaysInWords = (days: number) => {
  if (days < 1) {
    return "Ships next day";
  } else if (days === 1) {
    return "Ships in 1 day";
  } else if (days <= 18) {
    return `Ships in ${days} days`;
  } else {
    return `${Math.floor(days / 7)} weeks`;
  }
}