import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

class TextAreaValidator extends ValidatorComponent {
  render() {
    const {
      label,
      characterLimit,
      value,
      children,
      formGroupClassName,
      labelClassName,
      autoResize,
      ...childProps
    } = this.props
    const safeValue = value || ''
    // eslint-disable-next-line no-unused-vars
    const { validatorListener, errorMessages, ...textProps } = childProps

    return (
      <div
        className={`form-group ${formGroupClassName ? formGroupClassName : ''} ${
          this.errorText() ? 'has-error' : ''
        }`}
      >
        {label && (
          <label className={labelClassName} htmlFor={textProps && textProps.id}>
            {label}
          </label>
        )}
        <textarea
          {...textProps}
          value={safeValue}
          maxLength={characterLimit}
          ref={r => {
            this.input = r
          }}
          onChange={event => {
            if (this.errorText()) {
              this.setState({isValid: true})
            }
            if (textProps?.onChange) {
              textProps.onChange(event)
            }
          }}
          data-controller={autoResize && 'textarea-autoresize'}
          data-textarea-autoresize-offset-value={autoResize && 14}
        />
        {children}
        <div className='input-messages'>
          {this.errorText()}
          {characterLimit && (
            <div className='input-char-limit'>
              Remaining characters: {characterLimit - safeValue.length}
            </div>
          )}
        </div>
      </div>
    )
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return <div className='form-error-message'>{this.getErrorMessage()}</div>
  }
}

export default TextAreaValidator
