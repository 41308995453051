import React from 'react'
import InputValidator from '../../components/common/InputValidator'
import SelectValidator from '../../components/common/SelectValidator.jsx'
import { useRepeatableData } from '../../hooks/useRepeatableData'
import PartnerPartVariationService from '../../api/PartnerPartVariation/Service'
import { PartVariation } from '../../api/Common/part-variation'
import { PartnerPartVariation } from '../../api/Common/partner-part-variation'

interface AddPartVariantFormProps {
  availablePartNumbers: string[]
  currentPartVariations: PartVariation[] | PartnerPartVariation[]
  updatePartVariations: (
    repeatableData: ReadonlyArray<Partial<PartVariation | PartnerPartVariation>>
  ) => void
  partNumberKey: 'partner_part_number' | 'part_number'
}

const createEmptyPartVariation = (order: number): PartVariation | PartnerPartVariation =>
  ({
    order,
    label: '',
  } as PartVariation)

function AddPartVariantForm(props: AddPartVariantFormProps) {
  const { repeatableData, addItem, deleteItem, updateItem } = useRepeatableData<
    PartVariation | PartnerPartVariation
  >(props.currentPartVariations, props.updatePartVariations)

  const updatePartVariant = (key, value, index) => {
    updateItem({ ...repeatableData[index], [key]: value }, index)
  }

  const deletePartnerPartVariation = async partnerPartVariationId => {
    try {
      await PartnerPartVariationService.deletePartnerPartVariation(partnerPartVariationId)
      toastr.success('Successfully deleted partner part variation')
    } catch (error) {
      console.error(error)
      toastr.error('Failed deleting partner part variation')
    }
  }

  const deleteVariationItem = (index, itemId) => {
    deleteItem(index)

    if (itemId) {
      deletePartnerPartVariation(itemId)
    }
  }

  const displayPartnerPartVariations = (
    item: PartVariation | PartnerPartVariation,
    index: number
  ) => (
    <li key={index} className='partner-part-variation'>
      <label htmlFor='partner_part_variation_label'>Label*</label>
      <InputValidator
        id='partner_part_variation_label'
        className='form-control'
        onChange={event => updatePartVariant('label', event.target.value, index)}
        name='variation-label'
        value={item.label}
        placeholder='Label'
        validators={['required']}
        errorMessages={['This field is required']}
      />
      <label htmlFor='partner_part_variation_label'>Order*</label>
      <InputValidator
        id='partner_part_variation_order'
        className='form-control'
        onChange={event => updatePartVariant('order', event.target.value, index)}
        name='variation-order'
        value={item.order}
        placeholder='Order'
        validators={['required']}
        errorMessages={['This field is required']}
      />
      <SelectValidator
        className='form-control'
        value={item[props.partNumberKey]}
        options={props.availablePartNumbers}
        placeholderOption='Select Part Number'
        onChange={event => updatePartVariant(props.partNumberKey, event.target.value, index)}
        validators={['required']}
        errorMessages={['This field is required']}
        label='Part Number*'
      />
      <button
        className='button button-warning'
        type='button'
        onClick={() => deleteVariationItem(index, item.id)}
      >
        Delete Variation
      </button>
    </li>
  )

  return (
    <>
      <ul className='partner-part-variations-list'>
        {repeatableData.map(displayPartnerPartVariations)}
      </ul>
      <button
        className='design-requests-form-add-button'
        type='button'
        onClick={() => addItem(createEmptyPartVariation(props.currentPartVariations.length))}
      >
        <i className='fal fa-plus' />
        <span>Add Variation</span>
      </button>
    </>
  )
}

export default AddPartVariantForm
