import React from 'react'
import I18n from '../../../helpers/I18n'

const AddressBerlin: React.FC = () => {
  return (
    <>
      <div>{I18n.t('home.about.location.berlin.name')}</div>
      <div>{I18n.t('home.about.location.berlin.address')}</div>
      <div>
        10178, {I18n.t('home.about.location.berlin.city')},{' '}
        {I18n.t('home.about.location.berlin.country')}
      </div>
    </>
  )
}

export default AddressBerlin
