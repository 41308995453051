import React from "react";
import { ResultCard, PartResult } from "./ResultCard";
import { Result } from "@coveo/headless";
import { VBoldText } from "../../../components/text/Text";

export interface PartsRowProps {
  results: Result[];
  title: string;
}

export const PartsRow: React.FC<PartsRowProps> = (props: PartsRowProps) => {
  return (
    <div className="mt-4">
      <VBoldText fontSize="xl" className="mb-4">{ props.title }</VBoldText>
      <div className="grid gap-4 grid-flow-row grid-cols-2 2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3">

        {
          props.results.map((result) => (
            <ResultCard
              key={result.uniqueId}
              data={result as PartResult}
            />
          ))
        }
      </div>
    </div>
  );
}
