import React from "react";
import { Facet as FacetController, FacetValue } from "@coveo/headless";
import { VMediumText, VRegularText } from "../../../components/text/Text";
import { useControllerState } from "../../utils/useControllerState";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";

export interface BrandFacetProps {
    controller: FacetController;
    title: string;
}

export const BrandFacet: React.FC<BrandFacetProps> = (props) => {
    const { controller, title } = props;

    const state = useControllerState(controller);

    const handleOnSelect = (element: FacetValue) => {
        controller.toggleSelect(element);
    }

    const searchItem = (searchTerm: string) => {
        controller.facetSearch.updateText(searchTerm);
        controller.facetSearch.search();
    }

    return (
        <div className="min-w-56">
            <VMediumText fontSize="xl" className="flex flex-row bg-white justify-between w-full py-2 px-1 border border-b-2 border-t-0 border-l-0 border-r-0 border-slate-50 border-solid mt-2 mb-4">{title}</VMediumText>

            <Input onChange={(e) => searchItem(e.target.value)} placeholder="Search brand" className="mb-4 py-2 px-3 h-fit rounded-md bg-white border-1 border-solid border-slate-50 text-lg" />
            <div className="flex flex-col gap-2">
                {
                    !state?.facetSearch.isLoading && state?.values?.filter(value => (state?.facetSearch?.query?.length ?? 0) === 0 || state?.facetSearch?.values?.findIndex(element => element.rawValue === value.value) > -1).map((facetValue: FacetValue) => (
                        <div key={facetValue.value} className="flex flex-row gap-1">
                            <Checkbox
                                id={facetValue.value}
                                checked={facetValue.state === 'selected'}
                                onCheckedChange={() => handleOnSelect(facetValue)}
                            />
                            <button className="bg-transparent border-none p-0 m-0" onClick={() => handleOnSelect(facetValue)}>
                                <VRegularText> {facetValue.value} </VRegularText>
                            </button>
                        </div>
                    ))
                }
                {
                    state?.facetSearch.isLoading && (
                        <div className="flex flex-col gap-2">
                            {[1, 2, 3, 4, 5].map((index) => (
                                <div key={index} className="animate-pulse flex flex-row gap-2 items-center justify-center">
                                    <div className="h-5 w-5 bg-slate-100 rounded-sm"></div>
                                    <div className="h-4 w-full bg-slate-100 rounded"></div>
                                </div>
                            ))}
                        </div>
                    )
                }
            </div>
        </div>
    );
};
