import React, { FC } from "react"
import { VBoldText, VRegularText, VSemiBoldText } from "../../../components/text/Text"
import { FeaturedPartCategoryDTO } from "../../../api/Parts/dtos/FeaturedPartCategory.DTO"
import { ControllerType, useControllerContext } from "./EngineAndControllerProvider";
import { useControllerState } from "../../utils/useControllerState";

export type FeaturedPartCategoryListProps = {
    featuredPartCategories?: FeaturedPartCategoryDTO[];
    isLoading: boolean;
}

export const FeaturedPartCategoryList: FC<FeaturedPartCategoryListProps> = (props: FeaturedPartCategoryListProps) => {
    const categoryController = useControllerContext(ControllerType.CategoryFacet);
    const categoryState = useControllerState(categoryController);

    const handleCategoryClick = (category: FeaturedPartCategoryDTO) => {

        const fullCode = category.partCategory.parent ?`${category.partCategory.parent.code}|${category.partCategory.parent.code}-${category.partCategory.code}` : category.partCategory.code;

        const categoryFacet = categoryState.values.find(facet => facet.value === fullCode);

        if (categoryFacet) {
            categoryController.toggleSelect(categoryFacet);
        } else {
            categoryController.toggleSelect({
                value: category.partCategory.code,
                state: 'selected',
                numberOfResults: 0
            });
        }
    }

    if( !props.isLoading && !props.featuredPartCategories?.length) {
        return null;
    }

    return (
        <div className="mb-4">
            <VBoldText fontSize="xl">Featured Categories</VBoldText>
            <div className="flex flex-row flex-wrap py-4">
                 {
                    !props.isLoading && props?.featuredPartCategories?.map((featuredPartCategory) => (
                        <button
                            onClick={() => handleCategoryClick(featuredPartCategory)}
                            key={featuredPartCategory.id}
                            className="flex flex-col w-40 h-fit m-2 justify-center items-center bg-transparent border-none pt-4">
                            <img
                                className="w-36 h-36 min-w-36 min-h-36  rounded-full border-2 border-solid border-slate-200 hover:shadow-custom-hover"
                                src={featuredPartCategory?.partCategory?.imageUrl ?? "https://assets.vention.io/png/generic_machine.png"}
                                alt={featuredPartCategory.partCategory.name} />
                            <VSemiBoldText className="self-center mt-4">{featuredPartCategory.partCategory.name}</VSemiBoldText>
                        </button>
                    ))
                } 
                {
                    props.isLoading && (

                        [1, 2, 3, 4, 5].map((index) => (
                            <div
                                key={index}
                                className="flex flex-col justify-center items-center w-fit bg-transparent border-none pt-4 px-2">
                                <div className="w-36 h-36 rounded-full bg-lazy-load" />
                                <div className="w-16 h-5 self-center mt-4 bg-lazy-load rounded-sm" />
                            </div>
                        ))

                    )
                }

            </div>
        </div>
    )
}