import { Button } from "@/components/ui/button";
import { Facet } from "@coveo/headless";
import React, { useEffect, useState } from "react";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import {
  ControllerType,
  useControllerContext,
} from "../../../../coveo/components/part_library/EngineAndControllerProvider";

interface SearchAndFilterButtonProps {}

export const SearchAndFilterButton = React.forwardRef<
  HTMLButtonElement,
  SearchAndFilterButtonProps
>(({ ...props }, ref) => {
  const controller = useControllerContext(ControllerType.CategoryFacet);
  const state = useControllerState(controller);
  const filtesApplied = state.values.reduce(
    (acc, value) => acc + (controller.isValueSelected(value) ? 1 : 0),
    0,
  );

  return (
    <Button variant="secondary" className="w-full mt-2" {...props} ref={ref}>
      Sort & Filter
      {filtesApplied > 0 && <span className="ml-1">({filtesApplied})</span>}
    </Button>
  );
});
