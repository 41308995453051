import { request } from 'graphql-request'
import { GRAPHQL_PUBLIC_API } from '../../constants/Vention';
import { fetchFullFeaturedBrandsQuery } from './queries/AllFeaturedBrandQuery';
import { FeaturedBrandsCollectionDTO } from './Types';

export const FeaturedBrandsService = {
  queryFeaturedBrands: async (): Promise<FeaturedBrandsCollectionDTO> => {
    return request<FeaturedBrandsCollectionDTO>(
      GRAPHQL_PUBLIC_API,
      fetchFullFeaturedBrandsQuery,
      {}
    )
  }
} 