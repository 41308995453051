import React from "react";
import { ControllerType, useControllerContext } from "./EngineAndControllerProvider";
import { PartsRow } from "./PartsRow";
import { useSearchMade } from "../../hooks/part_library/useSearchMade";
import { useControllerState } from "../../utils/useControllerState";
import { useQuerySimpleGQL } from "../../../hooks/useSimpleGQL";
import { PartService } from "../../../api/Parts/PartsService";
import { FeaturedPartCategoryCollectionDTO } from "../../../api/Parts/Types";
import { FeaturedPartCategoryList } from "./FeaturePartCategoryList";
import { SearchResults } from "./SearchResults";

export const ResultsSection: React.FC = () => {
  const searchMade = useSearchMade();

  const resultListController = useControllerContext(ControllerType.ResultList);
  const featuredPartsResultListController = useControllerContext(ControllerType.FeaturedPartsResultList);
  const newPartsController = useControllerContext(ControllerType.NewPartsResultList);

  const resultListState = useControllerState(resultListController);
  const featuredPartsResultListState = useControllerState(featuredPartsResultListController);
  const newPartsState = useControllerState(newPartsController);
  const {result} = useQuerySimpleGQL<FeaturedPartCategoryCollectionDTO>("featuredPartCategories", [], PartService.queryFeaturedPartCategories, () => !searchMade);

  if (!searchMade) {
    return(
      <div>
        <FeaturedPartCategoryList featuredPartCategories={result.data?.featuredPartCategories?.collection} isLoading={result?.isLoading ?? false} />
        <PartsRow results={featuredPartsResultListState.results} title="Featured Parts"/>
        <PartsRow results={newPartsState.results} title="New Parts" />
      </div>
    )
  }
  
  return <SearchResults results={resultListState.results} />
}
