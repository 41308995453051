import React from 'react'

export interface BreadCrumbDataItem {
  readonly label: string
  readonly href?: string
}

interface Props {
  readonly data: ReadonlyArray<BreadCrumbDataItem>
  readonly remSize?: number
  readonly showHome?: boolean
}

const CustomBreadCrumb: React.FunctionComponent<Props> = ({ data, remSize, showHome = true }) => {
  const displayData = (dataItem, index): React.ReactNode =>
    dataItem.label && (
      <li className='custom-bread-crumbs-item' key={index}>
        {dataItem.href && (
          <a className='custom-bread-crumbs-item-link' href={dataItem.href}>
            {dataItem.label}
          </a>
        )}
      </li>
    )

  return (
    <ul className='custom-bread-crumbs' style={remSize ? { fontSize: `${remSize}rem` } : undefined}>
      {data.length > 0 && showHome && (
        <li className='custom-bread-crumbs-item'>
          <a className='custom-bread-crumbs-item-link' href='/?home'>
            Home
          </a>
        </li>
      )}
      {data.map(displayData)}
    </ul>
  )
}

export default CustomBreadCrumb
