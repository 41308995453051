import React from 'react'
import { ContentfulEntry } from '../Page'

interface Props {
  contentfulEntry: ContentfulEntry
}

const Header: React.FC<Props> = ({ contentfulEntry }) => {
  return (
    <header className='subscriptions-pricing__header'>
      <h1 className='subscriptions-pricing__header-supertitle'>
        {contentfulEntry.headerSupertitle}
      </h1>
      <h2 className='subscriptions-pricing__header-title'>
        {contentfulEntry.headerTitle}
      </h2>
      <p className='subscriptions-pricing__header-description'>
        {contentfulEntry.headerDescription}
      </p>
    </header>
  )
}

export default Header
