import React from 'react'
import CheckItemText from '../../../../../../../components/vention_ui/foundations/CheckItemText'

interface Props {
  index: number
  supertitle: string
  title: string
  description: string
  linkPath: string
  linkLabel?: string
  list: string[]
  setBackgroundImageIndex: React.Dispatch<React.SetStateAction<number>>
}

const HeaderCard: React.FC<Props> = ({
  index,
  supertitle,
  title,
  description,
  linkPath,
  linkLabel,
  list,
  setBackgroundImageIndex,
}) => {
  const handleMouseEnter = () => {
    setBackgroundImageIndex(index)
  }

  const handleMouseLeave = () => {
    setBackgroundImageIndex(0)
  }

  return (
    <a
      className='master-palletizer-landing-page__header-card'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      href={linkPath}
    >
      <div>
        <p className='master-palletizer-landing-page__header-card-supertitle'>{supertitle}</p>
        <p className='master-palletizer-landing-page__header-card-title'>{title}</p>
      </div>
      <p className='master-palletizer-landing-page__header-card-description'>{description}</p>
      <ul className='master-palletizer-landing-page__header-card-list'>
        {list.map((item, index) => (
          <CheckItemText
            key={index}
            color='primary'
            type='lineOnly'
            size='md'
            label={item}
            theme='dark'
          />
        ))}
      </ul>
      {linkLabel && (
        <div className='master-palletizer-landing-page__header-card-link'>{linkLabel}</div>
      )}
    </a>
  )
}

export default HeaderCard
