import React from 'react'
import { LocalizedPaths } from '../../../../types/Common'
import I18nProvider from '../../../../components/common/providers/I18nProvider'
import Index from './components/Index'

interface Props {
  localizedPaths: LocalizedPaths
}

const Page: React.FC<Props> = ({ localizedPaths }) => {
  return (
    <I18nProvider localizedPaths={localizedPaths}>
      <div className='contact'>
        <Index localizedPaths={localizedPaths} />
      </div>
    </I18nProvider>
  )
}

export default Page
