import classNames from 'classnames'
import React from 'react'

interface Props {
  size: 'sm' | 'md' | 'lg'
  color: 'brand' | 'gray' | 'error' | 'warning'
  label?: string
  theme?: 'dark'
  iconLeading?: string
}

const Badge: React.FC<Props> = ({ label, size, color, theme, iconLeading }) => {
  return (
    <div
      className={classNames(
        'vention-ui-badge',
        `vention-ui-badge--${size}`,
        theme ? `vention-ui-badge--${color}-${theme}` : `vention-ui-badge--${color}`
      )}
    >
      {iconLeading && <i className={iconLeading}></i>}
      {label && <p className='vention-ui-badge__label'>{label}</p>}
    </div>
  )
}

export default Badge
