import React, { useState, ChangeEvent, FormEvent } from "react";
import Button from "../../../../components/vention_ui/shared_components/Button";
import httpClient from "../../../../api/httpClient";
import { AxiosResponse } from "axios";
import I18n from "../../../../helpers/I18n";
import { GoogleCaptchaV3Service } from "../../../../api/Google/captchaV3.service";

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
}

interface FormErrors {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  companyName?: string;
}

interface FormProps {
  setFormSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  captchaKey: string;
  languageCode: string;
}

const ContactForm: React.FC<FormProps> = ({
  setFormSuccess,
  captchaKey,
  languageCode,
}) => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    companyName: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>("");

  // Handle input changes
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate form data
  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.phoneNumber)
      newErrors.phoneNumber = "Phone number is required";
    if (!formData.companyName)
      newErrors.companyName = "Company name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitForm = (sendData: Record<string, string>) => {
    setIsLoading(true);
    httpClient
      .post<any, AxiosResponse<{ ga_event: { id: number; name: string } }>>(
        "/try-before-you-buy/palletizing",
        sendData,
      )
      .then((response) => {
        setFormSuccess(true);
        if (Array.isArray(window.dataLayer)) {
          window.dataLayer.push({
            event: response.data.ga_event.name,
            event_id: response.data.ga_event.id,
          });
        }
      })
      .catch((error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : I18n.t("views.contacts.components.form.standard_error");
        setServerError(errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle form submission
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      GoogleCaptchaV3Service.verifyCaptcha((token) => {
        let sendData = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          phone: formData.phoneNumber,
          email: formData.email,
          company: formData.companyName,
          language_code: languageCode,
        };
        if (token) {
          sendData["g_recaptcha_response"] = token;
        }
        submitForm(sendData);
      }, captchaKey);
    }
  };

  return (
    <div className="try-before-you-buy-contact-form__container">
      <form onSubmit={handleSubmit}>
        <div className="input_container">
          <label className="required" htmlFor="email">
            {" "}
            {I18n.t("views.try_before_you_buy.contact_form.email")}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="you@company.com"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
        </div>

        <div className="input_container">
          <label className="required" htmlFor="firstName">
            {" "}
            {I18n.t("views.try_before_you_buy.contact_form.first_name")}{" "}
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder={I18n.t(
              "views.try_before_you_buy.contact_form.first_name",
            )}
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          {errors.firstName && (
            <p style={{ color: "red" }}>{errors.firstName}</p>
          )}
        </div>

        <div className="input_container">
          <label className="required" htmlFor="lastName">
            {I18n.t("views.try_before_you_buy.contact_form.last_name")}
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder={I18n.t(
              "views.try_before_you_buy.contact_form.last_name",
            )}
            value={formData.lastName}
            onChange={handleChange}
            required
          />
          {errors.lastName && <p style={{ color: "red" }}>{errors.lastName}</p>}
        </div>

        <div className="input_container">
          <label className="required" htmlFor="phoneNumber">
          {I18n.t("views.try_before_you_buy.contact_form.phone_number")}
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder= {I18n.t("views.try_before_you_buy.contact_form.phone_number")}
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
          {errors.phoneNumber && (
            <p style={{ color: "red" }}>{errors.phoneNumber}</p>
          )}
        </div>

        <div className="input_container">
          <label className="required" htmlFor="companyName">
          {I18n.t("views.try_before_you_buy.contact_form.company_name")}
          </label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            placeholder= {I18n.t("views.try_before_you_buy.contact_form.company_name")}
            value={formData.companyName}
            onChange={handleChange}
            required
          />
          {errors.companyName && (
            <p style={{ color: "red" }}>{errors.companyName}</p>
          )}
        </div>
        <Button
          className="submit_btn"
          heirarchy="primary"
          size="lg"
          type="submit"
          disabled={isLoading}
        >
          {I18n.t("views.try_before_you_buy.contact_form.btn_text")}
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
