import { Controller } from "@coveo/headless";
import { useEffect, useState } from "react";

export const useControllerState = <T extends Controller>(controller: T): T["state"] => {
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  return state;
}
