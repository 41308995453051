import { LeadDesigner, PartnerPart, ProductSpecification, ProductSpecificationGrouped } from '../Common/Types'
import { CollectionDTO, ErrorMetadata, PaginatedCollectionDTO, PaginationMetadata } from '../Types'
import { FeaturedPartCategoryDTO } from './dtos/FeaturedPartCategory.DTO'
import { PartDTO } from './dtos/PartDTO'
import { SimilarPartDTO } from './dtos/SimilarPartDTO'

export type PartStatus =
  | 'in_stock'
  | 'soon'
  | 'not_released'
  | 'internal'
  | 'deplete'
  | 'back_order'
  | 'deprecated'
  | 'not_available'
  | 'virtual'
  | 'ready'

export interface Part {
  hide_price: boolean
  disallow_checkout: boolean
  readonly id: number
  readonly name: string
  readonly description: string
  readonly part_number: string
  readonly part_type: 'Part'
  readonly status?: PartStatus
  readonly unit_price: string
  readonly currency_code: string
  readonly currency_symbol: string
  readonly category: string
  readonly sub_category1: string
  readonly weight: string
  readonly height: string
  readonly length: string
  readonly width: string
  readonly design_author_id: number
  readonly country_of_origin: string
  readonly material: string
  readonly created_at: string
  readonly image_path: string
  readonly thumbnail_image_path: string
  readonly tag_list?: ReadonlyArray<string>
  readonly associated_fasteners: ReadonlyArray<AssociatedFastener>
  readonly light_duty_fasteners: ReadonlyArray<LightDutyFastener>
  readonly surface_finish: string
  readonly applicable_standards: string
  readonly vention_comment: string
  readonly url?: string
  readonly shipment_delay?: number
  readonly unit_of_measurement?: string
  readonly unit_of_measurement_price_label?: string
  readonly specifications: ReadonlyArray<PartSpecification>
  readonly specifications_grouped?: ReadonlyArray<ProductSpecificationGrouped>
  readonly instance_parameters?: {
    imported_part_number?: string
    custom_shipping_delay?: number
    custom_unit_price?: string
    imported_part_name?: string
  }
  readonly hide_in_bom?: boolean
  included_parts?: ReadonlyArray<IncludedPart>
}

export interface IncludedPart {
  name: string
  quantity: number
  thumbnail_image_path: string // can be an invalid URL
  part_url?: string
}

export interface SpecificPartVariation {
  label: string
  order: number
}

export interface BomPart {
  readonly quantity: number
  readonly unit_price: string
  readonly row_total: string
  readonly is_imported: boolean
  readonly is_custom: boolean
  readonly is_partner_part: boolean
  computed_unit_quantity?: number
}

export type PartSpecification = ProductSpecification

export interface PartWithQuantity extends Part {
  readonly quantity?: number
}

interface Fastener {
  readonly fasteners_id: number
  readonly fasteners_count: number
  readonly fasteners_part_number: string
  readonly created_at: string
}

export type AssociatedFastener = Fastener & {
  part_number: string
  quantity: number
  name: string
  thumbnail_image_path: string
  part_url?: string
}

export type LightDutyFastener = Fastener

export interface ParentPart extends Part {
  other_lengths: ReadonlyArray<
    Part & {
      specific_part_variation: SpecificPartVariation
    }
  >
}

export interface Partner {
  readonly id: number
  readonly name: string
  readonly url?: string
  readonly description?: string | null
  readonly logo_url?: string
  readonly parts: ReadonlyArray<PartnerPart>
}

export interface FetchPartnerPartsQuerySettings {
  readonly partnerId: number
  readonly searchValue?: string
  readonly partsPerPage?: number
  readonly pageNumber: number
  readonly sortBy?: string
  readonly designerId?: string
  readonly category?: string
  readonly status?: string
  readonly filterBy?: string
}

export type PartListSortOrder =
  | 'last_created'
  | 'price_low_to_high'
  | 'price_high_to_low'
  | 'category'

export interface FetchPartnerPartsResponse {
  parts: PartnerPart[]
  readonly vention_parts: ReadonlyArray<Part>
  readonly max: number
  readonly max_all_parts: number
  readonly lead_designers: ReadonlyArray<LeadDesigner>
}

export interface FetchPartnerPartsByIdsQuerySettings {
  partner_part_ids: ReadonlyArray<number>
  vention_part_ids: ReadonlyArray<number>
}

export interface FetchPartnerPartsByIdsResponse {
  readonly partner_parts: ReadonlyArray<PartnerPart>
  readonly vention_parts: ReadonlyArray<Part>
}

export type FetchPartnerPartsByPartnerResponse = ReadonlyArray<Partner>

export interface PartnerPartQuote {
  first_name: string
  last_name: string
  email: string
  partner_part_id: number
}

export interface FetchTakeRatePartsQuerySettings {
  readonly filter?: string
}

export interface MachineLogicProgram {
  bill_material_id: number
  created_at: string
  id: number
  name: string
  price: string
  updated_at: string
}

export enum Status {
  IN_STOCK ='in_stock',
  SOON = 'soon',
  DEPLETE = 'deplete',
  DEPRECATED = 'deprecated',
  INTERNAL = 'internal'
}

export type SimilarPartCollectionDTO = PaginatedCollectionDTO<SimilarPartDTO, 'parts'>

export type PartCollectionDTO = PaginatedCollectionDTO<PartDTO, 'parts'>
  
export type FeaturedPartCategoryCollectionDTO = CollectionDTO<FeaturedPartCategoryDTO, 'featuredPartCategories'>
