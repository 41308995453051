import React from 'react'
import DesignCategoriesProvider from '../../../../components/common/providers/DesignCategoriesProvider'
import Applications from './Applications'

const ApplicationsPage: React.FC<{}> = () => {
  return (
    <DesignCategoriesProvider>
      <Applications />
    </DesignCategoriesProvider>
  )
}

export default ApplicationsPage
