import React from 'react'
import { Team } from '../../../api/Teams/Types'
import ModalButton from '../../../components/common/ModalButton'
import TeamInviteMembersModal from '../../../components/users/my_companies/TeamInviteMembersModal'
import I18n from '../../../helpers/I18n'

interface Props {
  team: Team
  currentUserId: number
}

const CartToolsTeamInviteButton: React.FC<Props> = ({ team, currentUserId }) => {
  const referralUrl = `${window.location.origin}/invite/${currentUserId}`

  return (
    <ModalButton
      button={
        <button
          className='cart-tools-resource-button'
          data-test='createTeamButton'
          data-analytic-id='checkout-cart-team-button'
        >
          <i className='cart-tools-resource-button-icon fal fa-user-plus' />
          {I18n.t('views.checkout.carts.add_deployment_team')}
        </button>
      }
      header='Invite Team Members'
      showCloseButton={true}
      width={550}
    >
      {closeModal => (
        <TeamInviteMembersModal
          teamId={team.id}
          closeModal={closeModal}
          existingInvitations={team.pending_invitations}
          existingTeamMembers={team.company_personnels}
          referralUrl={referralUrl}
          invitationSource='cart_invite'
        />
      )}
    </ModalButton>
  )
}

export default CartToolsTeamInviteButton
