import React from 'react'
import { ShowRecommendedPartSearch } from '../Page'
import { getStatusColor } from './Header'

interface Props {
  readonly recommendedPartSearches: ReadonlyArray<ShowRecommendedPartSearch>
}

const Recommendations: React.FC<Props> = ({ recommendedPartSearches }) => {
  return (
    <section
      id='part-recommendations'
      className='product-viewer__part-recommendations product-viewer__section'
    >
      <h2 className='product-viewer__section-heading'>Part Recommendations</h2>
      <div className='product-viewer__card-links'>
        {recommendedPartSearches.map((recommendedPartSearch, index) => (
          <a
            href={recommendedPartSearch.full_url}
            key={index}
            className='product-viewer__card-link'
          >
            <div className='product-viewer__card-link-image-container'>
              <img
                className='product-viewer__card-link-image'
                src={recommendedPartSearch.image_path}
                alt={recommendedPartSearch.part_name}
                loading='lazy'
              />
            </div>
            <div className='product-viewer__card-link-text'>
              {recommendedPartSearch.shipment_delay_weeks < 8 && <div
                className={`product-viewer__card-link-subtitle product-viewer__card-link-subtitle--${getStatusColor(
                  recommendedPartSearch.status_availability.className
                )}`}
              >
                {recommendedPartSearch.status_availability.text}
              </div>
              }
              <div className='product-viewer__card-link-title'>
                {recommendedPartSearch.part_name}
              </div>
              <div className='product-viewer__card-link-subtext'>
                {recommendedPartSearch.display_price}
              </div>
            </div>
          </a>
        ))}
      </div>
    </section>
  )
}

export default Recommendations
