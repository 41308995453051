import React from 'react'

interface Props {
  readonly label: string
  readonly linkOnClick: () => void
}
const ListPageSideNavTopTab: React.FunctionComponent<Props> = ({ label, linkOnClick }) => (
  <li className='list-page-side-nav-top-tab'>
    <span className='list-page-side-nav-top-tab-label'>{label}</span>
    <button className='list-page-side-nav-top-tab-link' type='button' onClick={linkOnClick}>
      See all
    </button>
  </li>
)

export default ListPageSideNavTopTab
