import React, { useState } from 'react'
import { DialCodeOption, dialCodeOptions } from './DialCodeOptions'
import { ValueType } from 'react-select'
import { Select } from 'react-select-virtualized'

interface Props {
  selectedDialCodeOption: DialCodeOption
  onSelectedCountryCodeChange: (option: ValueType<DialCodeOption>) => void
  useCountryCode?: boolean
}

const DropdownIndicator = () => (
  <div className='dropdown-indicator'>
    <svg>
      <path
        d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  </div>
)

const Menu = props => <div className='dial-code-dropdown-menu' {...props} />
const Blanket = props => <div className='dial-code-dropdown-blanket' {...props} />

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className='dial-code-dropdown'>
    {target}
    {isOpen && <Menu>{children}</Menu>}
    {isOpen && <Blanket onClick={onClose} />}
  </div>
)

const formatOptionLabel = option => (
  <div className='dial-code-dropdown-option'>
    <CountryFlag dialCodeOption={option} />
    <span className='dial-code-dropdown-option-country'>{option.name}</span>
    <span>({option.dialCode})</span>
  </div>
)

const CountryFlag = props => (
  <img
    className='dial-code-dropdown-flag'
    src={`https://component-assets.s3.amazonaws.com/phone-country-input/${props.dialCodeOption.countryCode.toLowerCase()}.svg`}
    alt={props.dialCodeOption.countryCode}
    data-phone-input-target='selectedCountryFlag'
    data-country-code={props.dialCodeOption.countryCode}
  />
)

const DialCodeDropdown: React.FC<Props> = ({
  selectedDialCodeOption,
  onSelectedCountryCodeChange,
  useCountryCode
}) => {
  const [isDialCodeOpened, setIsDialCodeOpened] = useState(false)

  return (
    <Dropdown
      isOpen={isDialCodeOpened}
      onClose={() => setIsDialCodeOpened(!isDialCodeOpened)}
      target={
        <div
          onClick={() => setIsDialCodeOpened(!isDialCodeOpened)}
          className='dial-code-dropdown-button'
        >
          {useCountryCode ? <p className='dial-code-dropdown-code'>{selectedDialCodeOption.countryCode}</p> : <CountryFlag dialCodeOption={selectedDialCodeOption} />}
          <i className='fas fa-chevron-down' />
        </div>
      }
    >
      <Select
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={option => {
          setIsDialCodeOpened(false)
          onSelectedCountryCodeChange(option)
        }}
        options={dialCodeOptions}
        formatOptionLabel={formatOptionLabel}
        placeholder='Search...'
        styles={{
          control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
          menu: provided => ({
            ...provided,
            width: 300,
          }),
        }}
        tabSelectsValue={false}
        value={selectedDialCodeOption}
        isOptionSelected={(option, options) =>
          !!options.find(o => o.countryCode === option.countryCode)
        }
      />
    </Dropdown>
  )
}

export default DialCodeDropdown
