import React from 'react'
import CheckItemText from '../../../../../../../components/vention_ui/foundations/CheckItemText'
import Button from '../../../../../../../components/vention_ui/shared_components/Button'

interface Props {
  contentfulEntry: any
}

const Footer: React.FC<Props> = ({ contentfulEntry }) => {
  return (
    <section className='master-palletizer-landing-page__footer'>
      <div className='master-palletizer-landing-page__footer-content'>
        <div>
          <h2 className='master-palletizer-landing-page__footer-title'>{contentfulEntry.footerSection.title}</h2>
          <ul className='master-palletizer-landing-page__footer-list'>
            {contentfulEntry.footerSection.list.map((feature, index) => (
              <CheckItemText key={index} color='primary' type='lineOnly' size='md' label={feature} theme='dark' />
            ))}
          </ul>
          <Button
            heirarchy='primary'
            size='xl'
            href={contentfulEntry.footerSection.linkPath}
            iconTrailing={'ti ti-arrow-right'}
          >
            {contentfulEntry.footerSection.linkLabel}
          </Button>
        </div>
        <div></div>
      </div>
      <div></div>
      <img className='master-palletizer-landing-page__footer-image' src={contentfulEntry.footerSection.media.file.url} alt={contentfulEntry.footerSection.title} />
    </section>
  )
}

export default Footer
