import React from 'react'
import classNames from 'classnames'

export interface Breadcrumb {
  readonly label: string
  readonly link: string
}

interface Props {
  readonly className?: string
  readonly type: 'extended'
  readonly divider: 'chevron'
  readonly breadcrumbs: Breadcrumb[]
}

const Breadcrumbs: React.FC<Props> = ({ className, type, divider, breadcrumbs, ...rest }) => {
  return (
    <nav
      {...rest}
      className={classNames(
        'vention-ui-breadcrumbs',
        `vention-ui-breadcrumbs--${type}`,
        `vention-ui-breadcrumbs--${divider}`,
        className
      )}
    >
      <a className='vention-ui-breadcrumbs__link vention-ui-breadcrumbs__home' href='/'>
        <i className='ti ti-home'></i>
      </a>
      {breadcrumbs.map((breadcrumb, index) => (
        <div key={index}>
          <i className='vention-ui-breadcrumbs__chevron-right ti ti-chevron-right'></i>
          <a
            className={classNames('vention-ui-breadcrumbs__link', {
              'vention-ui-breadcrumbs__link--active': index + 1 === breadcrumbs.length,
            })}
            href={breadcrumb.link}
          >
            {breadcrumb.label}
          </a>
        </div>
      ))}
    </nav>
  )
}

export default Breadcrumbs
