import React, { useEffect } from 'react'
import SignInProvider, { SignInConsumer } from '../../../components/common/providers/SignInProvider'
import SignInForm from '../../../components/users/sign_in/SignInForm'
import { User } from '../../../api/Users/Types'
import { LocalizedPaths } from '../../../types/Common'

interface Props {
  readonly currentUser?: User
  readonly redirectTo?: string
  readonly showSignUpButton?: boolean
  readonly localizedPaths: LocalizedPaths
}

const LoginPage: React.FunctionComponent<Props> = ({
  currentUser,
  redirectTo,
  showSignUpButton,
  localizedPaths,
}) => {
  useEffect(() => {
    if (window.location.search.includes('timedout')) {
      toastr.error('Your session expired. Please sign in again to continue.')
    }
  }, [])

  return (
    <SignInProvider currentUser={currentUser}>
      <SignInConsumer>
        {signInContext => (
          <SignInForm
            redirectTo={redirectTo}
            handleSubmit={signInContext.handleSubmit}
            isLoading={signInContext.isLoading}
            error={signInContext.error}
            showSignUpButton={showSignUpButton}
            forgotPasswordUrl={localizedPaths.new_user_password}
            handleOtpMethodSubmit={signInContext.handleOtpMethodSubmit}
            otpMethods={signInContext.otpMethods}
            otpDeliveryMessage={signInContext.otpDeliveryMessage}
            signInFirstStageToken={signInContext.signInFirstStageToken}
          />
        )}
      </SignInConsumer>
    </SignInProvider>
  )
}

export default LoginPage
