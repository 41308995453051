import React from 'react'
import VentionGlobe from '../../components/svgs/VentionGlobe'
import GenericBanner from '../../partials/common/banner/GenericBanner'
import LinkWithIcon from '../../components/common/buttons/LinkWithIcon'
import ClientList from '../../components/common/ClientList'
import { LocalizedPaths } from '../../types/Common'
import I18n from '../../helpers/I18n'
import AddressMontreal from '../../components/common/address/AddressMontreal'
import AddressBerlin from '../../components/common/address/AddressBerlin'
import { User } from '../../api/Users/Types'
import ContactForm from '../../components/views/contacts/ContactForm'
import classNames from 'classnames'
import I18nProvider from '../../components/common/providers/I18nProvider'

interface Props {
  readonly showAdditionalSupport?: boolean
  readonly localizedPaths: LocalizedPaths
  currentUser?: Pick<User, 'email' | 'phone'>
  captchaKey: string
}

const Contact: React.FC<Props> = ({
  showAdditionalSupport = true,
  localizedPaths,
  currentUser,
  captchaKey,
}) => {
  return (
    <I18nProvider localizedPaths={localizedPaths}>
      <div className={classNames('contact-us-page', 'contact-us-page-with-form')}>
        <GenericBanner
          title={I18n.t('home.contact.banner.title')}
          accentAUrl='https://assets.vention.io/page-assets/headers/generic-1.png'
          accentBUrl='https://assets.vention.io/page-assets/headers/generic-2.png'
        />
        <div className='contact-us-main'>
          <div className='wrapper'>
            <div className='contact-us-main-info'>
              <ContactForm currentUser={currentUser} captchaKey={captchaKey} />

              <div className='contact-us-main-addresses'>
                <div className='contact-us-main-addresses-item'>
                  <div className='contact-us-address'>
                    <VentionGlobe />
                    <h3 className='contact-us-section-subtitle'>
                      {I18n.t('home.contact.address.montreal.title')}
                    </h3>

                    <div className='contact-us-info-list'>
                      <a href='https://g.page/vention' target='_blank' rel='noopener noreferrer'>
                        <AddressMontreal />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='contact-us-main-addresses-item'>
                  <div className='contact-us-address'>
                    <VentionGlobe />
                    <h3 className='contact-us-section-subtitle'>
                      {I18n.t('home.contact.address.berlin.title')}
                    </h3>

                    <div className='contact-us-info-list'>
                      <a
                        href='https://goo.gl/maps/KH9wwqJ15xZyRazN6'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <AddressBerlin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {showAdditionalSupport && (
                <div className='contact-us-main-resources'>
                  <div className='text-italic'>{I18n.t('home.contact.resources.upper_text')}</div>
                  <h3 className='contact-us-main-resources-title'>
                    {I18n.t('home.contact.resources.title')}
                  </h3>
                  <p className='contact-us-resource-text'>
                    {I18n.t('home.contact.resources.body')}
                  </p>
                  <div className='contact-us-link-icon'>
                    <LinkWithIcon
                      target='/docs'
                      text={I18n.t('home.contact.resources.button')}
                      theme='plain'
                      arrowRight
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='contact-client'>
          <ClientList heading={I18n.t('home.contact.clients.title')} />
        </div>
      </div>
    </I18nProvider>
  )
}

export default Contact
