import React, { useEffect, useState } from 'react'
import { scrollTo } from '../../../../../helpers/WindowHelper'

const scrollToSection = (event: React.MouseEvent<HTMLAnchorElement>, anchor: string) => {
  event.preventDefault()
  const element = document.querySelector(anchor)
  if (element) {
    scrollTo(element)
  }
}

interface NavLink {
  readonly label: string
  readonly anchor: string
}

interface Props {
  readonly navLinks: ReadonlyArray<NavLink>
}

const Nav: React.FC<Props> = ({ navLinks }) => {
  const [activeAnchor, setActiveAnchor] = useState<string | null>(null)

  const handleIntersection = entries => {
    const entry = entries.find(entry => entry.isIntersecting)

    setActiveAnchor(entry?.target.id || null)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    })

    const targets = navLinks
      .map(link => document.querySelector(link.anchor))
      .filter(Boolean) as Element[]
    targets.forEach(target => observer.observe(target))

    return () => {
      targets.forEach(target => observer.unobserve(target))
    }
  }, [handleIntersection])

  return (
    <nav className='product-viewer__nav'>
      <div className='product-viewer__nav-links'>
        {navLinks.map(({ label, anchor }) => (
          <a
            key={anchor}
            href={anchor}
            className={`product-viewer__nav-link ${
              activeAnchor === anchor.substring(1) ? 'product-viewer__nav-link--active' : ''
            }`}
            onClick={event => scrollToSection(event, anchor)}
          >
            {label}
          </a>
        ))}
      </div>
    </nav>
  )
}

export default Nav
