import React from 'react';
import { Root, Track, Range, Thumb} from '@radix-ui/react-slider';

import { cn } from '@/lib/utils';

export interface DualRangeSliderProps extends React.ComponentProps<typeof Root> {
  labelPosition?: 'top' | 'bottom';
  label?: (value: number | undefined) => React.ReactNode;
}

export const DualRangeSlider = React.forwardRef<
  React.ElementRef<typeof Root>,
  DualRangeSliderProps
>(({ className, label, labelPosition = 'top', ...props }, ref) => {
  const initialValue = Array.isArray(props.value) ? props.value : [props.min, props.max];

  return (
    <Root
      ref={ref}
      className={cn('relative flex w-full touch-none select-none items-center', className)}
      {...props}
    >
      <Track className="relative h-1 w-full grow overflow-hidden rounded-full bg-secondary">
        <Range className="absolute h-full bg-blue-600" />
      </Track>
      {initialValue.map((value, index) => (
        <React.Fragment key={index}>
          <Thumb className="relative block h-6 w-6 bg-white rounded-full border-2 border-blue-600 border-solid bg-background disabled:pointer-events-none disabled:opacity-50">
            {label && (
              <span
                className={cn(
                  'absolute flex w-full justify-center',
                  labelPosition === 'top' && '-top-7',
                  labelPosition === 'bottom' && 'top-4',
                )}
              >
                {label(value)}
              </span>
            )}
          </Thumb>
        </React.Fragment>
      ))}
    </Root>
  );
});
DualRangeSlider.displayName = 'DualRangeSlider';
