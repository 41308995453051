import React from 'react'

const Section: React.FC<{}> = ({
  children,
  ...rest
}) => {
  return (
    <section {...rest} className='section'>
      {children}
    </section>
  )
}

export default Section
