import classNames from 'classnames'
import React from 'react'

interface Props {
  index: number
  icon: string
  title: string
  description: string
  linkLabel: string
  linkPath: string
  button?: boolean
  phoneText?: string
  backgroundImage?: boolean
  footerLinkLabel?: string
  footerLinkPath?: string
}

const Card: React.FC<Props> = ({
  index,
  icon,
  title,
  description,
  linkLabel,
  linkPath,
  button,
  phoneText,
  backgroundImage,
  footerLinkLabel,
  footerLinkPath
}) => {
  return (
    <div
      className={classNames('contact__card', `contact__card contact__card-${index + 1}`, {
        'contact__card--background-image': !!backgroundImage,
      })}
    >
      <div>
        <i className={`contact__card-icon ${icon}`}></i>
        <h3 className='contact__card-title'>{title}</h3>
        <p className='contact__card-description'>{description}</p>
      </div>
      <div className='contact__card-footer'>
        {phoneText && <p className='contact__card-phone-text'>{phoneText}</p>}
        <a
          className={classNames({
            'contact__card-button': !!button,
            'contact__card-link': !button,
            'contact__card-link--no-arrow': !!phoneText,
          })}
          href={linkPath}
        >
          <span>{linkLabel}</span>
        </a>
      </div>
      {footerLinkLabel && footerLinkPath && (
          <a
            className={'contact__card-footer-link'}
            href={footerLinkPath}
          >
            {footerLinkLabel}
          </a>
        )}
    </div>
  )
}

export default Card
