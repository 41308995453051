import React from 'react'

export const PartSkeletonCard: React.FC = () => {
  return (
    <a className="similar-part-card-list" target="_blank">

      <div className='skeleton skeleton-image-container'></div>

      <div style={{ padding: 10 }}>

        {/* Part Number */}
        <div className="skeleton skeleton-text-16-80"></div>

        {/* Part Name */}
        <div className="skeleton skeleton-text-24-100"></div>

        <div className="space-2"></div>

        {/* Part Price */}
        <div className="skeleton skeleton-text-24-80"></div>

        {/* Part Next Ship */}
        <div className="skeleton skeleton-text-16-100"></div>

        <div className="space-2"></div>

        {/* Add to cart button */}
        <div className="skeleton skeleton-text-16-100"></div>

      </div>
    </a>
  )
}