import classNames from 'classnames'
import React from 'react'

export interface TableGroupRowProps {
  title?: string
  description: string
  descriptionLink?: string
  checkedValues: Array<'true' | 'false'>
  subscriptionType?: 'freemium' | 'pro' | 'business' | 'robot'
  oddRow?: boolean
}

const TableGroupRow: React.FC<TableGroupRowProps> = ({
  title,
  description,
  descriptionLink,
  checkedValues,
  subscriptionType,
  oddRow,
}) => {
  const renderIcon = () => {
    const freemiumSubscription = subscriptionType === 'freemium' && checkedValues[0] === 'true'
    const proSubscription = subscriptionType === 'pro' && checkedValues[1] === 'true'
    const businessSubscription = subscriptionType === 'business' && checkedValues[2] === 'true'
    const robotSubscription = subscriptionType === 'robot'

    if (freemiumSubscription || proSubscription || businessSubscription || robotSubscription) {
      return <i className='subscriptions-pricing__table-group-row-icon fal fa-circle-check'></i>
    } else {
      return <i className='subscriptions-pricing__table-group-row-icon far fa-dash'></i>
    }
  }

  return (
    <div
      className={classNames('subscriptions-pricing__table-group-row', {
        'subscriptions-pricing__table-group-row--odd': oddRow,
      })}
    >
      <div>
        {title && <p className='subscriptions-pricing__table-group-row-title'>{title}</p>}
        {descriptionLink ? (
          <a
            className='subscriptions-pricing__table-group-row-link'
            href={descriptionLink}
            target='_blank'
            rel='noreferrer'
          >
            <p className='subscriptions-pricing__table-group-row-description'>{description}</p>
            <i className='far fa-arrow-up-right-from-square'></i>
          </a>
        ) : (
          <p className='subscriptions-pricing__table-group-row-description'>{description}</p>
        )}
      </div>
      {renderIcon()}
    </div>
  )
}

export default TableGroupRow
