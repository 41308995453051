import { buildSearchEngine, getOrganizationEndpoints, SearchEngine, SearchEngineOptions } from "@coveo/headless";
import httpClient from "../../api/httpClient";

export const createSearchEngine = (
  organizationId: string,
  accessToken: string,
  pipeline: string,
  searchHub: string,
  preprocessRequestBody?: (body: any) => any
): SearchEngine => {
  const options: SearchEngineOptions = {
    configuration: {
      organizationId: organizationId,
      accessToken: accessToken,
      organizationEndpoints: getOrganizationEndpoints(organizationId),
      search: { pipeline: pipeline },
      renewAccessToken() {
        return httpClient({
          url: "/graphql",
          method: "post",
          data: JSON.stringify({
            query: "mutation($searchHub: String!) { coveoJw tCreate(input: { searchHub: $searchHub }) { jwt } }",
            variables: { searchHub: searchHub }
          }),
        }).then((response) => response.data.data.coveoJwtCreate.jwt);
      }
    }
  }

  if (preprocessRequestBody) {
    options.configuration.preprocessRequest = (request, _clientOrigin, metadata) => {
      if (metadata?.method === "search" && request.body) {
        const body = JSON.parse(request.body.toString());

        request.body = JSON.stringify(preprocessRequestBody(body));
      }

      return request;
    };
  }

  return buildSearchEngine(options);
};