import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PartCategoryByCode, RegionType } from "../Types";
import { Header } from "./Header";
import SearchBox from "../../../../coveo/components/part_library/SearchBox";
import { EngineAndControllerProvider } from "../../../../coveo/components/part_library/EngineAndControllerProvider";
import { RegionProvider } from "../../../../coveo/components/part_library/RegionProvider";
import { MainSection } from "../../../../coveo/components/part_library/MainSection";

interface PageProps {
  accessToken: string;
  organizationId: string;
  region: RegionType;
  shipmentSiteKey: number;
  partCategoryByCode: PartCategoryByCode;
}

const queryClient = new QueryClient();

const Page: React.FC<PageProps> = ({
  accessToken,
  organizationId,
  region,
  shipmentSiteKey,
  partCategoryByCode,
}) => (
  <QueryClientProvider client={queryClient}>
    <RegionProvider region={region}>
      <EngineAndControllerProvider
        accessToken={accessToken}
        organizationId={organizationId}
        shipmentSiteKey={shipmentSiteKey}
      >
        <Header partCategoryByCode={partCategoryByCode}>
          <SearchBox />
        </Header>
        <MainSection partCategoryByCode={partCategoryByCode} />
      </EngineAndControllerProvider>
    </RegionProvider>
  </QueryClientProvider>
);

export default Page;
