import React, { PureComponent, ReactElement } from 'react'
import { breakpoints } from '../../constants/Layout.js'

interface Props {
  readonly numPages: number
  readonly currentPage: number
  readonly onPageChange: (page: number) => void
  readonly previousOn?: boolean
  readonly nextOn?: boolean
  readonly previousLabel?: string
  readonly nextLabel?: string
}

interface State {
  readonly currentPage: number
  readonly offsetNumber: number
}

const defaultProps = {
  previousOn: true,
  nextOn: true,
}

class Pagination extends PureComponent<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: this.props.currentPage,
      offsetNumber: 2,
    }
    this.handleClick = this.handleClick.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  static defaultProps = defaultProps

  updateDimensions(): void {
    if (window.innerWidth <= breakpoints.extraSmall) {
      this.setState({ offsetNumber: 0 })
    } else if (window.innerWidth <= breakpoints.small) {
      this.setState({ offsetNumber: 1 })
    } else {
      this.setState({ offsetNumber: 2 })
    }
  }

  UNSAFE_componentWillMount(): void {
    this.updateDimensions()
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateDimensions)
  }

  UNSAFE_componentWillReceiveProps(nextProps): void {
    if (nextProps.currentPage != this.state.currentPage) {
      return this.setState({ currentPage: nextProps.currentPage })
    }
  }

  handleClick(e): void {
    const page = parseInt(e.target.innerText)
    this.setState({ currentPage: page })
    window.scrollTo(0, 0)
    this.props.onPageChange(page)
  }

  nextPage(): void {
    const newPage = this.state.currentPage + 1

    if (newPage > this.props.numPages) return
    this.setState({ currentPage: newPage })
    window.scrollTo(0, 0)
    this.props.onPageChange(newPage)
  }

  previousPage(): void {
    const newPage = this.state.currentPage - 1

    if (newPage < 1) return
    this.setState({ currentPage: newPage })
    window.scrollTo(0, 0)
    this.props.onPageChange(newPage)
  }

  render(): ReactElement {
    const pages: Array<ReactElement> = []
    const previousLabel = this.props.previousLabel || 'Previous'
    const nextLabel = this.props.nextLabel || 'Next'

    // First page
    pages.push(
      <button
        key={'first-page'}
        className={`button button-xs button-link ${this.state.currentPage == 1 ? 'active' : ''}`}
        onClick={this.handleClick}
      >
        {1}
      </button>
    )
    if (this.props.numPages < 8) {
      for (let i = 2; i < this.props.numPages; i++) {
        pages.push(
          <button
            key={i}
            className={`button button-xs button-link ${
              this.state.currentPage == i ? 'active' : ''
            }`}
            onClick={this.handleClick}
          >
            {i}
          </button>
        )
      }
    } else if (
      this.state.currentPage >= 2 + this.state.offsetNumber &&
      this.state.currentPage <= this.props.numPages - 3
    ) {
      if (this.state.offsetNumber > 0) {
        pages.push(
          <span key={'ellip'} className={'button button-xs button-link'}>
            ...
          </span>
        )
      }
      for (
        let i = this.state.currentPage - this.state.offsetNumber;
        i <= this.state.currentPage + this.state.offsetNumber;
        i++
      ) {
        pages.push(
          <button
            key={i}
            className={`button button-xs button-link ${
              this.state.currentPage == i ? 'active' : ''
            }`}
            onClick={this.handleClick}
          >
            {i}
          </button>
        )
      }
      if (this.state.offsetNumber > 0) {
        pages.push(
          <span key={'ellip-2'} className={'button button-xs button-link'}>
            ...
          </span>
        )
      }
    } else if (this.state.currentPage <= 5) {
      for (let i = 2; i < this.state.offsetNumber + 4; i++) {
        pages.push(
          <button
            key={i}
            className={`button button-xs button-link ${
              this.state.currentPage == i ? 'active' : ''
            }`}
            onClick={this.handleClick}
          >
            {i}
          </button>
        )
      }
      pages.push(
        <span key={'ellip'} className={'button button-xs button-link'}>
          ...
        </span>
      )
    } else if (this.state.currentPage >= this.props.numPages - (2 + this.state.offsetNumber)) {
      if (this.state.offsetNumber > 0) {
        pages.push(<span className={'button button-xs button-link'}>...</span>)
      }
      for (
        let i = this.props.numPages - (2 + this.state.offsetNumber);
        i < this.props.numPages;
        i++
      ) {
        pages.push(
          <button
            key={i}
            className={`button button-xs button-link ${
              this.state.currentPage == i ? 'active' : ''
            }`}
            onClick={this.handleClick}
          >
            {i}
          </button>
        )
      }
    }

    // Last page
    if (this.props.numPages > 1) {
      pages.push(
        <button
          key={'last-page'}
          className={`button button-xs button-link ${
            this.state.currentPage == this.props.numPages ? 'active' : ''
          }`}
          onClick={this.handleClick}
        >
          {this.props.numPages}
        </button>
      )
    }

    return (
      <div className='pagination-component'>
        {this.props.numPages > 0 && (
          <div className='wrapper'>
            {this.props.previousOn && (
              <button
                className='button button-xs button-link'
                onClick={this.previousPage}
                disabled={this.state.currentPage <= 1}
              >
                <i className='fas fa-caret-left i-left'></i>
                <span className='text-underline'>
                  {this.state.offsetNumber > 0 ? previousLabel : ''}
                </span>
              </button>
            )}
            <div className='pagination-pages'>{pages}</div>
            {this.props.nextOn && (
              <button
                className='button button-xs button-link'
                onClick={this.nextPage}
                disabled={this.state.currentPage >= this.props.numPages}
              >
                <span className='text-underline'>
                  {this.state.offsetNumber > 0 ? nextLabel : ''}
                </span>
                <i className='fas fa-caret-right i-right'></i>
              </button>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Pagination
