import React, { useState } from 'react'
import DesignModel from '../../models/DesignModel'
import CustomRangeFilter from '../common/CustomRangeFilter'

interface Props {
  readonly handleCustomPriceRange: (min: number, max: number) => void
  readonly handleCustomAssemblyTimeRange: (min: number, max: number) => void
  readonly handlePriceRange: (value: number) => void
  readonly handleAssemblyTimeRange: (value: number) => void
  readonly customPriceApplied: boolean
  readonly customAssemblyTimeApplied: boolean
  readonly showPriceFilter: boolean
  readonly showAssemblyTimeFilter: boolean
  readonly clearPriceFilter: () => void
  readonly clearAssemblyTimeFilter: () => void
  readonly priceFilterCleared: boolean
  readonly assemblyTimeFilterCleared: boolean
}

const designPriceFilters = DesignModel.designPriceFilters
const assemblyTimeFilters = DesignModel.assemblyTimeFilters

const DesignBrowserRangeFilter: React.FunctionComponent<Props> = ({
  handleCustomPriceRange,
  handleCustomAssemblyTimeRange,
  handlePriceRange,
  handleAssemblyTimeRange,
  customAssemblyTimeApplied,
  customPriceApplied,
  showPriceFilter,
  showAssemblyTimeFilter,
  clearPriceFilter,
  clearAssemblyTimeFilter,
  priceFilterCleared,
  assemblyTimeFilterCleared,
}) => {
  const [activePriceRangeIndex, setActivePriceRangeIndex] = useState(-1)
  const [activeAssemblyTimeRangeIndex, setActiveAssemblyTimeRangeIndex] = useState(-1)
  const [togglePriceFilter, setTogglePriceFilter] = useState(showPriceFilter)
  const [toggleAssemblyTimeFilter, setToggleAssemblyTimeFilter] = useState(showAssemblyTimeFilter)

  const showPriceClear = activePriceRangeIndex > -1 || customPriceApplied

  const showAssemblyTimeClear = activeAssemblyTimeRangeIndex > -1 || customAssemblyTimeApplied

  const setActiveItem = (index, type) => {
    type === 'price' ? setActivePriceRangeIndex(index) : setActiveAssemblyTimeRangeIndex(index)
  }

  const isActive = (index, type) => {
    return type === 'price'
      ? index === activePriceRangeIndex
      : index === activeAssemblyTimeRangeIndex
  }

  const toggleFilterOpen = type => {
    type === 'price'
      ? setTogglePriceFilter(!togglePriceFilter)
      : setToggleAssemblyTimeFilter(!toggleAssemblyTimeFilter)
  }

  return (
    <div className='range-filter-wrapper'>
      <div className='price-filter design-browser-filter'>
        <button
          className={`design-browser-filter-header ${togglePriceFilter ? 'open' : ''}`}
          onClick={() => toggleFilterOpen('price')}
        >
          <h5 className='design-browser-filter-title'>Price Range</h5>
          <span className='design-filter-chevron'></span>
        </button>
        <div className={`filter-content ${togglePriceFilter ? 'open' : ''}`}>
          {showPriceClear && (
            <button
              onClick={() => {
                clearPriceFilter(), setActivePriceRangeIndex(-1)
              }}
              className='clear-filter'
            >
              Clear
            </button>
          )}
          <ul className='price-filter-options'>
            {designPriceFilters.map((range, index) => (
              <li
                className={
                  isActive(index, 'price') && !customPriceApplied && !priceFilterCleared
                    ? 'active'
                    : ''
                }
                onClick={() => {
                  handlePriceRange(range.value), setActiveItem(index, 'price')
                }}
                key={index}
              >
                {range.label}
              </li>
            ))}
          </ul>
          <CustomRangeFilter
            minPlaceholder='$ Min'
            maxPlaceholder='$ Max'
            handleSubmit={handleCustomPriceRange}
          />
        </div>
      </div>
      <div className='assembly-time-filter design-browser-filter'>
        <button
          className={`design-browser-filter-header  ${toggleAssemblyTimeFilter ? 'open' : ''}`}
          onClick={() => toggleFilterOpen('assemblyTime')}
        >
          <h5 className='design-browser-filter-title'>Assembly Time</h5>
          <span className='design-filter-chevron'></span>
        </button>
        <div className={`filter-content ${toggleAssemblyTimeFilter ? 'open' : ''}`}>
          {showAssemblyTimeClear && (
            <button
              onClick={() => {
                clearAssemblyTimeFilter(), setActiveAssemblyTimeRangeIndex(-1)
              }}
              className='clear-filter'
            >
              Clear
            </button>
          )}
          <ul className='assembly-time-filter-options'>
            {assemblyTimeFilters.map((range, index) => (
              <li
                onClick={() => {
                  handleAssemblyTimeRange(range.value), setActiveItem(index, 'assemblyTime')
                }}
                key={index}
                className={
                  isActive(index, 'assemblyTime') &&
                  !customAssemblyTimeApplied &&
                  !assemblyTimeFilterCleared
                    ? 'active'
                    : ''
                }
              >
                {range.label}
              </li>
            ))}
          </ul>
          <CustomRangeFilter handleSubmit={handleCustomAssemblyTimeRange} />
        </div>
      </div>
    </div>
  )
}

export default DesignBrowserRangeFilter
