import React from 'react'
import I18n from '../../../../../helpers/I18n'
import Pill from '../../show/components/Pill'
import Blogs from './Blogs'
import { BlogPost } from '../../../../../api/Blog/Types'

interface Props {
  blogs: BlogPost[]
}

const FormSuccess: React.FC<Props> = ({ blogs }) => {
  return (
    <div>
      <div className='contact__form'>
        <Pill label={I18n.t('views.contacts.show.response_time')} icon={'fas fa-bolt-lightning'} />
        <h1 className='contact__form-heading'>
          {I18n.t('views.contacts.components.form_success.heading')}
        </h1>
        <p className='contact__form-description'>
          {I18n.t('views.contacts.components.form_success.description')}
        </p>
      </div>
      {I18n.locale === 'en' && <Blogs blogs={blogs} />}
    </div>
  )
}

export default FormSuccess
