import React from 'react'
import DesignBrowserList from '../../components/designs/DesignBrowserList'
import Loading from '../../components/common/Loading'
import DesignService from '../../api/Designs/Service'
import DesignBrowserFiltersTagsNav from '../../components/designs/DesignBrowserFiltersTagsNav'
import { DesignQuerySettings, Tag } from '../../api/Designs/Types'
import Design from '../../models/DesignModel'
import ResourceHeaderMobile from '../../components/cms/docs/ResourceHeaderMobile'
import { makeImmutableArrayOfObjects } from '../../helpers/Collections'
import SignInProvider from '../../components/common/providers/SignInProvider'
import { User } from '../../api/Users/Types'
import { Partner } from '../../api/Designs/Types'
import ListingPageHeader from '../../components/designs/ListingPageHeader'
import DesignModel from '../../../../app/frontend/models/DesignModel'
import { BreadCrumbDataItem } from '../../components/common/CustomBreadCrumb'
import { DesignCategory } from '../../api/DesignCategories/Types'
import memoize from 'lodash/memoize'
import ActivityLogService from '../../api/ActivityLog/Service'

interface Props {
  isRequestView: boolean
  currentUser: User
  designCategoriesLoading: boolean
  designCategories: ReadonlyArray<DesignCategory>
}

export interface DesignAuthors {
  official: boolean
  community: boolean
}

interface State {
  readonly data: ReadonlyArray<any>
  readonly designCount: number
  readonly max: number
  readonly isRequestView: boolean
  readonly isLoading: boolean
  readonly querySettings: DesignQuerySettings
  readonly availableTags: ReadonlyArray<Tag>
  readonly partnerList: ReadonlyArray<Partner>
  readonly customPriceApplied: boolean
  readonly customAssemblyTimeApplied: boolean
  readonly priceFilterCleared: boolean
  readonly assemblyTimeFilterCleared: boolean
  readonly designAuthors: DesignAuthors
  readonly showPartnerFilter: boolean
  readonly showPriceFilter: boolean
  readonly showAssemblyTimeFilter: boolean
}

const memoizeCategoryNamesByCode = memoize((designCategories: ReadonlyArray<DesignCategory>) =>
  designCategories.reduce(
    (categoryNames, category) => {
      categoryNames.categories[category.code] = category.name
      if (category.subcategories?.length) {
        category.subcategories.forEach(subCategory => {
          categoryNames.subCategories[`${category.code}_${subCategory.code}`] = subCategory.name
        })
      }
      return categoryNames
    },
    {
      categories: {} as Record<string, string>,
      subCategories: {} as Record<string, string>,
    }
  )
)

class DesignBrowser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      data: [],
      designCount: 0,
      max: 0,
      isRequestView: props.isRequestView,
      isLoading: false,
      availableTags: [],
      customPriceApplied: false,
      customAssemblyTimeApplied: false,
      partnerList: [],
      showPartnerFilter: false,
      showPriceFilter: false,
      showAssemblyTimeFilter: false,
      priceFilterCleared: false,
      assemblyTimeFilterCleared: false,
      designAuthors: {
        official: false,
        community: false,
      },

      querySettings: {
        category: 'ALL',
        subCategory: '',
        pageNumber: 1,
        designsPerPage: 20,
        sortBy: 'popularity_month',
        designAuthor: '',
        designer: '',
        searchTerm: '',
        lifecycle: 'not_archived',
        tagList: [],
        price_range: '',
        assembly_time_range: '',
        min_price: '',
        max_price: '',
        min_assembly_time: '',
        max_assembly_time: '',
        filter_by_partner: [],
      },
    }
    this.turnToPage = this.turnToPage.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.fetchDesignAuthor = this.fetchDesignAuthor.bind(this)
    this.handleDesignAuthor = this.handleDesignAuthor.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleTagSelection = this.handleTagSelection.bind(this)
    this.formSearchParams = this.formSearchParams.bind(this)
    this.updateSelectedCategory = this.updateSelectedCategory.bind(this)
    this.handleManualApply = this.handleManualApply.bind(this)
    this.handleCustomPriceRange = this.handleCustomPriceRange.bind(this)
    this.handleCustomAssemblyTimeRange = this.handleCustomAssemblyTimeRange.bind(this)
    this.handlePriceRange = this.handlePriceRange.bind(this)
    this.handleAssemblyTimeRange = this.handleAssemblyTimeRange.bind(this)
    this.handlePartnerSelect = this.handlePartnerSelect.bind(this)
    this.clearPriceFilter = this.clearPriceFilter.bind(this)
    this.clearAssemblyTimeFilter = this.clearAssemblyTimeFilter.bind(this)
    this.clearPartnerPartFilter = this.clearPartnerPartFilter.bind(this)
    this.clearTagsFilter = this.clearTagsFilter.bind(this)
  }

  formSearchParams(changedObj?: object, pushState = true) {
    const { querySettings, isRequestView } = this.state
    const url = new URL(window.location.href)
    if (undefined == url.searchParams) {
      return {} /* Note: this will trigger default values in getMultipleDesigns */
    }
    const searchTerm = url.searchParams.get('searchTerm') || ''
    const pageNumber = Number(url.searchParams.get('pageNumber')) || 1
    const category = (url.searchParams.get('category') || 'ALL').toUpperCase()

    const subCategory = (url.searchParams.get('subCategory') || '').toUpperCase()
    const sortBy = url.searchParams.get('sortBy')
    const designsPerPage = querySettings.designsPerPage
    const price_range = querySettings.price_range
    const assembly_time_range = querySettings.assembly_time_range
    const min_price = querySettings.min_price
    const max_price = querySettings.max_price
    const min_assembly_time = querySettings.min_assembly_time
    const max_assembly_time = querySettings.max_assembly_time
    const designAuthor = url.searchParams.get('designAuthor') || ''
    const tagListStr = url.searchParams.get('tagList')
    const tagList = tagListStr ? tagListStr.split(',') : []
    const get_tags = true
    const get_partners = true
    const filter_by_partner =
      url.searchParams.get('filter_by_partner') || querySettings.filter_by_partner

    const lObj = {
      searchTerm,
      pageNumber,
      category,
      subCategory,
      sortBy,
      designsPerPage,
      designAuthor,
      tagList,
      get_tags,
      get_partners,
      price_range,
      assembly_time_range,
      min_price,
      max_price,
      min_assembly_time,
      max_assembly_time,
      filter_by_partner,
      is_design_request: isRequestView,
    }
    const newUrlState = Object.assign({}, lObj, changedObj)

    // eslint-disable-next-line no-unused-vars
    for (const key in newUrlState) {
      url.searchParams.set(key, newUrlState[key])
    }
    if (pushState) {
      url.pathname = isRequestView
        ? `/requests/category/${newUrlState.category}/${newUrlState.subCategory}`
        : `/designs/category/${newUrlState.category}/${newUrlState.subCategory}`
      window.history.pushState({}, '', url.href)
    }

    return newUrlState
  }

  getDesignAuthorCheckedState = (requestString): DesignAuthors => {
    const { designAuthors } = this.state
    const designAuthorsToUpdate = designAuthors
    switch (requestString) {
      case 'official':
        designAuthorsToUpdate.official = true
        break
      case 'community':
        designAuthorsToUpdate.community = true
        break
      case 'all':
        designAuthorsToUpdate.official = true
        designAuthorsToUpdate.community = true
        break
      default:
        designAuthorsToUpdate.official = false
        designAuthorsToUpdate.community = false
    }

    return designAuthorsToUpdate
  }

  componentWillMount() {
    const { querySettings } = this.state
    const url = new URL(window.location.href)
    const designAuthorRequestString = url.searchParams.get('designAuthor')
    const sortByParam = url.searchParams.get('sortBy')
    const designAuthorsToUpdate = this.getDesignAuthorCheckedState(
      designAuthorRequestString || querySettings.designAuthor
    )

    const formSearchParams = this.formSearchParams({
      sortBy: sortByParam || querySettings.sortBy,
      designAuthor: designAuthorRequestString || querySettings.designAuthor,
    })
    this.setState(
      {
        querySettings: { ...querySettings, ...formSearchParams },
        designAuthors: designAuthorsToUpdate,
      },
      this.fetchDesigns
    )

    window.onpopstate = () => {
      const { querySettings } = this.state
      const formSearchParams = this.formSearchParams({}, false)
      this.setState({ querySettings: { ...querySettings, ...formSearchParams } }, this.fetchDesigns)
    }
  }

  async fetchDesigns() {
    const { querySettings } = this.state
    this.setState({ isLoading: true })

    try {
      const libraryDesignRresponse = await DesignService.fetchLibraryDesigns(querySettings)

      this.setState({
        data: libraryDesignRresponse?.designs ?? [],
        isLoading: false,
        designCount: libraryDesignRresponse?.max ?? 0,
        availableTags: libraryDesignRresponse?.tag_list ?? [],
        partnerList: libraryDesignRresponse?.partner_list ?? [],
        querySettings: {
          ...querySettings,
          tagList: libraryDesignRresponse?.tag_list ?? [],
        },
      })
    } catch (error) {
      console.error(error)
      toastr.error('Could not retrieve designs from server.')
      this.setState({ isLoading: false })
    }
  }

  updateSelectedCategory(category, subCategory = '', resetTagList = true) {
    const { querySettings } = this.state
    const { tagList } = querySettings
    const forcedSortBy =
      category === 'ALL'
        ? 'popularity_month'
        : category === 'NEW'
        ? 'last_published'
        : querySettings.sortBy

    return this.formSearchParams({
      category: category,
      subCategory: subCategory,
      pageNumber: 1,
      sortBy: forcedSortBy,
      tagList: resetTagList ? [] : tagList,
    })
  }

  handleCategoryChange(category, subCategory) {
    const { querySettings } = this.state
    const formSearchParams = this.updateSelectedCategory(category, subCategory)

    this.setState(
      { querySettings: { ...querySettings, ...formSearchParams } },

      this.fetchDesigns
    )
  }

  handleTagSelection(updatedTags: Tag[]) {
    const { querySettings } = this.state
    const tagsArray = updatedTags.filter(x => x.selected).map(y => y.name)

    this.setState(
      {
        availableTags: updatedTags,
        querySettings: {
          ...querySettings,
          tagList: tagsArray,
          pageNumber: 1,
        },
      },
      () => {
        this.formSearchParams({
          tagList: tagsArray,
          pageNumber: 1,
        })
        this.fetchDesigns()
      }
    )
  }

  handleSort(sortValue) {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      pageNumber: querySettings.pageNumber,
      sortBy: sortValue,
    })
    this.setState({ querySettings: { ...querySettings, ...formSearchParams } }, this.fetchDesigns)
  }

  fetchDesignAuthor(designAuthorState) {
    let author = ''
    if (designAuthorState.official) {
      author = 'official'
    }
    if (designAuthorState.community) {
      author = 'community'
    }
    if (designAuthorState.community && designAuthorState.official) {
      author = 'all'
    }
    return author
  }

  handleDesignAuthor(value, checked) {
    const { querySettings, designAuthors } = this.state
    const updatedDesignAuthors = designAuthors

    value === 'official'
      ? (updatedDesignAuthors.official = checked)
      : (updatedDesignAuthors.community = checked)

    const author = this.fetchDesignAuthor(updatedDesignAuthors)
    const formSearchParams = this.formSearchParams({
      pageNumber: querySettings.pageNumber,
      designAuthor: author,
    })
    this.setState(
      {
        querySettings: { ...querySettings, ...formSearchParams },
        designAuthors: updatedDesignAuthors,
      },
      this.fetchDesigns
    )
  }

  handleSearch(searchTerm) {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      category: 'ALL',
      subCategory: '',
      searchTerm: searchTerm,
      pageNumber: 1,
    })
    this.setState({ querySettings: { ...querySettings, ...formSearchParams } }, this.fetchDesigns)
    if (searchTerm.trim().length > 0) {
      ActivityLogService.trackSearchQuery({ query: searchTerm, searchLocation: 'design_library' })
    }
  }

  turnToPage(pageNumber) {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      pageNumber: pageNumber,
    })
    this.setState({ querySettings: { ...querySettings, ...formSearchParams } }, this.fetchDesigns)
  }

  handleManualApply(updatedTags, filterCategory) {
    const { querySettings } = this.state

    const tagsArray = updatedTags.filter(x => x.selected).map(y => y.name)

    const resetTagList = false
    const formSearchParams = this.updateSelectedCategory(
      filterCategory[0],
      filterCategory[1],
      resetTagList
    )

    this.setState(
      {
        availableTags: updatedTags,
        querySettings: {
          ...querySettings,
          ...formSearchParams,
          tagList: tagsArray,
        },
      },
      () => {
        this.formSearchParams({
          tagList: tagsArray,
          pageNumber: 1,
        })
        this.fetchDesigns()
      }
    )
  }

  handleCustomPriceRange(min, max) {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      min_price: min,
      max_price: max,
      pageNumber: 1,
      price_range: '',
    })
    this.setState(
      {
        customPriceApplied: true,
        showPriceFilter: true,
        priceFilterCleared: false,
        querySettings: { ...querySettings, ...formSearchParams },
      },
      this.fetchDesigns
    )
  }

  handleCustomAssemblyTimeRange(min, max) {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      min_assembly_time: min,
      max_assembly_time: max,
      assembly_time_range: '',
      pageNumber: 1,
    })
    this.setState(
      {
        customAssemblyTimeApplied: true,
        showAssemblyTimeFilter: true,
        assemblyTimeFilterCleared: false,
        querySettings: { ...querySettings, ...formSearchParams },
      },
      this.fetchDesigns
    )
  }
  handlePriceRange(value) {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      price_range: value,
      pageNumber: 1,
      min_price: '',
      max_price: '',
    })
    this.setState(
      {
        customPriceApplied: false,
        showPriceFilter: true,
        priceFilterCleared: false,
        querySettings: { ...querySettings, ...formSearchParams },
      },
      this.fetchDesigns
    )
  }
  handleAssemblyTimeRange(value) {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      assembly_time_range: value,
      min_assembly_time: '',
      max_assembly_time: '',
      pageNumber: 1,
    })
    this.setState(
      {
        customAssemblyTimeApplied: false,
        assemblyTimeFilterCleared: false,
        showAssemblyTimeFilter: true,
        querySettings: { ...querySettings, ...formSearchParams },
      },
      this.fetchDesigns
    )
  }

  handlePartnerSelect(updatedPartners) {
    const { querySettings } = this.state
    const partnersArray = updatedPartners.filter(x => x.selected).map(y => y.name)

    const formSearchParams = this.formSearchParams({
      filter_by_partner: partnersArray,
      pageNumber: 1,
    })
    this.setState(
      {
        querySettings: { ...querySettings, ...formSearchParams },
        showPartnerFilter: true,
      },
      this.fetchDesigns
    )
  }

  clearPriceFilter() {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      price_range: '',
      min_price: '',
      max_price: '',
      pageNumber: 1,
    })
    this.setState(
      {
        customPriceApplied: false,
        showPriceFilter: true,
        priceFilterCleared: true,
        querySettings: { ...querySettings, ...formSearchParams },
      },
      this.fetchDesigns
    )
  }

  clearAssemblyTimeFilter() {
    const { querySettings } = this.state
    const formSearchParams = this.formSearchParams({
      assembly_time_range: '',
      min_assembly_time: '',
      max_assembly_time: '',
      pageNumber: 1,
    })
    this.setState(
      {
        customAssemblyTimeApplied: false,
        showAssemblyTimeFilter: true,
        assemblyTimeFilterCleared: true,
        querySettings: { ...querySettings, ...formSearchParams },
      },
      this.fetchDesigns
    )
  }

  clearPartnerPartFilter() {
    const { querySettings } = this.state

    const formSearchParams = this.formSearchParams({
      filter_by_partner: [],
      pageNumber: 1,
    })
    this.setState(
      {
        querySettings: { ...querySettings, ...formSearchParams },
        showPartnerFilter: true,
      },
      this.fetchDesigns
    )
  }

  clearTagsFilter() {
    const { querySettings } = this.state

    this.setState(
      {
        availableTags: [],
        querySettings: {
          ...querySettings,
          tagList: [],
          pageNumber: 1,
        },
      },
      () => {
        this.formSearchParams({
          tagList: [],
          pageNumber: 1,
        })
        this.fetchDesigns()
      }
    )
  }

  render() {
    const {
      availableTags,
      querySettings,
      isLoading,
      designCount,
      data,
      partnerList,
      customPriceApplied,
      customAssemblyTimeApplied,
      showPartnerFilter,
      showPriceFilter,
      showAssemblyTimeFilter,
    } = this.state
    const { category, subCategory, searchTerm, sortBy, designsPerPage, pageNumber } = querySettings
    const { isRequestView, currentUser, designCategoriesLoading, designCategories } = this.props

    const memoizedCategoryNamesByCode = memoizeCategoryNamesByCode(designCategories)

    const categoryLabel = DesignModel.customCategories[category]
      ? DesignModel.customCategories[category].label
      : category
      ? memoizedCategoryNamesByCode.categories[category]
      : DesignModel.customCategories.ALL.label

    const subCategoryLabel = memoizedCategoryNamesByCode.subCategories[`${category}_${subCategory}`]

    const renderBreadCumbData = () => {
      const baseUrl = '/designs/category/'
      const breadCrumbData: BreadCrumbDataItem[] = []

      if (category != 'ALL') {
        breadCrumbData.push({
          label: 'All Vention Designs',
          href: `${baseUrl}ALL?category=ALL`,
        })
      }

      if (categoryLabel) {
        breadCrumbData.push({
          label: categoryLabel,
          href: `${baseUrl}${category}?category=${category}`,
        })
      }

      if (subCategoryLabel) {
        breadCrumbData.push({
          label: subCategoryLabel,
          href: `${baseUrl}${category}/${subCategory}?category=${category}&subCategory=${subCategory}`,
        })
      }

      return breadCrumbData
    }

    const heading = searchTerm
      ? `${
          isRequestView
            ? `Design Requests related to "${searchTerm}"`
            : `Designs related to "${searchTerm}"`
        }`
      : subCategoryLabel || categoryLabel

    return (
      <SignInProvider currentUser={currentUser}>
        <div className='grid-container'>
          <ListingPageHeader
            heading={heading}
            breadCrumbData={renderBreadCumbData()}
            searchFunction={this.handleSearch}
            searchTerm={searchTerm instanceof Array ? searchTerm[0] : searchTerm}
            searchIsLoading={isLoading}
            sortBy={sortBy}
            updateSorting={this.handleSort}
            sortOptions={Design.sortOptions}
            resourceHeaderMobile={
              <ResourceHeaderMobile
                searchTerm={searchTerm instanceof Array ? searchTerm[0] : searchTerm}
                updateQuery={query => this.handleSearch(query.search)}
                searchPlaceholder='Search section...'
                searchIsLoading={isLoading}
                clickDropdowns={[
                  {
                    label: 'Sort',
                    inner: toggle => (
                      <ul className='side-nav mobile-sort'>
                        {Design.sortOptions.map(option => (
                          <li
                            onClick={() => {
                              this.handleSort(option.value)
                              toggle()
                            }}
                            key={`sort-by-${option.value}`}
                            className={`side-nav-tab ${sortBy === option.value ? 'active' : ''}`}
                          >
                            <button className='side-nav-tab-button'>{option.label}</button>
                          </li>
                        ))}
                      </ul>
                    ),
                  },
                  {
                    label: <>Filter</>,
                    inner: toggle => (
                      <DesignBrowserFiltersTagsNav
                        requiresManualApply
                        displayForMobile
                        changeFilterCategory={this.handleCategoryChange}
                        activeCategory={category}
                        activeSubCategory={subCategory}
                        isRequestView={isRequestView}
                        tagList={makeImmutableArrayOfObjects(availableTags)}
                        handleTagClick={this.handleTagSelection}
                        isTagsListLoading={isLoading}
                        handleClose={toggle}
                        onApplyClick={this.handleManualApply}
                        partnerList={partnerList}
                        isPartnerListLoading={isLoading}
                        showPartnerFilter={showPartnerFilter}
                        handleCustomPriceRange={this.handleCustomPriceRange}
                        handleCustomAssemblyTimeRange={this.handleCustomAssemblyTimeRange}
                        handlePriceRange={this.handlePriceRange}
                        handleAssemblyTimeRange={this.handleAssemblyTimeRange}
                        customPriceApplied={customPriceApplied}
                        customAssemblyTimeApplied={customAssemblyTimeApplied}
                        showPriceFilter={showPriceFilter}
                        showAssemblyTimeFilter={showAssemblyTimeFilter}
                        handlePartnerSelect={this.handlePartnerSelect}
                        handleDesignAuthor={this.handleDesignAuthor}
                        designAuthorState={this.state.designAuthors}
                        clearPriceFilter={this.clearPriceFilter}
                        clearAssemblyTimeFilter={this.clearAssemblyTimeFilter}
                        clearPartnerPartFilter={this.clearPartnerPartFilter}
                        priceFilterCleared={this.state.priceFilterCleared}
                        assemblyTimeFilterCleared={this.state.assemblyTimeFilterCleared}
                        clearTagsFilter={this.clearTagsFilter}
                      />
                    ),
                  },
                ]}
              />
            }
          />

          <DesignBrowserFiltersTagsNav
            changeFilterCategory={this.handleCategoryChange}
            activeCategory={category}
            activeSubCategory={subCategory}
            isRequestView={isRequestView}
            tagList={makeImmutableArrayOfObjects(availableTags)}
            handleTagClick={this.handleTagSelection}
            isTagsListLoading={isLoading}
            handleCustomPriceRange={this.handleCustomPriceRange}
            handleCustomAssemblyTimeRange={this.handleCustomAssemblyTimeRange}
            handlePriceRange={this.handlePriceRange}
            handleAssemblyTimeRange={this.handleAssemblyTimeRange}
            partnerList={partnerList}
            isPartnerListLoading={isLoading}
            showPartnerFilter={showPartnerFilter}
            showPriceFilter={showPriceFilter}
            showAssemblyTimeFilter={showAssemblyTimeFilter}
            customPriceApplied={customPriceApplied}
            customAssemblyTimeApplied={customAssemblyTimeApplied}
            handlePartnerSelect={this.handlePartnerSelect}
            handleDesignAuthor={this.handleDesignAuthor}
            designAuthorState={this.state.designAuthors}
            clearPriceFilter={this.clearPriceFilter}
            clearAssemblyTimeFilter={this.clearAssemblyTimeFilter}
            clearPartnerPartFilter={this.clearPartnerPartFilter}
            priceFilterCleared={this.state.priceFilterCleared}
            assemblyTimeFilterCleared={this.state.assemblyTimeFilterCleared}
            clearTagsFilter={this.clearTagsFilter}
          />

          {isLoading || designCategoriesLoading ? (
            <Loading message={'Fetching designs'} />
          ) : (
            <DesignBrowserList
              designs={data}
              designCount={designCount}
              designsPerPage={designsPerPage}
              currentPage={pageNumber}
              handleClick={this.turnToPage}
              isRequestView={isRequestView}
            />
          )}
        </div>
      </SignInProvider>
    )
  }
}
export default DesignBrowser
