export const data = [
  {
    title: 'Robot Cell Applications',
    description:
      'Design and commission complete, plug & play robot cells, with one-stop shop solutions form Vention. From robot pedestal & workstation, to 7th axis and range extender, to safety enclosure, to part dispenser, Vention modular automation components will empower you to go from idea to commission robot cells in just a few days.',
    imageUrl: 'https://assets.vention.io/page-assets/applications/app-robot-cell.jpg',
    videoUrl: 'https://www.youtube.com/embed/_bCRXVMlBeg',
    cards: [
      {
        name: 'Pedestal &amp; Workstation',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/pedestal-workstation.png',
        href: '/robot-pedestal',
      },
      {
        name: 'Range Extender &amp; 7th Axis',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/range-extender-v2.png',
        href: '/range-extender',
      },
      {
        name: 'MachineLogic for UR',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/robot-cell-integration.png',
        href: '/robot-cell',
      },
      {
        name: 'Machine Tending',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/machine-tending.png',
        href: '/machine-tending',
      },
      {
        name: 'Bin Picking',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/bin-picking.png',
        href: '/bin-picking',
      },
      {
        name: 'Cobot Palletizer',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/public-company-page/industrial-applications/cobot-palletizer.png',
        href: '/cobot-palletizer',
      },
      {
        name: 'Lab Automation',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/lab-automation.png',
        href: '/lab-automation',
      },
    ],
  },
  {
    title: 'Industrial Automation Applications',
    description:
      'Automate your production and material handling processes with a tailored industrial equipment from Vention. Save cost and accelerate design and commissioning by realizing the project yourself. Leverage MachineBuilder to design your equipment online and MachineLogic for easy programming and commissioning in a code-free environment.',
    imageUrl: 'https://assets.vention.io/page-assets/applications/app-automation.jpg',
    videoUrl: 'https://www.youtube.com/embed/kj31YFavZ0A',
    cards: [
      {
        name: 'Conveying',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/conveyor.png',
        href: '/conveyor',
      },
      {
        name: 'Cartesian Palletizer',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/palletizer-v2.png',
        href: '/cartesian-palletizer',
      },
      {
        name: 'Safety Enclosure',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/public-company-page/industrial-applications/safety-enclosure.png',
        href: '/safety-enclosure',
      },
      {
        name: 'Path Following',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/public-company-page/industrial-applications/path-following.png',
        href: 'path-following',
      },
    ],
  },
  {
    title: 'Manufacturing Applications',
    description:
      'Drive continuous improvement projects with manufacturing equipment tailored to your production floor. From workstations, to jigs & fixtures, to industrial carts, fixed equipment from Vention can be designed online quickly and shipped the next day.',
    imageUrl:
      'https://assets.vention.io/page-assets/applications/app-manufacturing.jpg',
    cards: [
      {
        name: 'Workstations',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/workstation.png',
        href: '/workstation',
      },
      {
        name: 'Jigs &amp; Fixtures',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/applications/renders/jig-fixture.png',
        href: '/jig-fixture',
      },
      {
        name: 'Industrial Carts',
        thumbnailUrl:
          'https://assets.vention.io/page-assets/public-company-page/industrial-applications/industrial-cart.png',
        href: '/industrial-cart',
      },
    ],
  },
]
