import httpClient from '../httpClient'

const PartnerPartVariationService = {
  deletePartnerPartVariation: async function (partnerPartVariationId) {
    try {
      const response = await httpClient.delete(`/admin/partner_part_variations/${partnerPartVariationId}`)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
}

export default PartnerPartVariationService
