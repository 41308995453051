import React from 'react'
import {
  PartDocumentationStatus,
  usePartDocumentationStatus,
} from '../../components/common/providers/PartDocuments/usePartDocumentation'
import { readFileAsync } from '../../helpers/Files'
import { PartDocument, PartDocumentTypes } from '../../api/PartDocument/Types'
import { PartType } from '../../types/PartType'
import {
  FileSelectInput,
  FileSelectInputType,
} from '../../components/common/inputs/FileSelectInput'
import Select from 'react-select'

interface Props {
  partId: number
  partType: PartType
  partDocuments: PartDocument[]
  partDocumentUpdated?: (boolean) => void
  updatePartDocuments: (updatedPartDocuments: PartDocument[]) => void
}

const DOC_MAX_SIZE_BYTES = 20971520 // 20MB

const PartDocumentTypeOptions = Object.entries(PartDocumentTypes)
  .filter(([key]) => typeof key === 'string')
  .map(([key, value]) => ({ label: value, value: key }))

const PartDocumentUpload: React.FunctionComponent<Props> = ({
  partType,
  partId,
  partDocuments,
  partDocumentUpdated,
  updatePartDocuments,
}) => {
  console.log(' Object.keys(PartDocumentTypes)', Object.keys(PartDocumentTypes))

  const {
    partDocumentStatus,
    createPartDocument,
    deletePartDocument,
  } = usePartDocumentationStatus()

  const onFileChange = async (event, partId) => {
    partDocumentUpdated && partDocumentUpdated(true)
    for (const file of event.target.files) {
      try {
        const { reader } = await readFileAsync(
          file,
          DOC_MAX_SIZE_BYTES,
          `Documentation (${file.name}) file size exceeds maximum allowed size of 20MB`
        )
        const response = await createPartDocument(partId, partType, reader.result, file.name)

        updatePartDocuments([...partDocuments, response])
      } catch (error) {
        console.error(error)
        toastr.error(error.message)
      }
    }
  }

  const deleteUploadedPartDocument = (index, document_id) => {
    const documents = [...partDocuments]
    documents.splice(index, 1)
    deletePartDocument(document_id)
    updatePartDocuments(documents)
  }

  const updatePartDocumentType = (index, newValue) => {
    partDocumentUpdated && partDocumentUpdated(true)
    const documents = [...partDocuments]
    documents[index].document_type = newValue
    updatePartDocuments(documents)
  }

  const displayDocumentation = (part_document: PartDocument, index: number): React.ReactNode => (
    <li key={index} className='part-document-item'>
      <div className='part-document-item-name'>
        <i className='icon-whitepaper icon' />
        <a href={part_document.file_url} target='_blank' rel='noopener noreferrer nofollow'>
          {part_document.file_name}
        </a>
      </div>

      <div className='form-group'>
        <Select
          className='part-document-item-type'
          classNamePrefix='part-document-item-type'
          isSearchable={false}
          isMulti={false}
          isClearable={false}
          value={PartDocumentTypeOptions.find(
            option => option.value == part_document.document_type
          )}
          options={PartDocumentTypeOptions}
          onChange={option => updatePartDocumentType(index, (option as any).value)}
        />
      </div>
      <div className='part-document-item-actions'>
        <button
          onClick={() => deleteUploadedPartDocument(index, part_document.id)}
          type='button'
          className='button-blank'
          title='delete document'
        >
          <i className='fal fa-trash-alt'></i>
          <span className='screen-reader-text'>Delete</span>
        </button>
      </div>
    </li>
  )

  const processingDocument = ![
    PartDocumentationStatus.Idle,
    PartDocumentationStatus.Initial,
  ].includes(partDocumentStatus)

  return (
    <div className='form-group part-document-upload'>
      {!!partDocuments.length && <ul>{partDocuments.map(displayDocumentation)}</ul>}

      <FileSelectInput
        accept='.pdf, .docx, .doc, .xls, .xlsx, .csv'
        buttonType={FileSelectInputType.AddMore}
        changeHandler={event => onFileChange(event, partId)}
        disabled={processingDocument}
        label='Add documents'
        loading={processingDocument}
        multiple={true}
      />
    </div>
  )
}

export default PartDocumentUpload
