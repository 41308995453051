import React from 'react'
import I18n from '../../helpers/I18n'

type ShineItem = Record<'title' | 'text', string>

const CareersShine = () => {
  const items = (I18n.t('careers.index.shine.items') as unknown) as ShineItem[]

  return (
    <div className='grid'>
      <img
        src='https://assets.vention.io/page-assets/careers/summit-lineup.jpg'
        alt='Team'
      />
      <div>
        <h2>{I18n.t('careers.index.shine.title')}</h2>
        <ul className='list-unstyled'>
          {items.map((item, i) => (
            <li key={`shine-${i}`}>
              <div className='careers-shine-heading'>{item.title}</div> {item.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CareersShine
