import React from 'react'
import { Prompt } from '../../routing/Router'

export interface SaveProps {
  readonly canSave: boolean
  readonly allowSave: (callback?: () => void) => void
  readonly disableSave: (callback?: () => void) => void
  readonly prompt: () => React.ReactNode
  readonly children: (func?: () => void) => React.ReactElement
}

interface Options {
  label?: string
  message?: string
  form?: string
  buttonClass?: string
}

function withSave<OwnProps>(
  Component: React.ComponentType<OwnProps & SaveProps>,
  options: Options
): React.ComponentClass<OwnProps> {
  const { label, message, form, buttonClass = 'button button-success button-md' } = options

  interface State {
    readonly canSave: boolean
  }

  class WithSave extends React.Component<OwnProps, State> {
    constructor(props) {
      super(props)
      this.state = {
        canSave: false,
      }
      this.allowSave = this.allowSave.bind(this)
      this.disableSave = this.disableSave.bind(this)
      this.prompt = this.prompt.bind(this)
    }

    allowSave(callback): void {
      this.setState({ canSave: true }, callback)
    }

    disableSave(callback): void {
      this.setState({ canSave: false }, callback)
    }

    prompt(): React.ReactNode {
      const { canSave } = this.state
      return (
        <Prompt
          when={canSave}
          message={message || 'Are you sure you want to leave with unsaved changes?'}
        />
      )
    }

    render(): React.ReactNode {
      const { canSave } = this.state
      return (
        <Component
          canSave={canSave}
          allowSave={this.allowSave}
          disableSave={this.disableSave}
          prompt={this.prompt}
          {...this.props}
        >
          {handleSave => {
            if (handleSave && !form) {
              return (
                <>
                  <button
                    className={buttonClass}
                    type='submit'
                    disabled={!this.state.canSave}
                    onClick={() => this.disableSave(handleSave)}
                  >
                    {label ? label : 'Save'}
                  </button>
                  {this.prompt()}
                </>
              )
            } else {
              return (
                <>
                  <button
                    className={buttonClass}
                    type='submit'
                    disabled={!this.state.canSave}
                    form={form}
                  >
                    {label ? label : 'Save'}
                  </button>
                  {this.prompt()}
                </>
              )
            }
          }}
        </Component>
      )
    }
  }

  return WithSave
}

export default withSave
