import classNames from 'classnames'
import moment from 'moment'
import React from 'react'

interface Props {
  link: string
  image: string
  supertitle?: string
  title: string
  description?: string
  author?: string
  date?: string
  theme?: 'dark'
}

const BlogPostCard: React.FC<Props> = ({
  image,
  link,
  supertitle,
  title,
  description,
  author,
  date,
  theme
 }) => {
  return (
    <a className={classNames('vention-ui-blog-post-card', {
      'vention-ui-blog-post-card--dark': theme === 'dark'
    })} href={link}>
      <img className='vention-ui-blog-post-card__image' src={image} alt={title} />
      {supertitle && (
        <p className='vention-ui-blog-post-card__supertitle'>{supertitle}</p>
      )}
      <div className='vention-ui-blog-post-card__title-wrapper'>
        <h4 className='vention-ui-blog-post-card__title'>{title}</h4>
        <i className='vention-ui-blog-post-card__icon fa-solid fa-arrow-up-right'></i>
      </div>
      {description && (
        <p className='vention-ui-blog-post-card__description'>{description}</p>
      )}
      {!!author && !!date && (
        <div className='vention-ui-blog-post-card__footer'>
          <p className='vention-ui-blog-post-card__author'>{author}</p>
          <span>|</span>
          <p className='vention-ui-blog-post-card__date'>{moment(date).format('MMMM D, YYYY')}</p>
        </div>
      )}
    </a>
  )
}

export default BlogPostCard
