import React from 'react'

interface Props {
  className?: string
}

const LabelValueViewContainer: React.FC<Props> = props => {
  return <div className={`label-value-view-container ${props.className || ''}`}>{props.children}</div>
}

export default LabelValueViewContainer
