import React, { useState } from "react";
import Button from "../../../../../components/vention_ui/shared_components/Button";
import QuantitySelector from "../../../../../components/vention_ui/shared_components/QuantitySelector";
import * as CartService from "../../../../../api/Cart/Service";
import { ShowPart } from "../Page";
import { FormattedPrice } from "./FormattedPrice";
import { SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS } from "../../constants";
import {
  VRegularText,
  VSemiBoldText,
} from "../../../../../components/text/Text";

interface Props {
  readonly product: ShowPart;
}

export const Cart: React.FC<Props> = ({ product }) => {
  const [quantity, setQuantity] = useState<number | null>(null);
  const [addingToCart, setAddingToCart] = useState(false);

  const onQuantityChange = (quantity: number) => {
    setQuantity(quantity);
  };

  const onAddToCartClick = () => {
    if (!quantity) return;

    setAddingToCart(true);
    CartService.quickAddToCart(
      null,
      `/parts/${product.id}/buy?quantity=${quantity}`,
      "Part",
      () => setAddingToCart(false),
    );
  };

  return (
    <div className="product-viewer-v2__cart-card">
      {product.display_price_raw && (
        <p className="product-viewer-v2__header-price">
          <FormattedPrice price={product.display_price_raw} />
        </p>
      )}
      {product.display_price_raw &&
        product.shipment_delay_weeks <
          SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS && (
          <label
            className={`product-viewer-v2__availability product-viewer-v2__availability-${product.status_availability.className}`}
          >
            {product.status_availability.text}
          </label>
        )}

      {product.allow_checkout &&
      product.shipment_delay_weeks <
        SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS ? (
        <>
          <div className="product-viewer-v2__header-selectors">
            <div className="product-viewer-v2__header-quantity">
              <QuantitySelector
                onQuantityChange={onQuantityChange}
                name="quantity"
                label="Quantity"
                size="xl"
              />
            </div>
          </div>
          <Button
            className="product-viewer-v2__header-cta"
            heirarchy="primary"
            size="xl"
            iconLeading="ti ti-shopping-cart"
            onClick={onAddToCartClick}
            disabled={addingToCart}
          >
            {addingToCart ? "Adding..." : "Add to cart"}
          </Button>
        </>
      ) : (
        <div className="flex flex-col max-w-80 w-80 min-w-52">
          <VSemiBoldText className="text-slate-700">
            Looking for more details?
          </VSemiBoldText>
          <VRegularText marginTop="xxs" className="text-slate-600">
            This part requires custom specifications. Contact our sales team for
            tailored pricing and detailed information to ensure it fits your
            project perfectly.
          </VRegularText>
          <Button
            className="mt-4 w-full px-2 py-4"
            heirarchy="primary"
            size="xl"
            iconLeading="ti ti-messages"
            href="/contact"
          >
            Contact Us
          </Button>
        </div>
      )}

      <div className="flex flex-row mt-4 gap-1">
        <VRegularText>Sold by</VRegularText>
        <VRegularText>{product.sold_by || "Vention"}</VRegularText>
      </div>

      <div className="flex flex-row mt-1 gap-1">
        <VRegularText>Ships from</VRegularText>
        <VRegularText>{product.ship_from || "Vention"}</VRegularText>
      </div>

      <hr />
      <div className="product-viewer-v2__cart-footer">
        <a href="/contact/new?choice=technical+support" target="_blank">
          <img src="https://assets.vention.io/svg/icons/Headset.svg" /> Talk
          with an Expert
        </a>
      </div>
    </div>
  );
};
