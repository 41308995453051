import React from 'react'
import DesignBrowserSideNavTab from './DesignBrowserSideNavTab'
import DesignModel from '../../models/DesignModel'
import ListPageSideNavTopTab from './ListPageSideNavTopTab'
import IconTagList from '../common/IconTagList'
import { useDesignCategoriesContext } from '../common/providers/DesignCategoriesProvider'
import { DesignAuthors } from '../../pages/designs/DesignBrowser'

interface Props {
  activeCategory?: string
  activeSubCategory?: string
  changeCategory?: Function
  className?: string
  handleDesignAuthor?: (value: string, checked: boolean) => void
  designAuthorState: DesignAuthors
  isRequestView?: boolean
}

const DesignBrowserSidenav: React.FC<Props> = ({
  activeCategory,
  activeSubCategory,
  changeCategory,
  className,
  handleDesignAuthor,
  designAuthorState,
}) => {
  const { designCategories } = useDesignCategoriesContext()

  const updateDesignAuthor = event =>
    handleDesignAuthor && handleDesignAuthor(event.target.value, event.target.checked)

  const designAuthors = DesignModel.designAuthors

  const content = designCategories.map((designCategory, index) => {
    return (
      <DesignBrowserSideNavTab
        activeCategory={activeCategory}
        activeSubCategory={activeSubCategory}
        categoryKey={designCategory.code}
        label={designCategory.name}
        subCategories={designCategory.subcategories}
        handleClick={changeCategory}
        key={index}
      />
    )
  })

  return (
    <div className={className || 'part-list-side-nav'}>
      <ul className='side-nav'>
        <IconTagList
          label='Design Author'
          tagList={designAuthors}
          onChange={updateDesignAuthor}
          designAuthorState={designAuthorState}
        />
        <ListPageSideNavTopTab
          label='Applications'
          linkOnClick={() =>
            changeCategory && changeCategory(DesignModel.customCategories.ALL.code)
          }
        />
        <DesignBrowserSideNavTab
          activeCategory={activeCategory}
          activeSubCategory={activeSubCategory}
          categoryKey={DesignModel.customCategories.NEW.code}
          label={DesignModel.customCategories.NEW.label}
          html={DesignModel.customCategories.NEW.html}
          handleClick={changeCategory}
        />
        {content}
      </ul>
    </div>
  )
}

export default DesignBrowserSidenav
