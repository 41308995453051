import { useState, useEffect } from 'react'
import { BlogPost, BlogPostQuerySettings } from '../api/Blog/Types'
import BlogService from '../api/Blog/Service'

interface UseBlogPosts {
  readonly blogPosts: ReadonlyArray<BlogPost>
  readonly fetchBlogPosts: (querySettings) => void
  readonly count: number
  readonly perPage: number
  readonly isLoading: boolean
}

export function useBlogPosts(querySettings: BlogPostQuerySettings = {}): UseBlogPosts {
  const [blogPosts, setBlogPosts] = useState<ReadonlyArray<BlogPost>>([])
  const [count, setCount] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  function fetchBlogPosts(querySettings): void {
    setIsLoading(true)
    BlogService.fetchBlogPosts(querySettings, res => {
      setBlogPosts(res.blog_posts)
      setCount(res.count)
      setPerPage(res.per_page)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchBlogPosts(querySettings)
  }, [])

  return { blogPosts, fetchBlogPosts, isLoading, count, perPage }
}
