import React from 'react'
import UserService from '../../api/Users/Service'
import I18n from '../../helpers/I18n'

interface Props {
  readonly mobile_device: boolean
}

interface Feature {
  title: string
  body: string
}

const ActivationPage: React.FunctionComponent<Props> = ({ mobile_device }) => {
  const resendActivationCode = async () => {
    try {
      await UserService.resendActivationCode()
      toastr.success(I18n.t('home.activation_page.resend_email.success'), 'Success')
    } catch (error) {
      toastr.error(I18n.t('home.activation_page.resend_email.error'))
    }
  }

  const renderFeature = (feature: Feature, index: number) => (
    <div className='item flex-centering' key={index}>
      <i className='far fa-check-circle fa-2x'></i>
      <h4 className='text'>
        <strong>{feature.title}</strong> {feature.body}
      </h4>
    </div>
  )

  const featureItems = (I18n.t('home.activation_page.items') as unknown) as Feature[]
  const featureItemsMobile = (I18n.t('home.activation_page.mobile.items') as unknown) as Feature[]

  const features = featureItems.map(renderFeature)
  const featuresMobile = featureItemsMobile.map(renderFeature)

  return (
    <div className='activation-page'>
      <h1 className='strong flex-centering'>{I18n.t('home.activation_page.title')}</h1>
      <div className='center-self header-image'>
        <img src='https://s3.amazonaws.com/ventioncms/vention_images/images/000/000/715/original/Progress.png?1544108161' />
      </div>
      <h4 className='text-center header-subtitle'>
        {I18n.t('home.activation_page.subtitle.line_1')}
        <br />
        {I18n.t('home.activation_page.subtitle.line_2')}
      </h4>
      <div className='content'>
        <div className='left'>
          {mobile_device ? (
            <div className='embed-container'>
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/sSCPhUx9a-E'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <img src='https://s3.amazonaws.com/ventioncms/vention_images/images/000/000/717/original/browser.png?1544111812' />
          )}
        </div>
        {mobile_device ? (
          <div className='right'>
            {featuresMobile}
            <div className='resend-button text-center'>
              <a
                className='button button-success button-md resend-activation'
                onClick={resendActivationCode}
              >
                {I18n.t('home.activation_page.resend_email.button')}
              </a>
            </div>
          </div>
        ) : (
          <div className='right'>
            {features}
            <div className='resend-button text-center'>
              <a
                className='button button-success button-md resend-activation'
                onClick={resendActivationCode}
              >
                {I18n.t('home.activation_page.resend_email.button')}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ActivationPage
