import { gql } from "graphql-request";

export const fetchSimilarPartsQuery = gql`
  query fetchSimilarParts(
    $excludePartIds: [ID!]
    $status: [Status!]
    $ignorePartPrefixesBy: [String!]
    $categoryIds: [ID!]
    $excludeHidden: Boolean
    $ignorePartPrefixesWithPartNumber: String!
    $isRobot: Boolean
    $page: Int
    $pageSize: Int
    $geographicalRegionId: ID
  ) {
    parts(
      page: $page
      pageSize: $pageSize
      status: $status
      ignorePartPrefixesBy: $ignorePartPrefixesBy
      excludePartIds: $excludePartIds
      excludeHidden: $excludeHidden
      ignorePartPrefixesWithPartNumber: $ignorePartPrefixesWithPartNumber
      categoryIds: $categoryIds
      isRobot: $isRobot
      geographicalRegionId: $geographicalRegionId
    ) {
      collection {
        id
        name
        partNumber
        description
        imagePath
        displayPrice
        fullUrl
        technicalSpecifications
        allowCheckout
        shipmentDelayWeeks
        displayPriceRaw {
          amount
          currencySymbol
          currencyCode
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      errors {
        title
        message
      }
    }
  }
`;
