import React, { useState } from 'react'
import DesignBrowserSideNav from './DesignBrowserSideNav'
import TagNav from '../common/TagNav'
import { Partner } from '../../api/Designs/Types'
import DesignBrowserSideNavFilters from './DesignBrowserSideNavFilters'
import { DesignAuthors } from '../../pages/designs/DesignBrowser'
import Loading from '../common/Loading'
import { useWindowSize } from '../../hooks/useWindowSize'

interface Props {
  readonly requiresManualApply?: boolean
  readonly changeFilterCategory?: Function
  readonly activeCategory?: string
  readonly activeSubCategory?: string
  readonly isRequestView?: boolean
  readonly tagList?: any
  readonly isPartnerListLoading: boolean
  readonly handleCustomPriceRange: (min: number, max: number) => void
  readonly handleCustomAssemblyTimeRange: (min: number, max: number) => void
  readonly handlePriceRange: (value: number) => void
  readonly handleAssemblyTimeRange: (value: number) => void
  readonly partnerList: ReadonlyArray<Partner>
  readonly customPriceApplied: boolean
  readonly customAssemblyTimeApplied: boolean
  readonly handlePartnerSelect?: Function
  readonly handleTagClick?: Function
  readonly isTagsListLoading?: boolean
  readonly displayForMobile?: boolean
  readonly handleClose?: any
  readonly onApplyClick?: Function
  readonly handleDesignAuthor?: (value: string, checked: boolean) => void
  readonly designAuthorState: DesignAuthors
  readonly showPartnerFilter: boolean
  readonly showPriceFilter: boolean
  readonly showAssemblyTimeFilter: boolean
  readonly clearPriceFilter: () => void
  readonly clearAssemblyTimeFilter: () => void
  readonly clearPartnerPartFilter: () => void
  readonly clearTagsFilter: () => void
  readonly priceFilterCleared: boolean
  readonly assemblyTimeFilterCleared: boolean
}

const DesignBrowserFiltersTagsNav: React.FunctionComponent<Props> = ({
  requiresManualApply,
  changeFilterCategory,
  activeCategory,
  activeSubCategory,
  isRequestView,
  tagList,
  handleTagClick,
  isTagsListLoading,
  displayForMobile,
  handleClose,
  onApplyClick,
  handleCustomPriceRange,
  handleCustomAssemblyTimeRange,
  handlePriceRange,
  handleAssemblyTimeRange,
  partnerList,
  isPartnerListLoading,
  customAssemblyTimeApplied,
  customPriceApplied,
  handlePartnerSelect,
  handleDesignAuthor,
  designAuthorState,
  showPartnerFilter,
  showPriceFilter,
  showAssemblyTimeFilter,
  clearPriceFilter,
  clearAssemblyTimeFilter,
  clearPartnerPartFilter,
  priceFilterCleared,
  assemblyTimeFilterCleared,
  clearTagsFilter,
}) => {
  const windowSize = useWindowSize()
  const [tags, setTags] = useState(tagList)

  const setTagsHandler = id => (callback?) => {
    const updatedTags = tagList.map(tag => {
      if (tag.id === id) {
        tag.selected = !tag.selected
      }
      return tag
    })
    setTags(updatedTags)
    if (typeof callback === 'function') {
      callback(updatedTags)
    }
  }

  const setPartnerHandler = partnerName => (callback?) => {
    const updatedPartners = partnerList.map(partner => {
      if (partner.name === partnerName) {
        partner.selected = !partner.selected
      }
      return partner
    })
    if (typeof callback === 'function') {
      callback(updatedPartners)
    }
  }

  const isMobileView = (windowSize.width || 1000) < 993
  if (!displayForMobile) {
    return (
      <div className={`side-nav ${isTagsListLoading ? 'loading-backdrop' : ''}`}>
        {isTagsListLoading && !isMobileView && <Loading className='loading' />}
        <DesignBrowserSideNav
          changeCategory={changeFilterCategory}
          activeCategory={activeCategory}
          activeSubCategory={activeSubCategory}
          isRequestView={isRequestView}
          handleDesignAuthor={handleDesignAuthor}
          designAuthorState={designAuthorState}
        />
        <DesignBrowserSideNavFilters
          partnerList={partnerList}
          isPartnerListLoading={isPartnerListLoading}
          handlePartnerSelect={handlePartnerSelect}
          handleCustomPriceRange={handleCustomPriceRange}
          handleCustomAssemblyTimeRange={handleCustomAssemblyTimeRange}
          handlePriceRange={handlePriceRange}
          handleAssemblyTimeRange={handleAssemblyTimeRange}
          customPriceApplied={customPriceApplied}
          customAssemblyTimeApplied={customAssemblyTimeApplied}
          setPartnerHandler={setPartnerHandler}
          showPartnerFilter={showPartnerFilter}
          showPriceFilter={showPriceFilter}
          showAssemblyTimeFilter={showAssemblyTimeFilter}
          clearPriceFilter={clearPriceFilter}
          clearAssemblyTimeFilter={clearAssemblyTimeFilter}
          clearPartnerPartFilter={clearPartnerPartFilter}
          priceFilterCleared={priceFilterCleared}
          assemblyTimeFilterCleared={assemblyTimeFilterCleared}
        />
        <TagNav
          tagList={tagList}
          handleClick={id => setTagsHandler(id)(handleTagClick)}
          isLoading={isTagsListLoading}
          clearTagsFilter={clearTagsFilter}
        />
      </div>
    )
  }

  const [filter, setFilter] = useState([activeCategory, activeSubCategory])

  const onClearFilters = () => {
    onApplyClick && onApplyClick([], ['ALL', ''])
    handleClose && handleClose()
  }

  const onApplyFilters = () => {
    onApplyClick && onApplyClick(tags, filter)
    handleClose && handleClose()
  }

  return (
    <>
      <div className='filters-tags-wrapper-mobile'>
        {requiresManualApply && true}
        <DesignBrowserSideNav
          className={'part-list-side-nav-mobile'}
          changeCategory={(mainCat, subCat) => setFilter([mainCat, subCat])}
          activeCategory={filter[0]}
          activeSubCategory={filter[1]}
          isRequestView={isRequestView}
          handleDesignAuthor={handleDesignAuthor}
          designAuthorState={designAuthorState}
        />
        <TagNav
          className={'tag-nav-mobile'}
          tagList={tags}
          handleClick={id => setTagsHandler(id)()}
          isLoading={isTagsListLoading}
        />
      </div>
      <div className='sidebar-filter-action-clear-wrapper'>
        <button className='sidebar-filter-apply-button' onClick={onApplyFilters} disabled={false}>
          Apply
        </button>
        <button className='button-link-underline' onClick={onClearFilters}>
          Clear All
        </button>
      </div>
    </>
  )
}

DesignBrowserFiltersTagsNav.defaultProps = {
  requiresManualApply: false,
} as Partial<Props>

export default DesignBrowserFiltersTagsNav
