import { ValidatorComponent } from 'react-form-validator-core'

class SelectValidator extends ValidatorComponent {
  componentDidMount() {
    if (this.input.value !== '') {
      this.input.classList.add('select-not-empty')
    }

    // anti-pattern to make it work
    super.componentDidMount()
  }

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      icon,
      label,
      options,
      placeholderOption,
      formGroupClassName,
      addWrapperAroundSelect,
      ...rest
    } = this.props

    const displaySelect = () => (
      <select
        className='form-control'
        {...rest}
        ref={r => {
          this.input = r
        }}
        onChange={event => {
          if (this.errorText()) {
            this.setState({isValid: true})
          }
          if (rest && rest.onChange) {
            rest.onChange(event)
          }
          if (event.target.value !== '') {
            event.target.classList.add('select-not-empty')
          } else {
            event.target.classList.remove('select-not-empty')
          }
        }}
      >
        {placeholderOption && (
          <option value='' className='select-placeholder-option'>
            {placeholderOption}
          </option>
        )}
        {options.map((option, key) =>
          typeof option === 'object' ? (
            <option key={key + 1} value={Object.values(option)[0]}>
              {Object.keys(option)[0]}
            </option>
          ) : (
            <option key={key + 1} value={option}>
              {option}
            </option>
          )
        )}
      </select>
    )

    return (
      <div
        className={`form-group ${formGroupClassName ? formGroupClassName : ''} ${
          this.errorText() ? 'has-error' : ''
        }`}
      >
        {label && (
          <label>
            {icon && <i className={icon} />}
            {label}
          </label>
        )}
        {addWrapperAroundSelect ? (
          <div className='select-wrapper'>{displaySelect()}</div>
        ) : (
          displaySelect()
        )}
        {this.props.children}
        {this.errorText()}
      </div>
    )
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return <div className='form-error-message'>{this.getErrorMessage()}</div>
  }
}

export default SelectValidator
