import React, { useState } from 'react'
import TableGroup from './TableGroup'
import SubscriptionCard from './SubscriptionCard'
import { ContentfulEntry, CurrencyExchangeRate } from '../Page'

interface Props {
  contentfulEntry: ContentfulEntry
  currencyExchangeRate: CurrencyExchangeRate
}

const RobotBundle: React.FC<Props> = ({ contentfulEntry, currencyExchangeRate }) => {
  const [showTable, setShowTable] = useState(false)

  return (
    <div>
      <div className='subscriptions-pricing__robot-bundle'>
        <div className='subscriptions-pricing__tiered-plans-card subscriptions-pricing__tiered-plans-card--blue'>
          <img
            className='subscriptions-pricing__tiered-plans-card-icon'
            src='https://assets.vention.io/page-assets/subscriptions/icons/arm-gripper.svg'
            alt={contentfulEntry.bundlePlanTitle}
          />
          <div className='subscriptions-pricing__tiered-plans-card-title-wrapper'>
            <h2 className='subscriptions-pricing__tiered-plans-card-title'>
              {contentfulEntry.bundlePlanTitle}
            </h2>
            <p className='subscriptions-pricing__pill'>{contentfulEntry.bundlePlanPill}</p>
          </div>
          <p className='subscriptions-pricing__tiered-plans-card-description'>
            {contentfulEntry.bundlePlanDescription}
          </p>
          <ul className='subscriptions-pricing__robot-bundle-list'>
            {contentfulEntry.bundlePlanFeaturesList?.map((feature, index) => (
              <li key={index}>
                <i className='subscriptions-pricing__robot-bundle-list-item-icon fal fa-circle-check'></i>
                <p className='subscriptions-pricing__robot-bundle-list-item-text'>{feature}</p>
              </li>
            ))}
          </ul>
        </div>
        <SubscriptionCard
          currencyExchangeRate={currencyExchangeRate}
          subscriptionType='robot'
          showTable={showTable}
          setShowTable={setShowTable}
          tableGroupsProps={contentfulEntry.bundlePlanTableGroups}
          {...contentfulEntry.bundlePlanPricingCard}
        />
      </div>
      {showTable && (
        <div className='subscriptions-pricing__tiered-plans-table subscriptions-pricing__tiered-plans-table-robot'>
          <div className='subscriptions-pricing__tiered-plans-table-column subscriptions-pricing__tiered-plans-table-column--hide-icons'>
            {contentfulEntry.bundlePlanTableGroups?.map((tableGroupProps, index) => (
              <TableGroup key={index} {...tableGroupProps} desktop />
            ))}
          </div>
        </div>
      )}
      {showTable && (
        <div className='subscriptions-pricing__robot-bundle-footer-links'>
          <div></div>
          <a
            className='subscriptions-pricing__subscription-card-link'
            href={contentfulEntry.bundlePlanPricingCard.linkPrimaryUrl}
          >
            {contentfulEntry.bundlePlanPricingCard.linkPrimaryLabel}
          </a>
        </div>
      )}
    </div>
  )
}

export default RobotBundle
