import React from 'react'

interface Props {
  readonly opt_out_key: string
}

const OptOutMarketing: React.FunctionComponent<Props> = ({ opt_out_key }) => {
  const onConfirmClick = () => toastr.success('Unsubscribe successfully')

  return (
    <div className='opt-out-marketing'>
      <div className='opt-out-marketing-wrapper'>
        <img
          className='opt-out-marketing-logo'
          src='https://assets.vention.io/png/mailer/logo_vention.png'
        />
        <h2 className='opt-out-marketing-heading'>Confirm Unsubscribe</h2>
        <p className='opt-out-marketing-text'>Are you sure you would like to unsubscribe?</p>
        <div className='opt-out-marketing-btn-wrapper'>
          <a
            className='opt-out-marketing-confirm-btn button button-vention'
            href={`/opt_out_of_marketing_email/${opt_out_key}`}
            onClick={onConfirmClick}
          >
            Confirm
          </a>
          <a className='opt-out-marketing-cancel-link' href='/'>
            Cancel
          </a>
        </div>
      </div>
    </div>
  )
}

export default OptOutMarketing
