import React from 'react'
import TableGroup, { TableGroupProps } from './TableGroup'
import { currencyCb, currencyString } from '../../../../../components/common/Currency'
import { CurrencyExchangeRate } from '../Page'
import StartBuildingButton from '../../../../../components/assembly_card/StartBuildingButton'
import { searchAndConvertPricesToCurrency } from '../../../../../helpers/StringHelper'

interface Props {
  subscriptionType: 'freemium' | 'pro' | 'business' | 'robot'
  supertitle: string
  pill?: string
  usdBasePrice: number
  trailingPriceText: string
  description: string
  linkPrimaryLabel: string
  linkPrimaryUrl?: string
  linkPrimaryToNewDesign?: boolean
  linkSecondaryLabel: string
  linkSecondaryAlternateLabel: string
  tableGroupsProps: TableGroupProps[]
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
  showTable: boolean
  currencyExchangeRate: CurrencyExchangeRate
}

const SubscriptionCard: React.FC<Props> = ({
  subscriptionType,
  supertitle,
  pill,
  usdBasePrice,
  trailingPriceText,
  description,
  linkPrimaryLabel,
  linkPrimaryUrl,
  linkPrimaryToNewDesign,
  linkSecondaryLabel,
  linkSecondaryAlternateLabel,
  tableGroupsProps,
  setShowTable,
  showTable,
  currencyExchangeRate,
}) => {
  return (
    <div className='subscriptions-pricing__subscription-card'>
      <div>
        <div className='subscriptions-pricing__subscription-card-supertitle-wrapper'>
          <p className='subscriptions-pricing__subscription-card-supertitle'>{supertitle}</p>
          {!!pill && <p className='subscriptions-pricing__pill'>{pill}</p>}
        </div>
        <hr className='subscriptions-pricing__subscription-card-rule' />
        <div className='subscriptions-pricing__subscription-card-price-wrapper'>
          <p className='subscriptions-pricing__subscription-card-price'>
            {currencyString(
              currencyCb(usdBasePrice * currencyExchangeRate.rate, { precision: 0 }),
              currencyExchangeRate.currency_symbol
            )}
          </p>
          <p className='subscriptions-pricing__subscription-card-price-per'>{trailingPriceText}</p>
        </div>
        <p className='subscriptions-pricing__subscription-card-description'>{searchAndConvertPricesToCurrency(description, currencyExchangeRate)}</p>
      </div>
      <div>
        <div className='subscriptions-pricing__subscription-card-buttons'>
          {linkPrimaryToNewDesign ? (
            <StartBuildingButton
              label={linkPrimaryLabel}
              className='subscriptions-pricing__subscription-card-link'
            />
          ) : (
            <a className='subscriptions-pricing__subscription-card-link' href={linkPrimaryUrl}>
              {linkPrimaryLabel}
            </a>
          )}
          <button
            className='subscriptions-pricing__subscription-card-button'
            onClick={() => setShowTable(!showTable)}
          >
            {showTable ? linkSecondaryAlternateLabel : linkSecondaryLabel}
          </button>
        </div>
        {showTable && (
          <div className='subscriptions-pricing__subscription-card-table'>
            {tableGroupsProps?.map((tableGroupProps, index) => (
              <TableGroup key={index} subscriptionType={subscriptionType} {...tableGroupProps} />
            ))}
          </div>
        )}
        {showTable && (
          <div className='subscriptions-pricing__subscription-card-buttons'>
            {linkPrimaryToNewDesign ? (
              <StartBuildingButton
                label={linkPrimaryLabel}
                className='subscriptions-pricing__subscription-card-footer-link'
              />
            ) : (
              <a
                className='subscriptions-pricing__subscription-card-footer-link'
                href={linkPrimaryUrl}
              >
                {linkPrimaryLabel}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SubscriptionCard
