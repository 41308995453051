import React from 'react'
import Filter from '../../components/common/Filter'

interface Props {
  readonly handleChangeCategory: (newCategory) => void
  readonly activeCategory: string | undefined
}

const BlogFilters: React.FC<Props> = ({ handleChangeCategory, activeCategory }) => {
  const filterOptions = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Hardware',
      value: 'hardware',
    },
    {
      label: 'Applications',
      value: 'applications',
    },
    {
      label: 'Automation',
      value: 'automation',
    },
    {
      label: 'Partners',
      value: 'partners',
    },
    {
      label: 'Design Tips',
      value: 'design-tips',
    },
    {
      label: 'Industry',
      value: 'industry',
    },
    {
      label: 'Kickstarting Your Automation Journey',
      value: 'automation-journey'
    }, 
    {
      label: 'Demo Day 2024 Announcements',
      value: 'demo-day-2024-announcements'
    }
  ]
  return (
    <div className='blog-filters'>
      <Filter
        filterOptions={filterOptions}
        label='Filter Topic'
        handleChange={handleChangeCategory}
        value={activeCategory}
      />
    </div>
  )
}

export default BlogFilters
