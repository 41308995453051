import React, { useState } from 'react'
import { Partner } from '../../api/Designs/Types'

interface Props {
  readonly partnerList: ReadonlyArray<Partner>
  readonly isLoading: boolean
  readonly handleClick: Function
  readonly showPartnerFilter: boolean
  readonly clearPartnerPartFilter: () => void
}

const DesignBrowserPartnerPartFilter: React.FunctionComponent<Props> = ({
  partnerList,
  isLoading,
  handleClick,
  showPartnerFilter,
  clearPartnerPartFilter,
}) => {
  const [togglePartnerFilter, setTogglePartnerFilter] = useState(showPartnerFilter)

  const toggleFilterOpen = () => setTogglePartnerFilter(!togglePartnerFilter)

  const showClear = partnerList.some(partner => partner.selected)

  const content = (partner, index) => (
    <li className='tag-nav-list-item' key={index}>
      <div className='checkbox'>
        <input
          type='checkbox'
          className='checkbox'
          name={partner.name}
          id={partner.name}
          value={partner.name}
          checked={partner.selected}
          onChange={() => handleClick(partner.name)}
        />
        <label className='checkbox-label' htmlFor={partner.name}>
          <span>{partner.name}</span>
        </label>
      </div>
    </li>
  )

  return (
    <div className='partner-part-filter design-browser-filter'>
      <button
        className={`design-browser-filter-header ${togglePartnerFilter ? 'open' : ''}`}
        onClick={() => toggleFilterOpen()}
      >
        <h5 className='design-browser-filter-title'>Partners</h5>
        <span className='design-filter-chevron'></span>
      </button>
      <div className={`filter-content ${togglePartnerFilter ? 'open' : ''}`}>
        {showClear && (
          <button onClick={() => clearPartnerPartFilter()} className='clear-filter'>
            Clear
          </button>
        )}
        {!isLoading && partnerList && <div>{partnerList.map(content)}</div>}
      </div>
    </div>
  )
}

export default DesignBrowserPartnerPartFilter
