import React from 'react'
import SoftwareFeature from './SoftwareFeature'
import SectionLead from '../../../../shared_components/SectionLead'

interface Props {
  contentfulEntry: any
}

const Software: React.FC<Props> = ({ contentfulEntry }) => {
  return (
    <section className='master-palletizer-landing-page__software section--spacing-9xl container-lg'>
      <SectionLead align='center' maxWidth='1000px' subHeading {...contentfulEntry.softwareSectionLead} />
      <div className='master-palletizer-landing-page__software-features'>
        {contentfulEntry.softwareSectionFeatures.map((featureProps, index) => (
          <SoftwareFeature key={index} layoutReverse={Math.abs(index % 2) == 1} {...featureProps} />
        ))}
      </div>
    </section>
  )
}

export default Software
