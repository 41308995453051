import React from 'react'
import DesignBrowserPartnerPartFilter from '../designs/DesignBrowserPartnerPartFilter'
import DesignBrowserRangeFilter from '../designs/DesignBrowserRangeFilter'
import { Partner } from '../../api/Designs/Types'

interface Props {
  readonly partnerList: ReadonlyArray<Partner>
  readonly isPartnerListLoading: boolean
  readonly handlePartnerSelect?: Function
  readonly handleCustomPriceRange: (min: number, max: number) => void
  readonly handleCustomAssemblyTimeRange: (min: number, max: number) => void
  readonly handlePriceRange: (value: number) => void
  readonly handleAssemblyTimeRange: (value: number) => void
  readonly customPriceApplied: boolean
  readonly customAssemblyTimeApplied: boolean
  readonly setPartnerHandler: Function
  readonly showPartnerFilter: boolean
  readonly showPriceFilter: boolean
  readonly showAssemblyTimeFilter: boolean
  readonly clearPriceFilter: () => void
  readonly clearAssemblyTimeFilter: () => void
  readonly clearPartnerPartFilter: () => void
  readonly priceFilterCleared: boolean
  readonly assemblyTimeFilterCleared: boolean
}
const DesignBrowserSideNavFilters: React.FunctionComponent<Props> = ({
  partnerList,
  isPartnerListLoading,
  handlePartnerSelect,
  handleCustomPriceRange,
  handleCustomAssemblyTimeRange,
  handlePriceRange,
  handleAssemblyTimeRange,
  customPriceApplied,
  customAssemblyTimeApplied,
  setPartnerHandler,
  showPartnerFilter,
  showPriceFilter,
  showAssemblyTimeFilter,
  clearPriceFilter,
  clearAssemblyTimeFilter,
  clearPartnerPartFilter,
  priceFilterCleared,
  assemblyTimeFilterCleared,
}) => {
  return (
    <div className='side-nav-filters'>
      {partnerList && !!partnerList.length && !isPartnerListLoading && (
        <DesignBrowserPartnerPartFilter
          isLoading={isPartnerListLoading}
          partnerList={partnerList}
          handleClick={name => setPartnerHandler(name)(handlePartnerSelect)}
          showPartnerFilter={showPartnerFilter}
          clearPartnerPartFilter={clearPartnerPartFilter}
        />
      )}
      <DesignBrowserRangeFilter
        handleCustomPriceRange={handleCustomPriceRange}
        handleCustomAssemblyTimeRange={handleCustomAssemblyTimeRange}
        handlePriceRange={handlePriceRange}
        handleAssemblyTimeRange={handleAssemblyTimeRange}
        customPriceApplied={customPriceApplied}
        customAssemblyTimeApplied={customAssemblyTimeApplied}
        showPriceFilter={showPriceFilter}
        showAssemblyTimeFilter={showAssemblyTimeFilter}
        clearPriceFilter={clearPriceFilter}
        clearAssemblyTimeFilter={clearAssemblyTimeFilter}
        priceFilterCleared={priceFilterCleared}
        assemblyTimeFilterCleared={assemblyTimeFilterCleared}
      />
    </div>
  )
}

export default DesignBrowserSideNavFilters
