import React, { useLayoutEffect, useRef, useState } from 'react'
import moment from 'moment'
import { BlogPost } from '../../../api/Blog/Types'
import { BlogTags } from './BlogTags'
import classNames from 'classnames'

type EditorClickDestination = 'editor-page' | 'posts-by-editor'

interface Props {
  post: BlogPost
  featured?: boolean
  showThumbnail?: boolean
  anchorTitle?: boolean
  editorClickDestination?: EditorClickDestination
  handleClickEditor?: (post: BlogPost) => void
  lazyLoadImage?: boolean
  className?: string
  includeRevealableBlurb?: boolean
  isHomepage?: boolean
  postNumber?: number
}

export const BlogTile: React.FC<Props> = ({
  post,
  featured,
  showThumbnail = true,
  anchorTitle = true,
  editorClickDestination = 'posts-by-editor',
  handleClickEditor = () => {},
  lazyLoadImage = false,
  className,
  includeRevealableBlurb = true,
  isHomepage,
  postNumber,
}) => {
  const tileRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | undefined>(undefined)
  // If no description on homepage set tile height based on content
  const shouldSetContentHeight = isHomepage && !post.description
  const style = includeRevealableBlurb
    ? { height: shouldSetContentHeight ? 'max-content' : `${height}px` }
    : {}

  const updateHeight = () => {
    setHeight(tileRef.current?.clientHeight)
  }

  const date = moment(post.author_created).format('MMMM D, YYYY')
  const editorLinkDestination = (): string => {
    switch (editorClickDestination) {
      case 'editor-page':
        return `/users/${post.blog_editor.id}`

      case 'posts-by-editor':
      default:
        return `/blogs?editor=${post.editor}`
    }
  }

  // To keep total height constant during effect of reducing thumbnail height
  useLayoutEffect(() => {
    updateHeight()
  }, [])

  return (
    <div className={classNames('blog-tile', className)} style={style} ref={tileRef}>
      {showThumbnail && (
        <div className='blog-tile-thumbnail'>
          <a href={post.categorized_path}>
            <img
              src={featured ? post.title_image : post.thumbnail_title_image}
              alt={post.title}
              loading={lazyLoadImage ? 'lazy' : undefined}
            />
          </a>
        </div>
      )}
      <div className='blog-tile-body'>
        {!isHomepage && <BlogTags className='blog-tile-tags' tags={post.tag_list} />}
        <div className='blog-tile-title'>
          {isHomepage && postNumber && (
            <div className='blog-title-title-number'>{postNumber.toString().padStart(2, '0')}</div>
          )}
          {anchorTitle ? (
            <a data-analytic-id='home-blog-title-link' href={post.categorized_path}>
              {post.title}
            </a>
          ) : (
            <h1>{post.title}</h1>
          )}
        </div>
        {!isHomepage && (
          <div className='blog-tile-details'>
            <span className='blog-tile-details-date'>{date}</span>
            <span className='blog-tile-details-separator'>|</span>
            <a
              className='blog-tile-details-author'
              href={editorLinkDestination()}
              onClick={() => handleClickEditor(post)}
            >
              {post.editor_name}
            </a>
          </div>
        )}
      </div>
      {includeRevealableBlurb && (
        <div>
          <div className='blog-tile-blurb'>{post.description}</div>{' '}
          {isHomepage && (
            <div className='blog-tile-homepage-details'>
              <span className='blog-tile-details-date'>{date}</span>
              <a
                className='blog-tile-details-author'
                href={editorLinkDestination()}
                onClick={() => handleClickEditor(post)}
              >
                {post.editor_name}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
