import React from 'react'
import BlogPostCard from '../../../../../../../components/vention_ui/marketing_website_components/BlogPostCard'
import SectionLead from '../../../../shared_components/SectionLead'
import { CaseStudy } from '../../../../../../../components/cms/api/Types'

interface Props {
  contentfulEntry: any
  caseStudies: CaseStudy[]
}

const CustomerStories: React.FC<Props> = ({ contentfulEntry, caseStudies }) => {
  return (
    <section className='master-palletizer-landing-page__customer-stories section--spacing-9xl container-lg'>
      <SectionLead align='center' maxWidth='1000px' subHeading {...contentfulEntry.customerStoriesSectionLead} />
      <div className='master-palletizer-landing-page__customer-stories-cards'>
        {caseStudies.map((caseStudy, index) => (
          <BlogPostCard
            key={index}
            link={caseStudy.full_path!}
            image={caseStudy.title_image}
            supertitle={caseStudy.company}
            title={caseStudy.title}
            description={caseStudy.subtitle}
          />
        ))}
      </div>
    </section>
  )
}

export default CustomerStories
