import React from 'react'
import { ResourceDocObject } from '../../../../../components/cms/api/Types'
import LandingPageHero from '../../../../../components/cms/landing_pages/sections/LandingPageHero'
import LandingPageTable from '../../../../../components/cms/landing_pages/sections/LandingPageTable'
import LandingPageInfoGraphic from '../../../../../components/cms/landing_pages/sections/LandingPageInfoGraphic'
import LandingPageMultiTile from '../../../../../components/cms/landing_pages/sections/LandingPageMultiTile'
import LandingPageResource from '../../../../../components/cms/landing_pages/sections/LandingPageResource'
import LandingPageStandardStartBuilding from '../../../../../components/cms/landing_pages/sections/LandingPageStandardStartBuilding'
import I18n from '../../../../../helpers/I18n'

interface Props {
  resourcesByUuid: Record<string, ResourceDocObject>
}

const Page: React.FC<Props> = ({ resourcesByUuid }) => {
  return (
    <div className='modular-hardware'>
      <LandingPageHero
        type='hero'
        pageTypeFlag={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.hero.page_type_flag'
        )}
        title={I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.hero.title')}
        subtitle={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.hero.subtitle'
        )}
        sectionCTA={{
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.modular_hardware.hero.section_cta'
          ),
          color: 'secondary',
        }}
        imageLayout='full'
        style={{
          backgroundImage:
            'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/866/original/hardware-banner-updated-optimized.jpg?1655944072',
          textColor: 'dark',
        }}
        resourcesByUuid={{}}
      />
      <LandingPageTable
        type='table'
        data={[
          I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.table.data_1').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.table.data_2').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.table.data_3').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.table.data_4').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.table.data_5').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.table.data_6').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
        ]}
        tableStyle={{
          layout: 'vertical',
          borderRow: true,
          borderColumn: false,
          columnWidths: [1, 1, 1, 1, 1, 1, 1],
        }}
        tableHeader={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.table.header'
        )}
        titleTextAlign='center'
        cta={{
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.modular_hardware.table.cta_label'
          ),
          url: 'https://vention.io/resources/guides/t-slot-aluminum-extrusion-structure-design-guide-77',
        }}
      />
      <LandingPageInfoGraphic
        type='infoGraphic'
        imageUrl='https://s3.amazonaws.com/ventioncms/vention_images/images/000/008/416/original/extrusion-infographic.jpg?1703023718'
        alt={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_1.image_alt'
        )}
        title={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_1.title'
        )}
        subtitle=''
        textAlign='center'
        cta={{
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_1.cta_label'
          ),
          url: 'https://vention.io/resources/guides/t-slot-aluminum-extrusion-structure-design-guide-77',
        }}
        description=''
        style={{
          backgroundColor: 'vention-blue',
          paddingBottom: '5rem',
        }}
      />
      <LandingPageInfoGraphic
        type='infoGraphic'
        title={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_2.title'
        )}
        subtitle={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_2.subtitle'
        )}
        imageUrl='https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/882/original/updated-extrusion-chart-en.jpg?1656434336'
        cta={{
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_2.cta_label'
          ),
          url: 'https://vention.io/tools/calculators/deflection',
        }}
        style={{
          backgroundColor: 'white',
        }}
      />
      <LandingPageInfoGraphic
        type='infoGraphic'
        title={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_3.title'
        )}
        subtitle={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_3.subtitle'
        )}
        imageUrl='https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/892/original/new-vibration-resistance-chart.jpg?1656628609'
        cta={{
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.modular_hardware.info_graphic_3.cta_label'
          ),
          url: 'https://vention.io/resources/guides/best-anti-vibration-products-for-fasteners-90',
        }}
        style={{
          backgroundColor: 'white',
        }}
      />
      <LandingPageMultiTile
        type='multiTile'
        title={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.title'
        )}
        subtitle=''
        imageBackgroundColor='white'
        countTiles={6}
        tiles={[
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/002/804/original/375x239-assembly-plates.png?1580429501',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.plates_and_connectors.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.plates_and_connectors.description'
            ),
            linkUrl: 'https://www.vention.io/parts-library/category/structural-and-framing',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.plates_and_connectors.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/002/805/original/375x239-panels.png?1580429502',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.custom_panels.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.custom_panels.description'
            ),
            linkUrl: 'https://www.vention.io/parts-library/category/panels-and-table-tops',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.custom_panels.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/002/808/original/375x239-tooling-plates-2.png?1580429723',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.tooling_and_fixtures.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.tooling_and_fixtures.description'
            ),
            linkUrl: 'https://www.vention.io/parts-library/category/fixtures-and-tooling',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.tooling_and_fixtures.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/917/original/frame-accessories-lde1.png?1657298666',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.frame_accessories.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.frame_accessories.description'
            ),
            linkUrl:
              'https://www.vention.io/parts-library/category/frame-accessories/leveling-feet-floor-mounts',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.frame_accessories.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/918/original/linear-motion-lde1.png?1657298667',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.linear_motion.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.linear_motion.description'
            ),
            linkUrl: 'https://www.vention.io/parts-library/category/linear-motion',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.linear_motion.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/919/original/safety-lde1.png?1657298668',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.safety.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.safety.description'
            ),
            linkUrl: 'https://www.vention.io/parts-library/category/safety-enclosure',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_1.safety.link_label'
            ),
          },
        ]}
        style={{
          backgroundColor: 'vention-grey',
        }}
      />
      <LandingPageMultiTile
        type='multiTile'
        title={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.title'
        )}
        subtitle=''
        imageBackgroundColor='white'
        countTiles={6}
        tiles={[
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/886/original/workstation-render-square-new.jpg?1656435229',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.workstations.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.workstations.description'
            ),
            linkUrl: 'https://vention.io/workstation',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.workstations.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/884/original/safety-enclosure-sqaure-new.jpg?1656435228',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.safety_enclosures.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.safety_enclosures.description'
            ),
            linkUrl: 'https://vention.io/safety-enclosure',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.safety_enclosures.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/885/original/shelves-racks-render-square-new.jpg?1656435229',
            titlePrefix: '',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.shelves_and_racks.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.shelves_and_racks.description'
            ),
            linkUrl: 'https://vention.io/shelving',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.multi_tile_2.shelves_and_racks.link_label'
            ),
          },
        ]}
        style={{
          backgroundColor: 'white',
        }}
      />
      <LandingPageResource
        type='resource'
        title={I18n.t('views.landing_pages.hardware_landing_pages.modular_hardware.resource.title')}
        uuids={['e1348f82-a430-41fc-b2cb-d9f85a150a08', 'aef358f0-20de-466c-9864-7ee0c893b212']}
        style={{
          backgroundColor: 'white',
        }}
        resourcesByUuid={resourcesByUuid}
      />
      <LandingPageStandardStartBuilding
        type='standardStartBuilding'
        title={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.start_building.title'
        )}
        description={I18n.t(
          'views.landing_pages.hardware_landing_pages.modular_hardware.start_building.description'
        )}
        buttons={{
          getStarted: {
            label: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.start_building.get_started_label'
            ),
          },
          contactUs: {
            label: I18n.t(
              'views.landing_pages.hardware_landing_pages.modular_hardware.start_building.contact_us_label'
            ),
          },
        }}
      />
    </div>
  )
}

export default Page
