import React, { SyntheticEvent } from 'react'
import { TeamPreview } from '../../api/Users/Types'

export interface TeamSelection {
  readonly teamId: null | string
  readonly active: boolean
}
interface Props {
  readonly existing_teams: ReadonlyArray<TeamPreview>
  readonly handleSelectTeam: (event: SyntheticEvent) => void
  readonly teamSelected: TeamSelection
  readonly handleJoinTeamSubmit: (event: SyntheticEvent) => void
  readonly selectedTeamError: boolean
  readonly companyIdFromInvitation?: number
  joiningTeam: boolean
}

const OnboardingJoinTeam: React.FunctionComponent<Props> = ({
  existing_teams,
  handleSelectTeam,
  teamSelected,
  handleJoinTeamSubmit,
  selectedTeamError,
  companyIdFromInvitation,
  joiningTeam,
}) => {
  const showTeamLogo = (teamLogoUrl: string | null | undefined) => {
    return teamLogoUrl ? (
      <img src={teamLogoUrl} alt='Team logo' />
    ) : (
      <i className='fal fa-user-friends fa-2x'></i>
    )
  }

  const isTeamSelected = (team: TeamPreview): boolean => {
    const isCompanyFromInvitation = companyIdFromInvitation === team.id
    const isTeamSelectedByUser =
      !!teamSelected.teamId &&
      parseInt(teamSelected.teamId) === team.id &&
      teamSelected.active === true

    return isCompanyFromInvitation || isTeamSelectedByUser
  }

  const showButton = (team: TeamPreview) =>
    isTeamSelected(team) ? (
      <button
        className={'button button-md clear-button'}
        onClick={handleSelectTeam}
        data-team-id={team.id}
        disabled={!!companyIdFromInvitation}
      >
        Clear
      </button>
    ) : (
      <button
        className='button button-vention button-md team-join-button'
        onClick={handleSelectTeam}
        data-team-id={team.id}
        data-test='join-team'
      >
        Join
      </button>
    )

  const showCheckIcon = (team: TeamPreview) =>
    isTeamSelected(team) && <i className='fas fa-check'></i>

  const showTeamRow = () => {
    return existing_teams.map((team, id) => {
      return (
        <div key={team.id} className='team-row'>
          <div className='team-row-image'>{showTeamLogo(team.logo?.url)}</div>
          <div className='team-row-name-members-wrapper'>
            <h4 className='team-row-name'>{team.name}</h4>
            <p className='team-row-members'>{team.public_users.length} members</p>
          </div>
          <div className='team-row-button-wrapper'>
            {showButton(team)}
            {showCheckIcon(team)}
          </div>
        </div>
      )
    })
  }

  return (
    <div className='join-team-form'>
      <h2 className='profile-form-heading make-team-heading'>Which team would you like to join?</h2>
      <div className='teams-wrapper'>
        {existing_teams.length < 1 &&
          'Sorry there are no existing teams associated with your email address'}
        {showTeamRow()}
      </div>
      {selectedTeamError && (
        <p className='error-join-team-text'>Please select a team</p>
      )}
      <div className='skip-btn-wrapper'>
        <button
          className='button button-success button-md next-btn'
          disabled={joiningTeam}
          onClick={handleJoinTeamSubmit}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default OnboardingJoinTeam
