import React, { useRef, useState, FormEvent } from 'react'
import NumberFormat from 'react-number-format'
import LabelValueView from '../../../components/common/display/LabelValueView'
import LabelValueViewContainer from '../../../components/common/display/LabelValueViewContainer'
import round from 'lodash/round'
import DeflectionCalculatorExtrusionOptionCard from './DeflectionCalculatorExtrusionOptionCard'
import I18n from '../../../helpers/I18n'

interface Extrusion {
  height: number
  image: string
  label: string
  value: string
  width: number
}

interface ExtrusionConfiguration {
  description: string
  image: string
  label: string
  value: string
}

interface ExtrusionConfigurationMap {
  [configurationValue: string]: ExtrusionConfiguration[]
}

const STANDARD_DUTY_EXTRUSIONS: Extrusion[] = [
  {
    height: 20,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-008-XXXX.png',
    label: I18n.t('views.deflection_calculator.standard_duty.round'),
    value: 'round',
    width: 20,
  },
  {
    height: 45,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-001-XXXX.png',
    label: '45x45mm Extrusion',
    value: '45x45',
    width: 45,
  },
  {
    height: 45,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-002-XXXX.png',
    label: '45x90mm Extrusion',
    value: '45x90',
    width: 90,
  },
  {
    height: 90,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-005-XXXX.png',
    label: '90x90mm Extrusion',
    value: '90x90',
    width: 90,
  },
  {
    height: 22.5,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-009-XXXX.png',
    label: '180x22.5mm Extrusion',
    value: '22.5x180',
    width: 180,
  },
  {
    height: 247.5,
    image: 'https://ventionblobs.s3.amazonaws.com/parts/png/ST-EXT-011-XXXX.png',
    label: '247.5x247.5mm Extrusion',
    value: '247.5x247.5',
    width: 247.5,
  },
]

const LIGHT_DUTY_EXTRUSIONS: Extrusion[] = [
  {
    height: 22.5,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-006-XXXX.png',
    label: '22.5x22.5mm Extrusion',
    value: '22.5x22.5',
    width: 22.5,
  },
  {
    height: 45,
    image:
      'https://assets.vention.io/page-assets/calculators/light-duty-extrusion-45x45.png',
    label: '45x45mm Extrusion',
    value: '45x45 light',
    width: 45,
  },
]

const EXTRUSIONS_ORIENTATIONS: ExtrusionConfigurationMap = {
  '45x90': [
    {
      label: 'X',
      description: I18n.t('views.deflection_calculator.orientations.X'),
      image:
        'https://assets.vention.io/page-assets/calculators/extrusion-orientation-x.jpg',
      value: '45x90X',
    },
    {
      label: 'Y',
      description: I18n.t('views.deflection_calculator.orientations.Y'),
      image:
        'https://assets.vention.io/page-assets/calculators/extrusion-orientation-y.jpg',
      value: '45x90Y',
    },
  ],
  '22.5x180': [
    {
      label: 'X',
      description: I18n.t('views.deflection_calculator.orientations.X'),
      image:
        'https://assets.vention.io/page-assets/calculators/extrusion-orientation-x.jpg',
      value: '22.5x180X',
    },
    {
      label: 'Y',
      description: I18n.t('views.deflection_calculator.orientations.Y'),
      image:
        'https://assets.vention.io/page-assets/calculators/extrusion-orientation-y.jpg',
      value: '22.5x180Y',
    },
  ],
}

interface ExtrusionConstants {
  areaMomentInertia: number
  crossSectionArea: number
  crossSectionMidPoint: number
  torsionalConstant: number
  weightPer45mm: number
  yieldStrength: number
  youngModulusE: number
}

interface ExtrusionConstantsMap {
  [key: string]: ExtrusionConstants
}

const EXTRUSION_CONSTANTS: ExtrusionConstantsMap = {
  '22.5x22.5': {
    areaMomentInertia: 8885,
    crossSectionArea: 165.89,
    crossSectionMidPoint: 11.25,
    torsionalConstant: 2043,
    weightPer45mm: 20.1,
    yieldStrength: 140,
    youngModulusE: 68900,
  },
  '45x45 light': {
    areaMomentInertia: 103180,
    crossSectionArea: 761.48,
    crossSectionMidPoint: 22.5,
    torsionalConstant: 31851,
    weightPer45mm: 26.33,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  '45x45': {
    areaMomentInertia: 162325,
    crossSectionArea: 761.48,
    crossSectionMidPoint: 22.5,
    torsionalConstant: 31851,
    weightPer45mm: 92.5,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  '45x90Y': {
    areaMomentInertia: 287967,
    crossSectionArea: 1275.43,
    crossSectionMidPoint: 22.5,
    torsionalConstant: 189708,
    weightPer45mm: 149.4,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  '45x90X': {
    areaMomentInertia: 1111757,
    crossSectionArea: 1275.43,
    crossSectionMidPoint: 45,
    torsionalConstant: 189708,
    weightPer45mm: 149.4,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  '90x90': {
    areaMomentInertia: 1850913,
    crossSectionArea: 1867.08,
    crossSectionMidPoint: 45,
    torsionalConstant: 941414,
    weightPer45mm: 228.6,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  '22.5x180Y': {
    areaMomentInertia: 77150,
    crossSectionArea: 1340.9,
    crossSectionMidPoint: 11.25,
    torsionalConstant: 52788,
    weightPer45mm: 162.9,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  '22.5x180X': {
    areaMomentInertia: 3783293,
    crossSectionArea: 1340.9,
    crossSectionMidPoint: 90,
    torsionalConstant: 52788,
    weightPer45mm: 162.9,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  round: {
    areaMomentInertia: 6470,
    crossSectionArea: 208.47,
    crossSectionMidPoint: 10,
    torsionalConstant: 12939,
    weightPer45mm: 12939,
    yieldStrength: 240,
    youngModulusE: 68900,
  },
  '247.5x247.5': {
    areaMomentInertia: 79886313.67,
    crossSectionArea: 9132.27,
    crossSectionMidPoint: 123.75,
    torsionalConstant: 0,
    weightPer45mm: 1108.29,
    yieldStrength: 145,
    youngModulusE: 68900,
  },
}

enum ExtrusionScenarioType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

interface ExtrusionScenario {
  label: string
  description: string
  value: ExtrusionScenarioType
  image: string
}

const EXTRUSION_SCENARIOS: ExtrusionScenario[] = [
  {
    label: I18n.t('views.deflection_calculator.scenarios.a.label'),
    description: I18n.t('views.deflection_calculator.scenarios.a.description'),
    value: ExtrusionScenarioType.A,
    image: 'https://assets.vention.io/page-assets/calculators/Bending_Scenario_A.jpg',
  },
  {
    label: I18n.t('views.deflection_calculator.scenarios.b.label'),
    description: I18n.t('views.deflection_calculator.scenarios.b.description'),
    value: ExtrusionScenarioType.B,
    image: 'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/Bending_Scenario_B.jpg',
  },
  {
    label: I18n.t('views.deflection_calculator.scenarios.c.label'),
    description: I18n.t('views.deflection_calculator.scenarios.c.description'),
    value: ExtrusionScenarioType.C,
    image: 'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/Bending_Scenario_C.jpg',
  },
  {
    label: I18n.t('views.deflection_calculator.scenarios.d.label'),
    description: I18n.t('views.deflection_calculator.scenarios.d.description'),
    value: ExtrusionScenarioType.D,
    image: 'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/Bending_Scenario_D.jpg',
  },
]

const maximumBendingStressFormulaImages = {
  [ExtrusionScenarioType.A]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/stress-formula-scenario-a.png',
  [ExtrusionScenarioType.B]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/stress-formula-scenario-b.png',
  [ExtrusionScenarioType.C]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/stress-formula-scenario-c.png',
  [ExtrusionScenarioType.D]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/stress-formula-scenario-d.png',
}

const maximumDeflectionFormulaImages = {
  [ExtrusionScenarioType.A]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/deflection-formula-scenario-a.png',
  [ExtrusionScenarioType.B]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/deflection-formula-scenario-b.png',
  [ExtrusionScenarioType.C]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/deflection-formula-scenario-c.png',
  [ExtrusionScenarioType.D]:
    'https://s3.amazonaws.com/ventionblobs/page-assets/calculators/deflection-formula-scenario-d.png',
}

const maximumBendingStressCalculationFunctions = {
  [ExtrusionScenarioType.A]: (L: number, F: number, c: number, I: number) => (F * L * c) / (8 * I),
  [ExtrusionScenarioType.B]: (L: number, F: number, c: number, I: number) => (F * L * c) / (24 * I),
  [ExtrusionScenarioType.C]: (L: number, F: number, c: number, I: number) => (F * L * c) / I,
  [ExtrusionScenarioType.D]: (L: number, F: number, c: number, I: number) => (F * L * c) / (2 * I),
}

function calculateMaximumBendingStress(
  variant: ExtrusionScenarioType,
  L: number,
  F: number,
  c: number,
  I: number
) {
  return maximumBendingStressCalculationFunctions[variant](L, F, c, I)
}

const maximumDeflectionCalculationFunctions = {
  [ExtrusionScenarioType.A]: (L: number, F: number, E: number, I: number) =>
    (L * L * L * F) / (192 * E * I),
  [ExtrusionScenarioType.B]: (L: number, F: number, E: number, I: number) =>
    (L * L * L * F) / (384 * E * I),
  [ExtrusionScenarioType.C]: (L: number, F: number, E: number, I: number) =>
    (L * L * L * F) / (3 * E * I),
  [ExtrusionScenarioType.D]: (L: number, F: number, E: number, I: number) =>
    (L * L * L * F) / (8 * E * I),
}

function calculateMaximumDeflection(
  variant: ExtrusionScenarioType,
  L: number,
  F: number,
  E: number,
  I: number
) {
  return maximumDeflectionCalculationFunctions[variant](L, F, E, I)
}

function calculateStressSafetyFactor(maxStress: number, yieldStress: number) {
  return yieldStress / maxStress
}

const oneKgForceInNewton = 9.80665

interface DeflectionCalculatorResult {
  safetyFactor: number
  maxDeflection: number
  maxBendStress: number
  locationOfMaximumDeflection: string
}

const SafetyFactorMessage = (props: {
  extrusionScenarioType: ExtrusionScenarioType | undefined
  deflectionCalculatorResult: DeflectionCalculatorResult | undefined
}) => {
  if (props.extrusionScenarioType === undefined || props.deflectionCalculatorResult === undefined) {
    return null
  }

  let message = I18n.t('views.deflection_calculator.results.success_message')
  let status = 'success'
  let icon = <i className='fas fa-check-circle' />

  if (props.deflectionCalculatorResult.safetyFactor < 1) {
    message = I18n.t('views.deflection_calculator.results.warning_message')
    status = 'warning'
    icon = <i className='fas fa-exclamation-triangle' style={{ color: '#d9534f' }} />
  }

  if (
    props.deflectionCalculatorResult.safetyFactor >= 1 &&
    props.deflectionCalculatorResult.safetyFactor < 2
  ) {
    message = I18n.t('views.deflection_calculator.results.caution_message')
    status = 'caution'
    icon = <i className='fas fa-exclamation-triangle' style={{ color: '#f4b73a' }} />
  }

  return (
    <>
      <div className='safety-factory-message'>
        {icon}
        <h3 className='results-text'>{I18n.t('views.deflection_calculator.results.label')}</h3>
        <div className={`${status}-text message`}>{message}</div>
        <LabelValueViewContainer>
          <LabelValueView
            label='Deflection'
            value={`${round(props.deflectionCalculatorResult.maxDeflection, 4)}mm`}
          />
          <LabelValueView
            label='Stress'
            value={`${round(props.deflectionCalculatorResult.maxBendStress, 2)}MPa`}
          />
          <LabelValueView
            label='Location of max deflection'
            value={`${props.deflectionCalculatorResult.locationOfMaximumDeflection}`}
          />
          <LabelValueView
            label='Safety factor'
            value={`${round(props.deflectionCalculatorResult.safetyFactor, 2)}`}
          />
        </LabelValueViewContainer>
        <div>
          {I18n.t('views.deflection_calculator.results.caution_message')}{' '}
          <a
            href='https://vention.io/resources/guides/t-slot-aluminum-extrusion-structure-design-guide-77'
            target='_blank'
            rel='noopener noreferrer'
          >
            {I18n.t('views.deflection_calculator.results.here')}
          </a>
        </div>
      </div>
    </>
  )
}

export default function DeflectionCalculator() {
  const [deflectionCalculatorResult, setDeflectionCalculatorResult] = useState<
    DeflectionCalculatorResult | undefined
  >()
  const [extrusionLength, setExtrusionLength] = useState<number | undefined>()
  const [distanceFromNeutralAxis, setDistanceFromNeutralAxis] = useState<number | undefined>()
  const [forceKg, setForceKg] = useState<number | undefined>()

  const [extrusionOrientations, setExtrusionOrientations] = useState<
    ExtrusionConfiguration[] | undefined
  >()
  const [extrusionConstants, setExtrusionConstants] = useState<ExtrusionConstants | undefined>()
  const [extrusionScenario, setExtrusionScenario] = useState<ExtrusionScenarioType | undefined>()
  const configurationSectionRef = useRef<HTMLDivElement>(null)
  const inputsSectionRef = useRef<HTMLDivElement>(null)

  const [extrusionType, setExtrusionType] = useState('')
  const [extrusionConfiguration, setExtrusionConfiguration] = useState('')

  const scrollToConfigurationSection = () => {
    configurationSectionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const scrollToInputsSection = () => {
    inputsSectionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const extrusionTypeChangeHandler = (value: string) => {
    if (value) {
      const extrusionT = value as string
      setExtrusionType(extrusionT)
      const extrusionO = EXTRUSIONS_ORIENTATIONS[extrusionT]

      if (extrusionO) {
        setExtrusionOrientations(extrusionO)
        setExtrusionConstants(undefined)
      } else {
        setExtrusionConstants(EXTRUSION_CONSTANTS[extrusionT])
        setDistanceFromNeutralAxis(EXTRUSION_CONSTANTS[extrusionT].crossSectionMidPoint)
        setExtrusionOrientations(undefined)
      }

      setDeflectionCalculatorResult(undefined)
      scrollToConfigurationSection()
    }
  }

  const extrusionConfigurationChangeHandler = (value: string) => {
    if (value) {
      const extrusionC = value as string
      setExtrusionConfiguration(extrusionC)
      setExtrusionConstants(EXTRUSION_CONSTANTS[extrusionC])
      setDistanceFromNeutralAxis(EXTRUSION_CONSTANTS[extrusionC].crossSectionMidPoint)
      setDeflectionCalculatorResult(undefined)
    }
  }

  const extrusionScenarioChangeHandler = (value: string) => {
    if (value) {
      const extrusionS = value as ExtrusionScenarioType
      setExtrusionScenario(extrusionS)
      setDeflectionCalculatorResult(undefined)
      scrollToInputsSection()
    }
  }

  function getLocationOfMaximumDeflection() {
    switch (extrusionScenario) {
      case ExtrusionScenarioType.A:
      case ExtrusionScenarioType.B:
        return 'mid span'
      default:
        return 'unsupported beam end'
    }
  }

  const calculationHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const variant = extrusionScenario
    if (!variant) {
      toastr.error('Please select a configuration')
      scrollToConfigurationSection()
      return
    }
    const L = extrusionLength || 0
    const F = (forceKg || 0) * oneKgForceInNewton
    const E = extrusionConstants!.youngModulusE
    const I = extrusionConstants!.areaMomentInertia
    const c = extrusionConstants!.crossSectionMidPoint

    try {
      const maxDeflection = calculateMaximumDeflection(variant!, L, F, E, I)
      const maxBendStress = calculateMaximumBendingStress(variant!, L, F, c, I)
      const safetyFactor = calculateStressSafetyFactor(
        maxBendStress,
        extrusionConstants?.yieldStrength!
      )
      const locationOfMaximumDeflection = getLocationOfMaximumDeflection()

      if (isNaN(safetyFactor) || isNaN(maxBendStress)) {
        throw new Error('Calculation error.')
      }

      setDeflectionCalculatorResult({
        safetyFactor,
        maxDeflection,
        maxBendStress,
        locationOfMaximumDeflection,
      })
    } catch (error) {
      console.error(error)
      toastr.error(error.message)
    }
  }

  return (
    <>
      <div className='deflector-calculator'>
        <div className='calculator-container'>
          <h2 className='calculator-page-subtitle'>
            <div>{I18n.t('views.deflection_calculator.header')}</div>
          </h2>
          <form onSubmit={calculationHandler}>
            <h3 className='calculator-page-section-title'>
              {I18n.t('views.deflection_calculator.light_duty.label')}
            </h3>

            <div className='tools-calculator-options-list'>
              {LIGHT_DUTY_EXTRUSIONS.map(extrusion => (
                <DeflectionCalculatorExtrusionOptionCard
                  changeHandler={extrusionTypeChangeHandler}
                  imageSrc={extrusion.image}
                  key={`extrusion_${extrusion.value}`}
                  label={extrusion.label}
                  value={extrusion.value}
                  extrusionOption={extrusionType}
                />
              ))}
            </div>
            <h3 className='calculator-page-section-title'>
              {I18n.t('views.deflection_calculator.standard_duty.label')}
            </h3>

            <div className='tools-calculator-options-list'>
              {STANDARD_DUTY_EXTRUSIONS.map(extrusion => (
                <DeflectionCalculatorExtrusionOptionCard
                  changeHandler={extrusionTypeChangeHandler}
                  imageSrc={extrusion.image}
                  key={`extrusion_${extrusion.value}`}
                  label={extrusion.label}
                  value={extrusion.value}
                  extrusionOption={extrusionType}
                />
              ))}
            </div>

            {!!extrusionOrientations && (
              <>
                <h3 className='calculator-page-section-title'>
                  {I18n.t('views.deflection_calculator.orientations.label')}
                </h3>
                <div className='tools-calculator-options-list'>
                  {extrusionOrientations.map(orientation => (
                    <DeflectionCalculatorExtrusionOptionCard
                      changeHandler={extrusionConfigurationChangeHandler}
                      description={orientation.description}
                      imageSrc={orientation.image}
                      key={`orientation_${orientation.value}`}
                      label={orientation.label}
                      value={orientation.value}
                      extrusionOption={extrusionConfiguration}
                    />
                  ))}
                </div>
              </>
            )}
            <h3 className='calculator-page-section-title'>
              {I18n.t('views.deflection_calculator.scenarios.label')}
            </h3>
            <div className='tools-calculator-options-list' ref={configurationSectionRef}>
              {EXTRUSION_SCENARIOS.map(scenario => (
                <DeflectionCalculatorExtrusionOptionCard
                  changeHandler={extrusionScenarioChangeHandler}
                  description={scenario.description}
                  imageSrc={scenario.image}
                  key={`scenario_${scenario.value}`}
                  label={scenario.label}
                  value={scenario.value}
                  extrusionOption={extrusionScenario}
                />
              ))}
            </div>

            <div className='deflection-calculator-free-inputs' ref={inputsSectionRef}>
              <div>
                <h3 className='calculator-page-section-title'>
                  {I18n.t('views.deflection_calculator.form.extrusion_length')}
                </h3>
                <label>
                  <NumberFormat
                    allowLeadingZeros={false}
                    allowNegative={false}
                    allowEmptyFormatting={true}
                    decimalSeparator={false}
                    onValueChange={values => setExtrusionLength(values.floatValue)}
                    suffix='mm'
                    value={extrusionLength}
                    className='form-control'
                  />
                </label>
              </div>
              <div>
                <h3 className='calculator-page-section-title'>
                  {I18n.t('views.deflection_calculator.form.applied_load')}
                </h3>
                <label>
                  <NumberFormat
                    allowLeadingZeros={false}
                    allowNegative={false}
                    allowEmptyFormatting={true}
                    decimalScale={2}
                    onValueChange={values => setForceKg(values.floatValue)}
                    suffix='kg'
                    value={forceKg}
                    className='form-control'
                  />
                </label>
              </div>
            </div>

            <div className='calculator-controls'>
              <button
                className='button-success button-lg'
                type='submit'
                disabled={!extrusionConstants || !forceKg || !extrusionLength}
              >
                {I18n.t('views.deflection_calculator.form.submit')}
              </button>
            </div>
          </form>
        </div>
        <div className='safety-factor-container'>
          <SafetyFactorMessage
            extrusionScenarioType={extrusionScenario}
            deflectionCalculatorResult={deflectionCalculatorResult}
          />
        </div>
      </div>
    </>
  )
}
