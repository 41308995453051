import React from 'react'
import TieredPlans from './TieredPlans'
import RobotBundle from './RobotBundle'
import { ContentfulEntry, CurrencyExchangeRate } from '../Page'

interface Props {
  contentfulEntry: ContentfulEntry
  currencyExchangeRate: CurrencyExchangeRate
}

const Subscriptions: React.FC<Props> = ({ contentfulEntry, currencyExchangeRate }) => {
  return (
    <div className='subscriptions-pricing__subscriptions'>
      <TieredPlans contentfulEntry={contentfulEntry} currencyExchangeRate={currencyExchangeRate} />
      <hr className='subscriptions-pricing__rule' />
      <RobotBundle contentfulEntry={contentfulEntry} currencyExchangeRate={currencyExchangeRate} />
    </div>
  )
}

export default Subscriptions
