import React from 'react'

interface Props {
  label: string
  icon: string
}

const Pill: React.FC<Props> = ({ label, icon }) => {
  return (
    <div className='contact__pill'>
      <i className={`contact__pill-icon ${icon}`}></i>
      <p className='contact__pill-label'>{label}</p>
    </div>
  )
}

export default Pill
