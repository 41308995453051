import React from 'react'
import Pill from './Pill'
import I18n from '../../../../../helpers/I18n'
import Card from './Card'
import { LocalizedPaths } from '../../../../../types/Common'

interface Props {
  localizedPaths: LocalizedPaths
}

const Index: React.FC<Props> = ({ localizedPaths }) => {
  const CARDS_ROW_FIRST_DATA = [
    {
      icon: 'ti ti-device-imac',
      title: I18n.t('views.contacts.show.book_demo.title'),
      description: I18n.t('views.contacts.show.book_demo.description'),
      linkLabel: I18n.t('views.contacts.show.book_demo.link'),
      backgroundImage: true,
      button: true,
      linkPath: `${localizedPaths.new_contact}?choice=book+a+demo`,
    },
    {
      icon: 'ti ti-headphones',
      title: I18n.t('views.contacts.show.technical_support.title'),
      description: I18n.t('views.contacts.show.technical_support.description'),
      linkLabel: I18n.t('views.contacts.show.technical_support.link'),
      linkPath: `${localizedPaths.new_contact}?choice=technical+support`,
    },
    {
      icon: 'ti ti-help',
      title: I18n.t('views.contacts.show.general_inquiries.title'),
      description: I18n.t('views.contacts.show.general_inquiries.description'),
      linkLabel: I18n.t('views.contacts.show.general_inquiries.link'),
      linkPath: `${localizedPaths.new_contact}?choice=general+inquiries`,
    },
    {
      icon: 'ti ti-tool',
      title: I18n.t('views.contacts.show.tools.title'),
      description: I18n.t('views.contacts.show.tools.description'),
      linkLabel: I18n.t('views.contacts.show.tools.link'),
      linkPath: '/tools',
    },
    {
      icon: 'ti ti-book-2',
      title: I18n.t('views.contacts.show.resource_center.title'),
      description: I18n.t('views.contacts.show.resource_center.description'),
      linkLabel: I18n.t('views.contacts.show.resource_center.link'),
      linkPath: '/resources',
    },
    {
      icon: 'ti ti-users-group',
      title: I18n.t('views.contacts.show.community.title'),
      description: I18n.t('views.contacts.show.community.description'),
      linkLabel: I18n.t('views.contacts.show.community.link'),
      linkPath: 'https://forum.vention.io/',
    },
    {
      icon: 'ti ti-users',
      title: I18n.t('views.contacts.show.partner_network.title'),
      description: I18n.t('views.contacts.show.partner_network.description'),
      linkLabel: I18n.t('views.contacts.show.partner_network.link'),
      linkPath: '/vention-automation-partner',
    },
  ]

  const CARDS_ROW_THIRD_DATA = [
    {
      icon: 'ti ti-map-2',
      title: I18n.t('views.contacts.show.find_us.title'),
      description: I18n.t('views.contacts.show.find_us.description'),
      linkLabel: I18n.t('views.contacts.show.find_us.link'),
      linkPath: localizedPaths.experience_center,
    },
    {
      icon: 'ti ti-building-skyscraper',
      title: I18n.t('views.contacts.show.montreal.title'),
      description: I18n.t('views.contacts.show.montreal.description'),
      phoneText: I18n.t('views.contacts.show.montreal.phone_text'),
      linkLabel: '+1 (800) 940-3617',
      linkPath: 'tel:18009403617',
    },
    {
      icon: 'ti ti-building',
      title: I18n.t('views.contacts.show.berlin.title'),
      description: I18n.t('views.contacts.show.berlin.description'),
      phoneText: I18n.t('views.contacts.show.berlin.phone_text'),
      linkLabel: '+49 30 56795545',
      linkPath: 'tel:493056795545',
      footerLinkLabel: I18n.t('views.contacts.show.berlin.footer_link_label'),
      footerLinkPath: localizedPaths.impressum
    },
  ]

  return (
    <div className='contact__index'>
      <Pill label={I18n.t('views.contacts.show.response_time')} icon={'fas fa-bolt-lightning'} />
      <h1 className='contact__heading'>{I18n.t('views.contacts.show.questions')}</h1>
      <div className='contact__cards-row--first'>
        {CARDS_ROW_FIRST_DATA.map((data, index) => (
          <Card
            key={index}
            index={index}
            icon={data.icon}
            title={data.title}
            description={data.description}
            linkLabel={data.linkLabel}
            backgroundImage={data.backgroundImage}
            button={data.button}
            linkPath={data.linkPath}
          />
        ))}
      </div>
      <Pill label={I18n.t('views.contacts.show.hands_on_experience')} icon={'ti ti-hand-stop'} />
      <h1 className='contact__heading'>{I18n.t('views.contacts.show.doors_open')}</h1>
      <div className='contact__cards-row--second'>
        {CARDS_ROW_THIRD_DATA.map((data, index) => (
          <Card
            key={index}
            index={index}
            icon={data.icon}
            title={data.title}
            description={data.description}
            linkLabel={data.linkLabel}
            linkPath={data.linkPath}
            phoneText={data.phoneText}
            footerLinkLabel={data.footerLinkLabel}
            footerLinkPath={data.footerLinkPath}
          />
        ))}
      </div>
    </div>
  )
}

export default Index
