import React, { ChangeEvent, useEffect, useState } from 'react'
import InputValidator from '../../InputValidator'
import { ValidatorForm } from 'react-form-validator-core'
import UserService from '../../../../api/Users/Service'
import { DialCodeOption, dialCodeOptions, dialCodeOptionsByCountryCode } from './DialCodeOptions'
import { ValueType } from 'react-select'
import DialCodeDropdown from './DialCodeDropdown'
import LocalizedStrings from 'react-localization'
import I18n from '../../../../helpers/I18n'

interface Props {
  label?: string
  phone?: string
  onChange: (phone: string) => void
  required?: boolean
  placeholder?: string
  className?: string
  useCountryCode?: boolean
}

const defaultDialCodeOption = dialCodeOptionsByCountryCode['US']

const getDialCodeOptionFromDialCode = (phoneNumberWithDialCode: string) => {
  const dialCodeRegex = /^\+\d+/gm

  const regexResult = dialCodeRegex.exec(phoneNumberWithDialCode)
  if (regexResult) {
    return (
      dialCodeOptions.find(option => option.dialCode === regexResult[0]) || defaultDialCodeOption
    )
  }

  return defaultDialCodeOption
}

const extractPhoneNumber = (phoneNumberWithDialCode: string) => {
  const dialCodeRegex = /^\+\d+/gm

  return (phoneNumberWithDialCode || '').replace(dialCodeRegex, '').trim()
}

const localizedErrorMessages = new LocalizedStrings({
  en: {
    isPhoneNumberValid:
      'Please enter a valid phone number (it can only contain numbers, "(", ")" or "-")',
  },
  fr: {
    isPhoneNumberValid:
      'Veuillez entrer un numéro de téléphone valide (il ne peut contenir que des chiffres, "(", ")" ou "-")',
  },
  de: {
    isPhoneNumberValid:
      'Bitte geben Sie eine gültige Telefonnummer ein (sie darf nur Zahlen „(“, „)“ oder „-“ enthalten).',
  },
})

const PhoneCountryInput: React.FC<Props> = ({
  label,
  phone,
  onChange,
  required,
  placeholder,
  className,
  useCountryCode,
  children,
}) => {
  const [selectedDialCodeOption, setSelectedDialCodeOption] = useState(defaultDialCodeOption)
  const [phoneNumberWithoutDialCode, setPhoneNumberWithoutDialCode] = useState('')

  localizedErrorMessages.setLanguage(I18n.locale)

  useEffect(() => {
    ValidatorForm.addValidationRule('isPhoneNumberValid', phoneNumber => {
      return /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(phoneNumber)
    })
  }, [])

  const validators = [
    'isPhoneNumberValid',
    ...(required ? [] : []),
  ]
  const errorMessages = [
    localizedErrorMessages.isPhoneNumberValid,
    ...(required ? [] : []),
  ]

  useEffect(() => {
    UserService.checkCountryCode().then(userCountryCode => {
      const dialCodeOption = dialCodeOptionsByCountryCode[userCountryCode]
      if (dialCodeOption) {
        setSelectedDialCodeOption(dialCodeOption)
      }
    })
  }, [])

  useEffect(() => {
    if (phone) {
      setSelectedDialCodeOption(getDialCodeOptionFromDialCode(phone))
      setPhoneNumberWithoutDialCode(extractPhoneNumber(phone))
    }
  }, [])

  useEffect(() => {
    const dialCode = selectedDialCodeOption?.dialCode || ''
    onChange(`${dialCode} ${phoneNumberWithoutDialCode.trim()}`)
  }, [selectedDialCodeOption, phoneNumberWithoutDialCode])

  const onPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPhoneNumberWithoutDialCode(event.target.value)
  }

  const onSelectedCountryCodeChange = (option: ValueType<DialCodeOption>) => {
    setSelectedDialCodeOption(option as DialCodeOption)
  }

  return (
    <div className='phone-country-input'>
      <DialCodeDropdown
        selectedDialCodeOption={selectedDialCodeOption}
        onSelectedCountryCodeChange={onSelectedCountryCodeChange}
        useCountryCode={useCountryCode}
      />
      <InputValidator
        className={className}
        label={label}
        id='phone'
        name='phone'
        type='tel'
        placeholder={placeholder}
        value={phoneNumberWithoutDialCode}
        onChange={onPhoneNumberChange}
        validators={validators}
        errorMessages={errorMessages}
      >
        {children}
      </InputValidator>
    </div>
  )
}

export default PhoneCountryInput
