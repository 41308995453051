import React from 'react'
import classNames from 'classnames'

interface DeflectionCalculatorExtrusionOptionProps {
  changeHandler: (value: string) => void
  description?: string
  imageSrc: string
  label: string
  footer?: string
  value: string
  extrusionOption?: string
}

const DeflectionCalculatorExtrusionOptionCard: React.FC<DeflectionCalculatorExtrusionOptionProps> = ({
  changeHandler,
  description,
  imageSrc,
  label,
  footer,
  value,
  extrusionOption,
}) => {
  return (
    <div
      className={classNames('deflection-calculator-card-wrapper', {
        'selected-extrusion-option': extrusionOption === value,
        'with-description': description,
      })}
      onClick={() => changeHandler(value)}
    >
      <div className='option-image'>
        <img src={imageSrc} alt={label} title={label} />
      </div>
      <div className='option-text'>
        <div className='option-label'>{label}</div>
        <div className='option-label'>{footer}</div>
        <div className='option-description'>{description}</div>
      </div>
    </div>
  )
}

export default DeflectionCalculatorExtrusionOptionCard
