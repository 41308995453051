import React from 'react'
import { User } from '../../api/Users/Types'
import BookMeetingForm, { DefaultInputs } from '../../components/views/home/BookMeetingForm'
import { TalkToSupportInputs } from '../../components/views/home/TalkToSupportForm'
import CurrencyProvider, {
  CurrencyConsumer,
} from '../../components/common/providers/CurrencyProvider'
import Loading from '../../components/common/Loading'
import ExpertInfo from './ExpertInfo'
import DesignCategoriesProvider from '../../components/common/providers/DesignCategoriesProvider'
import classNames from 'classnames'
import TalkToSupportForm from '../../components/views/home/TalkToSupportForm'
import I18nProvider from '../../components/common/providers/I18nProvider'
import { LocalizedPaths } from '../../types/Common'
import I18n from '../../helpers/I18n'
import ClientList from '../../components/common/ClientList'

interface Props {
  readonly currentUser?: User
  readonly captchaKey: string
  readonly formType: string
  readonly localizedPaths: LocalizedPaths
  readonly talkToExpertSuccessMessage?: string
  withSocialProof?: boolean
}

const BookMeeting: React.FC<Props> = ({
  currentUser,
  captchaKey,
  formType,
  localizedPaths,
  talkToExpertSuccessMessage,
  withSocialProof,
}) => {
  function getExpertInfoProps() {
    const supportListItems = (I18n.t('home.talk_to_support.features.items') as unknown) as string[]
    const expertListItems = (I18n.t('home.book_meeting.features.items') as unknown) as string[]

    const tteProps = {
      headerText: I18n.t('home.book_meeting.features.title'),
      listItems: expertListItems,
      background: 'dark',
      expertImageUrl: 'https://assets.vention.io/page-assets/book-meeting/book-meeting.jpg',
    }
    const ttsProps = {
      headerText: I18n.t('home.talk_to_support.features.title'),
      listItems: supportListItems,
      background: 'light',
      expertImageUrl:
        'https://assets.vention.io/page-assets/contact-support/talk-to-support.jpg',
    }

    return formType === 'talk_to_expert' ? tteProps : ttsProps
  }

  const { headerText, listItems, background, expertImageUrl } = getExpertInfoProps()

  return (
    <I18nProvider localizedPaths={localizedPaths}>
      <div className={classNames('book-meeting-page-container', background)}>
        <div
          className={classNames('book-meeting-page', {
            'book-meeting-page-with-social-proof': withSocialProof,
          })}
        >
          <div className='book-meeting-page-form'>
            <DesignCategoriesProvider skipFetch>
              <CurrencyProvider skipCheckCountryCode={true}>
                <CurrencyConsumer>
                  {currencyContext =>
                    currencyContext.isLoaded ? (
                      formType === 'talk_to_support' ? (
                        <TalkToSupportForm
                          currentUser={currentUser}
                          captchaKey={captchaKey}
                          inputs={TalkToSupportInputs}
                        />
                      ) : (
                        <BookMeetingForm
                          currentUser={currentUser}
                          captchaKey={captchaKey}
                          inputs={DefaultInputs}
                          showUseCase={false}
                          successMessage={talkToExpertSuccessMessage}
                          submitText={withSocialProof ? 'Talk to our experts' : undefined}
                        >
                          {withSocialProof ? (
                            <h1 className='h2 contact-form-title'>
                              <span className='contact-form-title-bolder'>
                                Schedule time with an automation expert
                              </span>
                            </h1>
                          ) : (
                            <h1 className='h2 contact-form-title'>
                              {I18n.t('home.book_meeting.form.title.part_1')}{' '}
                              <span className='contact-form-title-bolder'>
                                {I18n.t('home.book_meeting.form.title.part_2')}
                              </span>
                            </h1>
                          )}
                        </BookMeetingForm>
                      )
                    ) : (
                      <>
                        <Loading />
                      </>
                    )
                  }
                </CurrencyConsumer>
              </CurrencyProvider>
            </DesignCategoriesProvider>
          </div>

          {withSocialProof ? (
            <div className={`expert-info-background background-dark`}>
              <div className={`expert-info-container container-dark`}>
                <div className='expert-info-container-image-with-list'>
                  <img
                    className='expert-info-image'
                    src='https://assets.vention.io/page-assets/book-meeting/book-meeting-expert.jpg'
                    alt='Talk to an expert'
                  />
                  <div>
                    <h1 className='expert-info-title'>Get in touch</h1>
                    <ul className='expert-info-list'>
                      <li>Explain your equipment requirements</li>
                      <li>Evaluate feasibility and ROI</li>
                      <li>Get design assistance from our specialists</li>
                    </ul>
                  </div>
                </div>
                <ClientList color='white' />
              </div>
            </div>
          ) : (
            <ExpertInfo
              headerText={headerText}
              listItems={listItems}
              background={background}
              expertImageUrl={expertImageUrl}
            />
          )}
        </div>
      </div>
    </I18nProvider>
  )
}

export default BookMeeting
