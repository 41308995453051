import React from "react";
import { FacetList } from "./FacetList";
import { ResultCount } from "./ResultCount";
import { ResultsSection } from "./ResultsSection";
import Pager from "./Pager";
import { Sort } from "./Sort";
import { useNoResults } from "../../hooks/part_library/noResults";
import { NoResults } from "./NoResults";
import { PartCategoryByCode } from "../../../pages/views/part_searches/Types";
import { Banner } from "../../../pages/views/part_searches/search_coveo/Banner";

interface MainSectionProps {
  partCategoryByCode: PartCategoryByCode;
}

export const MainSection: React.FC<MainSectionProps> = ({ partCategoryByCode }) => {
  const noResults = useNoResults()

  if (noResults) {
    return <NoResults />
  }

  return (
    <div className="md:flex gap-5 px-10 py-5">
      <div className="md:w-[216px] max-lg:hidden">
        <FacetList
          partCategoryByCode={partCategoryByCode}
          
        />
      </div>
      <div className="flex-grow">
        <Banner partCategoryByCode={partCategoryByCode} />
        <div className="flex justify-between items-center">
          <div>
            <ResultCount />
          </div>
          <Sort />
        </div>
        <ResultsSection />
        <Pager />
      </div>
    </div>
  )
}