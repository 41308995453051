import React, { useState } from 'react'
import { isEmpty } from '../../helpers/StringHelper'
import UserService from '../../api/Users/Service'
import classnames from 'classnames'

interface Props {
  readonly title?: string | React.ReactElement
  readonly subtitle?: string
  readonly image?: string
  readonly addClass?: string
}

const VentionNews: React.FC<Props> = ({
  title = 'Vention News',
  subtitle = 'Get the latest from Vention',
  image,
  addClass,
}) => {
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState(false)
  const classes = classnames('vention-news', addClass)

  function validateEmail(): boolean {
    const emailInput = document.getElementById('newsletter_subscribe_email') as HTMLInputElement

    // If empty
    if (isEmpty(email)) {
      toastr.error('Email cannot be empty.')
      return false
    }

    // If invalid
    if (!emailInput.checkValidity()) {
      toastr.error(emailInput.validationMessage)
      return false
    }

    return true
  }

  async function submitEmail(): Promise<void> {
    try {
      await UserService.subscribeToMarketingEmails(email)
      setSubscribed(true)
      toastr.success('Thank you for subscribing.')
    } catch (error) {
      toastr.error('There was an error subscribing with the provided email address.')
    }
  }

  function handleClick(): void {
    if (validateEmail()) {
      submitEmail()
    }
  }

  function handleKeyPress(event): void {
    if (event.key === 'Enter' && validateEmail()) {
      submitEmail()
    }
  }

  function handleChange(event): void {
    setEmail(event.target.value)
  }

  return (
    <div id='blog-detail-subscription' className={classes}>
      <div className='vention-news-title'>{title}</div>
      {subtitle && <div className='vention-news-subtitle'>{subtitle}</div>}
      {image && <img className='vention-news-image' src={image} alt='News image' />}
      <div className='vention-news-form'>
        <input
          onKeyPress={handleKeyPress}
          value={email}
          onChange={handleChange}
          disabled={subscribed}
          id='newsletter_subscribe_email'
          className='form-control '
          placeholder='Your Email Address'
          type='email'
        />
        {!subscribed && (
          <button
            id='blogs_subscribe_email_confirm'
            className='form-control vention-news-button'
            onClick={handleClick}
          >
            Subscribe
          </button>
        )}
      </div>
      {subscribed && <p className='bold'>Thank you for subscribing!</p>}
    </div>
  )
}

export default VentionNews
