import React, { useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import LabelValueView from '../../../components/common/display/LabelValueView'
import LabelValueViewContainer from '../../../components/common/display/LabelValueViewContainer'
import round from 'lodash/round'
import DeflectionCalculatorExtrusionOptionCard from './DeflectionCalculatorExtrusionOptionCard'
import I18n from '../../../helpers/I18n'

interface Extrusion {
  height: number
  image: string
  label: string
  footer: string
  value: string
  width: number
}

interface ExtrusionConfiguration {
  description: string
  image: string
  label: string
  value: string
}

interface ExtrusionConfigurationMap {
  [configurationValue: string]: ExtrusionConfiguration[]
}

const RECOMMENDED_SAFETY_FACTOR = 5

const STANDARD_DUTY_EXTRUSIONS: Extrusion[] = [
  {
    height: 45,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-001-XXXX.png',
    label: '45x45mm Extrusion',
    footer: `${I18n.t('views.friction_calculator.bolt_torque')}: 13-15Nm`,
    value: '45x45',
    width: 45,
  },
  {
    height: 45,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-002-XXXX.png',
    label: '45x90mm Extrusion',
    footer: `${I18n.t('views.friction_calculator.bolt_torque')}: 13-15Nm`,
    value: '45x90',
    width: 90,
  },
  {
    height: 90,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-005-XXXX.png',
    label: '90x90mm Extrusion',
    footer: `${I18n.t('views.friction_calculator.bolt_torque')}: 13-15Nm`,
    value: '90x90',
    width: 90,
  },
  {
    height: 22.5,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-009-XXXX.png',
    label: '180x22.5mm Extrusion',
    footer: `${I18n.t('views.friction_calculator.bolt_torque')}: 13-15Nm`,
    value: '22.5x180',
    width: 180,
  },
  {
    height: 247.5,
    image: 'https://ventionblobs.s3.amazonaws.com/parts/png/ST-EXT-011-XXXX.png',
    label: '247.5x247.5mm Extrusion',
    footer: `${I18n.t('views.friction_calculator.bolt_torque')}: 10-13Nm`,
    value: '247.5x247.5',
    width: 247.5,
  },
]

const LIGHT_DUTY_EXTRUSIONS: Extrusion[] = [
  {
    height: 22.5,
    image: 'https://s3.amazonaws.com/ventionblobs/parts/png/ST-EXT-006-XXXX.png',
    label: '22.5x22.5mm Extrusion',
    footer: `${I18n.t('views.friction_calculator.bolt_torque')}: 13-15Nm`,
    value: '22.5x22.5',
    width: 22.5,
  },
  {
    height: 45,
    image:
      'https://ventionblobs.s3.amazonaws.com/page-assets/calculators/light-duty-extrusion-45x45.png',
    label: '45x45mm Extrusion',
    footer: `${I18n.t('views.friction_calculator.bolt_torque')}: 10-13Nm`,
    value: '45x45 light',
    width: 45,
  },
]

interface ExtrusionConstants {
  installationTorqueMin: number
  installationTorqueMax: number
  frictionPerFastener: number
}

interface ExtrusionConstantsMap {
  [key: string]: ExtrusionConstants
}

const EXTRUSION_CONSTANTS: ExtrusionConstantsMap = {
  '22.5x22.5': {
    installationTorqueMin: 10,
    installationTorqueMax: 13,
    frictionPerFastener: 2300,
  },
  '45x45 light': {
    installationTorqueMin: 10,
    installationTorqueMax: 13,
    frictionPerFastener: 1950,
  },
  '45x45': {
    installationTorqueMin: 13,
    installationTorqueMax: 15,
    frictionPerFastener: 2300,
  },
  '45x90': {
    installationTorqueMin: 13,
    installationTorqueMax: 15,
    frictionPerFastener: 2300,
  },
  '90x90': {
    installationTorqueMin: 13,
    installationTorqueMax: 15,
    frictionPerFastener: 2300,
  },
  '22.5x180': {
    installationTorqueMin: 13,
    installationTorqueMax: 15,
    frictionPerFastener: 2300,
  },
  '247.5x247.5': {
    installationTorqueMin: 10,
    installationTorqueMax: 13,
    frictionPerFastener: 1950,
  },
}

function calculateTotalJointCapacity(numberOfBolts, extrusionConstants) {
  return (
    numberOfBolts && extrusionConstants && numberOfBolts * extrusionConstants.frictionPerFastener
  )
}

interface FrictionCalculatorResult {
  safetyFactor: number
  totalJointCapacity: number
  maximumRecommendedLoad: number
  appliedLoad?: number
}

const SafetyFactorMessage = (props: {
  frictionCalculatorResult: FrictionCalculatorResult | undefined
}) => {
  if (props.frictionCalculatorResult === undefined) {
    return null
  }

  let message = I18n.t('views.friction_calculator.results.success_message')
  let status = 'success'
  let icon = <i className='fas fa-check-circle' />

  if (props.frictionCalculatorResult.safetyFactor < 4) {
    message = I18n.t('views.friction_calculator.results.error_message')
    status = 'warning'
    icon = <i className='fas fa-exclamation-triangle warning' />
  } else if (props.frictionCalculatorResult.safetyFactor < 5) {
    message = I18n.t('views.friction_calculator.results.warning_message')
    status = 'caution'
    icon = <i className='fas fa-exclamation-triangle caution' />
  }

  return (
    <>
      <div className='safety-factory-message'>
        {icon}
        <h3 className='results-text'>{I18n.t('views.friction_calculator.results.label')}</h3>
        <div className={`${status}-text message`}>{message}</div>
        <LabelValueViewContainer>
          <LabelValueView
            label={I18n.t('views.friction_calculator.results.total_joint_capacity')}
            value={`${round(props.frictionCalculatorResult.totalJointCapacity, 2)}N`}
          />
          <LabelValueView
            label={I18n.t('views.friction_calculator.results.max_rec_load')}
            value={`${round(props.frictionCalculatorResult.maximumRecommendedLoad, 2)}N`}
          />
          {props.frictionCalculatorResult.appliedLoad && (
            <LabelValueView
              label={I18n.t('views.friction_calculator.results.safety_factor')}
              value={`${round(props.frictionCalculatorResult.safetyFactor, 2)}`}
            />
          )}
        </LabelValueViewContainer>
      </div>
    </>
  )
}

export default function FrictionCalculator() {
  const [frictionCalculatorResult, setFrictionCalculatorResult] = useState<
    FrictionCalculatorResult | undefined
  >()
  const [numberOfBolts, setNumberOfBolts] = useState<number | undefined>()
  const [appliedLoad, setAppliedLoad] = useState<number | undefined>()
  const [extrusionConstants, setExtrusionConstants] = useState<ExtrusionConstants | undefined>()
  const inputsSectionRef = useRef<HTMLDivElement>(null)
  const [extrusionType, setExtrusionType] = useState('')

  const extrusionTypeChangeHandler = value => {
    if (value) {
      const extrusionT = value as string
      setExtrusionType(extrusionT)
      setExtrusionConstants(EXTRUSION_CONSTANTS[extrusionT])
      setFrictionCalculatorResult(undefined)
    }
  }

  const calculationHandler = e => {
    e.preventDefault()

    try {
      const totalJointCapacity = calculateTotalJointCapacity(numberOfBolts, extrusionConstants)

      if (!totalJointCapacity) {
        throw new Error('Calculation error.')
      }

      const safetyFactor = appliedLoad
        ? totalJointCapacity / appliedLoad
        : RECOMMENDED_SAFETY_FACTOR

      const maximumRecommendedLoad = totalJointCapacity / RECOMMENDED_SAFETY_FACTOR

      setFrictionCalculatorResult({
        safetyFactor,
        totalJointCapacity,
        maximumRecommendedLoad,
        appliedLoad,
      })
    } catch (error) {
      console.error(error)
      toastr.error(error.message)
    }
  }

  return (
    <>
      <div>
        <div className='friction-calculator deflector-calculator'>
          <div className='calculator-container'>
            <div className='friction-calculator-header-description'>
              <p>{I18n.t('views.friction_calculator.header_instructions_1')}</p>
              <p>
                <strong>{I18n.t('views.friction_calculator.warning')}:</strong>
              </p>
              <p className='friction-calculator-warning'>
                <span>&bull;</span>{' '}
                <span>{I18n.t('views.friction_calculator.warning_point_1')}</span>
              </p>
              <p className='friction-calculator-warning'>
                <span>&bull;</span>{' '}
                <span>{I18n.t('views.friction_calculator.warning_point_2')}</span>
              </p>
              <p>{I18n.t('views.friction_calculator.header_instructions_2')}</p>
            </div>
            <form onSubmit={calculationHandler}>
              <h3 className='calculator-page-section-title'>
                {I18n.t('views.friction_calculator.light_duty_extrusions')}
              </h3>
              <div className='tools-calculator-options-list'>
                {LIGHT_DUTY_EXTRUSIONS.map(extrusion => (
                  <DeflectionCalculatorExtrusionOptionCard
                    changeHandler={extrusionTypeChangeHandler}
                    imageSrc={extrusion.image}
                    key={`extrusion_${extrusion.value}`}
                    label={extrusion.label}
                    footer={extrusion.footer}
                    value={extrusion.value}
                    extrusionOption={extrusionType}
                  />
                ))}
              </div>
              <h3 className='calculator-page-section-title'>
                {I18n.t('views.friction_calculator.standard_duty_extrusions')}
              </h3>
              <div className='tools-calculator-options-list'>
                {STANDARD_DUTY_EXTRUSIONS.map(extrusion => (
                  <DeflectionCalculatorExtrusionOptionCard
                    changeHandler={extrusionTypeChangeHandler}
                    imageSrc={extrusion.image}
                    key={`extrusion_${extrusion.value}`}
                    label={extrusion.label}
                    footer={extrusion.footer}
                    value={extrusion.value}
                    extrusionOption={extrusionType}
                  />
                ))}
              </div>

              <p>{I18n.t('views.friction_calculator.footer_instructions')}</p>

              <div className='friction-calculator-example'>
                <div>
                  <label>
                    <em>{I18n.t('views.friction_calculator.example.label')}:</em>
                  </label>
                  <p>
                    <em
                      dangerouslySetInnerHTML={{
                        __html: I18n.t('views.friction_calculator.example.text'),
                      }}
                    ></em>
                  </p>
                </div>
                <img
                  className='friction-calculator-example-image'
                  src='https://ventionblobs.s3.amazonaws.com/page-assets/calculators/assembly-plate.png'
                  alt='Assembly Plate'
                />
              </div>

              <div className='deflection-calculator-free-inputs' ref={inputsSectionRef}>
                <div>
                  <h3 className='calculator-page-section-title'>
                    {I18n.t('views.friction_calculator.number_of_bolts')} *
                  </h3>
                  <label>
                    <NumberFormat
                      allowLeadingZeros={false}
                      allowNegative={false}
                      allowEmptyFormatting={true}
                      decimalSeparator={false}
                      onValueChange={values => setNumberOfBolts(values.floatValue)}
                      value={numberOfBolts}
                      className='form-control'
                    />
                  </label>
                </div>
                <div>
                  <h3 className='calculator-page-section-title'>
                    {I18n.t('views.friction_calculator.applied_load')}{' '}
                    <em>({I18n.t('views.friction_calculator.optional')})</em>
                  </h3>
                  <label className='friction-calculator-applied-load-label'>
                    <NumberFormat
                      allowLeadingZeros={false}
                      allowNegative={false}
                      allowEmptyFormatting={true}
                      decimalScale={2}
                      onValueChange={values => setAppliedLoad(values.floatValue)}
                      value={appliedLoad}
                      className='form-control'
                    />
                    <p className='form-control-end-adornment'>N</p>
                  </label>
                </div>
              </div>

              <div className='calculator-controls'>
                <button
                  className='button-success button-lg'
                  type='submit'
                  disabled={!extrusionConstants || !numberOfBolts}
                >
                  {I18n.t('views.friction_calculator.form.submit')}
                </button>
              </div>
            </form>
          </div>
          <div className='safety-factor-container'>
            <SafetyFactorMessage frictionCalculatorResult={frictionCalculatorResult} />
          </div>
        </div>
      </div>
    </>
  )
}
