import React from 'react'

interface ButtonData {
  href: string
  label: string
}

interface Props {
  icon?: React.ReactNode
  title: string
  subtitle: string | React.ReactNode
  buttonData?: ButtonData
  showNeedHelp?: boolean
}

const EmptySection: React.FunctionComponent<Props> = ({
  icon = <i className='fal fa-search' />,
  title,
  subtitle,
  buttonData,
  showNeedHelp,
  children,
}) => {
  return (
    <div className='empty-search'>
      <div className='empty-search-wrapper'>
        {icon}
        <h3 className='empty-search-title'>{title}</h3>
        <p className='empty-search-subtitle'>{subtitle}</p>
        {showNeedHelp && (
          <p className='empty-search-need-help'>
            Need help? Visit our <a href='/resources'>resource center</a> or{' '}
            <a href='/contact'>contact us</a>
          </p>
        )}
        {buttonData && (
          <a className='empty-search-button' href={buttonData.href}>
            {buttonData.label}
          </a>
        )}
      </div>
      <div className='empty-search-footer'>{children}</div>
    </div>
  )
}

export default EmptySection
