import { request } from 'graphql-request'
import { GRAPHQL_PUBLIC_API } from '../../constants/Vention';
import { fetchSimilarPartsQuery } from './queries/SimilarPartQuery';
import { fetchFullPartsByIdsQuery } from './queries/FullPartQuery';
import { RequestPartStatusDTO } from './dtos/PartStatusDTO';
import { FeaturedPartCategoryCollectionDTO, PartCollectionDTO, SimilarPartCollectionDTO } from './Types';
import { fetchFeaturedPartCategoriesQuery } from './queries/FeaturedPartCategoriesQuery';

export type QuerySimilarPartsParams = [ number| undefined, string| undefined, RequestPartStatusDTO[] | undefined, number[] | undefined, boolean| undefined, number| undefined]

export type QueryFullPartsByIdsParams = [ (number | string)[], number| undefined]

export const PartService = {
  querySimilarParts: async (page: number, perPage: number, partId?: number, partNumber?: string, statuses?: RequestPartStatusDTO[], categoryId?: number[], isRobot?: boolean, geographicalRegionId?: number): Promise<SimilarPartCollectionDTO> => {
    if (!partId || !categoryId) {
      return {
        parts: {
          collection: [],
          metadata: {
            currentPage: 0,
            limitValue: 0,
            totalCount: 0,
            totalPages: 0
          },
          errors: []
        },
      };
    }
    return request<SimilarPartCollectionDTO>(
      GRAPHQL_PUBLIC_API,
      fetchSimilarPartsQuery,
      {
        excludePartIds: [partId],
        categoryIds: categoryId,
        ignorePartPrefixesWithPartNumber: partNumber,
        excludeHidden: true,
        status: statuses,
        ignorePartPrefixesBy: ["ST-EXT-", "MO-CV-"],
        isRobot,
        geographicalRegionId,
        page,
        pageSize: perPage,
      }
    )
  },
  queryFullPartsByIds : async(page: number, perPage: number, includePartIds: (number | string)[], geographicalRegionId?: number): Promise<PartCollectionDTO> => {
    return request<PartCollectionDTO>(
      GRAPHQL_PUBLIC_API,
      fetchFullPartsByIdsQuery,
      {
        page,
        pageSize: perPage,
        includePartIds,
        excludeHidden: true,
        geographicalRegionId
      }
    )
  },
  queryFeaturedPartCategories: async (): Promise<FeaturedPartCategoryCollectionDTO> => {
    return request<FeaturedPartCategoryCollectionDTO>(
      GRAPHQL_PUBLIC_API,
      fetchFeaturedPartCategoriesQuery,
      {}
    )
  }
}
