import { gql } from 'graphql-request'

export const fetchFullFeaturedBrandsQuery = gql`
query fetchAllFeaturedBrands() {
  featuredBrands {
    collection {
      name
      order
    }
    errors {
      title,
      message
    }
  }
}
`